import React from 'react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useDragLayer } from 'react-dnd';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
};

const getItemStyles = (initialOffset, currentOffset) => {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const ItemDragLayer = () => {
	const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) {
		return null;
	}

	return (
		<div style={layerStyles}>
			<div style={getItemStyles(initialOffset, currentOffset)}>
				<div className="drag-preview">
					<UnfoldMoreIcon fontSize="large" /> {item.name}
				</div>
			</div>
		</div>
	);
};

export default ItemDragLayer;
