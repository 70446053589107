import React from 'react';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const UploadStatusDisplay = ({ success, filename }) => {
	if (success === null) return null;

	return (
		<div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
			{success ? (
				<CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
			) : (
				<ErrorIcon style={{ color: 'red', marginRight: '8px' }} />
			)}
			<Typography variant="body1">
				{success
					? `Upload successful: ${filename}`
					: `Upload failed: ${filename}`}
			</Typography>
		</div>
	);
};

export default UploadStatusDisplay;
