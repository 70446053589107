import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Typography,
	Stack
} from '@mui/material';
import {
	SecurityButton
} from '../../ui';
import { ProjectContext } from '../../../contexts';
import ProjectsTableComponent from './ProjectsTableComponent';
import ProjectEditPage from './ProjectEditPage';
import ProjectCreatePage from './ProjectCreatePage';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const ProjectsPage = (props) => {
	const [busy, setBusy] = useState(false);
	const [edit, setEdit] = useState(false);
	const [create, setCreate] = useState(false);
	const [projects, setProjects] = useState([]);
	const [projectId, setProjectId] = useState(null);

	const { loadProjectsBySite } = useContext(ProjectContext);

	const getApis = useCallback(async () => {
		setBusy(true);

		const responses = await Promise.all([
			loadProjectsBySite()
		]);
		const projectsData = await responses[0];
		setProjects(projectsData);

		setBusy(false);
	}, [loadProjectsBySite])

	useEffect(() => { getApis() }, [getApis]);

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Project management</Typography>
				{busy && <CircularProgress />}
				{!busy && !edit && !create &&
					<>
						<ProjectsTableComponent
							projects={projects}
							setEdit={setEdit}
							getApis={getApis}
							setProjectId={setProjectId}
						/>
						<Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
							<SecurityButton
								featureName={SECURITY_FEATURES.PROJECT_PAGE_CREATE}
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setCreate(true)}>
								Create
							</SecurityButton>
						</Stack>
					</>
				}
				{edit &&
					<ProjectEditPage setEdit={setEdit} getApis={getApis} projectId={projectId} />
				}
				{create &&
					<ProjectCreatePage setCreate={setCreate} getApis={getApis} />
				}
			</Stack>
		</Box>
	)
}
export default ProjectsPage;