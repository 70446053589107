import React from 'react';
import {
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	SelectDropdown,
	FilterFormComponent,
	ArrayStringTextArea
} from '../../ui';
import {
	AttrConfigFormFilterDict
} from '../../../constants/LOIConstants';

const matchModeList = [
	{
		id: 1,
		name: "Contains"
	},
	{
		id: 2,
		name: "Starts With"
	},
	{
		id: 3,
		name: "Ends With"
	},
	{
		id: 4,
		name: "Exact"
	}
];

function IncludeExcludeConfigurator({ id, label, validationType, selectedTypeId, matchModeValue, textValue, onChange }) {
	return (
		<Grid item xs={12} sm={5} container>
			<Typography variant="h6">{label}</Typography>
			<FilterFormComponent
				selectedId={selectedTypeId}
				allowedIds={AttrConfigFormFilterDict[`${validationType}${id}`]}
				labelOnNonAllowed={"N/A"}
			>
				<Stack spacing={1} style={{ overflow: 'auto', paddingTop: '10px' }}>
					<SelectDropdown
						id={`matchMode-${id}`}
						label="Match Mode"
						value={matchModeValue}
						options={matchModeList} // Assume this is a prop or imported
						onChange={(value) => onChange(`${id}Format`, value)} // Assume this function is passed as a prop or defined
						fullWidth
						setToDefault={false}
					/>
					<ArrayStringTextArea
						variant="outlined"
						value={textValue}
						onChange={(value) => onChange(id, value)}
						minRows={4}
						placeholder={`Enter ${label}`}
					/>
				</Stack>
			</FilterFormComponent>
		</Grid>
	);
}

export default IncludeExcludeConfigurator;