import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@mui/material';
import NextBtn from '../../ui/NextBtn';
import { searchUsers } from '../../../api/AdminApi';
import { isValidEmail } from '../../../services/Validation';
import { SingleSelectUserTable } from '../../ui/Tables';
import { transformSearchData } from './UserEmailHelpers';

const NewSingleUserEmailInput = ({ onInputEmails, onComplete, allowUserSelection }) => {
	const [email, setEmail] = useState('');
	const [isValid, setIsValid] = useState(true);

	const validateEmail = (email) => {
		return isValidEmail(email);
	};

	const handleChange = (e) => {
		setEmail(e.target.value);
		setIsValid(validateEmail(e.target.value));
	};

	const handleNext = async () => {
		if (!isValid) return;

		// Convert email to user object
		let userResult = await searchUsers([email]);
		const confirmUser = transformSearchData(userResult);
		onInputEmails(confirmUser);
		onComplete();
	};

	const handleUserClick = (user) => {
		if (!validateEmail(user.email)) return;

		setEmail(user.email);
		setIsValid(true);
	};

	return (
		<div>
			<Typography variant="h6">Enter the email address of the new user</Typography>
			<Typography variant="body1" color="textSecondary">
				Please enter a valid email address.
			</Typography>
			{allowUserSelection && (
				<Typography variant="body1" color="textSecondary">
					or click on the existing User from the list below to add, then press OK.
				</Typography>
			)}
			<TextField
				fullWidth
				placeholder="Enter email address here..."
				value={email}
				onChange={handleChange}
				error={!isValid}
				helperText={!isValid && "Invalid email format."}
				style={{ marginBottom: '10px' }}
			/>
			{allowUserSelection && <SingleSelectUserTable onUserClick={handleUserClick} />}
			<NextBtn disabled={!email.trim() || !isValid} onClick={handleNext} />
		</div>
	);
};

NewSingleUserEmailInput.propTypes = {
	onInputEmails: PropTypes.func.isRequired,
	onComplete: PropTypes.func
};

export default NewSingleUserEmailInput;
