import React, { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Typography
} from '@mui/material';
import { BasicTxtField, SearchButton } from '../../ui';
import { SingleSelectCompanyTable } from '../../ui/Tables';
import { UserContext } from '../../../contexts';

// onConfirmAddUsers - Callback used to pass all confirmed users to calling component
function CompanySearchModal({
	onConfirmCompany,
	open,
	onClose
}) {
	const { searchCompanyByName } = useContext(UserContext);
	const [companySearch, setCompanySearch] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [searchResult, setSearchResult] = useState('');

	const handleChange = (companyName) => {
		setCompanySearch(companyName);
	}

	const handleSearchClick = async () => {
		setIsSearching(true);
		let search = await searchCompanyByName(companySearch);
		setIsSearching(false);
		if (search.length === 0) {
			setSearchResult("No match found!");
			return;
		}

		setSearchResult("Match Found - Selected below:");
		setCompanyId(search[0].id);
	}

	const handleCompanyClick = (companyId) => {
		setCompanyId(companyId);
	};

	const handleConfirmCompany = () => {
		onClose();
		onConfirmCompany(companyId);
	}

	const canUserConfirm = companyId !== '';

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle variant={"h5"}>
				Set Contractor
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant="h6">Enter the company name to search for</Typography>
						<Typography variant="body1" color="textSecondary">
							or click on an existing company from the list below to add, then press Confirm.
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<BasicTxtField
							fullWidth
							id="companySearch"
							placeholder="Enter company name here..."
							value={companySearch}
							onChange={handleChange}
							style={{ marginBottom: '10px' }}
						/>
					</Grid>
					<Grid item xs={3}>
						<SearchButton
							isSearching={isSearching}
							onClick={handleSearchClick}
						/>
					</Grid>
				</Grid>
				<Typography variant={"body2"} style={{ color: 'red' }}>
					{searchResult}
				</Typography>
				<SingleSelectCompanyTable
					onChange={handleCompanyClick}
					selectedId={companyId}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={onClose}
					color="error">
					Cancel
				</Button>
				<Button
					variant="contained"
					color={"success"}
					disabled={!canUserConfirm}
					onClick={handleConfirmCompany}
					autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CompanySearchModal;