import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Typography,
	Stack
} from '@mui/material';
import {
	SecurityButton
} from '../../ui';
import CloseIcon from '@mui/icons-material/Close';
import { WorkflowsContext } from '../../../contexts';
import { WorkflowStepEditModal } from '../../modals';
import { ApiStatusDisplay } from '../../ui/';
import WorkflowTableComponent from './WorkflowTableComponent';
import WorkflowEditPage from './WorkflowEditPage';
import WorkflowStepCreatePage from './WorkflowStepCreatePage';
import WorkflowStepEditPage from './WorkflowStepEditPage';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const WorkflowPage = ({ setView, workflowId }) => {
	const [busy, setBusy] = useState(false);
	const [workflow, setWorkflow] = useState({});
	const [steps, setSteps] = useState([]);
	const [editStep, setEditStep] = useState(false);
	const [stepId, setStepId] = useState(null);
	const [editWorkflow, setEditWorkflow] = useState(false);
	const [createStep, setCreateStep] = useState(false);

	const { loadWorkflowById } = useContext(WorkflowsContext);

	const getapis = useCallback(async () => {
		setBusy(true);

		const responses = await Promise.all([
			loadWorkflowById(workflowId)
		]);
		const workflowData = await responses[0];
		setWorkflow(workflowData);
		setSteps(workflowData.steps);

		setBusy(false);
	}, [loadWorkflowById, workflowId])

	useEffect(() => { getapis() }, [getapis]);

	const handleSaveStep = (editedStep) => {
		// TODO: This should really use editedStep
		// and update it in memory rather than reloading all data again.
		getapis();
	}

	// useEffect(() => {
	// 	if (editWorkflow || createStep || deleteStep) return;
	// 	setBusy(true);

	// 	async function getapis() {
	// 		const responses = await Promise.all([
	// 			loadWorkflowById(workflowId)
	// 		]);
	// 		const workflowData = await responses[0];
	// 		setWorkflow(workflowData);
	// 		setSteps(workflowData.steps);
	// 		setBusy(false);
	// 	}

	// 	getapis();
	// }, [
	// 	loadWorkflowById,
	// 	workflowId,
	// 	editWorkflow,
	// 	createStep,
	// 	deleteStep
	// ]);

	return (
		<Box>
			<Stack spacing={2}>
				<ApiStatusDisplay />
				{busy && <CircularProgress />}
				{!busy && !editWorkflow && !createStep &&
					<>
						<Button
							variant="outlined"
							size="small"
							sx={{ display: 'flex', alignSelf: 'flex-start' }}
							startIcon={<CloseIcon />}
							onClick={() => setView(false) }>
							{workflow.title}
						</Button>
						<WorkflowTableComponent
							steps={steps}
							setEditStep={setEditStep}
							setStepId={setStepId}
							getapis={getapis}
						/>
						<Stack direction='row' spacing={2} sx={{ justifyContent: 'flex-end' }}>
							<SecurityButton
								featureName={SECURITY_FEATURES.WORKFLOW_PAGE}
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setEditWorkflow(true)}>
								Edit workflow
							</SecurityButton>
							<SecurityButton
								featureName="workflowstep_create_delete"
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setCreateStep(true)}>
								Add step
							</SecurityButton>
						</Stack>
					</>
				}
				{editWorkflow &&
					<WorkflowEditPage
						workflowData={workflow}
						setEditWorkflow={setEditWorkflow}
						getapis={getapis}
					/>
				}
				{createStep &&
					<WorkflowStepCreatePage
						workflowData={workflow}
						setCreateStep={setCreateStep}
						getapis={getapis}
					/>
				}
				{editStep &&
					<WorkflowStepEditModal
						open={editStep}
						onClose={() => setEditStep(false)}
						onSave={handleSaveStep}
						workflowId={workflow?.id}
						stepData={steps.find(elem => elem.id === stepId)}
					/>
				}
			</Stack>
		</Box>
	)
}
export default WorkflowPage;