import React, { useEffect, useRef } from 'react';
import { ListItem } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from "react-dnd-html5-backend";

function shouldMoveItem(draggingDown, hoverBoundingRect, clientOffset) {
	// Get vertical middle
	const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

	// Get pixels to the top
	const hoverClientY = clientOffset.y - hoverBoundingRect.top;

	// Check the dragging direction
	if (draggingDown) {
		// Dragging downwards, only move when the cursor is below 50%
		if (hoverClientY < hoverMiddleY) {
			return false;
		}
	} else {
		// Dragging upwards, only move when the cursor is above 50%
		if (hoverClientY > hoverMiddleY) {
			return false;
		}
	}

	// Return true if the item should move
	return true;
}


// Draggable component
export const DraggableItem = ({ listItem, index, moveItem, listType, itemType }) => {
	const ref = useRef(null);
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: itemType,
		item: () => {
			return { index, id: listItem.id, name: listItem.name, dragListType: listType };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(), // Correct usage to track dragging state
		}),
	});
	const [{ isOver, isDraggingCurrentItem }, drop] = useDrop({
		accept: itemType,
		collect: (monitor) => {
			const draggedItem = monitor.getItem(); // Get the currently dragged item
			return {
				isOver: monitor.isOver(),
				isDraggingCurrentItem: draggedItem ? draggedItem.id === listItem.id : false, // Compare current item ID with dragged item ID
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}

			const dragIndex = item.index;
			const hoverIndex = index;
			const sameType = item.dragListType === listType;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex && sameType) {
				return
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			const isDraggingDown = dragIndex < hoverIndex;

			if (!sameType || shouldMoveItem(isDraggingDown, hoverBoundingRect, clientOffset)) {
				// Time to actually perform the action
				moveItem(dragIndex, hoverIndex, item.dragListType, listType)
				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				item.index = hoverIndex;
				item.dragListType = listType;
			}
		}
	});

	useEffect(() => {
		dragPreview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		const handleScrollWhileDragging = (e) => {
			if (isDragging && ref.current) {
				// Adjust scroll position based on wheel movement
				const container = ref.current.closest('.scrollable-container'); // Adjust selector as needed
				if (container) {
					container.scrollTop += e.deltaY;
					container.scrollLeft += e.deltaX;
				}
			}
		};

		// Add the event listener to enable scroll during drag
		window.addEventListener('wheel', handleScrollWhileDragging);

		// Clean up the event listener
		return () => {
			window.removeEventListener('wheel', handleScrollWhileDragging);
		};
	}, [isDragging]);

	drag(drop(ref));

	return (
		<ListItem
			ref={ref}
			sx={{
				marginBottom: 1,
				border: isDraggingCurrentItem ? '2px dashed #007BFF' : '1px solid #ccc', // Blue dashed border when hovering
				borderRadius: 1,
				padding: 0,
				backgroundColor: isDraggingCurrentItem ? '#E3F2FD' : 'white', // Blueish background when hovering
				color: isDraggingCurrentItem ? 'transparent' : 'black', // Text becomes invisible when hovering
				transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition between states
				cursor: 'move',
				fontSize: '10px'
			}}
		>
			<UnfoldMoreIcon fontSize={"small"}/> {listItem.name}
		</ListItem>
	);
};