import React from 'react';
import { Avatar, Badge } from '@mui/material';

const ResponsibleUserAvatar = React.forwardRef(function BadgeAvatar({ children, ...props }, ref) {
	return (
		<Badge
			overlap="circular"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			color="primary"
		>
			<Avatar ref={ref} {...props} sx={{ width: 30, height: 30, backgroundColor: '#1565C0', fontSize: '0.9rem' }}>
				{children}
			</Avatar>
		</Badge>
	);
});
export default ResponsibleUserAvatar;
