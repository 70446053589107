import { useState } from 'react';

const useFetchData = (apiFunction) => {
	const [loadingData, setLoadingData] = useState(false);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const fetchData = async (req) => {
		setLoadingData(true);
		try {
			const response = await apiFunction(req);
			setData(response);
		} catch (error) {
			setError(error);
		} finally {
			setLoadingData(false);
		}
	};

	return { loadingData, data, error, fetchData };
};

export default useFetchData;