import React, { useState, useContext, useEffect } from 'react';
import {
	Button,
	Divider,
	Typography,
	Stack,
} from '@mui/material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import {
	BasicTxtField,
	CustomCKEditor,
	SBCheckBoxField,
	SelectDropdown,
} from '../ui';
import { HelpContext, WorkflowsContext } from '../../contexts';
import * as Validation from '../../services/Validation';
import { LoadingModal } from './';

const formValidators = {
	title: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	description: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	durationDays: [Validation.validateIsNumber],
	position: [Validation.validateIsNumber],
	weighting: [Validation.validateIsNumber]
}

const initialValue = {
	actionTemplate: -1,
};

const helper_texts = {
	"generateStepAction": "Setting the flag 'Generate workflow step action' will automatically set an action on the responsible user when the section moves into the workflow step",
	"notificationTitle": "Action subject must be populated with the title that is required to be subject of the action. Choose from the 'Action templates' drop down for pre-formatted options",
	"notificationMessage": "Action message must be populated with the message that is required to be displayed when the action is generated. Choose from the 'Action templates' drop down for pre-formatted options",
	"durationDays": "The number of business days allocated for this step",
	"colour": "Allows the workflow step to be set to a given colour",
	"isCommenting": "Setting the flag 'Commenting' allows comments to be made when a section enters into this workflow step",
	"isMilestone": "Setting the flag 'Displayed' includes the workflow step in the document dashboard",
	"position": "This value sets the order of the workflow steps. The first workflow step will be the lowest number",
	"isReportedProcess": "Setting the flag 'Reported process' includes this workflow step as part of the Process completed report",
	"isReportedContent": "Setting the flag 'Reported content' includes this workflow step as part of the Content completed report",
	"isAutomatedStepChange": "Setting the flag 'Automated step change' automatically moves the section to the next Workflow step once all of the reviews have been completed. Automated Step Change only applies to workflow steps that have Commenting enabled",
	"forecastCompletionStartStep": "Setting the flag 'Forecast completion start step' dictates the first step to be included in the system forecast report. This can only be set for 1 step and will use the last step that has been set as the start point",
	"forecastCompletionReportEnabled": "Setting the flag 'Forecast completion report enabled' will display the workflow step in the System Forecast report",
	"weighting": "Range to be from 1 to 999"
};

const helpTitles = [
	'generateStepAction',
	'workflowStepActionSubject',
	'workflowStepActionMessage',
	'workflowStepDuration',
	'workflowStepColour',
	'workflowstepIsCommenting',
	'workflowStepDisplayed',
	'workflowStepOrder',
	'isReportedProcess',
	'isReportedContent',
	'isAutomatedStepChange',
	'forecastCompletionStartStep',
	'forecastCompletionReportEnabled',
	'forceGuidance',
	'stepWeighting'
];

const WorkflowStepEditModal = ({ open, onClose, onSave, workflowId, stepData }) => {
	const [busy, setBusy] = useState(false);
	const [step, setStep] = useState({ ...initialValue, ...stepData });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	const {
		actionTemplates,
		stepChangeOptions,
		updateWorkflowStep,
		loadActionTemplates,
		loadStepChangeOptions
	} = useContext(WorkflowsContext);
	const { helpTexts, loadHelpByTitles } = useContext(HelpContext);

	useEffect(() => {
		setStep({ ...initialValue, ...stepData });
		resetFormErrors();
	}, [stepData, resetFormErrors]);

	useEffect(() => {
		console.log('edited step', step);
	}, [step])

	useEffect(() => {
		setBusy(true);

		async function loadReqData() {
			await Promise.all([
				loadActionTemplates(),
				loadStepChangeOptions(),
				loadHelpByTitles(helpTitles),
			]);
			setBusy(false);
		}

		loadReqData();
	}, [loadActionTemplates, loadStepChangeOptions, loadHelpByTitles]);

	const handleDataChange = (newValue, propertyName) => {
		if (propertyName === 'isAutomatedStepChange' && newValue === false) {
			setStep(prevData => ({
				...prevData,
				automatedStepChangeOption: 0,
			}));
		}

		setStep(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSelectActionTemplate = (action) => {
		setStep(prevData => ({
			...prevData,
			actionTemplate: action,
			notificationTitle: actionTemplates[action].subject,
			notificationMessage: actionTemplates[action].message
		}));
	}

	const handleSubmit = async () => {
		if (!isFormValid(step)) return;

		onClose();
		step.workFlowId = workflowId;
		const newStepData = await updateWorkflowStep(step);

		onSave(newStepData);
	};

	if (!open)
		return null;

	if (busy) {
		return <LoadingModal open={busy && open} onClose={onClose} title={"Loading Workflow Step"} />
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h4">
				Edit Workflow Step
			</DialogTitle>
			<DialogContent>
				<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
					<Stack direction='row' justifyContent="space-between" alignItems="flex-end">
						<Typography variant="body1">Edit workflow step</Typography>
						<Typography variant="body1">* Required field</Typography>
					</Stack>
					<BasicTxtField
						id="title"
						value={step.title}
						onChange={handleDataChange}
						errors={formErrors['title']}
						required
						fullWidth
					/>
					<BasicTxtField
						id="description"
						value={step.description}
						onChange={handleDataChange}
						errors={formErrors['description']}
						required
						fullWidth
					/>
					<Divider />
					<Typography variant="body1">Action configuration</Typography>
					<SBCheckBoxField
						id="generateStepAction"
						label="Generate workflow action step"
						checked={step.generateStepAction}
						onChange={handleDataChange}
						helperText={helpTexts["generateStepAction"]}
					/>
					<SelectDropdown
						id="actionTemplate"
						label="Action Template"
						value={step.actionTemplate}
						onChange={handleSelectActionTemplate}
						options={actionTemplates}
						fullWidth
					/>
					<BasicTxtField
						id="notificationTitle"
						label="Action subject"
						value={step.notificationTitle || ''}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepActionSubject"]}
						fullWidth
					/>
					<CustomCKEditor
						id="notificationMessage"
						data={step.notificationMessage || ''}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepActionMessage"]}
					/>
					<Divider />
					<Typography variant="body1">Setup configuration</Typography>
					<BasicTxtField
						id="durationDays"
						label="Duration days"
						value={step.durationDays}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepDuration"]}
						errors={formErrors['durationDays']}
						required
						fullWidth
					/>
					<BasicTxtField
						id="colour"
						label="Colour"
						value={step.colour}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepColour"]}
						type="color"
						fullWidth
					/>
					<SBCheckBoxField
						id="isCommenting"
						label="Is commenting"
						checked={step.isCommenting}
						onChange={handleDataChange}
						helperText={helpTexts["workflowstepIsCommenting"]}
					/>
					<SBCheckBoxField
						id="isMilestone"
						label="Is displayed"
						checked={step.isMilestone}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepDisplayed"]}
					/>
					<BasicTxtField
						id="position"
						label="Order"
						value={step.position}
						onChange={handleDataChange}
						helperText={helpTexts["workflowStepOrder"]}
						errors={formErrors['position']}
						type="number"
						required
						fullWidth
					/>
					<SBCheckBoxField
						id="isReportedProcess"
						label="Report process"
						checked={step.isReportedProcess}
						onChange={handleDataChange}
						helperText={helpTexts["isReportedProcess"]}
					/>
					<SBCheckBoxField
						id="isReportedContent"
						label="Report content"
						checked={step.isReportedContent}
						onChange={handleDataChange}
						helperText={helpTexts["isReportedContent"]}
					/>
					<SBCheckBoxField
						id="isAutomatedStepChange"
						label="Is automated step change"
						checked={step.isAutomatedStepChange}
						onChange={handleDataChange}
						helperText={helpTexts["isAutomatedStepChange"]}
					/>
					{step.isAutomatedStepChange && <SelectDropdown
						id="automatedStepChangeOption"
						label="Automated step change on..."
						value={step.automatedStepChangeOption || 0}
						onChange={handleDataChange}
						options={stepChangeOptions}
						fullWidth
					/>}
					<SBCheckBoxField
						id="forecastCompletionStartStep"
						label="Forecast completion start step"
						checked={step.forecastCompletionStartStep}
						onChange={handleDataChange}
						helperText={helpTexts["forecastCompletionStartStep"]}
					/>
					<SBCheckBoxField
						id="forecastCompletionReportEnabled"
						label="Forecast completion report enabled"
						checked={step.forecastCompletionReportEnabled}
						onChange={handleDataChange}
						helperText={helpTexts["forecastCompletionReportEnabled"]}
					/>
					<SBCheckBoxField
						id="forceShowGuidance"
						label="Force guidance to be displayed"
						checked={step.forceShowGuidance}
						onChange={handleDataChange}
						helperText={helpTexts["forceGuidance"]}
					/>
					<BasicTxtField
						id="weighting"
						value={step.weighting}
						onChange={handleDataChange}
						helperText={helpTexts["weighting"]}
						type="number"
						errors={formErrors['stepWeighting']}
						required
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleSubmit}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
export default WorkflowStepEditModal;