import {
	Divider,
	Grid
} from '@mui/material';

const GridDivider = () => (
	<Grid item xs={12}>
		<Divider />
	</Grid>
);

export default GridDivider;