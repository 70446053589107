import React from 'react';
import { ListItem } from '@mui/material';
import { useDrop } from 'react-dnd';

export const DroppableItem = ({ onDropItem, index, itemType, listType }) => {
	const [, drop] = useDrop({
		accept: itemType,
		drop(item, monitor) {
			// Handle item drop
			onDropItem(item.index, index, item.dragListType, listType);
		},
	});

	return (
		<ListItem
			ref={drop}
			sx={{
				minHeight: 50, // Adjust the size of the droppable area
				backgroundColor: '#f0f0f0',
				border: '1px dashed #ccc',
				marginBottom: 1,
				padding: 1,
				textAlign: 'center',
			}}
		>
			Drop item here
		</ListItem>
	);
};