import React, { useState, useContext } from 'react';
import {
	Box,
	Button,
	Grid,
	Typography,
	Tabs,
	Tab,
} from '@mui/material';
import {
	SelectDropdown,
	CustomTabPanel,
} from '../ui';
import { AgGridUsersRolesView, AgGridUsersReportView } from '../DataGridContainers';
import DataLoadHandler from '../containers/DataLoadHandler';
import LoadingPage from './LoadingPage';
import { ProjectContext } from '../../contexts/';

const UsersPage = (props) => {
	const [selectedProjects, setSelectedProjects] = useState([]);
	const [currentView, setCurrentView] = useState(0);
	const { projects, dataLoaded, loadedDocTeamsProjectIds, loadDocumentsTeamsByProjectId } = useContext(ProjectContext);

	const handleOpenUserManage = () => {
		props.navigateTo("UserSiteManage");
	};

	const handleProjectChange = async (selected) => {
		setSelectedProjects([...selected]);
	};

	const handleTabChange = (event, newValue) => {
		console.log("TAB:", newValue);
		setCurrentView(newValue);
	};

	if (!dataLoaded) {
		return <LoadingPage />;
	}

	let displayGrid = selectedProjects.length > 0;

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h4">Users</Typography>
					<Typography variant="body1" color="textSecondary">
						Add and edit users for an individual project a team at a time.
					</Typography>
				</Grid>
				<Grid item xs={0} md={6}></Grid>
				<Grid item xs={12}>
					<Typography variant="h6">1. Select a project or multiple project</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectDropdown
						multiple
						id={"projects"}
						value={selectedProjects}
						onChange={handleProjectChange}
						options={projects}
						fullWidth
					/>
				</Grid>
				<Grid item xs={0} md={6}></Grid>
				<Grid item xs={6}>
					<Tabs value={currentView} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
						<Tab label="Roles View" value={0} />
						<Tab label="Report View" value={1} />
					</Tabs>
				</Grid>
				<Grid item xs={6}>
					<Box display="flex" justifyContent="flex-end">
						<Button variant="contained" onClick={handleOpenUserManage}>
							+ Manage Users
						</Button>
					</Box>
				</Grid>
			</Grid>
			<br />
			{/* Used to load any document team members that are selected */}
			<DataLoadHandler
				loadedIds={loadedDocTeamsProjectIds}
				idsToLoad={selectedProjects}
				loadFunction={loadDocumentsTeamsByProjectId}
			>
				{displayGrid && (
					<>
						<CustomTabPanel value={currentView} index={0}>
							<AgGridUsersRolesView
								selectedProjects={selectedProjects}
							/>
						</CustomTabPanel>
						<CustomTabPanel value={currentView} index={1}>
							<AgGridUsersReportView
								selectedProjects={selectedProjects}
							/>
						</CustomTabPanel>
					</>
				)}
			</DataLoadHandler>
		</div>
	);
}

export default UsersPage;