import React, { forwardRef, useEffect, useState } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
//import './customAgGrid.css';

const AgGridBaseStyle = forwardRef(({
	columnDefs,
	rowData,
	gridOptions,
	nonAllowedHeight = '335px',
	...props
}, ref) => {
	const [columnDefsProtected] = useState(columnDefs);

	useEffect(() => {
		if (ref) {
			console.log("Updating grid options", ref);
			ref.current?.api?.updateGridOptions(gridOptions);
		}
	}, [gridOptions, ref]);

	useEffect(() => {
		if (ref) {
			console.log("Setting new columns");
			// ref.current?.api?.setColumnDefs(columnDefsProtected); Uncomment and use if needed
			ref.current?.api?.refreshHeader();
		}
	}, [columnDefs, ref]);

	//console.log("ROW DATA:", rowData);
	//console.log("COLUMNS:", columnDefsProtected);
	console.log("COLUMN DEFS:", columnDefs);

	return (
		<div className="ag-theme-alpine" style={{ height: `calc(100vh - ${nonAllowedHeight})`, width: '100%' }}>
			<AgGridReact
				ref={ref} // Pass the forwarded ref to AgGridReact
				columnDefs={columnDefs}
				rowData={rowData}
				gridOptions={gridOptions}
				animateRows
				{...props}
			>
			</AgGridReact>
		</div>
	);
});

export default AgGridBaseStyle;