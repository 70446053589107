import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Grid,
} from '@mui/material';
import AddLOIRow from './AddLOIRow';
import { LOIContext } from '../../../contexts/';

const headerStyle = {
	fontWeight: 'bold',
	fontSize: '16px',
	color: '#333333', // or any color of your choice
	textAlign: 'left',
	textTransform: 'uppercase',
	padding: '5px 0'
};

function BulkAddLOIModal({ initialData, open, onClose, selectedLOI }) {
	const [bulkData, setBulkData] = useState(initialData);
	const { updateLOIData } = useContext(LOIContext);

	useEffect(() => {
		setBulkData(initialData);
	}, [initialData]);

	const handleDataChange = (classification, attributeId, propertyName, newValue) => {
		const updatedData = bulkData.map(item => {
			if (item.classification === classification && item.attributeId === attributeId) {
				return { ...item, [propertyName]: newValue };
			}
			return item;
		});
		setBulkData(updatedData);
	};

	const handleSaveClick = () => {
		updateLOIData(bulkData, selectedLOI);
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xl"
		>
			<DialogTitle variant="h4">
				Bulk Add/Edit LOI Requirement
				<Grid container
					style={{ backgroundColor: '#f2f2f2' }}
				>
					<Grid item xs={3} style={headerStyle}>
						Common Name
					</Grid>
					<Grid item xs={3} style={headerStyle}>
						Attribute
					</Grid>
					<Grid item xs={1} style={headerStyle}>
						Enabled
					</Grid>
					<Grid item xs={3} style={headerStyle}>
						RIBA Stage
					</Grid>
					<Grid item xs={1} style={headerStyle}>
						Displayed
					</Grid>
					<Grid item xs={1} style={headerStyle}>
						Required
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<br />
				<Divider />
				<Grid container spacing={2}>
					{bulkData.map((cell, index) => {
						return (
							<Grid
								key={index}
								item
								container
								style={{ backgroundColor: index % 2 === 1 ? '#f2f2f2' : '#ffffff' }}
							>
								<AddLOIRow {...cell} onDataChange={handleDataChange} />
							</Grid>
						)
					})}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSaveClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Update
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default BulkAddLOIModal;