import { springboardApiRequest, springboardUploadFileRequest } from './SpringboardApiHandler';
import { chunkArray } from '../helpers/GeneralHelpers';
import { getSiteId } from './configManager';
import endpoints from './endpoints';
import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './Admin/devApi';

// Api for:
// - Admins
// - User role changes
// - Golden Thread
// - Help

export async function uploadFile(fileInfo, progressCallback) {
	try {
		let fileUploadInfo = await springboardUploadFileRequest(endpoints.file.upload(), fileInfo, progressCallback);
		console.log("Upload result:", fileUploadInfo);
		return fileUploadInfo;
	}
	catch (error) {
		// Handle error here
		console.log("ERROR ON FILE LOAD");
		console.error('Error while uploading data file', error);
		throw new Error('Failed to upload data file');
	}
}

export async function processDataMaskFile(dataMaskOptions) {
	try {
		const processEndpoint = endpoints.file.processDataMaskLookupFile();
		let result = await springboardApiRequest(processEndpoint, "POST", dataMaskOptions);
		console.log("Process result:", result);
	}
	catch (error) {
		// Handle error here
		console.log("ERROR ON DATA MASK PROCESS REQUEST");
		console.error('Error while processing data mask file', error);
		throw new Error('Failed to process data mask file');
	}
}

// Admins //

export async function loadAdminsForSite(siteId) {
	try {
		const getAdminsBySiteReq = endpoints.admins.getAdminsBySiteId(siteId);
		const data = await springboardApiRequest(getAdminsBySiteReq, "GET");
		downloadJsonData(data.data, "loadAdminsForSite");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching admins by site data:', error);
		throw new Error('Failed to fetch admins by site data.');
	}
}

export async function createAdmins(admins) {
	try {
		const data = await springboardApiRequest(endpoints.admins.create(), "POST", admins);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating admins data:', error);
		throw new Error('Failed to create admins data.');
	}
}

export async function deleteAdmins(admins) {
	try {
		const data = await springboardApiRequest(endpoints.admins.delete(), "POST", admins);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting admins data:', error);
		throw new Error('Failed to delete admins data.');
	}
}

export async function swapMaster(userId) {
	try {
		const data = await springboardApiRequest(endpoints.admins.swapMaster(), "POST", userId);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while swapping master admin data:', error);
		throw new Error('Failed to swap master admin data.');
	}
}

// User Role Page:
export async function loadUserRoleChanges(requestOptions) {
	try {
		const siteId = getSiteId();
		const updatedRequestOptions = { ...requestOptions, siteId };
		const data = await springboardApiRequest(endpoints.reporting.getUserRoleChanges(updatedRequestOptions), "GET");
		downloadJsonData(data.data, "loadUserRoleChanges");
		return data.data;
	} catch (error) {
		console.error('Error while loading user role changes data:', error);
		throw new Error('Failed loading user role changes data.');
	}
}

// Golden Thread Page:
export async function loadTransactionLogs(requestOptions) {
	try {
		const siteId = getSiteId();
		const updatedRequestOptions = { ...requestOptions, siteId };
		const data = await springboardApiRequest(endpoints.goldenThread.getLogs(updatedRequestOptions), "GET");
		downloadJsonData(data.data, "loadTransactionLogs");
		return data.data;
	} catch (error) {
		console.error('Error while loading transaction log data:', error);
		throw new Error('Failed loading transaction log data.');
	}
}

// Help
export async function loadHelpByTitle(title) {
	try {
		const loadHelpByTitleEndpoint = endpoints.help.byTitle(title);
		const data = await springboardApiRequest(loadHelpByTitleEndpoint, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching help by title data:', error);
		throw new Error('Failed to fetch help by title data.');
	}
}

export async function loadHelpByTitles(titles) {
	try {
		const helpByTitlesEndpoint = endpoints.help.byTitles();
		// Split help request into chunks of 20 titles
		const titleChunks = chunkArray(titles, 20);
		// Create an perform requests based on title chunks
		const requests = titleChunks.map(chunk => {
			const helpEndpoint = createHelpEndpointWithTitles(helpByTitlesEndpoint, chunk);
			return springboardApiRequest(helpEndpoint, "GET");
		});
		// Execute requests in parallel
		const responses = await Promise.all(requests);
		console.log("HELP PREFLATTEN:", responses);
		// Extract 'data' property from each response and flatten
		const allHelpData = responses.map(response => response.data).flat();
		return allHelpData;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching form data:', error);
		throw new Error('Failed to fetch form data.');
	}
}

const createHelpEndpointWithTitles = (baseEndpoint, titles) => {
	const params = titles.map(title => `titles=${title}`).join('&');
	return `${baseEndpoint}?${params}`;
}

export * from './Admin/';