import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Divider,
	Grid,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import {
	BasicTxtField,
	SelectDropdown,
} from '../../ui';
import { SiteDataContext } from '../../../contexts';
import StructuralNamesCheckbox from './StructuralNamesCheckbox';

function getDefaultClassificationGroup() {
	return {
		projectId: "",
		name: "",
		structuralNames: [],
		groupId: "",
		groupName: "",
		classifications: []
	};
}

function ClassificationGroupEditor({ open, projectClassification, onSave, onClose }) {
	const {
		siteClassifications,
	} = useContext(SiteDataContext);
	const [editProjectClassificationData, setEditProjectClassificationData] = useState({});

	useEffect(() => {
		let defaultClassGroup = getDefaultClassificationGroup();
		setEditProjectClassificationData({ ...defaultClassGroup, ...projectClassification });
	}, [projectClassification]);

	const handleChangeName = (name) => {
		setEditProjectClassificationData(prevData => ({ ...prevData, name }));
	}

	const handleSetGroupId = (groupId) => {
		let siteClass = siteClassifications.find(item => item.id === groupId);
		setEditProjectClassificationData(prevData => ({ ...prevData, groupId, groupName: siteClass.name }));
	}

	const handleChangeStructuralNames = (structuralNameList) => {
		setEditProjectClassificationData(prevData => ({ ...prevData, structuralNames: structuralNameList }));
	}

	const handleSaveClick = () => {
		console.log("SAVE EDIT classification CLICKED");
		onSave(editProjectClassificationData);
		onClose();
	};

	const isValid = () => {
		let data = editProjectClassificationData;

		if (data.structuralNames.length === 0) {
			return false;
		}

		if (data.groupId === "") {
			return false;
		}
		return true;
	}

	// If id is not defined then this is not an update operation
	const isNameEditable = editProjectClassificationData.id === undefined;

	console.log("Project Classification:", projectClassification);
	if (!open) {
		return null;
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h4">
				Classification Group
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<BasicTxtField
							id={"GroupName"}
							label="Group Name"
							value={editProjectClassificationData.name}
							onChange={handleChangeName}
							fullWidth
							disabled={!isNameEditable}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">Classification set</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">
							This is the stage that the attribute rules apply to
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<SelectDropdown
							id="classificationSet"
							label="Set"
							value={editProjectClassificationData.groupId}
							onChange={handleSetGroupId}
							options={siteClassifications}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">Allowed Structural Name(s)</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">
							Select the structural names that you wish this classification set to apply to
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<StructuralNamesCheckbox
							structuralNameList={editProjectClassificationData.structuralNames}
							onCheckedItemsChange={handleChangeStructuralNames}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					disabled={!isValid()}
					onClick={handleSaveClick}
					variant="contained"
					color={"success"}
					autoFocus>
					Save
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default ClassificationGroupEditor;