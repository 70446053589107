import React, { useContext, useMemo } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { UserContext } from '../../../contexts';
import { SelectableTable } from '.';

const columns = [
	{
		field: 'name',
		headerName: 'Name'
	},
	{
		field: 'ad1',
		headerName: 'Address 1'
	},
	{
		field: 'ad2',
		headerName: 'Address 2'
	},
	{
		field: 'ad3',
		headerName: 'Address 3'
	},
]



const SingleSelectCompanyTable = ({ onChange, selectedId }) => {
	const { companies } = useContext(UserContext);
	const selectedItems = useMemo(() => [selectedId], [selectedId]);

	return (
		<SelectableTable
			data={companies}
			columns={columns}
			selectedItems={selectedItems}
			onChange={onChange}
			allowMultiple={false}
		>
		</SelectableTable>
	);
};

/*
		<SortableTable data={companies} columns={columns}>
			{(displayedCompanies) => (
				<>
					{displayedCompanies.map((company) => (
						<TableRow
							key={company.id}
							onClick={() => handleCompanyClick(company)}
							style={{ cursor: 'pointer' }}						>
							<TableCell>{company.name}</TableCell>
							<TableCell>
								<Typography>
									{company.ad1} <br />
									{company.ad2} <br />
									{company.ad3}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</>
			)}
		</SortableTable>

		*/

export default SingleSelectCompanyTable;