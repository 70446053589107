import React, { useState, useContext } from 'react';
import { Grid } from '@mui/material';
import { LOIContext, SiteDataContext } from '../../../../contexts';
import {
	SelectDropdown,
} from '../../../ui';
import ImportLOIModal from '../../../modals/ImportLOIModal/ImportLOIModal';
import DataMaskFileUploadModal from '../../../modals/FileUploadModal/DataMaskFileUploadModal';
import CopyLOIModal from '../../../modals/CopyLOIModal';

const BulkActionList = [
	{
		id: "Download",
		name: "Download"
	},
	{
		id: "Generate PDTs",
		name: "Generate PDT's - NOT IMPLEMENTED"
	},
	{
		id: "Copy LOI",
		name: "Copy LOI from another site"
	},
	{
		id: "LOI Upload",
		name: "LOI Upload"
	},
	{
		id: "Add Data Mask File",
		name: "Add Data Mask Lookup File"
	},
	{
		id: "Delete LOI",
		name: "Remove LOI Completely"
	},
	{
		id: "Update Schedule",
		name: "Update Schedule to Match LOI"
	},
];

const BulkActionManager = ({ display, selectedProjectId, selectedLOI, featureAllowed }) => {
	const {
		loadLoiImportOptions,
		downloadLOI,
		deleteLOI,
		updateScheduleToMatchLOI,
	} = useContext(LOIContext);
	const {
		loadMySitesData
	} = useContext(SiteDataContext);
	const [openImportLOI, setOpenImportLOI] = useState(false);
	const [openUploadMaskFile, setOpenUploadMaskFile] = useState(false);
	const [openCopyLOI, setOpenCopyLOI] = useState(false);

	const handleSelectBulkAction = (bulkAction) => {
		console.log(bulkAction);
		if (bulkAction === "LOI Upload") {
			setOpenImportLOI(true);
			if (selectedProjectId !== "SITE")
				loadLoiImportOptions(selectedProjectId);
		}
		else if (bulkAction === "Add Data Mask File") {
			setOpenUploadMaskFile(true);
		}
		else if (bulkAction === "Download") {
			downloadLOI(selectedLOI);
		}
		else if (bulkAction === "Delete LOI") {
			deleteLOI(selectedLOI);
		}
		else if (bulkAction === "Copy LOI") {
			console.log("Loading?")
			loadMySitesData();
			setOpenCopyLOI(true);
			// TODO: Load copy loi data here?
		}
		else if (bulkAction === "Update Schedule") {
			updateScheduleToMatchLOI(selectedLOI);
		}
	}


	if (!display) {
		return null;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<SelectDropdown
					id={"bulkAction"}
					label={"Bulk Action"}
					value={""}
					onChange={handleSelectBulkAction}
					options={BulkActionList}
					fullWidth
					canEdit={featureAllowed}
				/>
			</Grid>
			{openImportLOI && (
				<ImportLOIModal
					projectId={selectedProjectId}
					open={openImportLOI}
					onClose={() => setOpenImportLOI(false)}
				/>
			)}
			{openUploadMaskFile && (
				<DataMaskFileUploadModal
					loiName={selectedLOI}
					open={openUploadMaskFile}
					onClose={() => setOpenUploadMaskFile(false)}
				/>
			)}
			{openCopyLOI && (
				<CopyLOIModal
					open={openCopyLOI}
					onClose={() => setOpenCopyLOI(false)}
				/>
			)}
		</Grid>
	);
}

export default BulkActionManager;