import React from 'react';
import * as Api from '../api/AdminApi';
import {
	AdminsProvider,
	DocumentProvider,
	HelpProvider,
	LOIProvider,
	ProjectProvider,
	SiteDataProvider,
	SiteSecurityProvider,
	UserProvider,
	WorkflowsProvider,
	WorkflowResponsibilityProvider,
} from '../contexts';

class ApiDbProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {
		return (
			<SiteDataProvider>
				<SiteSecurityProvider>
					<UserProvider>
						<ProjectProvider>
							<WorkflowResponsibilityProvider>
								<LOIProvider>
									<DocumentProvider>
										<WorkflowsProvider>
											<AdminsProvider>
												<HelpProvider>
													{this.props.children}
												</HelpProvider>
											</AdminsProvider>
										</WorkflowsProvider>
									</DocumentProvider>
								</LOIProvider>
							</WorkflowResponsibilityProvider>
						</ProjectProvider>
					</UserProvider>
				</SiteSecurityProvider>
			</SiteDataProvider>
		);
	}
}

export default ApiDbProvider;
