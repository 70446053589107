import React, { useContext, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Stack,
	Switch,
	Typography,
} from '@mui/material';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
	SelectDropdown,
} from '../ui';
import { LOIContext } from '../../contexts/';
import { LOIStageList } from '../../constants/LOIConstants';

function AddEditLOIModal({ initialData, open, onClose, selectedLOI }) {
	const [editLoiData, setEditLoiData] = useState(initialData);
	const { updateLOIData } = useContext(LOIContext);

	useEffect(() => {
		setEditLoiData({...initialData});
	}, [initialData]);

	const handleDataChange = (propertyName, newValue) => {
		console.log("EDIT LOI DATA CHANGE");
		setEditLoiData(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSwitchChange = (e) => {
		handleDataChange(e.target.name, e.target.checked);
	}

	const handleSaveClick = () => {
		console.log("SAVE EDIT LOI CLICKED");
		onClose();
		updateLOIData([editLoiData], selectedLOI);
		//props.onSaveClick();
	};

	if (!editLoiData || Object.keys(editLoiData).length === 0) {
		return null;
	}
	//console.log("Initial Data:", editLoiData);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h4">
				Add/Edit LOI Requirement
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Box display="flex" alignItems="center">
						<Avatar alt="Avatar" variant="square" sx={{ width: 32, height: 32 }}>
							{editLoiData.stage}
						</Avatar>
						<Typography variant="h6" style={{ marginLeft: 8 }}>
							RIBA stage
						</Typography>
					</Box>
					<Typography variant="body1">
						This is the stage that the attribute rules apply to
					</Typography>
					<SelectDropdown
						id={"Stage"}
						value={editLoiData.stage}
						options={LOIStageList}
						onChange={(value) => handleDataChange("stage", value)}
						fullWidth
					/>
					<Divider />

					<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
						<Box display="flex" alignItems="center">
							<Avatar alt="Avatar" variant="rounded" sx={{ width: 32, height: 32 }}>
								<StarSharpIcon />
							</Avatar>
							<Typography variant="h6" style={{ marginLeft: 8 }}>
								Required
							</Typography>
						</Box>
						<Switch
							name="required"
							checked={editLoiData.required}
							onChange={handleSwitchChange}
							color="primary"
							size="medium"
						/>
					</Box>
					<Typography variant="body1">
						Force this attribute to be required. A form cannot be saved with this field missing.
					</Typography>
					<Divider />

					<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
						<Box display="flex" alignItems="center">
							<Avatar alt="Avatar" variant="circular" sx={{ width: 32, height: 32 }}>
								<RemoveRedEyeIcon />
							</Avatar>
							<Typography variant="h6" style={{ marginLeft: 8 }}>
								Displayed
							</Typography>
						</Box>
						<Switch
							name="displayed"
							checked={editLoiData.displayed}
							onChange={handleSwitchChange}
							color="primary"
							size="medium"
						/>
					</Box>

					<Typography variant="body1">
						The data will be in the background, but if you want to see this attribute in reports or summaries then enable this option.
					</Typography>
					<Divider />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSaveClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Update
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default AddEditLOIModal;