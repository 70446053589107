import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, Stack, Snackbar, Typography } from '@mui/material';
import * as Api from '../../../api/AdminApi';
import LoadingPage from '../LoadingPage';
import SecurityRolesPage from './SecurityRolesPage';
import SiteSecurityTable from './SiteSecurityTable';
import SecurityEditModal from './SiteSecurityEditModal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { UserContext } from '../../../contexts';

// TODO: Currently this does not use the site security context?

function SiteSecurityPage() {
	const [loadingData, setLoadingData] = useState(true);
	const [securityRoles, setSecurityRoles] = useState([]);
	const [securitySettings, setSecuritySettings] = useState([]);
	const [editSecurityOpen, setEditSecurityOpen] = useState(false);
	const [currentSecurity, setCurrentSecurity] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const { editSiteRoleMatrix, exportSecurity, loadSecurityRolesBySiteId } = useContext(UserContext);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setLoadingData(true);
		try {
			const roles = await Api.getSecurityRolesBySiteId();
			const settings = await Api.getGlobalSecurity();
			settings.forEach(sec => {
				sec.roles = roles.filter(role => role.securityId === sec.id);
			});
			settings.sort((a, b) => {
				const namespaceComparison = a.namespace.localeCompare(b.namespace);
				if (namespaceComparison !== 0) {
					return namespaceComparison;
				}
				return a.class.localeCompare(b.class);
			});
			setSecurityRoles(roles);
			setSecuritySettings(settings);
		} catch (error) {
			console.error('Error fetching data', error);
		}
		setLoadingData(false);
	};

	const handleEditSecurity = (security) => {
		setCurrentSecurity(security);
		setEditSecurityOpen(true);
	};

	const handleSave = async (savedSecurity) => {
		const addedRoles = savedSecurity.roles.filter(sRole =>
			!currentSecurity.roles.some(cRole => cRole.roleId === sRole.roleId)
		);

		const removedRoles = currentSecurity.roles.filter(cRole =>
			!savedSecurity.roles.some(sRole => sRole.roleId === cRole.roleId)
		);

		const siteSecurityPostData = {
			id: savedSecurity.id,
			rolesToAdd: addedRoles.map(({roleId}) => roleId),
			rolesToRemove: removedRoles.map(({roleId}) => roleId)
		}

		const siteSecurityData = await editSiteRoleMatrix(siteSecurityPostData);
		if (!siteSecurityData) return;

		const updatedSecurity = securitySettings.map(sec =>
			sec.id === savedSecurity.id ? savedSecurity : sec
		);

		setSecuritySettings(updatedSecurity);
		loadSecurityRolesBySiteId();
	};

	const handExportSecurity = async () => {
		const exportSecurityData = await exportSecurity();
		if (!exportSecurityData) return;
		setOpenSnackbar(true);
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') return;
		setOpenSnackbar(false);
	};

	if (loadingData) {
		return <LoadingPage />
	}

	return (
		<Box>
			<Stack spacing={2}>
				<SecurityRolesPage />
				<Divider />
				<Typography variant="h4">Site Security</Typography>
				<SiteSecurityTable settings={securitySettings} onEdit={handleEditSecurity} />
				<SecurityEditModal
					open={editSecurityOpen}
					onClose={() => setEditSecurityOpen(false)}
					currentSecurity={currentSecurity}
					onSave={handleSave}
				/>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => handExportSecurity() }
					sx={{ display: 'flex', alignSelf: 'flex-end' }}>
					Security config export
				</Button>
				<Snackbar
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					message="Please check your inbox for requested file"
					action={
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleCloseSnackbar}
						>
							<CloseIcon fontSize="small" />
      			</IconButton>
					}
				/>
			</Stack>
		</Box>
	);
}

export default SiteSecurityPage;