import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
	Box,
	CircularProgress,
	Typography,
	Stack
} from '@mui/material';
import { AdminsContext } from '../../../contexts/';
import { NewUserEmailModal } from '../../modals/NewUserEmailModal';
import AdminsTableComponent from './AdminsTableComponent';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const AdminsPage = (props) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [busy, setBusy] = useState(false);
	const [admins, setAdmins] = useState([]);
	const [adminsDetails, setAdminsDetails] = useState(null);

	const { loadAdminsForSite, loadAdminsById, createAdmins } = useContext(AdminsContext);

	const getapis = useCallback(async () => {
		setBusy(true);

		const adminsData = await loadAdminsForSite(siteId);
		const masterAdmin = adminsData.find(admin => admin.type === 'MasterAdmin');
		const cleanAdminsData = adminsData.filter(admin => admin.userId !== masterAdmin.userId || admin.type === 'MasterAdmin');
		setAdmins(cleanAdminsData);

		const userIds = cleanAdminsData.map(data => data.userId);
		const adminsDetailsData = await loadAdminsById(userIds);
		setAdminsDetails(adminsDetailsData);

		setBusy(false);
	}, [loadAdminsForSite, siteId, loadAdminsById])

	useEffect(() => {
		getapis();
	}, [getapis]);

	const handleConfirmNewAdmins = async (newAdminUsers) => {
		setBusy(true);
		const userIdsArray = newAdminUsers.map(({id}) => id);
		const newAdminsData = await createAdmins({userIds: userIdsArray});
		if (newAdminsData) getapis();
		else setBusy(false);
	}

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Administrators</Typography>
				{busy && <CircularProgress />}
				{!busy &&
					<>
						<AdminsTableComponent
							admins={admins}
							adminsDetails={adminsDetails}
							getapis={getapis}
							setBusy={setBusy}
						/>
						<NewUserEmailModal
							title={"Add administrators"}
							btnText={"Add administrators"}
							btnVariantStyle={"contained"}
							btnStyle={{ display: 'inline-flex', alignSelf: 'flex-end' }}
							confirmMsg={"The following users will be added as administrators"}
							onConfirmAddUsers={handleConfirmNewAdmins}
							featureName={SECURITY_FEATURES.ADMINS_PAGE_EDIT}
						/>
					</>
				}
			</Stack>
		</Box>
	)
}
export default AdminsPage;