const UserReportCellStyle = (params) => {
	if (params.value) {
		return {
			backgroundColor: '#0000000A',
			border: '1px solid white'
		};
	}
	else {
		return {
			backgroundColor: '#616161E5',
			border: '1px solid white'
		}
	}
}

export default UserReportCellStyle;