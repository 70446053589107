import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack, Typography } from '@mui/material';
import {
	LOIContext,
} from '../../../../contexts';
import {
	SelectDropdown,
} from '../../../ui';

function AdvancedLOIModal({ open, onClose, setSelectedLOI, selectedLOI }) {
	const {
		loiNamesSelectList
	} = useContext(LOIContext);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h5">
				Advanced LOI Settings
			</DialogTitle>
			<DialogContent>
				<Stack sx={{ mb: 3 }} direction='column' spacing={1}>
					<Typography variant="body1">
						Select any LOI here to see its configuration
					</Typography>
					<SelectDropdown
						id={"LOI"}
						value={selectedLOI}
						options={loiNamesSelectList}
						onChange={setSelectedLOI}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={onClose}
					color="primary">
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default AdvancedLOIModal;