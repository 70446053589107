import React from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	CircularProgress,
} from '@mui/material';
import {
	EDocsLogo
} from '../ui';

function LoadingModal({ title, open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h5">
				{title}
			</DialogTitle>
			<DialogContent>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					bgcolor="white"
					padding={2}
					borderRadius={2}
				>
					<EDocsLogo />
					<CircularProgress color="primary" />
					<Typography variant="h6" mt={2}>
						Loading...
					</Typography>
				</Box>
			</DialogContent>
		</Dialog >
	);
}

export default LoadingModal;