import React from 'react';
import * as Api from '../api/AdminApi';
export const HelpContext = React.createContext(null);

export class HelpProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			helpTexts: {}
		};
	}

	addHelpTextsToSite = (newHelpTexts) => {
		if (newHelpTexts.length === 0)
			return;

		this.setState(prevState => {
			// Filtering out new helpTexts whose data is already in the state
			const filteredNewHelp = newHelpTexts.filter(newHelp => {
				return !(newHelp.title in prevState.helpTexts);
			});
			const helpDict = filteredNewHelp.reduce((acc, item) => {
				acc[item.title] = item.helpText;
				return acc;
			}, {});
			const updatedHelpTexts = { ...prevState.helpTexts, ...helpDict };

			console.log("NEW HELP:", updatedHelpTexts);
			return {
				helpTexts: updatedHelpTexts
			};
		});

	}

	loadHelpByTitle = async (title) => {
		try {
			const help = await Api.loadHelpByTitle(title);
			return help;
		} catch (error) {
			console.error(`Error loading help by title ${title}:`, error);
		}
	}

	loadHelpByTitles = async (titles) => {
		try {
			const newHelpTitles = titles.filter(title => {
				return !(title in this.state.helpTexts);
			});
			if (newHelpTitles.length > 0) {
				let helpData = await Api.loadHelpByTitles(newHelpTitles);
				this.addHelpTextsToSite(helpData);
			}
		} catch (error) {
			console.error(`Error loading help by titles ${titles}:`, error);
		}
	}

	render() {
		const helpContextValue = {
			helpTexts: this.state.helpTexts,
			loadHelpByTitle: this.loadHelpByTitle,
			loadHelpByTitles: this.loadHelpByTitles,
		}

		return (
			<HelpContext.Provider value={helpContextValue}>
				{this.props.children}
			</HelpContext.Provider>
		);
	}
}

export default HelpProvider;