import React, { useContext } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { SiteSecurityContext } from '../../contexts';

// FEATURE NAME = Required
// Security ID = optional depending on feature
const SecurityButton = (props) => {
	const {
		securityLoaded,
		isFeatureAllowed
	} = useContext(SiteSecurityContext);
	const { featureName, featureSecurityId, tooltipText, disabled, sx, children, ...otherProps } = props;

	if (!securityLoaded) {
		return (
			<Tooltip title={"SECURITY FEATURE LOADING"} arrow>
				<div style={{ display: 'inline-block' }}>
					<Button
						size="small"
						variant="contained"
						disabled={true}
					>
						<CircularProgress size={24} />
					</Button>
				</div>
			</Tooltip>)
	}
	let displayedTooltip = tooltipText || "";

	let isFeatureDisabled = !isFeatureAllowed(featureName, featureSecurityId);
	let isDisabled = disabled || isFeatureDisabled;

	if (isFeatureDisabled) {
		displayedTooltip = "You do not have permission to use this feature!"
	}

	return (
		<Tooltip title={displayedTooltip} arrow>
			<div style={sx}>
				<Button
					disabled={isDisabled}
					sx={sx}
					{...otherProps}
				>
					{children}
				</Button>
			</div>
		</Tooltip>
	);
}

export default SecurityButton;