import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Typography,
	Stack
} from '@mui/material';
import {
	SecurityButton
} from '../../ui';
import { WorkflowsContext } from '../../../contexts/';
import WorkflowsTableComponent from './WorkflowsTableComponent';
import WorkflowPage from './WorkflowPage';
import WorkflowCreatePage from './WorkflowCreatePage';
import WorkflowDuplicatePage from './WorkflowDuplicatePage';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const WorkflowsPage = (props) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [busy, setBusy] = useState(false);
	const [workflows, setWorkflows] = useState([]);
	const [view, setView] = useState(false);
	const [create, setCreate] = useState(false);
	const [duplicate, setDuplicate] = useState(false);
	const [workflowId, setWorkflowId] = useState(null);

	const { loadWorkflowsForSite, getLinkedWorkflowIdToView, setLinkedWorkflowIdToView } = useContext(WorkflowsContext);

	const getapis = useCallback(async () => {
		setBusy(true);

		const responses = await Promise.all([
			loadWorkflowsForSite(siteId)
		]);
		const workflowsData = await responses[0];
		setWorkflows(workflowsData);

		setBusy(false);
	}, [loadWorkflowsForSite, siteId])

	// Check if user has requested to view a specific workflow from another
	// section eg. Documents, and load this workflow if they have.
	const outsiteLinkCheck = useCallback(() => {
		const linkedWorkflowId = getLinkedWorkflowIdToView();

		if (linkedWorkflowId) {
			setWorkflowId(linkedWorkflowId);
			setLinkedWorkflowIdToView(null);
			setView(true);
		}
	}, [getLinkedWorkflowIdToView, setLinkedWorkflowIdToView])

	useEffect(() => {
		if (view) return;

		getapis().then(() => outsiteLinkCheck());
	}, [getapis, view, outsiteLinkCheck]);

	// useEffect(() => {
	// 	if (create || view) return;
	// 	setBusy(true);

	// 	async function getapis() {
	// 		const responses = await Promise.all([
	// 			loadWorkflowsForSite(siteId)
	// 		]);
	// 		const workflowsData = await responses[0];
	// 		setWorkflows(workflowsData);
	// 		setBusy(false);
	// 	}

	// 	getapis();
	//  }, [loadWorkflowsForSite, siteId, create, view]);

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Workflows</Typography>
				{busy && <CircularProgress />}
				{!busy && !view && !create && !duplicate &&
					<>
						<WorkflowsTableComponent
							workflows={workflows}
							setView={setView}
							setWorkflowId={setWorkflowId}
							getapis={getapis}
						/>
						<Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
							<SecurityButton
								featureName="workflow_create_update_delete"
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setDuplicate(true)}>
								Duplicate / copy workflow
							</SecurityButton>
							<SecurityButton
								featureName={SECURITY_FEATURES.WORKFLOW_PAGE}
								variant="contained"
								color="primary"
								size="small"
								onClick={() => setCreate(true)}>
								Add workflow
							</SecurityButton>
						</Stack>
					</>
				}
				{view &&
					<WorkflowPage setView={setView} workflowId={workflowId} />
				}
				{create &&
					<WorkflowCreatePage setCreate={setCreate} getapis={getapis} />
				}
				{duplicate &&
					<WorkflowDuplicatePage setDuplicate={setDuplicate} getapis={getapis} />
				}
			</Stack>
		</Box>
	)
}
export default WorkflowsPage;