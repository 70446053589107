import React from 'react';
import { Box } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const ImageChecklist = ({ images, selectedImages, onToggle }) => {
	return (
		<Box display="flex" flexWrap="wrap">
			{images.map((image) => (
				<Box
					key={image.id}
					sx={{
						position: 'relative',
						margin: 1,
						cursor: 'pointer',
						border: selectedImages.includes(image.id) ? '2px solid #1976d2' : '2px solid transparent',
						borderRadius: '4px',
					}}
					onClick={() => onToggle(image.id)}
				>
					<img src={image.url} alt={image.title} style={{ width: 100, height: 100 }} />
					{selectedImages.includes(image.id) && (
						<CheckCircleIcon
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								color: '#1976d2',
								backgroundColor: 'white',
								borderRadius: '50%',
							}}
						/>
					)}
				</Box>
			))}
		</Box>
	);
};

export default ImageChecklist;
