import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
} from '@mui/material';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField,
	SBCheckBoxField,
} from '../../../components/ui/';

const formValidators = {
	name: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	description: [Validation.validateNotEmpty, Validation.validateMaxLength(50)]
}

const initialValue = {
	name: "",
	description: ""
}

const FileCategoryModal = ({ open, onClose, currentCategory, onSave }) => {
	const [editedCategory, setEditedCategory] = useState({ ...initialValue, ...currentCategory });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedCategory({ ...initialValue, ...currentCategory });
		resetFormErrors();
	}, [currentCategory, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedCategory({ ...editedCategory, [propertyName]: newValue });
	};

	const handleSaveLocal = () => {
		if (!isFormValid(editedCategory)) return;
		onSave(editedCategory);
	};


	const newCategory = !editedCategory.id;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h4">
				{editedCategory?.id ? 'Edit Category' : 'Add Category'}
			</DialogTitle>
			<DialogContent>
				<Stack spacing={1} mt={1}>
					<BasicTxtField
						id="name"
						label="Category Name"
						value={editedCategory?.name || ''}
						onChange={handleDataChange}
						errors={formErrors['name']}
						fullWidth
					/>
					<BasicTxtField
						id="description"
						value={editedCategory?.description || ''}
						onChange={handleDataChange}
						errors={formErrors['description']}
						fullWidth
					/>
					{!newCategory && (
						<>
							<SBCheckBoxField
								id="multiAssign"
								label="Enable MultiAssign"
								checked={editedCategory?.multiAssign || false}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="multiFilter"
								label="Enable MultiFilter"
								checked={editedCategory?.multiFilter || false}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="required"
								label="Required"
								checked={editedCategory?.required || false}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="deleted"
								label="Deleted"
								checked={editedCategory?.deleted || false}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="spaceLookup"
								label="Location \ Space lookup"
								checked={editedCategory?.spaceLookup || false}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="floorLookup"
								label="Location \ Floor lookup"
								checked={editedCategory?.floorLookup || false}
								onChange={handleDataChange}
							/>
						</>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleSaveLocal}>
					Save
				</Button>
			</DialogActions>
		</Dialog >
	);
};


export default FileCategoryModal;