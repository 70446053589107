import React, { useMemo } from 'react';
import {
	getBaseColDefForGrid,
	getRowDataForGrid
} from '../../../helpers/AgGrid/LocationsGridHelpers';
import AgGridBaseLocationView from './AgGridBaseLocationView';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const AgGridZonesView = ({ locationsData, loiAttributes, loiClassifications, performCRUDOperation, loiData }) => {
	const baseColDefs = useMemo(() => { return getBaseColDefForGrid() }, []);
	return (
		<AgGridBaseLocationView
			locationData={locationsData.zones}
			loiAttributes={loiAttributes}
			loiClassifications={loiClassifications}
			performCRUDOperation={performCRUDOperation}
			structuralName={"Zone"}
			baseColDefs={baseColDefs}
			generateRowDataFunc={getRowDataForGrid}
			securityFeatureName={SECURITY_FEATURES.LOCATIONS_PAGE_ZONE}
			loiData={loiData}
		/>
	);
};

export default AgGridZonesView;