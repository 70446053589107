import React, { useContext } from 'react';
import {
	Grid,
	Typography,
	TextField,
} from '@mui/material';
import {
	SelectDropdown,
	ConditionalRenderer,
	GridDivider
} from '../../../ui';
import { LOIContext } from '../../../../contexts';
import AttributeLookup from './AttributeLookup';
import {
	getSelectListFromObjectKeys
} from '../../../../helpers/GeneralHelpers';

const dataConfigArrIndexs = {
	DATA_INPUT_TYPE: 0,
	SHEET_NAME: 1,
	DISPLAY_COL_NAME: 2,
	LOOKUP_COL_NAME: 3,
	UPDATED_BY: 4,
	RENDER_TYPE: 5,
	GHOSTSCRIPT: 6
}

const renderTypeList = [
	{
		id: "Default",
		name: "Default"
	},
	{
		id: "Readonly",
		name: "Readonly"
	},
	{
		id: "maskedInput",
		name: "Masked Input"
	}
];

function DataLookupConfig({ dataConfigArr, onDataChange, selectedLOI }) {
	const { loiDataConfigByName } = useContext(LOIContext);
	//const [sheetNameList, setSheetNameList] = useState([]);
	const sheetNameList = getSelectListFromObjectKeys(loiDataConfigByName[selectedLOI]);
	/*
	useEffect(() => {
		const sheetList = Object.keys(loiDataConfigByName[selectedLOI]).map((name) => {
			return {
				id: name,
				name: name
			};
		});
		setSheetNameList(sheetList);
	}, [loiDataConfigByName, selectedLOI]);
*/

	const dataSheetConfig = loiDataConfigByName[selectedLOI];
	const sheetName = dataConfigArr[dataConfigArrIndexs.SHEET_NAME];

	//console.log("DATA SHEET CONFIG:", JSON.stringify(dataSheetConfig, 4));
	console.log("DATA SHEET CONFIG:", dataSheetConfig);
	console.log("SHEET LIST:", sheetNameList);
	console.log("SHEET NAME:", JSON.stringify(sheetName, 4));

	return (
		<>
			<Grid item xs={6}>
				<SelectDropdown
					id={"sheetName"}
					label={"Sheet Name"}
					value={sheetName}
					options={sheetNameList}
					onChange={(value) => onDataChange(dataConfigArrIndexs.SHEET_NAME, value)}
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Sheet name to look your data up from
				</Typography>
			</Grid>
			<GridDivider />
			{sheetName !== "" && (
				<ConditionalRenderer shouldRenderChildren={sheetName !== ""}>
					<Grid item xs={6}>
						<SelectDropdown
							id={"displayColName"}
							label={"Display Column Name"}
							value={dataConfigArr[dataConfigArrIndexs.DISPLAY_COL_NAME]}
							options={dataSheetConfig[sheetName] || []}
							onChange={(value) => onDataChange(dataConfigArrIndexs.DISPLAY_COL_NAME, value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant={"body"}>
							The column data that will be displayed
						</Typography>
					</Grid>

					<GridDivider />

					<Grid item xs={6}>
						<SelectDropdown
							id={"lookupColName"}
							label={"Lookup Column Name"}
							value={dataConfigArr[dataConfigArrIndexs.LOOKUP_COL_NAME]}
							options={dataSheetConfig[sheetName] || []}
							onChange={(value) => onDataChange(dataConfigArrIndexs.LOOKUP_COL_NAME, value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant={"body"}>
							The column data that will be searched on
						</Typography>
					</Grid>

					<GridDivider />

					<Grid item xs={6}>
						<AttributeLookup
							value={dataConfigArr[dataConfigArrIndexs.UPDATED_BY]}
							onChange={(value) => onDataChange(dataConfigArrIndexs.UPDATED_BY, value)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant={"body"}>
							Optional - Leave unset if you dont not want this feature,
							when the option is set the selected attribute is used
							to update this attribute (like an excel vlookup)
						</Typography>
					</Grid>

					<GridDivider />
				</ConditionalRenderer>
			)}
			<Grid item xs={6}>
				<SelectDropdown
					id={"renderType"}
					label={"Render Type"}
					value={dataConfigArr[dataConfigArrIndexs.RENDER_TYPE]}
					options={renderTypeList}
					onChange={(value) => onDataChange(dataConfigArrIndexs.RENDER_TYPE, value)}
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					How we should display this lookup, default, readonly or masked input
				</Typography>
			</Grid>

			<GridDivider />

			<Grid item xs={6}>
				<TextField
					size="small"
					label="Ghostscript"
					variant="outlined"
					value={dataConfigArr[dataConfigArrIndexs.GHOSTSCRIPT]}
					onChange={(e) => onDataChange(dataConfigArrIndexs.GHOSTSCRIPT, e.target.value)}
					placeholder="Enter ghostscript"
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Value to show if no value, example: ABCD-A2B2-1234-ABCD
				</Typography>
			</Grid>

			<GridDivider />
		</>
	);
}

export default DataLookupConfig;