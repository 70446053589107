import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import { CRUD_ACTIONS } from '../../constants/ApiConstants';
import endpoints from '../endpoints';

export async function getSharedLinksByDocumentId(documentId) {
	try {
		const endpoint = endpoints.sharedLinks.getSharedLinksByDocumentId(documentId);
		const data = await springboardApiRequest(endpoint, 'GET');
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching shared links by document id:', error);
		throw new Error('Failed to fetch shared links by document id.');
	}
}

export async function deleteSharedLinkById(id, documentId) {
	try {
		const endpoint = endpoints.sharedLinks.deleteSharedLinkById(id);
		const request = {};
		const customHeaders = { documentId: documentId };
		const data = await springboardApiRequest(endpoint, "POST", request, customHeaders);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting shared link:', error);
		throw new Error('Failed to delete shared link.');
	}
}

export async function updateSharedLink(sharedLink, documentId) {
	try {
		const endpoint = endpoints.sharedLinks.updateSharedLink();
		const customHeaders = { documentId: documentId };
		const data = await springboardApiRequest(endpoint, "POST", sharedLink, customHeaders);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating shared link:', error);
		throw new Error('Failed to update shared link.');
	}
}

export async function shareLinkWithUsers(sharedLink, documentId) {
	try {
		const endpoint = endpoints.sharedLinks.shareLinkWithUsers();
		const customHeaders = { documentId: documentId };
		const data = await springboardApiRequest(endpoint, "POST", sharedLink, customHeaders);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while sharing link with users:', error);
		throw new Error('Failed to share link with users.');
	}
}