import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, title, message, onConfirm }) => {
	const handleConfirm = () => {
		onClose();
		onConfirm();
	}
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle sx={{ fontSize: '1rem' }}>{title}</DialogTitle>
			<DialogContent>
				<Alert severity="warning">{message}</Alert>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					size="small"
					onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					size="small"
					onClick={handleConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;