import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	Stack
} from '@mui/material';
import {
	BasicTxtField,
	SelectDropdown,
	InputError
} from '../../ui';
import { WorkflowsContext, SiteDataContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	title: [Validation.validateNotEmpty],
	sourceId: [Validation.validateNotEmpty]
}

const WorkflowDuplicatePage = ({ setDuplicate, getapis }) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [busy, setBusy] = useState(false);
	const [workflow, setWorkflow] = useState({
		siteId: siteId,
		sourceId: '',
		title: '',
		description: ''
	});
	const [sites, setSites] = useState([]);
	const [workflows, setWorkflows] = useState([]);
	const [formErrors, setFormErrors] = useState({});

	const { duplicateWorkflow, loadWorkflowsForSite } = useContext(WorkflowsContext);
	const { loadMySitesData } = useContext(SiteDataContext);

	useEffect(() => {
		setBusy(true);

		async function getApis() {
			const responses = await Promise.all([
				loadMySitesData(),
				loadWorkflowsForSite(workflow.siteId)
			]);
			const sitesData = await responses[0];
			const workflowsData = await responses[1];

			setSites(sitesData);

			const renamedWorkflows = workflowsData.map(({ title, id }) => ({ name: title, id: id }));
			setWorkflows(renamedWorkflows);

			setBusy(false);
		}

		getApis();
	}, [loadMySitesData, loadWorkflowsForSite, workflow.siteId])

	useEffect(() => {
		console.log('workflow', workflow);
	}, [workflow])

	const handleDataChange = (propertyName, newValue) => {
		if (propertyName === 'siteId') {
			setWorkflow(prevData => (
				{
					...prevData,
					sourceId: ''
				}
			));
		}
		setWorkflow(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const workflowData = await duplicateWorkflow(workflow);

		if (!workflowData) {
			return setBusy(false);
		}
		else {
			setDuplicate(false);
			getapis();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(workflow.title, "title", formValidators, newFormErrors);
		Validation.setFieldErrors(workflow.sourceId, "sourceId", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
							<Typography variant="body1">Duplicate / copy workflow</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<SelectDropdown
							id={"Sites"}
							value={workflow.siteId}
							onChange={(value) => handleDataChange("siteId", value)}
							options={sites}
							setToDefault={false}
							fullWidth={true}
						/>
						<SelectDropdown
							id={"Workflows"}
							value={workflow.sourceId}
							onChange={(value) => handleDataChange("sourceId", value)}
							options={workflows}
							setToDefault={false}
							fullWidth={true}
						/>
						<InputError field="Workflows" errors={formErrors["sourceId"]} />
						<BasicTxtField
							id={"Title"}
							label="Title"
							value={workflow.title}
							onChange={(value) => handleDataChange("title", value)}
							fullWidth={true}
							errors={formErrors['title']}
							required
						/>
						<BasicTxtField
							id={"Description"}
							label="Description"
							value={workflow.description}
							onChange={(value) => handleDataChange("description", value)}
							fullWidth={true}
							multiline
							rows={4}
						/>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setDuplicate(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)

}
export default WorkflowDuplicatePage;