import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';


const AddressCard = ({ address }) => (
	<Card>
		<CardContent>
			<Typography variant="h6">Address</Typography>
			<Typography>{address.add1}</Typography>
			<Typography>{address.add2}</Typography>
			<Typography>{address.add3}</Typography>
			<Typography>{address.townCity}</Typography>
			<Typography>{address.county}</Typography>
			<Typography>{address.country}</Typography>
			<Typography>{address.postcode}</Typography>
			<Typography>Lat, Lng: {address.lat}, {address.lng}</Typography>
		</CardContent>
	</Card>
);

export default AddressCard;
