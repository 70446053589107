import React, { useEffect, useState, useContext } from 'react';
import {
	Box,
	Button,
	Grid,
	Typography,
	Tabs,
	Tab,
} from '@mui/material';
import {
	SelectDropdown,
	CustomTabPanel,
	LoadingSpinner
} from '../ui';
import {
	AgGridSpacesView,
	AgGridZonesView,
	AgGridFacilitiesView,
	AgGridFloorsView
} from '../DataGridContainers/LocationGrids/';
import { isObjectEmpty } from '../../helpers/GeneralHelpers';
import LoadingPage from './LoadingPage';
import * as LOIGridHelpers from '../../helpers/AgGrid/LOIGridHelpers';
import * as LocationGridHelpers from '../../helpers/AgGrid/LocationsGridHelpers'
import { LOIContext, ProjectContext } from '../../contexts/';
import { CRUD_ACTIONS } from '../../constants/ApiConstants';

const STRUCT_NAME_MAP = {
	"Zone": "zones",
	"Floor": "floors",
	"Facility": "facilities",
	"Space": "spaces"
}

const LocationsPage = (props) => {
	const [selectedProject, setSelectedProject] = useState([]);
	const [loiNameForProject, setLoiNameForProject] = useState("");
	const [classificationIdForProject, setClassificationIdForProject] = useState("SITE");
	const [locationsData, setLocationsData] = useState({});
	const [dataLocationsLoading, setDataLocationsLoading] = useState(false);
	const [currentGridView, setCurrentGridView] = useState(0);
	const [hasLOIDataLoaded, setHasLOIDataLoaded] = useState(false);

	const {
		loiNames,
		loiAttributesByLoiName,
		loiClassificationsByProjectId,
		loadLOITableSchema,
		loadLOINames,
		loiByName,
		loadAllLOIData,
	} = useContext(LOIContext);
	const {
		projects,
		loadNodeLocationsByProjectId,
		updateNodeLocation,
		createNodeLocation,
		deleteNodeLocations
	} = useContext(ProjectContext);

	useEffect(() => {
		async function fetchLOIData() {
			await Promise.all([
				//loadLOITableSchema(),
				loadLOINames()
			]);
			setHasLOIDataLoaded(true);
		};
		fetchLOIData();
	}, [loadLOITableSchema, loadLOINames]);

	// TODO: Make this happen in 'bulk' form for mass copy / paste data...
	const performCRUDOperation = async (structuralName, operation, data) => {
		const locationType = STRUCT_NAME_MAP[structuralName];
		if (operation === CRUD_ACTIONS.UPDATE) {
			let newLocation = await updateNodeLocation(structuralName, selectedProject, data);
			if (!newLocation)
				return;

			setLocationsData((currentLocationsData) => {

				// Update the specified location within the currentLocationsData
				const updatedLocationsData = currentLocationsData[locationType].map(location => {
					if (location.id === newLocation.id) {
						LocationGridHelpers.updateSpacesOnLocationChange(currentLocationsData, location, newLocation, locationType);
						return newLocation;
					}
					return location;
				});

				return { ...currentLocationsData, [locationType]: updatedLocationsData };
			});
		}
		if (operation === CRUD_ACTIONS.DELETE) {
			let idsToDelete = data;
			let success = await deleteNodeLocations(structuralName, selectedProject, idsToDelete);
			if (!success)
				return;
			setLocationsData((currentLocationsData) => {
				LocationGridHelpers.updateSpacesOnLocationDelete(currentLocationsData, idsToDelete, locationType);

				let updatedLocationsData = currentLocationsData[locationType]
					.filter(location => !idsToDelete.includes(location.id));

				return { ...currentLocationsData, [locationType]: updatedLocationsData };
			});
		}
		if (operation === CRUD_ACTIONS.CREATE) {
			let newLocation = await createNodeLocation(structuralName, selectedProject, data);
			setLocationsData((currentLocationsData) => {
				let newLocationsData = [...currentLocationsData[locationType], newLocation];

				return { ...currentLocationsData, [locationType]: newLocationsData };
			});
		}
	}

	const handleProjectChange = async (selProjectId) => {
		setHasLOIDataLoaded(false);
		setSelectedProject(selProjectId);
		setDataLocationsLoading(true);
		const loiName = LOIGridHelpers.findLOINameByProjectId(loiNames, selProjectId);
		// Uses either project ID or site for classID depending on if this project has a specific LOI.
		setClassificationIdForProject(loiName?.isProjectLevelLOI ? selProjectId : "SITE");
		setLoiNameForProject(loiName?.name);
		await loadAllLOIData(loiName?.name);
		let locationData = await loadNodeLocationsByProjectId(selProjectId);
		setDataLocationsLoading(false);
		setLocationsData(locationData);
		setHasLOIDataLoaded(true);
	};

	const handleTabChange = (event, newValue) => {
		setCurrentGridView(newValue);
	};

	const hasBaseDataLoaded = projects.length > 0 && loiNames.length > 0;

	if (!hasBaseDataLoaded) {
		return <LoadingPage />;
	}

	//console.log("PROJECTS:", projects);
	//console.log("LOI NAMES:", loiNames);
	//console.log("LOI ATTRS:", loiAttributesByLoiName);
	console.log("CLASSIFICATIONS:", loiClassificationsByProjectId);
	console.log("PRoject:", selectedProject);
	const loiClassifications = loiClassificationsByProjectId[classificationIdForProject];

	const hasLocationsLoaded = !isObjectEmpty(locationsData);
	const canDisplayLocationGrid = hasLOIDataLoaded && hasLocationsLoaded;
	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Typography variant="h4">Project Locations</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">1. Select a project</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectDropdown
						id={"projects"}
						value={selectedProject}
						onChange={handleProjectChange}
						options={projects}
						fullWidth
					/>
				</Grid>
				<Grid item xs={0} md={6}></Grid>
				<Grid item xs={12}>
					<Tabs value={currentGridView} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
						<Tab label="Manage Zones" value={0} />
						<Tab label="Manage Spaces" value={1} />
						<Tab label="Manage Floors" value={2} />
						<Tab label="Manage Facilities" value={3} />
					</Tabs>
				</Grid>
				<Grid item xs={12}>
					{canDisplayLocationGrid && (
						<>
							<CustomTabPanel value={currentGridView} index={0}>
								<AgGridZonesView
									locationsData={locationsData}
									loiAttributes={loiAttributesByLoiName[loiNameForProject]}
									loiClassifications={loiClassifications}
									performCRUDOperation={performCRUDOperation}
									loiData={loiByName[loiNameForProject]}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={currentGridView} index={1}>
								<AgGridSpacesView
									locationsData={locationsData}
									loiAttributes={loiAttributesByLoiName[loiNameForProject]}
									loiClassifications={loiClassifications}
									performCRUDOperation={performCRUDOperation}
									loiData={loiByName[loiNameForProject]}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={currentGridView} index={2}>
								<AgGridFloorsView
									locationsData={locationsData}
									loiAttributes={loiAttributesByLoiName[loiNameForProject]}
									loiClassifications={loiClassifications}
									performCRUDOperation={performCRUDOperation}
									loiData={loiByName[loiNameForProject]}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={currentGridView} index={3}>
								<AgGridFacilitiesView
									locationsData={locationsData}
									loiAttributes={loiAttributesByLoiName[loiNameForProject]}
									loiClassifications={loiClassifications}
									performCRUDOperation={performCRUDOperation}
									loiData={loiByName[loiNameForProject]}
								/>
							</CustomTabPanel>

						</>
					)}
					<LoadingSpinner isLoading={dataLocationsLoading || !hasLOIDataLoaded} />
				</Grid>
			</Grid>
		</>
	);
}

export default LocationsPage;