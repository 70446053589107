import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
} from '@mui/material';
import {
	LOIContext,
} from '../../../contexts';
import { isEmpty } from '../../../helpers/GeneralHelpers';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField,
} from '../../../components/ui/';

const formValidators = {
	name: [Validation.validateNotEmpty, Validation.validateMaxLength(50)]
}

const initialValue = {
	name: "",
	loiName: "",
	hide: false,
	scope: "Assets",
	structuralType: "Assets",
	contextGroupId: null,
	contextAttributes: [],
	dataAttributes: [],
	guidance: "",
	sortOrder: 1,
	isCore: false
}

const AttributeGroupCreateModal = ({ open, onClose, currentGroup }) => {
	const {
		createAttributeGroup,
	} = useContext(LOIContext);
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	const [newGroup, setNewGroup] = useState({ ...initialValue, ...currentGroup });


	useEffect(() => {
		setNewGroup({ ...initialValue, ...currentGroup });
		resetFormErrors();
	}, [currentGroup, resetFormErrors]);


	const handleDataChange = (newValue, propertyName) => {
		setNewGroup({ ...newGroup, [propertyName]: newValue });
	};

	const handleCreate = () => {
		if (isEmpty(currentGroup))
			return false;
		if (!isFormValid(newGroup)) return;

		onClose();
		createAttributeGroup(newGroup.name, newGroup.loiName);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h4">
				Attribute Group Create
			</DialogTitle>
			<DialogContent>
				<Stack spacing={1} mt={1}>
					<BasicTxtField
						id="name"
						value={newGroup?.name || ''}
						onChange={handleDataChange}
						errors={formErrors['name']}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleCreate}>
					Create
				</Button>
			</DialogActions>
		</Dialog >
	);
};


export default AttributeGroupCreateModal;

//					<DragDropAttributes attributes={attributes} setAttributes={setAttributes} />