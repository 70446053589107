

const UserRolesCellStyle = (params) => {
	if (params.value && params.value.length > 0) {
		return {
			backgroundColor: '#0000000A',
			border: '1px solid white'
		};
	}
	else {
		return {
			backgroundColor: '#616161E5',
			border: '1px solid white'
		}
	}
}

export default UserRolesCellStyle;