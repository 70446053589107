

export const AllowedActions = {
	REMOVE_ACCESS: "1",
	SET_ACCESS_EXPIRATION: "2",
	ADD_ROLE: "3",
	SET_JOB_TITLE: "4",
	REMOVE_ROLE: "5",
	SET_ROLES: "6",
	SET_ROLE_DESCRIPTION: "7"
};

export const DATE_FORMAT = "DD/MM/YYYY";
export const STR_DATE_FORMAT = "DD MMM YY";
export const SERVER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const EXPIRED_DATE_COLOR = "#E91E63";


export const WorkflowGridColors = {
	COMPLETE_STEP: '#c9c9c990',
	CURRENT_STEP: '#3FCD40',
	FUTURE_STEP: '#ffffff0a'
}

export const SharedLinkType = {
	0: 'Document editor',
	1: 'Cobie validator',
	2: 'Single asset'
}