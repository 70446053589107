import React, { useCallback } from 'react';
import { DroppableList } from './DroppableList';
import ItemDragLayer from './ItemDragLayer';

const DEFAULT_ITEM_TYPE = "ITEM";

// Potential for future allow item types to be specified in a 'list config'
/*
ItemLists: Should be in the format of a dictionary of lists
{
	["listType1"] : [items],
	["listType2"] : [items]
	...
}
setItemLists: Should be the setState function for where the itemLists is stored.
*/
const MultiListDragDrop = ({ itemLists, setItemLists, itemType = DEFAULT_ITEM_TYPE }) => {

	const moveItem = useCallback(
		(fromIndex, toIndex, fromListType, toListType) => {
			setItemLists((prevItemLists) => {
				const fromList = [...prevItemLists[fromListType]];

				// If source and destination lists are the same, reorder the items
				if (fromListType === toListType) {
					const [movedItem] = fromList.splice(fromIndex, 1); // Remove the item
					fromList.splice(toIndex, 0, movedItem); // Insert it at the new position

					return {
						...prevItemLists,
						[fromListType]: fromList, // Update the reordered list
					};
				}

				// If the source and destination lists are different, move between lists
				const toList = [...prevItemLists[toListType]];
				const [movedItem] = fromList.splice(fromIndex, 1); // Remove item from source list
				toList.splice(toIndex, 0, movedItem); // Add item to destination list

				return {
					...prevItemLists,
					[fromListType]: fromList, // Update the source list
					[toListType]: toList, // Update the destination list
				};
			});
		},
		[setItemLists]
	);

	return (
		<div className="scrollable-container">
			<ItemDragLayer />
			{Object.keys(itemLists).map((listType) => (
				<DroppableList
					key={listType}
					title={listType}
					itemList={itemLists[listType]}
					moveItem={moveItem}
					listType={listType}
					itemType={itemType}
				/>
			))}
		</div>
	);
};

export default MultiListDragDrop;