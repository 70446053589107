import {
	generateRowDataForUserGridRoles
} from '../../helpers/AgGrid/UserGridHelpers';
import {
	UserRolesCellRenderer
} from '../DataGridComponents/CellRenderers';
import {
	UserRolesCellStyle
} from '../DataGridComponents/CellStyles';
import AgGridUsersView from './AgGridUsersView';


const AgGridUsersRolesView = ({selectedProjects}) => {
	return (
		<AgGridUsersView
			selectedProjects={selectedProjects}
			generateRowDataFunction={generateRowDataForUserGridRoles}
			cellStyleFunction={UserRolesCellStyle}
			cellRendererFunction={UserRolesCellRenderer}
		/>
	);
}

AgGridUsersRolesView.defaultProps = {
	selectedProjects: null
};

export default AgGridUsersRolesView;