import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserEmailConfirmForm from './UserEmailConfirmForm';
import NewUsersReviewList from './NewUsersReviewList';
import NewUsersEmailInput from './NewUsersEmailInput';
import NewSingleUserEmailInput from './NewSingleUserEmailInput';
import MultiStepModal from '../MultiStepModal';
import { createUsers } from '../../../api/AdminApi';

const transformUsersData = users =>
	users.map(user => ({
		"id": user.id,
		"email": user.email,
		"name": user.firstName + " " + user.secondName,
		"isMe": false,
		"firstName": user.firstName,
		"secondName": user.secondName,
		"companyName": user.company,
		"phonenumber": user.phone
	}));

// onConfirmAddUsers - Callback used to pass all confirmed users to calling component
function NewUserEmailModal({
	onConfirmAddUsers,
	title = "Add New Users",
	btnText = "+ NEW",
	btnVariantStyle = "outlined",
	confirmMsg = " The following users will be added or created",
	btnStyle = { display: 'flex', alignSelf: 'flex-end' },
	allowUserSelection = false, // Allow user emails to be selected from a list
	allowMultipleUsers = true,
	open, // If set component modal open will be controlled by parent
	onClose, // Must be set if open is set
	featureName = null
}) {
	const [users, setUsers] = useState([]);

	const handleInputEmails = (users) => {
		setUsers(users);
	}

	const updateUserData = (index, key, value) => {
		const updatedUsers = [...users];
		updatedUsers[index][key] = value || ''; // Null check temporary?
		setUsers(updatedUsers);
	};

	const handleConfirmUsers = async () => {
		let usersData = transformUsersData(users);
		const newUsers = usersData.filter(user => user.id === "NEW");
		const otherUsers = usersData.filter(user => user.id !== "NEW");

		if (newUsers.length > 0) {
			let createdUsers = await createUsers(newUsers);
			otherUsers.push(...createdUsers);
		}
		onConfirmAddUsers(otherUsers);
	}

	return (
		<MultiStepModal
			onModalComplete={handleConfirmUsers}
			title={title}
			buttonText={btnText}
			btnVariantStyle={btnVariantStyle}
			btnStyle={btnStyle}
			open={open}
			onClose={onClose}
			featureName={featureName}
		>
			{allowMultipleUsers ?
				<NewUsersEmailInput
					onInputEmails={handleInputEmails}
					allowUserSelection={allowUserSelection}
				/>
				:
				<NewSingleUserEmailInput
					onInputEmails={handleInputEmails}
					allowUserSelection={allowUserSelection}
				/>
			}
			<UserEmailConfirmForm
				users={users}
				updateUserData={updateUserData}
			/>
			<NewUsersReviewList
				confirmMsg={confirmMsg}
				users={users}
			/>
		</MultiStepModal>
	);
}

NewUserEmailModal.propTypes = {
	onConfirmAddUsers: PropTypes.func.isRequired,
};

export default NewUserEmailModal;