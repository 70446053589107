import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import EDocsLogo from '../ui/EDocsLogo';

function LoadingBox() {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			bgcolor="white"
			padding={2}
			borderRadius={2}
		>
			<EDocsLogo />
			<CircularProgress color="primary" />
			<Typography variant="h6" mt={2}>
				Loading...
			</Typography>
		</Box>
	)
}

export default LoadingBox;