import React, { useContext } from 'react';
import {
	Chip,
	Grid,
} from '@mui/material';
import { SiteDataContext } from '../../../contexts';

const StructuralNamesDisplay = ({ selectedStructuralNames }) => {
	const {
		structuralNames,
	} = useContext(SiteDataContext);

	return (
		<Grid container spacing={1}>
			{selectedStructuralNames.map(index => (
				<Grid item key={index}>
					<Chip
						label={structuralNames[index.toString()]}
						color="info"
						size="medium"
					/>
				</Grid>
			))}
		</Grid>
	);
}

export default StructuralNamesDisplay;