import React from 'react';
import { Typography } from '@mui/material';

const InputError = ({field, errors}) => {
	if (!errors || errors.length === 0) {
		return null;
	}

	return (
		<Typography variant="body1" color="error" mt={1}>
			{`${field}: ${errors.join(' ')}`}
		</Typography>
	)
}

export default InputError;