import React, { useState } from 'react';
import CheckBoxList from '../../CheckBoxList';

const CheckBoxCellEditor = ({ initialValue, onValueChange, options, ...props }) => {
	// Convert initialValue to a dictionary for easier management of checked state
	const initialCheckedState = initialValue.reduce((acc, currentValue) => ({
		...acc,
		[currentValue]: true
	}), {});

	const [checkedItems, setCheckedItems] = useState(initialCheckedState);

	// Check if options is provided and is an array
	if (!Array.isArray(options)) {
		console.error('CheckBoxCellEditor requires "options" prop to be an array.');
		return null;
	}

	const handleCheckedChange = (newCheckedItems) => {
		// Update the checked state based on changes
		setCheckedItems(newCheckedItems);

		// Immediately convert checkedItems back to array format for onValueChange
		const selectedValues = Object.entries(newCheckedItems)
			.filter(([key, value]) => value)
			.map(([key]) => key);

		onValueChange(selectedValues.sort());
	};

	return (
		<div style={{ backgroundColor: 'white' }}>
			<CheckBoxList
				id="checklist"
				data={options}
				checkedItems={checkedItems}
				onCheckedItemsChange={handleCheckedChange}
			/>
		</div>
	);
};

export default CheckBoxCellEditor;