import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { LevelContext } from './LevelContext.js';
import CheckBoxLabel from './CheckBoxLabel';

const CheckBoxSubList = ({ subListData, checkedItems, filterText, onChange }) => {
	const level = useContext(LevelContext);
	if (!subListData.length)
		return null;
	//console.log("Checkbox Sublist render");
	//console.log(subListData);
	return (
		<div>
			{subListData.map(listData => (
				<FormGroup key={listData.id}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!!checkedItems[listData.id]}
								onChange={(e) => onChange(e, listData.subList)}
								name={listData.id}
								style={{ marginLeft: `${level * 20}px` }}
								//checkedIcon={<CheckCircleRoundedIcon color="primary" />}
								//icon={<CancelIcon color="action" />}
							/>
						}
						label={<CheckBoxLabel item={listData} />}
						disabled={listData.disabled}
					/>
					{listData.subList && listData.subList.length > 0 &&
						<LevelContext.Provider value={level + 1}>
							<CheckBoxSubList
								subListData={listData.subList}
								checkedItems={checkedItems}
								filterText={filterText}
								onChange={onChange}
							/>
						</LevelContext.Provider>
					}
				</FormGroup>
			))
			}
		</div>
	);
};


export default CheckBoxSubList;