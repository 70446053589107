import React from 'react';
import { CircularProgress }  from '@mui/material';

const LoadingSpinner = ({isLoading}) => {
	if (!isLoading) {
		return null;
	}
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
			<CircularProgress />
			<span>Loading ...</span>
		</div>
	);
};

export default LoadingSpinner;