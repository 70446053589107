import React from 'react';
import apiRequestManager from '../api/Services/ApiRequestManager';
import * as Api from '../api/AdminApi';
import * as SpringDB from '../helpers/SpringBoardDBHelpers';
import { UserContext } from './UserContext';
import { hasDataLoaded } from '../helpers/GeneralHelpers';
export const ProjectContext = React.createContext(null);

export class ProjectProvider extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		this.state = {
			projects: [],
			documentsTeamsByProject: {},
			loadedDocTeamsProjectIds: [],
			projectsLoaded: false,
		};
	}

	componentDidMount() {
		this.performInitialDataLoad();
	}

	async performInitialDataLoad() {
		if (!this.state.projectsLoaded) {
			await this.loadProjects();
			this.setState({ projectsLoaded: true });
		}
	}

	// NOT USED
	loadAllProjectDocuments = async () => {
		this.loadProjects()
			.then((projects) => {
				projects.forEach((project) => {
					this.loadDocumentsTeamsByProjectId(project.id);
				})
			})
	}

	loadProjects = async () => {
		if (hasDataLoaded(this.state.projects))
			return;
		try {
			console.log("projects load");
			const projects = await Api.loadProjects();
			this.setState({ projects });
			return projects;
		} catch (error) {
			console.error("Error loading projects:", error);
		}
	}

	loadDocumentsTeamsByProjectId = async (projectId) => {
		try {
			let documents = await Api.loadDocumentsTeamsByProjectId(projectId);
			// Filter out unrelated documents, temp solution until real API is sorted.
			console.log("LOADING DOCS:");
			console.log(documents);
			documents = documents.filter(doc => doc.projectId === projectId);
			documents = documents.sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			});
			// Extract all usersIds from document teamMembers
			const userIds = documents.reduce((acc, item) => {
				const teamMemberIds = item.teamMembers.map(member => member.userId);
				const allUserIds = teamMemberIds.concat(item.responsibleUserId);
				return acc.concat(allUserIds);
			}, []);

			await this.context.loadUsersById(userIds);
			this.setState(prevState => ({
				loadedDocTeamsProjectIds: [...prevState.loadedDocTeamsProjectIds, projectId],
				documentsTeamsByProject: {
					...prevState.documentsTeamsByProject,
					[projectId]: documents
				}
			}));
		} catch (error) {
			console.error(`Error loading documents for project ${projectId}:`, error);
		}
	}

	// TODO: Ensure state is deep copied here to avoid issues:
	updateDocumentsTeamsByProject = (changeList, updateFunction) => {
		let documents = this.state.documentsTeamsByProject;
		updateFunction(changeList, documents);
		this.setState({ documentsTeamsByProject: documents });
	}

	// #####################
	// USERS PAGE - POST API
	// #####################

	addRoleToUsers = async (changeList) => {
		if (changeList.length === 0)
			return;
		let serverChangeList = await Api.addRolesToTeamMembers(changeList);
		this.updateDocumentsTeamsByProject(serverChangeList, SpringDB.addRolesToDocuments);
	}

	deleteRoleFromUsers = async (changeList) => {
		if (changeList.length === 0)
			return;
		let serverChangeList = await Api.removeRolesFromTeamMembers(changeList);
		this.updateDocumentsTeamsByProject(serverChangeList, SpringDB.deleteRolesFromDocuments);
	}

	setExpirationDateForUsers = async (changeList) => {
		if (changeList.length === 0)
			return;
		let serverChangeList = await Api.setExpirationForTeamMembers(changeList);
		this.updateDocumentsTeamsByProject(serverChangeList, SpringDB.setExpirationForTeamMembers);
	}

	setRoleDescriptionForUsers = async (changeList) => {
		if (changeList.length === 0)
			return;
		let serverChangeList = await Api.setRoleDescriptionForTeamMembers(changeList);
		this.updateDocumentsTeamsByProject(serverChangeList, SpringDB.setRoleDescriptionForTeamMembers);
	}

	setRemoveAccessForUsers = async (changeList) => {
		if (changeList.length === 0)
			return;
		let serverChangeList = await Api.removeRolesFromTeamMembers(changeList.flat());
		this.updateDocumentsTeamsByProject(serverChangeList, SpringDB.deleteRolesFromDocuments);
	}

	//## projects API
	loadProjectById = async (projectId) => {
		try {
			console.log("project load:", projectId);
			const project = await Api.loadProjectById(projectId);
			return project;
		} catch (error) {
			console.error("Error loading projects:", error);
		}
	}

	// ## Project Locations API
	loadZonesByProjectId = async (projectId) => {
		try {
			const project = await Api.loadZonesForProject(projectId);
			return project;
		} catch (error) {
			console.error(error);
		}
	}

	loadSpacesByProjectId = async (projectId) => {
		try {
			const project = await Api.loadSpacesForProject(projectId);
			return project;
		} catch (error) {
			console.error(error);
		}
	}

	loadFloorsByProjectId = async (projectId) => {
		try {
			const project = await Api.loadFloorsForProject(projectId);
			return project;
		} catch (error) {
			console.error(error);
		}
	}

	loadFacilitiesByProjectId = async (projectId) => {
		try {
			const project = await Api.loadFacilitiesForProject(projectId);
			return project;
		} catch (error) {
			console.error(error);
		}
	}

	loadNodeLocationsByProjectId = async (projectId) => {
		const promises = [
			this.loadZonesByProjectId(projectId),
			this.loadSpacesByProjectId(projectId),
			this.loadFloorsByProjectId(projectId),
			this.loadFacilitiesByProjectId(projectId),
		];

		// Use Promise.all to wait for all promises to resolve
		const [zones, spaces, floors, facilities] = await Promise.all(promises);

		// Combine the results into one object
		const nodeLocations = {
			zones: zones,
			spaces: spaces,
			floors: floors,
			facilities: facilities
		};
		console.log("locations:", nodeLocations);

		return nodeLocations;
	}

	updateNodeLocation = async (structuralName, projectId, data) => {
		try {
			const location = await Api.updateNodeLocation(structuralName, projectId, data);
			console.log("UPDATE NODE: ", location);
			return location;
		} catch (error) {
			console.error(error);
		}
	}

	createNodeLocation = async (structuralName, projectId, data) => {
		try {
			const location = await Api.createNodeLocation(structuralName, projectId, data);
			console.log("CREATE NODE: ", location);
			return location;
		} catch (error) {
			console.error(error);
		}
	}

	deleteNodeLocations = async (structuralName, projectId, data) => {
		try {
			const idsToDelete = {
				ids: data
			};
			const location = await Api.deleteNodeLocations(structuralName, projectId, idsToDelete);
			console.log("DELETE NODE: ", location);
			return location;
		} catch (error) {
			console.error(error);
		}
	}

	// Project Classifications:
	loadProjectClassifications = async (projectId) => {
		try {
			const classifications = await Api.getClassificationsByProjectId(projectId);
			return classifications;
		} catch (error) {
			console.error(error);
		}
	}

	createProjectClassification = async (projectClassification) => {
		let requestId = apiRequestManager.addRequestToQueue("createProjectClassification");
		try {
			let response = await Api.createProjectClassification(projectClassification);
			apiRequestManager.setRequestSuccess(requestId, "Successfully created project classification!");
			return response;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	updateProjectClassification = async (projectClassification) => {
		let requestId = apiRequestManager.addRequestToQueue("updateProjectClassification");
		try {
			let response = await Api.updateProjectClassification(projectClassification);
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated project classification!");
			return response;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	deleteProjectClassification = async (projectClassification) => {
		let requestId = apiRequestManager.addRequestToQueue("deleteProjectClassification");
		try {
			let response = await Api.deleteProjectClassification(projectClassification);
			apiRequestManager.setRequestSuccess(requestId, "Successfully deleted project classification!");
			return response;
		} catch (error) {
			console.error("ERROR:", error);
			apiRequestManager.setRequestFailure(requestId, error.message);
		}
	}

	loadProjectsBySite = async () => {
		try {
			const projects = await Api.loadProjects();
			return projects;
		} catch (error) {
			console.error('Error loading projects by site', error);
		}
	}

	deleteProjectById = async (id) => {
		try {
			const project = await Api.deleteProject(id);
			return project;
		} catch (error) {
			console.error(`Error deleting project by id ${id}:`, error);
		}
	}

	createProject = async (projectData) => {
		try {
			const project = await Api.createProject(projectData);
			return project;
		} catch (error) {
			console.error("Error creating project:", error);
		}
	}

	updateProject = async (projectData) => {
		try {
			const project = await Api.updateProject(projectData);
			return project;
		} catch (error) {
			console.error("Error updating project:", error);
		}
	}

	render() {
		const projectContextValue = {
			projects: this.state.projects,
			documentsTeamsByProject: this.state.documentsTeamsByProject,
			loadedDocTeamsProjectIds: this.state.loadedDocTeamsProjectIds,
			loadDocumentsTeamsByProjectId: this.loadDocumentsTeamsByProjectId,
			addRoleToUsers: this.addRoleToUsers,
			deleteRoleFromUsers: this.deleteRoleFromUsers,
			setExpirationDateForUsers: this.setExpirationDateForUsers,
			setRoleDescriptionForUsers: this.setRoleDescriptionForUsers,
			setRemoveAccessForUsers: this.setRemoveAccessForUsers,
			dataLoaded: this.state.projectsLoaded,
			loadProjectById: this.loadProjectById,
			loadNodeLocationsByProjectId: this.loadNodeLocationsByProjectId,
			updateNodeLocation: this.updateNodeLocation,
			createNodeLocation: this.createNodeLocation,
			deleteNodeLocations: this.deleteNodeLocations,
			loadProjectClassifications: this.loadProjectClassifications,
			createProjectClassification: this.createProjectClassification,
			updateProjectClassification: this.updateProjectClassification,
			deleteProjectClassification: this.deleteProjectClassification,
			loadProjectsBySite: this.loadProjectsBySite,
			deleteProjectById: this.deleteProjectById,
			createProject: this.createProject,
			updateProject: this.updateProject,
		};
		return (
			<ProjectContext.Provider value={projectContextValue}>
				{this.props.children}
			</ProjectContext.Provider>
		);
	}
}

export default ProjectProvider;