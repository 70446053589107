import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stack,
} from '@mui/material';
import * as Validation from '../../../../services/Validation';
import {
	BasicTxtField
} from '../../../../components/ui/';

const formValidators = {
	number: [Validation.validateIsNumber],
	title: [Validation.validateNotEmpty],
	description: [Validation.validateNotEmpty],
	url: [Validation.validateNotEmpty],
};

const initialValue = {
	number: '',
	title: '',
	description: '',
	url: '',
};

const EditQuickLinksModal = ({ open, onClose, currentLink, onSave }) => {
	const [editedLink, setEditedLink] = useState({ ...initialValue, ...currentLink });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedLink({ ...initialValue, ...currentLink });
		resetFormErrors();
	}, [currentLink, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedLink((prev) => ({ ...prev, [propertyName]: newValue }));
	};

	const handleSave = () => {
		if (!isFormValid(editedLink)) return;
		onClose();
		onSave(editedLink);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle variant="h4">
				{editedLink?.id ? 'Edit Quick Link' : 'Add Quick Link'}
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2} mt={2}>
					<BasicTxtField
						id="number"
						label="Position"
						value={editedLink.number}
						onChange={handleDataChange}
						errors={formErrors['number']}
						type={"number"}
						fullWidth
					/>
					<BasicTxtField
						id="description"
						label="Description"
						value={editedLink.description}
						onChange={handleDataChange}
						errors={formErrors['description']}
						fullWidth
					/>
					<BasicTxtField
						id="title"
						label="Title"
						value={editedLink.title}
						onChange={handleDataChange}
						errors={formErrors['title']}
						fullWidth
					/>
					<BasicTxtField
						id="url"
						label="URL"
						value={editedLink.url}
						onChange={handleDataChange}
						errors={formErrors['url']}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditQuickLinksModal;
