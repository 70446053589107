import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stack,
} from '@mui/material';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField
} from '../';

const formValidators = {
	title: [Validation.validateNotEmpty],
	description: [Validation.validateNotEmpty],
	url: [Validation.validateNotEmpty],
};

const initialValue = {
	title: "test",
	description: "test",
	url: "http://google.com",
	urlType: "predefined",
	color: "#000000",
	radius: 0,
};

const EditMapMarkerModal = ({ open, onClose, currentMarker, onSave }) => {
	const [editedMarker, setEditedMarker] = useState({ ...initialValue, ...currentMarker });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedMarker({ ...initialValue, ...currentMarker });
		resetFormErrors();
	}, [currentMarker, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedMarker((prev) => ({ ...prev, [propertyName]: newValue }));
	};

	const handleSave = () => {
		if (!isFormValid(editedMarker)) return;
		onClose();
		onSave(editedMarker);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle variant="h4">
				Edit Map Marker
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2} mt={2}>
					<BasicTxtField
						id="title"
						label="Title"
						value={editedMarker.title}
						onChange={handleDataChange}
						errors={formErrors['title']}
						fullWidth
					/>
					<BasicTxtField
						id="description"
						label="Description"
						value={editedMarker.description}
						onChange={handleDataChange}
						errors={formErrors['description']}
						fullWidth
					/>
					<BasicTxtField
						id="url"
						label="URL"
						value={editedMarker.url}
						onChange={handleDataChange}
						errors={formErrors['url']}
						fullWidth
					/>
					<BasicTxtField
						id="color"
						label="Color"
						value={editedMarker.color}
						onChange={handleDataChange}
						errors={formErrors['color']}
						type="color"
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={handleSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditMapMarkerModal;
