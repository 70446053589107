import React, { useState, useContext } from 'react';
import {
	Container,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
	ApiStatusDisplay,
	LoadingSpinner,
	SecurityButton,
	SelectDropdown,
} from '../../ui';
import LoadingPage from '../LoadingPage';
import { ProjectContext, SiteDataContext } from '../../../contexts';
import ClassificationGroupsDisplay from './ClassificationGroupsDisplay';
import ClassificationTemplateModal from './ClassificationTemplateModal';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';


const ClassificationsPage = (props) => {
	const [selectedProjectId, setSelectedProjectId] = useState(null);
	const [selectedClassGroupId, setClassGroupId] = useState(null);
	const [projectClassifications, setProjectClassifications] = useState([]);
	const [openSettings, setOpenSettings] = useState(false);
	const [isLoadingClassications, setIsLoading] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

	const {
		projects,
		loadProjectClassifications,
		createProjectClassification,
		updateProjectClassification,
		deleteProjectClassification,
	} = useContext(ProjectContext);
	const {
		loadStructuralNames,
		loadAllClassifications,
		loadAllClassificationTemplates,
		applyProjectClassificationConfig,
		saveProjectConfigAsTemplate
	} = useContext(SiteDataContext);

	const handleProjectChange = async (selProjectId) => {
		setSelectedProjectId(selProjectId);
		setIsLoading(true);
		await Promise.all([
			loadAllClassifications(),
			loadAllClassificationTemplates(),
			loadStructuralNames()
		]);
		setProjectClassifications(await loadProjectClassifications(selProjectId));
		setIsLoading(false);
	};

	const handleEditClassGroup = async (classGroup) => {
		console.log("ON EDIT CLASS GROUP CALLED");
		await updateProjectClassification(classGroup);
		setProjectClassifications(currentClassifications => {
			return currentClassifications.map(classification => {
				if (classification.id === classGroup.id) {
					return classGroup;
				}
				return classification;
			});
		});
	}

	const handleOpenDeleteDialog = (classGroup) => {
		setClassGroupId(classGroup);
		setOpenDeleteConfirm(true);
	};

	const handleCloseDeleteDialog = () => {
		setClassGroupId(null);
		setOpenDeleteConfirm(false);
	};

	const handleAddNewClassGroup = async (classGroup) => {
		console.log("ON ADD CLASS GROUP CALLED");
		classGroup.projectId = selectedProjectId;
		let newClassGroup = await createProjectClassification(classGroup);
		setProjectClassifications(currentClassifications => [
			...currentClassifications,
			...newClassGroup
		]);
	}

	const handleDeleteClassGroup = async () => {
		console.log("ON DELETE CLASS GROUP CALLED");
		// TODO: Get mike to change this endpoint..
		await deleteProjectClassification({ id: selectedClassGroupId });
		setProjectClassifications(currentClassifications => {
			return currentClassifications.filter(classification => classification.id !== selectedClassGroupId);
		});

		setClassGroupId(null);
		setOpenDeleteConfirm(false);
	};

	const handleApplyTemplate = async (template) => {
		let applyReq = {
			templateId: template.id,
			projectId: selectedProjectId
		};
		await applyProjectClassificationConfig(applyReq);
		setProjectClassifications(template.config);
	}

	const handleCreateTemplate = async (templateName) => {
		let createReq = {
			projectId: selectedProjectId,
			name: templateName
		};
		await saveProjectConfigAsTemplate(createReq);
	}

	const hasProjectDataLoaded = projects.length > 0;

	if (!hasProjectDataLoaded) {
		return <LoadingPage />;
	}

	const dataLoaded = !isLoadingClassications && selectedProjectId !== null;
	return (
		<Container maxWidth="md">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<ApiStatusDisplay />
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h4">Project Classifications</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">1. Select a project</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectDropdown
						id={"projects"}
						value={selectedProjectId}
						onChange={handleProjectChange}
						options={projects}
						fullWidth
					/>
				</Grid>
				{dataLoaded && (
					<>
						<Grid item xs={12} container>
							<SecurityButton
								featureName={SECURITY_FEATURES.PROJECT_CLASSIFICATIONS_PAGE}
								onClick={() => setOpenSettings(true)}
								variant="outlined"
								color={"primary"}
							>
								Template Settings
							</SecurityButton>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">
								The classification sets and tables active within this project can be an industry standard set,
								a customized project specific set, or a mixed classifications set can be configured.
							</Typography>
						</Grid>

						<Grid item xs={12} container spacing={1}>
							<ClassificationGroupsDisplay
								projectClassifications={projectClassifications}
								onEditGroup={handleEditClassGroup}
								onAddGroup={handleAddNewClassGroup}
								onDeleteGroup={handleOpenDeleteDialog}
							/>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<LoadingSpinner isLoading={isLoadingClassications} />
				</Grid>

				<ClassificationTemplateModal
					open={openSettings}
					onApply={handleApplyTemplate}
					onCreate={handleCreateTemplate}
					onClose={() => setOpenSettings(false)}
				/>
			</Grid>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete Classification Group"
				message={`Please confirm that you would like to delete this classification group`}
				onConfirm={handleDeleteClassGroup}
			/>
		</Container>
	);
}

export default ClassificationsPage;