import React, { useContext, useEffect, useMemo, useState } from 'react';
import CheckBoxList from '../../CheckBoxList';
import { SiteDataContext } from '../../../contexts';

function convertStructuralNamesForCheckbox(structuralNames) {
	return Object.keys(structuralNames).map(key => ({
		id: key,
		name: structuralNames[key]
	}));
}

function convertListToCheckedItems(list) {
	if (!Array.isArray(list)) {
		return {};
	}
	return list.reduce((acc, currentValue) => {
		acc[currentValue] = true;
		return acc;
	}, {});
}

function StructuralNamesCheckbox({ structuralNameList, onCheckedItemsChange }) {
	const {
		structuralNames,
	} = useContext(SiteDataContext);
	const structNamesChecklist = useMemo(() => {
		return convertStructuralNamesForCheckbox(structuralNames);
	}, [structuralNames]);
	const [selectedStructuralNames, setSelectedStructuralNames] = useState({});

	useEffect(() => {
		setSelectedStructuralNames(convertListToCheckedItems(structuralNameList));
	}, [structuralNameList]);

	const handleCheckedItemsChange = (checkedItems) => {
		setSelectedStructuralNames(checkedItems);
		// Convert back to simple array
		let checkedStructNames = Object.keys(checkedItems)
			.filter(key => checkedItems[key])
			.map(key => parseInt(key, 10));
		onCheckedItemsChange(checkedStructNames);
	}

	console.log("Selected struct names:", selectedStructuralNames);

	return (
		<>
			<CheckBoxList
				data={structNamesChecklist}
				checkedItems={selectedStructuralNames}
				onCheckedItemsChange={handleCheckedItemsChange}
			/>
		</>
	);
}

export default StructuralNamesCheckbox;