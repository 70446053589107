import React from 'react';
import { Alert, CircularProgress, Box } from '@mui/material';

const SecurityLoadingAlert = ({ loading }) => {

	if (!loading) {
		return null;
	}

	return (
		<Alert severity="warning" icon={false}>
			<Box display="flex" alignItems="center">
				<CircularProgress size={24} sx={{ mr: 2 }} />
				Security permissions are loading...
			</Box>
		</Alert>
	);
};

export default SecurityLoadingAlert;
