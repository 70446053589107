import React, { useContext, useState } from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { SiteDataContext } from '../../../contexts';
import { IMAGE_TYPE } from '../../../constants/FileTypeConstants';
import SiteImagesUploadModal from '../../modals/FileUploadModal/SiteImagesUploadModal';

function ManageImageMapImage({ imageId, url, siteLocationId, onDeleteImage }) {
	const [openImageUpload, setOpenImageUpload] = useState(false);

	const {
		deleteImageMapImage
	} = useContext(SiteDataContext);

	const handleDelete = async () => {
		let success = await deleteImageMapImage(imageId, siteLocationId);
		if (success && onDeleteImage) {
			onDeleteImage(siteLocationId);
		}
	}

	const hasImage = url !== null;

	return (
		<>
			<Stack direction='row' spacing={1}>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => setOpenImageUpload(true)}
					startIcon={<AddIcon />}
				>
					{hasImage ? "Replace Image" : "Upload Image"}
				</Button>
				{hasImage &&
					<Button
						variant="contained"
						color="secondary"
						onClick={handleDelete}
						startIcon={<DeleteIcon />}
					>
						Remove Image
					</Button>
				}
			</Stack>
			<SiteImagesUploadModal
				open={openImageUpload}
				onClose={() => setOpenImageUpload(false)}
				siteLocationId={siteLocationId}
				imageType={IMAGE_TYPE.LocationImageMap}
			/>
		</>
	);
}

export default ManageImageMapImage;
