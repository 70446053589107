import React, { useState, useMemo, useContext } from 'react';
import { SelectDropdown } from '../../ui';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import DataLoadHandler from '../../containers/DataLoadHandler';
import WorkflowResponsibilityDetailPage from './WorkflowResponsibilityDetailPage';
import { UserContext, ProjectContext, WorkflowResponsibilityContext } from '../../../contexts';
import { LoadingPage } from '..';
import * as AgGridWorkflowHelpers from '../../../helpers/AgGrid/WorkflowGridHelpers';
import * as workflowDB from '../../../helpers/Database/WorkflowDBHelpers';


function WorkflowResponsibilityPage() {
	const {
		projects,
		documentsTeamsByProject,
		dataLoaded,
		loadedDocTeamsProjectIds,
		loadDocumentsTeamsByProjectId
	} = useContext(ProjectContext);
	const {
		createWorkflowReviewers,
		createWorkflowResponsibleUsers,
		clearWorkflowResponsibleUsers,
		removeWorkflowReviewers,
		setReviewSections,
		setSectionsStartDate,
		setSectionsContractor,
		loadWorkflowForDoc
	} = useContext(WorkflowResponsibilityContext);
	const { loadUsersById } = useContext(UserContext);
	const [selectedProjectName, setSelectedProjectName] = useState("");
	const [selectedProjectId, setSelectedProject] = useState("");
	const [selectedDocName, setSelectedDocName] = useState("");
	const [selectedDocumentId, setSelectedDocument] = useState("");
	const [workflowData, setWorkflowData] = useState({});
	const [sections, setSections] = useState([]);
	const [responsibilityData, setResponsibilityData] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [sectionTree, setSectionTree] = useState([]);
	const [defaultResponsibleUserId, setDefaultResponsibleUserId] = useState('');
	const [openDetail, setOpenDetail] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	console.log("DOCS BY PROJECT:", documentsTeamsByProject);
	const documentOptions = useMemo(() =>
		(documentsTeamsByProject[selectedProjectId] || []).map(doc => ({
			id: doc.documentId,
			name: doc.name
		})), [documentsTeamsByProject, selectedProjectId]);

	const handleSetProject = (projectId) => {
		setSelectedProject(projectId);
		let project = projects.find((project) => project.id === projectId);
		setSelectedProjectName(project.name);
		setSelectedDocument('');
	};

	const handleSetDocument = (documentId) => {
		setSelectedDocument(documentId);
		let projectDocuments = documentsTeamsByProject[selectedProjectId];
		let doc = projectDocuments?.find((doc) => doc.documentId === documentId);
		console.log("Project docs:", projectDocuments);
		console.log("DOC :", doc);
		setSelectedDocName(doc?.name);
		setDefaultResponsibleUserId(doc?.responsibleUserId);
	}

	const handleOpenWorkflowDetail = async () => {
		setIsLoading(true);
		const workflowData = await loadWorkflowForDoc(selectedDocumentId);
		console.log("Loaded workflow data:", workflowData);
		setWorkflowData(workflowData.workflow);
		setResponsibilityData(workflowData.responsibility);
		setReviewers(workflowData.reviewers);
		setSections(workflowData.sections);
		setSectionTree(workflowData.sectionTree);
		setIsLoading(false);
		setOpenDetail(true);
	}

	const handleUpdateWorkflowStep = async (updatedStep) => {
		setWorkflowData(prevData => {
			const updatedSteps = prevData.steps.map(step =>
				step.id === updatedStep.id ? { ...step, ...updatedStep } : step
			);
			return { ...prevData, steps: updatedSteps };
		});
	}

	const getReviewerReq = (reviews) => {
		return {
			documentId: selectedDocumentId,
			reviews: reviews
		};
	}

	const handleUpdateReviewers = async (newReviewers) => {
		let reqReviewers = getReviewerReq(newReviewers);
		await createWorkflowReviewers(reqReviewers);
		const newReviewersUsersIds = [];
		newReviewers.forEach(review => {
			review.users.forEach(user => {
				newReviewersUsersIds.push(user);
			})
		});
		await loadUsersById(newReviewersUsersIds);
		setReviewers(prevReviewers =>
			AgGridWorkflowHelpers.mergeReviewers(prevReviewers, newReviewers));
	}

	const handleRemoveReviewers = async (reviewersToRemove) => {
		let reqReviewers = getReviewerReq(reviewersToRemove);
		await removeWorkflowReviewers(reqReviewers);
		setReviewers(prevReviewers =>
			AgGridWorkflowHelpers.removeReviewers(prevReviewers, reviewersToRemove));
	}

	const getResponsibleUserReq = (responsibleUsers) => {
		return {
			documentId: selectedDocumentId,
			responsibilities: responsibleUsers
		};
	}

	const handleUpdateResponsibleUsers = async (newResponsibleUsers) => {
		let reqResponsibleUsers = getResponsibleUserReq(newResponsibleUsers);
		await createWorkflowResponsibleUsers(reqResponsibleUsers);
		const newResponsibleUsersIds = newResponsibleUsers.map(({userId}) => userId);
		await loadUsersById(newResponsibleUsersIds);
		setResponsibilityData(prevResponsibleUsers =>
			AgGridWorkflowHelpers.mergeResponsibleUsers(prevResponsibleUsers, newResponsibleUsers)
		);
	}

	const handleClearResponsibleUsers = async (responsibleUsersToClear) => {
		let reqResponsibleUsers = getResponsibleUserReq(responsibleUsersToClear);
		await clearWorkflowResponsibleUsers(reqResponsibleUsers);
		setResponsibilityData(prevResponsibleUsers =>
			prevResponsibleUsers.filter(responsibility =>
				!responsibleUsersToClear.some(clearUser =>
					clearUser.stepId === responsibility.stepId && clearUser.sectionId === responsibility.sectionId
				)
			)
		);
	}

	const handleUpdateReviewSections = async (sectionIds) => {
		let updatedSections = await setReviewSections(selectedDocumentId, sectionIds);
		if (updatedSections) {
			setSections(updatedSections);
			setSectionTree(prevTree => {
				return workflowDB.updateSectionTreeBySelectedIds(prevTree, sectionIds);
			});
		}
	}

	const handleUpdateSectionStartDate = async (sectionIds, startDate) => {
		let updatedSections = await setSectionsStartDate(selectedDocumentId, sectionIds, startDate);
		if (updatedSections) {
			setSections(prevSections => {
				return workflowDB.updateSectionsStartDate(prevSections, sectionIds, startDate);
			});
		}
	}

	const handleUpdateSectionContractor = async (sectionIds, companyId) => {
		let updatedSections = await setSectionsContractor(selectedDocumentId, sectionIds, companyId);
		if (updatedSections) {
			setSections(prevSections => {
				return workflowDB.updateSectionsContractor(prevSections, sectionIds, companyId);
			});
		}
	}

	const projectHasDocs = () => {
		return selectedProjectId !== '' && documentOptions.length > 0;
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	const isViewDisabled = selectedDocumentId === '' || selectedProjectId === '';
	if (openDetail) {
		return (
			<WorkflowResponsibilityDetailPage
				documentId={selectedDocumentId}
				defaultResponsibleUserId={defaultResponsibleUserId}
				documentName={selectedDocName}
				projectName={selectedProjectName}
				workflowData={workflowData || {}}
				sectionData={sections || []}
				responsibilityData={responsibilityData || []}
				reviewsData={reviewers || []}
				sectionTreeData={sectionTree || []}
				onBackClick={() => setOpenDetail(false)}
				onUpdateWorkflowStep={handleUpdateWorkflowStep}
				onUpdateReviewers={handleUpdateReviewers}
				onUpdateResponsibleUsers={handleUpdateResponsibleUsers}
				onClearResponsibleUsers={handleClearResponsibleUsers}
				onRemoveReviewers={handleRemoveReviewers}
				onUpdateReviewSections={handleUpdateReviewSections}
				onUpdateSectionsStartDate={handleUpdateSectionStartDate}
				onUpdateSectionContractor={handleUpdateSectionContractor}
			/>
		);
	}

	return (
		<Container maxWidth="sm">
			<Typography variant="h4" gutterBottom>
				Workflow responsibility and reviewers
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SelectDropdown
						id={"Project"}
						value={selectedProjectId}
						options={projects}
						onChange={handleSetProject}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<DataLoadHandler
						loadedIds={loadedDocTeamsProjectIds}
						idsToLoad={[selectedProjectId]}
						loadFunction={loadDocumentsTeamsByProjectId}
					>
						{projectHasDocs() ? (
							<SelectDropdown
								id={"Document"}
								value={selectedDocumentId}
								options={documentOptions}
								onChange={handleSetDocument}
								fullWidth
							/>
						) : (
							<Typography variant="body" gutterBottom>
								No documents for selected project
							</Typography>
						)}
					</DataLoadHandler>
				</Grid>
				<Grid item xs={12}>
					<br />
					<Box display="flex" justifyContent="flex-end">
						<Button
							variant="contained"
							size="large"
							disabled={isViewDisabled}
							onClick={handleOpenWorkflowDetail}
						>
							View
						</Button>
					</Box>
				</Grid>
			</Grid>
			<br />
			<Divider />
			<br />
		</Container>
	);
}

export default WorkflowResponsibilityPage;