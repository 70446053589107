import React, { useState, useContext } from 'react';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { WorkflowsContext } from '../../../contexts';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'Workflows-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const WorkflowsTableComponent = ({ workflows, setView, setWorkflowId, getapis }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [deleteWorkflow, setDeleteWorkflow] = useState({ id: '', title: '' })

	const { deleteWorkflowById } = useContext(WorkflowsContext);

	const handleOpenDeleteDialog = (workflow) => {
  	setOpenDeleteConfirm(true);
		setDeleteWorkflow({ id: workflow.id, title: workflow.title })
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
	};

	const handleSubmit = async () => {
		const workflowData = await deleteWorkflowById(deleteWorkflow.id);

		if (!workflowData) {
			return setOpenDeleteConfirm(false);
		}
		else {
			setOpenDeleteConfirm(false);
			getapis();
		}
	}

	const columns = [
		{
			field: 'title',
			headerName: 'Name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'description',
			headerName: 'Description',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<Button
						color="primary"
						onClick={() => { setView(true); setWorkflowId(params.row.id) }}>
						View
					</Button>
					<SecurityButton
						featureName={SECURITY_FEATURES.WORKFLOW_PAGE}
						color="primary"
						onClick={() => { handleOpenDeleteDialog(params.row) }}>
						Delete
					</SecurityButton>
				</ButtonGroup>
			)
		},
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={workflows}
					columns={columns}
					rowHeight={42}
					autoHeight
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			</Box>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete workflow"
				message={`Please confirm that you would like to delete workflow ${deleteWorkflow.title}`}
				onConfirm={handleSubmit}
			/>
		</>
	);
};

export default WorkflowsTableComponent;