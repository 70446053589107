// src/ImageListForm.js
import React, { useContext, useState } from 'react';
import { Button, Box, Card, CardContent, Stack, Typography, Modal } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { SiteDataContext } from '../../../../contexts';
import { IMAGE_TYPE } from '../../../../constants/FileTypeConstants';
import SiteImagesUploadModal from '../../../modals/FileUploadModal/SiteImagesUploadModal';
import { ImageCheckList } from '../../../ui';

const ImageListForm = ({ siteLocationId, images, onChange, onAddImage }) => {
	const [openRemoveImages, setOpenRemoveImages] = useState(false);
	const [openAddImages, setOpenAddImages] = useState(false);
	const [selectedImages, setSelectedImages] = useState(images.map(image => image.id));

	const {
		deleteSiteImages
	} = useContext(SiteDataContext);

	const handleOpenRemoveImages = () => {
		setSelectedImages(images.map(image => image.id)); // Initialize all as selected
		setOpenRemoveImages(true);
	};

	const handleCloseRemoveImages = () => {
		setOpenRemoveImages(false);
	};

	const handleToggle = (id) => {
		setSelectedImages((prevSelected) =>
			prevSelected.includes(id) ? prevSelected.filter((imageId) => imageId !== id) : [...prevSelected, id]
		);
	};

	// Remove any images that are not selected
	const handleRemove = async () => {
		handleCloseRemoveImages();
		const imageIdsToDelete = images
			.filter((image) => !selectedImages.includes(image.id))
			.map((image) => image.id);
		if (imageIdsToDelete.length === 0)
			return;

		await deleteSiteImages(imageIdsToDelete, siteLocationId);
		const updatedImages = images.filter((image) => selectedImages.includes(image.id));
		onChange(updatedImages);
	};

	const handleImageUpload = (success, newImageData) => {
		if (!success)
			return;

		onAddImage(newImageData);
	}

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Images</Typography>
				<Box display="flex" flexWrap="wrap">
					{images.map((image) => (
						<img key={image.id} src={image.url} alt={image.title} style={{ width: 100, height: 100, margin: 5 }} />
					))}
				</Box>
				<Stack direction='row' spacing={1}>
					<Button variant="contained" color="secondary" onClick={handleOpenRemoveImages} startIcon={<DeleteIcon />}>
						Remove Images
					</Button>
					<Button variant="contained" color="secondary" onClick={() => setOpenAddImages(true)} startIcon={<AddIcon />}>
						Add Images
					</Button>
				</Stack>
				<Modal open={openRemoveImages} onClose={handleCloseRemoveImages}>
					<Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 550, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
						<Typography variant="h5" gutterBottom>Select images to keep</Typography>
						<ImageCheckList images={images} selectedImages={selectedImages} onToggle={handleToggle} />
						<Button variant="contained" color="primary" onClick={handleRemove}>
							Confirm
						</Button>
					</Box>
				</Modal>
				<SiteImagesUploadModal
					open={openAddImages}
					onClose={() => setOpenAddImages(false)}
					siteLocationId={siteLocationId}
					imageType={IMAGE_TYPE.Siteimages}
					onComplete={handleImageUpload}
				/>
			</CardContent>
		</Card>
	);
};

export default ImageListForm;
