import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import { UserModalTrigger } from '../../modals';
import { UserContext } from '../../../contexts/';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'TransactionLog-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const TransactionLogsTable = ({ logs }) => {
	const { users } = useContext(UserContext);

	const userIdToNameMap = useMemo(() => users.reduce((map, user) => {
		map[user.id] = user.name;
		return map;
	}, {}), [users]);

	const columns = [
		{
			field: 'userid',
			headerName: 'User',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return userIdToNameMap[row.userid];
			},
			renderCell: (params) => (
				<UserModalTrigger userId={params.row.userid} />
			)
		},
		{
			field: 'timestamp',
			headerName: 'Date',
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row) => {
				return new Date(row.timestamp).toLocaleString({}, {dateStyle: 'short', timeStyle: 'medium'});
			}
		},
		{
			field: 'eventType',
			headerName: 'Type',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'message',
			headerName: 'Description',
			minWidth: 160,
			flex: 1,
		}
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={logs}
				columns={columns}
				autoHeight
				rowHeight={42}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
				getRowId={row => row.rowKey}
			/>
		</Box>
	);
};

export default TransactionLogsTable;
