import React from 'react';
import {
	Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

// Accepts an array and parses it to display each
// item as a new line. Any input then gets converted back to original format of
// an array based on each new line.
const ArrayTextArea = ({ value, label, onChange, ...props }) => {
	// Convert the incoming value to newline-separated format
	const displayValue = value ? value.join('\n') : '';

	const handleTextFieldChange = (e) => {
		const newValue = e.target.value;
		// Convert back to string list format
		const arrayValue = newValue.split('\n');
		console.log("ARRAY TEXT:", arrayValue);
		if (onChange) {
			onChange(arrayValue);
		}
	};

	return (
		<>
			{label &&
				<Typography variant="h6">
					{label}
				</Typography>
			}
			<TextareaAutosize
				{...props}
				value={displayValue}
				onChange={handleTextFieldChange}
			/>
		</>

	);
};

export default ArrayTextArea;