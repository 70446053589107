import React, { useContext, useState } from 'react';
import { ButtonGroup, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import {
	LOIContext,
} from '../../../contexts';
import { PaginatedTable, SecurityButton } from '../../ui';

const defaultDelData = {
	id: '',
	loiName: '',
	name: ''
}

const AttributeGroupsTable = ({ attrGroupsData, onEditGroup }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [deleteAttrGroup, setDeleteAttrGroup] = useState({ ...defaultDelData });
	const {
		deleteAttributeGroup
	} = useContext(LOIContext);

	const handleOpenDeleteDialog = (attrGroup) => {
		setOpenDeleteConfirm(true);
		setDeleteAttrGroup({ id: attrGroup.id, name: attrGroup.name, loiName: attrGroup.loiName });
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
		setDeleteAttrGroup({ ...defaultDelData});
	};

	const handleDelete = async () => {
		setOpenDeleteConfirm(false);
		await deleteAttributeGroup(deleteAttrGroup.id, deleteAttrGroup.loiName);
	}

	return (
		<>
			<PaginatedTable data={attrGroupsData}>
				{(displayedData) => (
					<>
						<TableHead>
							<TableRow>
								<TableCell>Site LOI Name</TableCell>
								<TableCell>Title</TableCell>
								<TableCell>Scope</TableCell>
								<TableCell>Hide</TableCell>
								<TableCell>Order</TableCell>
								<TableCell>
									Attributes (Count)
									Contextual &lt;&gt; Data
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{displayedData.map((item) => (
								<TableRow key={item.id}>
									<TableCell>{item.loiName}</TableCell>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.scope}</TableCell>
									<TableCell>{item.hide ? 'True' : 'False'}</TableCell>
									<TableCell>{item.sortOrder}</TableCell>
									<TableCell>{item.contextAttributes.length + " <> " + item.dataAttributes.length}</TableCell>
									<TableCell>
										<ButtonGroup variant="outlined" size="small">
											<SecurityButton
												featureName={SECURITY_FEATURES.LOI_CREATE_UPDATE_DELETE}
												color="primary"
												onClick={() => { onEditGroup(item) }}>
												Edit
											</SecurityButton>
											<SecurityButton
												featureName={SECURITY_FEATURES.LOI_CREATE_UPDATE_DELETE}
												color="primary"
												onClick={() => { handleOpenDeleteDialog(item) }}>
												Delete
											</SecurityButton>
										</ButtonGroup>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</>
				)}
			</PaginatedTable>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete Attribute Group"
				message={`Please confirm that you would like to delete ${deleteAttrGroup.name}`}
				onConfirm={handleDelete}
			/>
		</>

	);
};

export default AttributeGroupsTable;
