// Functions used exclusively in development to transform data to the same format as that returned by server

function generateRandomId(length = 10) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}


function convertUpdateToLoiCell(loiUpdate) {
	return {
		id: loiUpdate.Id,
		classification: loiUpdate.CommonName,
		attributeId: loiUpdate.AttributeId,
		stage: loiUpdate.Stage,
		displayed: loiUpdate.IsDisplayed,
		required: loiUpdate.IsValidationRequired,
		responsibility: "???",
		order: 0,
		lastSaved: {
			userId: "00000000-0000-0000-0000-000000000000",
			when: "0001-01-01T00:00:00"
		}
	};
}

export function bulkUpdateLoiCellAdaptor(loiCellData) {

	const result = loiCellData.reduce((acc, item) => {
		console.log("cell item:", item);
		if (item.IsEnabled) {
			if (!item.Id)
				item.Id = generateRandomId();
			acc.data.push(convertUpdateToLoiCell(item));
		} else {
			if (item.Id)
				acc.deletes.push(item.Id);
		}
		return acc;
	}, { data: [], deletes: [] });

	return result;
}