import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormGroup,
	Typography,
	Stack
} from '@mui/material';
import {
	SelectDropdown,
	BasicTxtField,
	InputError
} from '../../ui';
import { DocumentContext, ProjectContext, SiteDataContext } from '../../../contexts';
import SiteLocationsComponent from './SiteLocationsComponent';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	name: [Validation.validateNotEmpty],
	locationIds: [Validation.validateNotEmptyArray]
}

const DocumentPage = ({ setCreate, handleProjectChange, projectId }) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [busy, setBusy] = useState(true);
	const [document, setDocument] = useState({
		siteId: siteId,
		projectId: projectId,
		name: '',
		workflowId: '',
		weightedValue: '1',
		locationIds: []
	});
	const [workflows, setWorkflows] = useState([]);
	const [formErrors, setFormErrors] = useState({});

	const {
		projects
	} = useContext(ProjectContext);
	const {
		loadWorkflowsForSite,
		createDocument
	} = useContext(DocumentContext);

	const {
		siteLocations,
		loadSiteLocations
	} = useContext(SiteDataContext);


	useEffect(() => {
		setBusy(true);

		async function getApis() {
			const responses = await Promise.all([
				loadWorkflowsForSite(siteId),
				loadSiteLocations()
			]);

			const workflowsData = await responses[0];
			const renamedWorkflows = workflowsData.map(({ title, id }) => ({ name: title, id: id }));
			setWorkflows(renamedWorkflows);
			setBusy(false);
		}

		getApis();
	}, [loadSiteLocations, loadWorkflowsForSite, siteId])

	// useEffect(() => {
	// 	console.log('edited document', document);
	// }, [document])

	const handleDataChange = (propertyName, newValue) => {
		setDocument(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const documentData = await createDocument(document);

		if (!documentData) {
			return setBusy(false);
		}
		else {
			setCreate(false);
			handleProjectChange(document.projectId);
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(document.name, "name", formValidators, newFormErrors);
		Validation.setFieldErrors(document.locationIds, "locationIds", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between" alignItems="flex-end">
							<Typography variant="body1">Create new document</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<BasicTxtField
							id={"Document name"}
							label="Document name"
							value={document.name}
							onChange={(value) => handleDataChange("name", value)}
							fullWidth={true}
							errors={formErrors['name']}
							required
						/>
						<SelectDropdown
							id={"Projects"}
							value={document.projectId}
							onChange={(value) => handleDataChange("projectId", value)}
							options={projects}
							setToDefault={true}
							fullWidth={true}
						/>
						<SelectDropdown
							id={"Workflow"}
							value={document.workflowId}
							onChange={(value) => handleDataChange("workflowId", value)}
							options={workflows}
							setToDefault={true}
							fullWidth={true}
						/>
						<Divider />
						<Typography variant="body1">Tag locations to document</Typography>
						<FormGroup errors={formErrors['locationIds']}>
							<SiteLocationsComponent locations={siteLocations} document={document} handleDataChange={handleDataChange} />
							<InputError field="Locations" errors={formErrors["locationIds"]} />
						</FormGroup>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setCreate(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)
}
export default DocumentPage;