import React, { useState, useContext } from 'react';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { WorkflowsContext } from '../../../contexts';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'Workflow-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const WorkflowTableComponent = ({ steps, setEditStep, setStepId, getapis }) => {
	const [open, setOpen] = useState(false);
	const [deleteWorkflowStep, setDeleteWorkflowStep] = useState({ id: '', title: '' });

	const { deleteWorkflowStepById } = useContext(WorkflowsContext);

	const handleOpenDialog = (step) => {
  	setOpen(true);
		setDeleteWorkflowStep({ id: step.id, title: step.title })
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		const stepData = await deleteWorkflowStepById(deleteWorkflowStep.id);

		if (!stepData) {
			return setOpen(false);
		}
		else {
			setOpen(false);
			getapis();
		}
	}

	const columns = [
		{
			field: 'position',
			headerName: 'Position',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'title',
			headerName: 'Title',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'colour',
			headerName: 'Colour',
			minWidth: 80,
			flex: 0.5,
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<WaterDropIcon sx={{ color: params.row.colour, height: '100%' }} />
			)
		},
		{
			field: 'description',
			headerName: 'Description',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'durationDays',
			headerName: 'Duration (days)',
			minWidth: 160,
			flex: 0.5,
		},
		{
			field: 'isMilestone',
			headerName: 'Display step in report',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isCommenting',
			headerName: 'Review enabled',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'generateStepAction',
			headerName: 'Workflow action enabled',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isReportedProcess',
			headerName: 'Report process',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isReportedContent',
			headerName: 'Report content',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isAutomatedStepChange',
			headerName: 'Auto step change',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'forecastCompletionStartStep',
			headerName: 'Forecast step start',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<SecurityButton
						featureName={SECURITY_FEATURES.WORKFLOW_STEP_UPDATE}
						color="primary"
						onClick={() => { setEditStep(true); setStepId(params.row.id) }}>
						Edit
					</SecurityButton>
					<SecurityButton
						featureName={SECURITY_FEATURES.WORKFLOW_STEP_CREATE_DELETE}
						color="primary"
						onClick={() => { handleOpenDialog(params.row) }}>
						Delete
					</SecurityButton>
				</ButtonGroup>
			)
		}
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={steps}
					columns={columns}
					rowHeight={42}
					autoHeight
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			</Box>
			<Dialog open={open} onClose={handleCloseDialog}>
				<DialogTitle sx={{ fontSize: '1rem' }}>Delete step</DialogTitle>
				<DialogContent>
					<Alert severity="warning">Please confirm that you would like to delete step {deleteWorkflowStep.title}</Alert>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						size="small"
						onClick={() => handleCloseDialog() }>
						Cancel
					</Button>
					<Button
						variant="contained"
						size="small"
						onClick={handleSubmit}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default WorkflowTableComponent;