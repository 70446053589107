import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Menu, MenuItem } from '@mui/material';
import TreeDragLayer from './TreeDragLayer';
import TreeNode from './TreeNode';
import '../SBTreeCore.css';
import './SBTree.css';

const SBTreeDragNDropView = ({ treeItems, contextMenuItems, onMoveItem, onSelectItem }) => {
	const [treeData, setTreeData] = useState(treeItems);
	const [selectedId, setSelectedId] = useState(null);
	const [hoveredNodeId, setHoveredNodeId] = useState(null);
	const [dropPosition, setDropPosition] = useState(null);
	const [boundingRect, setBoundingRect] = useState(null);
	const [contextMenu, setContextMenu] = useState(null);

	useEffect(() => {
		setTreeData(treeItems);
	}, [treeItems]);

	const handleContextMenu = (event, node) => {
		event.preventDefault();
		handleSetSelectedId(node.id);
		setContextMenu(
			contextMenu === null
				? {
					mouseX: event.clientX - 2,
					mouseY: event.clientY - 4,
				}
				: null,
		);
	};

	const handleSetSelectedId = (itemId) => {
		onSelectItem(itemId);
		setSelectedId(itemId);
	}

	const handleCloseMenu = () => {
		setContextMenu(null);
	};

	const handleMoveNode = (draggedNodeId, targetNodeId, position) => {
		if (draggedNodeId === targetNodeId) return;
		onMoveItem(draggedNodeId, targetNodeId, position);
	};

	const renderMenuItems = (items) => {
		return items.map((item, index) => {
			if (item.subMenu) {
				return (
					<MenuItem key={index}>
						{item.name}
						<Menu
							open={Boolean(contextMenu)}
							anchorReference="anchorPosition"
							anchorPosition={
								contextMenu !== null
									? { top: contextMenu.mouseY, left: contextMenu.mouseX }
									: undefined
							}
						>
							{renderMenuItems(item.subMenu)}
						</Menu>
					</MenuItem>
				);
			}
			return (
				<MenuItem key={index} onClick={() => {
					item.action(selectedId);
					handleCloseMenu();
				}}>
					{item.name}
				</MenuItem>
			);
		});
	};

	const renderTree = (nodes, level = 0) => {
		return (
			<ul>
				{nodes.map((node) => (
					<TreeNode
						key={node.id}
						node={node}
						level={level}
						selectedId={selectedId}
						setSelectedId={handleSetSelectedId}
						onMoveNode={handleMoveNode}
						hoveredNodeId={hoveredNodeId}
						setHoveredNodeId={setHoveredNodeId}
						dropPosition={dropPosition}
						setDropPosition={setDropPosition}
						setBoundingRect={setBoundingRect}
						onContextMenu={handleContextMenu}
					/>
				))}
			</ul>
		);
	};

	// Ensures drop icon is reset if mouse goes out of last known droppable item
	useEffect(() => {
		if (boundingRect && dropPosition) {
			const handleDragOver = (event) => {
				if (!dropPosition) return;
				const { clientX, clientY } = event;
				if (
					clientX < boundingRect.left ||
					clientX > boundingRect.right ||
					clientY < boundingRect.top ||
					clientY > boundingRect.bottom
				) {
					setDropPosition(null);
				}
			};
			document.addEventListener('dragover', handleDragOver);
			return () => {
				document.removeEventListener('dragover', handleDragOver);
			};
		}
	}, [boundingRect, dropPosition]);

	return (
		<DndProvider backend={HTML5Backend}>
			<div className="tree">
				{renderTree(treeData)}
				<TreeDragLayer dropPosition={dropPosition} />
				<Menu
					open={contextMenu !== null}
					onClose={handleCloseMenu}
					anchorReference="anchorPosition"
					anchorPosition={
						contextMenu !== null
							? { top: contextMenu.mouseY, left: contextMenu.mouseX }
							: undefined
					}
				>
					{renderMenuItems(contextMenuItems)}
				</Menu>
			</div>
		</DndProvider>
	);
};

export default SBTreeDragNDropView;
