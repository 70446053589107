import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import {
	ServerDateDisplay,
	SortableTable
} from '../../ui';
import { UserModalTrigger } from '../../modals';

const columns = [
	{ field: 'originalFileName', headerName: 'File Name' },
	{ field: 'description', headerName: 'Description' },
	{ field: 'type', headerName: 'Type' },
	{ field: 'status', headerName: 'Status' },
	{ field: 'revision', headerName: 'Revision' },
	{ field: 'issue', headerName: 'Issue' },
	{ field: 'company', headerName: 'Author Company' },
	{ field: 'uploadedBy', headerName: 'Uploaded By', isUserId: true },
	{ field: 'uploadDate', headerName: 'Upload Date' },
	{ field: 'size', headerName: 'Size' }
];

const FileTableDisplay = ({ files, selectedFileId, setSelectedFileId }) => {
	const handleRowClick = (fileId) => {
		setSelectedFileId(fileId);
	};

	const rowStyles = (fileId) => ({
		cursor: 'pointer',
		backgroundColor: selectedFileId === fileId ? '#e0f7fa' : '',
	});

	return (
		<>
			<SortableTable data={files} columns={columns} hasFilter>
				{(displayedFiles) => (
					<>
						{displayedFiles.map((file, index) => (
							<TableRow
								key={index}
								hover
								onClick={() => handleRowClick(file.id)}
								selected={selectedFileId === file.id}
								style={rowStyles(file.id)}
							>
								<TableCell>{file.originalFileName}</TableCell>
								<TableCell>{file.description}</TableCell>
								<TableCell>{file.type}</TableCell>
								<TableCell>{file.status}</TableCell>
								<TableCell>{file.revision}</TableCell>
								<TableCell>{file.issue}</TableCell>
								<TableCell>{file.company}</TableCell>
								<TableCell><UserModalTrigger userId={file.uploadedBy} /></TableCell>
								<TableCell><ServerDateDisplay date={file.uploadDate} /></TableCell>
								<TableCell>{file.size}</TableCell>
							</TableRow>
						))}
					</>
				)}
			</SortableTable>
		</>
	);
};

FileTableDisplay.propTypes = {
	files: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedFileId: PropTypes.string,
	setSelectedFileId: PropTypes.func
};


export default FileTableDisplay;