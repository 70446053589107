import React, { useContext, useState } from 'react';
import { Button, Card, CardHeader, CardContent, Stack, Typography } from '@mui/material';
import * as Api from '../../../../api/AdminApi';
import ConfirmationDialog from '../../../modals/ConfirmationDialog';
import {
	BasicTxtField
} from '../../../ui';

const AddressForm = ({ data, onChange }) => {
	const [openConfirmLatLong, setOpenConfirmLatLong] = useState(false);
	const [suggestedLatLong, setSuggestedLatLong] = useState({});

	const isValidSearch = () => {
		return data.add1 && data.postcode && data.country;
	}

	const handleSearchLatLong = async () => {
		let searchParams = {
			Address: data.add1,
			Postcode: data.postcode,
			Country: data.country
		}
		try {
			let searchResults = await Api.searchLatlong(searchParams);
			if (searchResults === null) {
				searchResults = {
					lat: 0,
					lng: 0
				}
			}
			setSuggestedLatLong(searchResults);
			setOpenConfirmLatLong(true);
		} catch (error) {
			console.error("ERROR during lat long search:", error);
		}
	}

	const handleConfirmLatLong = () => {
		onChange(suggestedLatLong.lat, "lat");
		onChange(suggestedLatLong.lng, "lng");
	}

	return (
		<Card>
			<CardHeader
				title={<Typography variant="h6">Address Information</Typography>}
			/>
			<CardContent>
				<Stack spacing={1} mt={1} mb={1}>
					<BasicTxtField
						fullWidth
						label="Address 1"
						id="add1"
						value={data.add1}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Address 2"
						id="add2"
						value={data.add2}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Address 3"
						id="add3"
						value={data.add3}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Town/City"
						id="townCity"
						value={data.townCity}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="County"
						id="county"
						value={data.county}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Country"
						id="country"
						value={data.country}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Postcode"
						id="postcode"
						value={data.postcode}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Latitude"
						id="lat"
						value={data.lat}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						label="Longitude"
						id="lng"
						value={data.lng}
						onChange={onChange}
					/>
				</Stack>
				<Button
					variant="contained"
					onClick={handleSearchLatLong}
					disabled={!isValidSearch()}
				>
					Search Lat Long
				</Button>
				{!isValidSearch() && (
					<Typography color="error" variant="body1">
						Please enter Address 1, Postcode and Country to perform a LatLong Search
					</Typography>
				)}
				<ConfirmationDialog
					open={openConfirmLatLong}
					onClose={() => setOpenConfirmLatLong(false)}
					title="Confirm Lat Long"
					message={`Please confirm that you would like to assign the values LAT: ${suggestedLatLong.lat} LNG: ${suggestedLatLong.lng}`}
					onConfirm={handleConfirmLatLong}
				/>
			</CardContent>
		</Card>
	);
};

export default AddressForm;