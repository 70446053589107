import React from 'react';
import {
	Grid,
	Typography,
	TextField,
} from '@mui/material';
import {
	GridDivider
} from '../../../ui';

const dataConfigArrIndexs = {
	DATA_INPUT_TYPE: 0,
	SEPERATOR: 1,
	FORMAT: 2,
	GHOSTSCRIPT: 3,
}

function MaskedInputConfig({ dataConfigArr, onDataChange }) {
	return (
		<>
			<Grid item xs={6}>
				<TextField
					label="Seperator"
					variant="outlined"
					value={dataConfigArr[dataConfigArrIndexs.SEPERATOR]}
					onChange={(e) => onDataChange(dataConfigArrIndexs.SEPERATOR, e.target.value)}
					placeholder="Enter seperator"
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Could be a "-" or a "\" but NOT  "&" or "#" or "$"
				</Typography>
			</Grid>

			<GridDivider />

			<Grid item xs={6}>
				<TextField
					label="Format"
					variant="outlined"
					value={dataConfigArr[dataConfigArrIndexs.FORMAT]}
					onChange={(e) => onDataChange(dataConfigArrIndexs.FORMAT, e.target.value)}
					placeholder="Enter format"
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					& = AplhaNumeric, # = Numberic, $ = Aplha  (example) ABCD-&&&&-####-$$$$
				</Typography>
			</Grid>

			<GridDivider />

			<Grid item xs={6}>
				<TextField
					size="small"
					label="Ghostscript"
					variant="outlined"
					value={dataConfigArr[dataConfigArrIndexs.GHOSTSCRIPT]}
					onChange={(e) => onDataChange(dataConfigArrIndexs.GHOSTSCRIPT, e.target.value)}
					placeholder="Enter ghostscript"
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Value to show if no value, example: ABCD-A2B2-1234-ABCD
				</Typography>
			</Grid>

			<GridDivider />
		</>
	);
}

export default MaskedInputConfig;