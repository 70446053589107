export function generateRandomID(length) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
}

// Stress testing function:
export const duplicateArrData = (arr, times) => {
	const duplicatedData = [...arr]; // Start with the original data

	for (let i = 0; i < times; i++) {
		arr.forEach(user => {
			const newUser = { ...user, id: generateRandomID(32) }; // Duplicate the data and assign new id
			duplicatedData.unshift(newUser);
		});
	}

	return duplicatedData;
};

// DATE HELPERS:
export function convertDotNetDateToTimestamp(dotNetDateStr) {
	const timestamp = parseInt(dotNetDateStr.match(/-?\d+/)[0], 10);
	return timestamp;
}

// STRING HELPERS:
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDisplayLabel(label, id) {
	let displayLabel = label !== null ? label : id;
	return capitalizeFirstLetter(displayLabel);
}

export function getFileExtension(filename) {
	if (!filename || typeof filename !== 'string') {
		return '';
	}

	const parts = filename.split('.');

	return parts.length > 1 ? parts[parts.length - 1] : '';
}

// OBJECT HELPERS:
export function doesObjectContainField(obj, field) {
	return obj && typeof obj === 'object' && field in obj;
}

export function isObjectEmpty(obj) {
	return Object.keys(obj).length === 0;
}

export function isEmpty(data) {
	if (data == null) {
		return true;
	}

	if (Array.isArray(data)) {
		return data.length === 0;
	}

	if (typeof data === 'object') {
		return isObjectEmpty(data);
	}

	return false; // for all other types that are not considered in this context
}

export function hasDataLoaded(data) {
	return !isEmpty(data);
}

export function updateNestedFormProperty(formData, subformName, nestedPropertyName, newValue) {
	if (!formData) formData = {};  // If formData is null or undefined, initialize it as an empty object

	if (!formData[subformName]) {
		formData[subformName] = {};  // If the subform doesn't exist, create it
	}

	return {
		...formData,
		[subformName]: {
			...formData[subformName],
			[nestedPropertyName]: newValue,
		},
	};
}

// Rich TREE view Helpers:

export function convertToTreeStructure(items, idKey = 'id', labelKey = 'name', childrenKey = 'children') {
	return items.map(item => {
		const node = {
			id: item[idKey].toString(),
			label: item[labelKey],
			children: item[childrenKey] ? convertToTreeStructure(item[childrenKey], idKey, labelKey, childrenKey) : []
		};
		return node;
	});
}

const selectAll = (item) => true;

export const getAllItemIds = (treeData, childname, selectorFunc = selectAll) => {
	const ids = [];
	const registerItemId = (item) => {
		if (selectorFunc(item))
			ids.push(item.id);
		item[childname]?.forEach(registerItemId);
	};
	treeData.forEach(registerItemId);

	return ids;
};

// Converts JSON object into camel case
export function toCamelCase(obj) {
	if (Array.isArray(obj)) {
		return obj.map(v => toCamelCase(v));
	} else if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((result, key) => {
			// This will convert the keys to camelCase
			const camelCaseKey = key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase())
				.replace(/([A-Z]+)([A-Z][a-z])/g, (match, p1, p2) => p1 + p2.toLowerCase())
				.replace(/^([A-Z])/, (match, p1) => p1.toLowerCase());
			result[camelCaseKey] = toCamelCase(obj[key]);
			return result;
		}, {});
	}
	return obj;
}

// Sets obj's sub obj at path to value
// e.g. path could be attributes.config
export function setValueAtPath(obj, path, value) {
	const keys = path.split('.'); // Split the path into keys
	let current = obj;
	for (let i = 0; i < keys.length - 1; i++) { // Navigate through the object to the last key
		const key = keys[i];
		if (!(key in current)) {
			current[key] = {}; // Create nested objects as needed
		}
		current = current[key];
	}
	current[keys[keys.length - 1]] = value; // Set the value at the nested path
}

// Converts an object (non nested) into a list of params in the form key=value&key=value
// as long as the obj value is NOT NULL
export function getParamsFromObj(obj) {
	return Object.entries(obj)
		.filter(([key, value]) => value !== null)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');
}

// ARRAY HELPERS:

export function arraysAreEqual(arr1, arr2) {
	if (arr1.length !== arr2.length) return false;

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	return true;
}

export function removeItemFromList(arr, item) {
	return arr.filter(arrItem => arrItem !== item)
}

export function sortArrByProperty(array, property) {
	return array.sort((a, b) => {
		const propA = a[property].toUpperCase();
		const propB = b[property].toUpperCase();

		if (propA < propB) {
			return -1;
		}
		if (propA > propB) {
			return 1;
		}
		return 0;
	});
}

export function chunkArray(array, chunkSize) {
	let chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize));
	}
	return chunks;
}

export function transformArrToIdChecklist(arr) {
	return arr.reduce((acc, item) => {
		acc[item.id] = true;
		return acc;
	}, {});
}

export function getSelectListFromObjectKeys(object) {
	return Object.keys(object).map((key) => {
		return {
			id: key,
			name: key
		};
	});
}

export function convertArrayToSelectList(arr) {
	return arr.map((name, index) => ({
		id: name,
		name: name
	}));
}

export function addNewUniqueIds(prevItems, newIds) {
	const itemSet = new Set(prevItems);
	newIds.forEach(id => itemSet.add(id));
	return Array.from(itemSet);
}

// Datagrid helpers

export function csvTitleDate() {
	return new Date().toLocaleString('default', { dateStyle: 'short' });
}