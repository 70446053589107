import React from 'react';
import {
	Button,
	CircularProgress
} from '@mui/material';

function SearchButton({ isSearching, onClick }) {
	return (
		<Button
			size="small"
			variant="contained"
			onClick={onClick}
			color="primary"
			disabled={isSearching}
		>
			{isSearching ? <CircularProgress size={24} /> : 'Search'}
		</Button>
	);
}

export default SearchButton;