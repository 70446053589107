import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Grid, Typography } from '@mui/material';
import { LOIContext, SiteDataContext } from '../../contexts';
import {
	SelectDropdown,
	LoadingSpinner,
	VirtualisedList
} from '../ui';
import LoadingModal from './LoadingModal';
import { useDataLoader } from '../customHooks/useDataLoader';

/*
						<SelectDropdown
							id={"siteSrc"}
							label={"Select SRC SITE"}
							value={srcSite}
							options={mySitesData}
							onChange={handleSetSrcSite}
							fullWidth
						/>

*/

function CopyLOIModal({ open, onClose }) {
	const {
		loadLOINamesForSite,
		copyLOI,
	} = useContext(LOIContext);
	const {
		mySitesData
	} = useContext(SiteDataContext);
	const { isLoading, error, loadData } = useDataLoader();
	const [srcSite, setSrcSite] = useState("");
	const [srcLoi, setSrcLoi] = useState("");
	const [newLOIName, setNewLOIName] = useState("");
	const [siteLoiNames, setSiteLoiNames] = useState([]);

	// Site/srcloi and newLoiName must not be null for this to be valid
	const isValid = () => {
		return srcSite && srcLoi && newLOIName;
	};

	const handleSetSrcSite = async (srcSiteId) => {
		setSrcSite(srcSiteId);
		setSrcLoi("");
		const loiNames = await loadData(loadLOINamesForSite, srcSiteId);
		if (loiNames) {
			setSiteLoiNames(loiNames);
		}
	}

	const handleProcessClick = () => {
		console.log("Process COPY");

		const copyLoiData = {
			SourceSiteId: srcSite,
			SourceLOIName: srcLoi,
			NewLOIName: newLOIName,
		};
		onClose();
		copyLOI(copyLoiData);
	}

	const siteDataLoading = mySitesData.length === 0;
	const isLoiListLoading = isLoading || siteLoiNames.length === 0;
	const isLoiListEmpty = !isLoading && srcSite && siteLoiNames.length === 0;

	if (siteDataLoading) {
		return <LoadingModal title="Copy LOI" open={open} onClose={onClose} />
	}

	console.log("MY sites:", mySitesData);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h5">
				Copy LOI From Another Site
			</DialogTitle>
			<DialogContent>
				<Grid container mt={1} spacing={1}>
					<Grid item xs={12}>
						<VirtualisedList
							title={"Select Source Site"}
							items={mySitesData}
							onChange={handleSetSrcSite}
							value={srcSite}
						/>
					</Grid>
					<Grid item xs={12}>
						{!isLoiListLoading &&
							<SelectDropdown
								id={"loiSrc"}
								label={"Select SRC LOI"}
								value={srcLoi}
								options={siteLoiNames}
								onChange={setSrcLoi}
								fullWidth
							/>
						}
						<LoadingSpinner isLoading={isLoading} />
						{isLoiListEmpty &&
							<Typography>
								No LOIs exist for site!
							</Typography>
						}
					</Grid>
					<Grid item xs={12}>
						{!isLoiListLoading &&
							<TextField
								size="small"
								label="New LOI Name"
								variant="outlined"
								value={newLOIName}
								onChange={(e) => setNewLOIName(e.target.value)}
								placeholder="Enter New LOI Name"
								fullWidth
							/>
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					disabled={!isValid()}
					onClick={handleProcessClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Process
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default CopyLOIModal;