import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import * as Api from '../../../api/AdminApi';
import UserRoleChangesTable from './UserRoleChangesTable';
import {
	LoadingSpinner,
	SBDateRangePicker
} from '../../ui';
import { UserContext, DocumentContext } from '../../../contexts/';

const MAX_DAY_DIFF = 90;

function UserRoleChangesPage() {
	const [loadingData, setLoadingData] = useState(false);
	const [userRoleChanges, setUserRoleChanges] = useState([]);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [documents, setDocuments] = useState([]);
	const { loadUsersById } = useContext(UserContext);
	const { cachedDocuments, loadDocumentsBySite } = useContext(DocumentContext);

	useEffect(() => {
		fetchData({});
	}, []);

	const getUniqueUserIdsFromChanges = (roleChanges) => {
		const uniqueUserIds = new Set();

		// Iterate over the data and add userId and byUserId to the set
		roleChanges.forEach(item => {
			if (item.userId) uniqueUserIds.add(item.userId);
			if (item.byUserId) uniqueUserIds.add(item.byUserId);
		});

		// Convert the set to an array
		return Array.from(uniqueUserIds);
	}

	const fetchData = async (requestOptions) => {
		setLoadingData(true);
		try {
			const roleChanges = await Api.loadUserRoleChanges(requestOptions);
			const userIdsToLoad = getUniqueUserIdsFromChanges(roleChanges);
			await loadUsersById(userIdsToLoad);
			setUserRoleChanges(roleChanges);

			if (!cachedDocuments.length) {
				const documentsData = await loadDocumentsBySite();
				setDocuments(documentsData);
			}
			else setDocuments(cachedDocuments);
		} catch (error) {
			console.error('Error fetching data', error);
		}
		setLoadingData(false);
	};

	const handleSetToFromDate = (newFromDate, newToDate) => {
		if (newFromDate && newToDate) {
			const requestOptions = {
				from: newFromDate,
				to: newToDate
			};
			fetchData(requestOptions);
		}
		setFromDate(newFromDate);
		setToDate(newToDate);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant={'h4'}>
					User Role Changes
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<SBDateRangePicker
					fromDate={fromDate}
					toDate={toDate}
					setToFromDate={handleSetToFromDate}
					maxDayDiff={MAX_DAY_DIFF}
				/>
			</Grid>
			{!loadingData &&
				<Grid item xs={12}>
					<UserRoleChangesTable userRoleChanges={userRoleChanges} documents={documents} />
				</Grid>
			}
			<Grid item xs={12}>
				<LoadingSpinner isLoading={loadingData} />
			</Grid>
		</Grid>
	);
}
//<LocationDetails location={selectedLocation} />
export default UserRoleChangesPage;