import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { drawerWidth, menuItems } from '../constants/MenuConstants';
import { SiteSecurityContext } from '../contexts';

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

/*
const CustomMenuListItem = ({ title, open, navigateTo, page = "NotAdded", icon: IconComponent, isHeader = false, isAdminOnly = false }) => (
	const { isSiteAdmin } = useContext(SiteSecurityContext);

	if (title === "Divider")
		{
			return <Divider />
		}
		if (isAdminOnly && !isSiteAdmin) {
			return null;
		}
	<ListItem disablePadding={true} sx={{ display: 'block' }}>
		{isHeader ? (
			<Typography
				variant="body1"
				color="textSecondary"
				sx={{
					opacity: open ? 1 : 0,
					px: 2,
					paddingTop: 1,
					paddingBottom: 0,
					fontWeight: 'bold',
					fontSize: '18px'
				}}
			>
				{title}
			</Typography>
		) : (
			<ListItemButton
				sx={{
					minHeight: 38,
					justifyContent: open ? 'initial' : 'center',
					px: 2.5,
					paddingTop: 0,
					paddingBottom: 0,
				}}
				onClick={() => {
					navigateTo(page);
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : 'auto',
						justifyContent: 'center',
					}}
				>
					<IconComponent />
				</ListItemIcon>
				<ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
			</ListItemButton>
		)}
	</ListItem>
);
*/

const CustomMenuListItem = ({ title, open, navigateTo, page = "NotAdded", icon: IconComponent, isHeader = false, isAdminOnly = false, index }) => {
	const { isSiteAdmin } = useContext(SiteSecurityContext);

	if (title === "Divider") {
		return <Divider key={index} />;
	}

	if (isAdminOnly && !isSiteAdmin) {
		return null;
	}

	if (isHeader) {
		return (
			<ListItem disablePadding sx={{ display: 'block' }}>
				<Typography
					variant="body1"
					color="textSecondary"
					sx={{
						opacity: open ? 1 : 0,
						px: 2,
						paddingTop: 1,
						paddingBottom: 0,
						fontWeight: 'bold',
						fontSize: '18px'
					}}
				>
					{title}
				</Typography>
			</ListItem>
		);
	}

	// Default menu item
	return (
		<ListItem disablePadding sx={{ display: 'block' }}>
			<ListItemButton
				sx={{
					minHeight: 38,
					justifyContent: open ? 'initial' : 'center',
					px: 2.5,
					paddingTop: 0,
					paddingBottom: 0,
				}}
				onClick={() => {
					navigateTo(page);
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : 'auto',
						justifyContent: 'center',
					}}
				>
					<IconComponent />
				</ListItemIcon>
				<ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
			</ListItemButton>
		</ListItem>
	);
};

//useMediaQuery(theme.breakpoints.up('md'))

export default function MainNavigationBar({ children, navigateTo }) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap component="div">
						EDocs Platform / Settings
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{menuItems.map((item, index) => (
						item.title === "Divider" ? <Divider key={index} /> :
							<CustomMenuListItem key={index} open={open} navigateTo={navigateTo} {...item} />
					))}
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				{children}
			</Box>
		</Box>
	);
}