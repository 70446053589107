import React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {
	BasicTxtField
} from '../';


const AddTreeNodeModal = ({ open, onClose, onSubmit }) => {
	const [newNodeName, setNewNodeName] = React.useState('');

	const handleDialogClose = () => {
		setNewNodeName('');
		onClose();
	};

	const handleSave = () => {
		onSubmit(newNodeName);
		handleDialogClose();
	};

	return (
		<Dialog open={open} onClose={handleDialogClose}>
			<DialogTitle>Add New Location</DialogTitle>
			<DialogContent>
				<Box mt={1}>
					<BasicTxtField
						id="name"
						value={newNodeName}
						onChange={setNewNodeName}
						fullWidth
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDialogClose} variant="outlined" color="primary" size="small">
					Cancel
				</Button>
				<Button onClick={handleSave} variant="contained" color="primary" size="small">
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddTreeNodeModal;