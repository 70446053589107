
const deepClone = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

export const updateSectionTreeBySelectedIds = (sectionTree, selectedIds) => {
	const updateNode = (node) => {
		if (selectedIds.includes(node.id)) {
			node.isKeyForm = true;
		} else {
			node.isKeyForm = false;
		}
		if (node.nodes && node.nodes.length > 0) {
			node.nodes.forEach(updateNode);
		}
	};

	const clonedTree = deepClone(sectionTree);

	clonedTree.forEach(updateNode);
	return clonedTree;
};

export const updateSectionsStartDate = (sections, sectionIds, startDate) => {
	return sections.map(section => {
		if (sectionIds.includes(section.id)) {
			return {
				...section,
				startDate: startDate
			};
		}
		return section;
	});
}

export const updateSectionsContractor = (sections, sectionIds, companyId) => {
	return sections.map(section => {
		if (sectionIds.includes(section.id)) {
			return {
				...section,
				contractor: companyId
			};
		}
		return section;
	});
}