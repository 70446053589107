import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack
} from '@mui/material';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField
} from '../../../components/ui/';

const formValidators = {
	name: [Validation.validateNotEmpty, Validation.validateMaxLength(100)]
};

const initialValue = {
	name: ''
};

const EditRoleModal = ({ open, onClose, currentRole, onSave }) => {
	const [editedRole, setEditedRole] = useState({ ...initialValue, ...currentRole });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedRole({ ...initialValue, ...currentRole });
		resetFormErrors();
	}, [currentRole, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedRole({ ...editedRole, [propertyName]: newValue });
	};

	const handleSaveLocal = () => {
		if (!isFormValid(editedRole)) return;
		onClose();
		onSave(editedRole);
	};

	const isNewRole = !editedRole.id;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h4">
				{editedRole?.id ? 'Edit Site Security Role' : 'Add Site Security Role'}
			</DialogTitle>
			<DialogContent>
				<Stack spacing={1} mt={1}>
					<BasicTxtField
						id="name"
						label="Role Name"
						value={editedRole.name}
						onChange={handleDataChange}
						errors={formErrors['name']}
						fullWidth
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={handleSaveLocal}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditRoleModal;
