import React, { useState, useEffect, useContext } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import {
	LOIContext,
} from '../../../contexts';
import {
	ApiStatusDisplay,
	SelectDropdown,
	SecurityButton,
	LoadingSpinner
} from '../../ui';
import { LoadingPage } from '..';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';
import AttributeGroupsTable from './AttributeGroupsTable';
import AttributeGroupModal from './AttributeGroupModal';
import AttributeGroupCreateModal from './AttributeGroupCreateModal';


const AttributeGroupsPage = () => {
	const {
		loiNamesSelectList,
		loiAttributeGroupsByLoiName,
		loadLOIAttributes,
		loadAttributeGroupsByLOIName,
		loadLOINames,
	} = useContext(LOIContext);
	const [dataLoaded, setDataloaded] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [selectedLOI, setLOI] = useState("");
	const [currentGroup, setCurrentGroup] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [createModalOpen, setCreateModalOpen] = useState(false);

	useEffect(() => {
		loadLOIAttributes();
		loadLOINames();
	}, [loadLOINames, loadLOIAttributes]);

	const selectLOI = async (loiName) => {
		setDataloaded(false);
		setDataLoading(true);
		setLOI(loiName);
		setDataloaded(await loadAttributeGroupsByLOIName(loiName));
		setDataLoading(false);
	}

	const handleEditGroup = (attrGroup) => {
		console.log("LOADING EDIT ATTRIBUTE GROUP");
		setCurrentGroup(attrGroup);
		setModalOpen(true);
	}

	const handleCreateGroup = (loiName) => {
		setCurrentGroup({ loiName });
		setCreateModalOpen(true);
	}

	const hasLOILoaded = !isObjectEmpty(loiNamesSelectList);
	if (!hasLOILoaded) {
		return <LoadingPage />;
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h4">Attribute Groups</Typography>
			</Grid>
			<Grid item xs={3}>
				<SelectDropdown
					id={"LOI"}
					value={selectedLOI}
					options={loiNamesSelectList}
					onChange={selectLOI}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<ApiStatusDisplay />
				{dataLoaded &&
					<>
						<AttributeGroupsTable
							onEditGroup={handleEditGroup}
							attrGroupsData={loiAttributeGroupsByLoiName[selectedLOI]}
						/>
						<Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
							<SecurityButton
								featureName={SECURITY_FEATURES.LOI_CREATE_UPDATE_DELETE}
								variant="contained"
								color="primary"
								size="small"
								onClick={() => handleCreateGroup(selectedLOI)}>
								Create
							</SecurityButton>
						</Stack>
					</>
				}
				<LoadingSpinner isLoading={dataLoading} />
			</Grid>
			<Grid item xs={12}>
				<AttributeGroupModal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					currentGroup={currentGroup || {}}
				/>
				<AttributeGroupCreateModal
					open={createModalOpen}
					onClose={() => setCreateModalOpen(false)}
					currentGroup={currentGroup || {}}
				/>
			</Grid>
		</Grid>
	);

}

export default AttributeGroupsPage;