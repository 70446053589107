
export function transformSearchData(data) {
	return data
		.filter(item => item.hasValidEmail) // Filter out invalid emails
		.map(item => {
			const user = item.users[0]; // Assuming there's always at least one user
			return {
				id: user?.id || 'NEW',
				typedEmail: item.email,
				matchType: item.matchType,
				email: user?.email || item.email,
				firstName: user?.firstName || '',
				secondName: user?.secondName || '',
				company: user?.companyName || '',
				phone: '',
				MatchType: item.matchType,
				matchedUsers: item.users
			};
		});
}