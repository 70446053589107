import React from 'react';
import { Marker, Popup, Polygon, Circle, Rectangle } from 'react-leaflet';
import { Typography } from '@mui/material';

const MapPopup = ({ element }) => {
	return (
		<Popup>
			<Typography variant="body2">{element.title}</Typography>
			<a href={element.url} target="_blank" rel="noopener noreferrer">{element.url}</a>
		</Popup>
	);
}

const MapElement = ({ element, onRightClick }) => {
	const colorProps = { color: element.color, fillColor: element.color, fillOpacity: 0.2 };

	const handleContextMenu = (e) => {
		e.originalEvent.preventDefault();
		onRightClick(element, e);
	};

	switch (element.renderType) {
		case 'marker':
			return (
				<Marker
					customId={element.id}
					layerType={element.renderType}
					position={[element.positions[0].lat, element.positions[0].lng]}
					eventHandlers={{ contextmenu: handleContextMenu }}
				>
					<MapPopup element={element} />
				</Marker>
			);
		case 'polygon':
			return (
				<Polygon
					customId={element.id}
					layerType={element.renderType}
					positions={element.positions.map(pos => [pos.lat, pos.lng])}
					pathOptions={colorProps}
					eventHandlers={{ contextmenu: handleContextMenu }}
				>
					<MapPopup element={element} />
				</Polygon>
			);
		case 'rectangle':
			return (
				<Rectangle
					customId={element.id}
					layerType={element.renderType}
					bounds={element.positions.map(pos => [pos.lat, pos.lng])}
					pathOptions={colorProps}
					eventHandlers={{ contextmenu: handleContextMenu }}
				>
					<MapPopup element={element} />
				</Rectangle>
			);
		case 'circle':
			return (
				<Circle
					customId={element.id}
					layerType={element.renderType}
					center={[element.positions[0].lat, element.positions[0].lng]}
					radius={element.radius}
					pathOptions={colorProps}
					eventHandlers={{ contextmenu: handleContextMenu }}
				>
					<MapPopup element={element} />
				</Circle>
			);
		default:
			return null;
	}
};

export default MapElement;