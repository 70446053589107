import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';

export async function getCurrentSiteData() {
	try {
		console.log("Loading site by id");
		if (DEV_TEST_MODE) {
			return loadJsonFile('getCurrentSiteData.json');
		}

		let data = await springboardApiRequest(endpoints.site.getById(getSiteId()), "GET");
		console.log("CURRENT SITE DATA:", data.data);
		downloadJsonData(data.data, "getCurrentSiteData");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching site data:', error);
		throw new Error('Failed to fetch site data.');
	}
}

export async function loadSiteLocations() {
	try {
		console.log("Loading SiteLocations");
		if (DEV_TEST_MODE) {
			return loadJsonFile('LoadSiteLocations.json');
		}

		const getRootLocationsEndpoint = endpoints.siteLocations.getRootLocations();

		console.log("Loading SiteLocations from: ", getRootLocationsEndpoint);
		let data = await springboardApiRequest(getRootLocationsEndpoint, "GET");
		console.log("SITE LOCATIONS LOAD:", data);
		downloadJsonData(data.data, "LoadSiteLocations");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching locations data:', error);
		throw new Error('Failed to fetch locations data.');
	}
}

export async function searchLatlong(searchParams) {
	try {
		const data = await springboardApiRequest(endpoints.siteLocations.searchLatlong(searchParams), "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating mappoint data:', error);
		throw new Error('Failed to update mappoint data.');
	}
}

export async function addSiteLocation(locationData) {
	return await apiRequestManager.managePostRequest("siteLocations", "addLocation", locationData);
}

export async function deleteBranch(deleteModel) {
	return await apiRequestManager.managePostRequest("siteLocations", "deleteBranch", deleteModel);
}

export async function moveLocation(locationData) {
	return await apiRequestManager.managePostRequest("siteLocations", "moveLocation", locationData);
}

export async function updateSite(siteData) {
	return await apiRequestManager.managePostRequest("site", "update", siteData);
}

export async function createQuickLink(linkData) {
	return await apiRequestManager.managePostRequest("siteLocations", "createQuickLink", linkData);
}

export async function deleteQuickLink(linkData) {
	return await apiRequestManager.managePostRequest("siteLocations", "deleteQuickLink", linkData);
}

export async function updateQuickLink(linkData) {
	return await apiRequestManager.managePostRequest("siteLocations", "updateQuickLink", linkData);
}

export async function updateSiteLocation(locationData) {
	return await apiRequestManager.managePostRequest("siteLocations", "updateLocation", locationData);
}

export async function processSiteImage(imageOptions) {
	return await apiRequestManager.managePostRequest("file", "processSiteImage", imageOptions);
}

export async function processImageMapImage(imageOptions) {
	return await apiRequestManager.managePostRequest("file", "processImageMapImage", imageOptions);
}

export async function processSiteBrandingImage(imageOptions) {
	return await apiRequestManager.managePostRequest("file", "processSiteBrandingImage", imageOptions);
}

export async function updateImageMapPoints(mapPointsData) {
	return await apiRequestManager.managePostRequest("siteLocations", "updateImageMapPoints", mapPointsData);
}

export async function deleteSiteImages(deleteImagesReq) {
	let requestId = apiRequestManager.addRequestToQueue("deleteSiteImages");
	try {
		const deleteEndpoint = endpoints.file.deleteSiteImages();

		// Perform the delete operation for each image in deleteImagesReq
		const deletePromises = deleteImagesReq.map(async (imageReq) => {
			// Make the API request to delete the image
			let result = await springboardApiRequest(deleteEndpoint, "POST", imageReq);
			console.log("Delete site image result:", result);
			return result.data;
		});

		apiRequestManager.setRequestSuccess(requestId, "Successfully deleted site images!");

		const results = await Promise.all(deletePromises);
		return results;
	} catch (error) {
		apiRequestManager.setRequestFailure(requestId, error.message);
		console.error('Error while deleting site image files', error);
		throw new Error('Failed to delete site image files');
	}
}

export async function deleteImageMapImage(deleteImageReq) {
	return await apiRequestManager.managePostRequest("file", "deleteImageMapImage", deleteImageReq);
}