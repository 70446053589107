import { Typography } from "@mui/material";

function UserReportCellRenderer(props) {
	const roles = props.value;
	if (!roles || roles.length === 0) {
		return null;
	}

	return (
		<>
			{roles.map(role => (
				<Typography key={role}>
					{role}
				</Typography>
			))}
		</>
	);
}

export default UserReportCellRenderer;