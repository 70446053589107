import React, { useContext, useMemo } from 'react';
import {
	Box,
	Button,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import { UserContext } from '../../../contexts';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'SiteSecurity-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const SiteSecurityTable = ({ settings, onEdit }) => {
	// const { roles } = useContext(UserContext);
	// const rolesDict = useMemo(() => {
	// 	return roles.reduce((acc, role) => {
	// 		acc[role.id] = role;
	// 		return acc;
	// 	}, {});
	// }, [roles]);

	// const getRoleName = (id) => {
	// 	return rolesDict[id].name;
	// }

	const columns = [
		{
			field: 'namespace',
			headerName: 'Namespace',
			minWidth: 160,
			flex: 0.5,
		},
		{
			field: 'class',
			headerName: 'Class',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'isSiteLevel',
			headerName: 'Site level',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'siteLevelAdminOnly',
			headerName: 'Admin only',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'preRestTreeCache',
			headerName: 'Pre',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'postRestTreeCache',
			headerName: 'Post',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'preRestTaskCache',
			headerName: 'Pre',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'postRestTaskCache',
			headerName: 'Post',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'enableHTMLsanitise',
			headerName: 'HTML sanitise',
			minWidth: 160,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'feature',
			headerName: 'Feature',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'description',
			headerName: 'Description',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'roles',
			headerName: 'Roles',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				if (!row.roles.length) return;
				const roles = row.roles.map(role => { return role.name }).join(', ');
				return roles;
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<Button
					variant="outlined"
					size="small"
					color="primary"
					onClick={() => { onEdit(params.row) }}>
					Edit
				</Button>
			)
		},
	]

	const columnGroupingModel = [
		{
			groupId: 'Security level',
			children: [{ field: 'isSiteLevel' }, { field: 'siteLevelAdminOnly' }],
			headerAlign: 'center'
		},
		{
			groupId: 'Reset tree cache',
			children: [{ field: 'preRestTreeCache' }, { field: 'postRestTreeCache' }],
			headerAlign: 'center'
		},
		{
			groupId: 'Reset task cache',
			children: [{ field: 'preRestTaskCache' }, { field: 'postRestTaskCache' }],
			headerAlign: 'center'
		}
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={settings}
				columns={columns}
				rowHeight={42}
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
				columnGroupingModel={columnGroupingModel}
			/>
		</Box>
	);
};

export default SiteSecurityTable;