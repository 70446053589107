import React from 'react';
import {
	Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

// Accepts a string that contains an array and parses it to display each
// item as a new line. Any input then gets converted back to original format of
// a string array based on each new line.
const ArrayStringTextArea = ({ value, label, onChange, ...props }) => {
	// Convert the incoming value to newline-separated format
	const displayValue = value ? JSON.parse(value).join('\n') : '';

	const handleTextFieldChange = (e) => {
		const newValue = e.target.value;
		// Convert back to string list format
		const arrayValue = newValue.split('\n');
		if (onChange) {
			onChange(JSON.stringify(arrayValue));
		}
	};

	return (
		<>
			{label &&
				<Typography variant="h6">
					{label}
				</Typography>
			}
			<TextareaAutosize
				{...props}
				value={displayValue}
				onChange={handleTextFieldChange}
			/>
		</>

	);
};

export default ArrayStringTextArea;