import React, { useContext, useState, useMemo } from 'react';
import {
	Grid,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	TextField
} from '@mui/material';
import useDebounce from '../../../customHooks/useDebounce';
import { UserContext } from '../../../contexts';
import { PaginatedTable } from '..';

const SortableTable = ({ data, columns, hasFilter = false, children }) => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(null);
	const [filter, setFilter] = useState('');
	const { userIdMap } = useContext(UserContext);

	const debouncedFilter = useDebounce(filter, 300);

	const filteredData = useMemo(() => {
		if (debouncedFilter === '') {
			return data;
		}
		return data.filter(item => {
			return columns.some(column => {
				const value = item[column.field];
				// Special filter search if the column is a userId
				if (column.isUserId) {
					const user = userIdMap[value];
					return user?.name?.toLowerCase().includes(debouncedFilter.toLowerCase());
				}
				return value?.toString().toLowerCase().includes(debouncedFilter.toLowerCase());
			});
		});
	}, [data, debouncedFilter, columns, userIdMap]);

	const handleRequestSort = (property) => {
		if (orderBy === property) {
			if (order === 'asc') {
				setOrder('desc');
			} else if (order === 'desc') {
				setOrderBy(null);
			} else {
				setOrder('asc');
			}
		} else {
			setOrderBy(property);
			setOrder('asc');
		}
	};

	const sortedData = useMemo(() => {
		if (!orderBy) return filteredData;

		const comparator = (a, b) => {
			if (a[orderBy] < b[orderBy]) {
				return order === 'asc' ? -1 : 1;
			}
			if (a[orderBy] > b[orderBy]) {
				return order === 'asc' ? 1 : -1;
			}
			return 0;
		};
		return [...filteredData].sort(comparator);
	}, [filteredData, order, orderBy]);

	return (
		<>
			{hasFilter &&
				<Grid item xs={3}>
					<TextField
						size="small"
						label="Filter"
						variant="outlined"
						margin="normal"
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
						fullWidth
					/>
				</Grid>
			}
			<PaginatedTable data={sortedData}>
				{(displayedData) => (
					<>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.field} sortDirection={orderBy === column.field ? order : false}>
										<TableSortLabel
											active={orderBy === column.field}
											direction={orderBy === column.field ? order : 'asc'}
											onClick={() => handleRequestSort(column.field)}
										>
											{column.headerName}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{children(displayedData)}
						</TableBody>
					</>
				)}
			</PaginatedTable>
		</>
	);
};

export default SortableTable;
