import {
	generateRowDataForUserGridReport
} from '../../helpers/AgGrid/UserGridHelpers';
import {
	UserReportCellRenderer
} from '../DataGridComponents/CellRenderers';
import {
	UserReportCellStyle
} from '../DataGridComponents/CellStyles';
import AgGridUsersView from './AgGridUsersView';


const AgGridUsersReportView = ({selectedProjects}) => {
	function UserReportValueFormatter(props) {
		if (!props.value) return;

		let inviteDate = '';
		if (!props.value.lastLoginSent || props.value.lastLoginSent === '1900-01-01T00:00:00') inviteDate = 'Never';
		else inviteDate = new Date(props.value.lastLoginSent).toLocaleString({}, {dateStyle: 'medium'});

		let expiresDate = '';
		if (!props.value.dateExpires || props.value.dateExpires === '1900-01-01T00:00:00') expiresDate = 'Never';
		else expiresDate = new Date(props.value.dateExpires).toLocaleString({}, {dateStyle: 'medium'});

		let viewedDate = '';
		if (!props.value.lastViewed || props.value.lastViewed === '1900-01-01T00:00:00') viewedDate = 'Never';
		else viewedDate = new Date(props.value.lastViewed).toLocaleString({}, {dateStyle: 'medium'});

		return `Project invite sent: ${inviteDate}, Expires: ${expiresDate}, Last viewed: ${viewedDate}`;
	}

	return (
		<AgGridUsersView
			selectedProjects={selectedProjects}
			generateRowDataFunction={generateRowDataForUserGridReport}
			cellStyleFunction={UserReportCellStyle}
			cellRendererFunction={UserReportCellRenderer}
			valueFormatterFunction={UserReportValueFormatter}
		/>
	);
}

AgGridUsersReportView.defaultProps = {
	selectedProjects: null
};

export default AgGridUsersReportView;