import React from 'react';
import {
	Grid,
	Typography,
} from '@mui/material';

function FilterFormComponent({ selectedId, allowedIds, labelOnNonAllowed, children }) {
	const safeAllowedIds = allowedIds ?? [];
	const shouldRenderChildren = safeAllowedIds.includes(selectedId);

	// Render children if the condition is met
	if (shouldRenderChildren) {
		return <>{children}</>;
	}

	// Otherwise, if label set render label.
	if (labelOnNonAllowed) {
		return (
			<Grid item xs={12}>
				<Typography variant="body">
					{labelOnNonAllowed}
				</Typography>
			</Grid>
		);
	}

	return null;
}

export default FilterFormComponent;