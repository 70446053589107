import React from 'react';
import {
	Box,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
	ButtonGroup
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'FileTagCategoryName-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const FileTagsTableComponent = ({ tags, onEditTag }) => {
	const columns = [
		{
			field: 'name',
			headerName: 'Tag name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'deleted',
			headerName: 'Deleted',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 1,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<SecurityButton
					featureName={SECURITY_FEATURES.FILE_TAGS_PAGE}
					color="primary"
					size="small"
					variant="outlined"
					onClick={() => { onEditTag(params.row) }}>
					Edit tag
				</SecurityButton>
			)
		},
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={tags}
				columns={columns}
				rowHeight={42}
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
			/>
		</Box>
	);
};

export default FileTagsTableComponent;