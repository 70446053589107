import React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';
import EDocsLogo from '../ui/EDocsLogo';

function ErrorPage({ error }) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			bgcolor="grey"
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				bgcolor="white"
				padding={5}
				borderRadius={2}
				boxShadow={3}
			>
				<EDocsLogo />
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			</Box>
		</Box>
	);
}

export default ErrorPage;