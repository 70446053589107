import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import { CRUD_ACTIONS } from '../../constants/ApiConstants';
import endpoints from '../endpoints';


// ################# Project Locations #####################

export async function loadLocationsForProject(projectId, structuralName) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile(`get${structuralName}s.json`);
		}

		const endpoint = endpoints.nodeLocations[structuralName].get(projectId);
		let data = await springboardApiRequest(endpoint, "GET");
		console.log(`${structuralName} DATA:`, data);
		downloadJsonData(data.data, `get${structuralName}s`);
		return data.data;
	} catch (error) {
		console.error(`Error while fetching location ${structuralName} data:`, error);
		throw new Error(`Failed to fetch location ${structuralName} data.`);
	}
}

export async function loadSpacesForProject(projectId) {
	return await loadLocationsForProject(projectId, "Space");
}

export async function loadZonesForProject(projectId) {
	return await loadLocationsForProject(projectId, "Zone");
}

export async function loadFloorsForProject(projectId) {
	return await loadLocationsForProject(projectId, "Floor");
}

export async function loadFacilitiesForProject(projectId) {
	return await loadLocationsForProject(projectId, "Facility");
}

async function performNodeLocationApiOperation(operationType, structuralName, projectId, data) {
	try {
		if (DEV_TEST_MODE) {
			return true;
		}

		// Construct the endpoint URL based on the operationType and structuralName
		let endpoint = endpoints.nodeLocations[structuralName][operationType]();
		const postData = {
			...data,
			siteId: getSiteId(),
			projectId: projectId,
			structuralType: structuralName
		}

		console.log(`${structuralName} ${operationType.toUpperCase()} POST Data:`, postData);

		// Make the API request
		let responseData = await springboardApiRequest(endpoint, "POST", postData);
		console.log(`${structuralName} ${operationType.toUpperCase()} RESPONSE:`, responseData);

		return responseData.data;
	} catch (error) {
		console.error(`Error while ${operationType}ing ${structuralName}:`, error);
		throw new Error(`Failed to ${operationType} ${structuralName}.`);
	}
}

const STRUCT_MAP = {
	"Floor" : "floor",
	"Zone" : "zone",
	"Facility" : "facility",
	"Space" : "space"
};

export async function createNodeLocation(structuralType, projectId, data) {
	return await performNodeLocationApiOperation(CRUD_ACTIONS.CREATE, structuralType, projectId, data);
}

export async function deleteNodeLocations(structuralType, projectId, data) {
	return await performNodeLocationApiOperation(CRUD_ACTIONS.DELETE, structuralType, projectId, data);
}

export async function updateNodeLocation(structuralType, projectId, data) {
	return await performNodeLocationApiOperation(CRUD_ACTIONS.UPDATE, structuralType, projectId, data);
}