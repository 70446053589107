import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import {
	LoadingBox
} from '../ui';

// Modal used to display a loading state if necessary but potentially allow
// a user to select an item to load
function SelectableLoaderModal({ title, maxWidth, open, onClose, loading, children }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth={maxWidth}
		>
			<DialogTitle variant="h5">
				{title}
			</DialogTitle>
			<DialogContent>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					bgcolor="white"
					padding={2}
					borderRadius={2}
				>
					{children}
					{/* Conditionally render loading indicator */}
					{loading && (<LoadingBox />)}
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default SelectableLoaderModal;