

export const DOCUMENT_FEATURES = [
	"document_update",
	"document_delete"
];

export const SECURITY_FEATURES = {
	ADMIN_ONLY: "admin_only",
	LOCATIONS_PAGE_FACILITY: "facility_create_update_delete",
	LOCATIONS_PAGE_FLOOR: "floor_create_update_delete",
	LOCATIONS_PAGE_SPACE: "space_create_update_delete",
	LOCATIONS_PAGE_ZONE: "zone_create_update_delete",
	ADMINS_PAGE_EDIT: "administrators_edit",
	ADMINS_PAGE_SWAP: "administrators_swap",
	PROJECT_CLASSIFICATIONS_PAGE: "projectclassification_create_update_delete",
	DOCUMENT_PAGE_CREATE: "document_create",
	DOCUMENT_PAGE_UPDATE: "document_update",
	DOCUMENT_PAGE_DELETE: "document_delete",
	LOI_CREATE_UPDATE_DELETE: "LOI_create_update_delete",
	FILE_TAGS_PAGE: "fileTag_create_update_delete",
	PROJECT_PAGE_CREATE: "project_create",
	PROJECT_PAGE_EDIT: "project_edit",
	PROJECT_PAGE_DELETE: "project_delete",
	WORKFLOW_RESPONSIBILITY_PAGE: "workflowManagement_create_update_delete",
	WORKFLOW_PAGE: "workflow_create_update_delete",
	WORKFLOW_STEP_CREATE_DELETE: "workflowstep_create_delete",
	WORKFLOW_STEP_UPDATE: "workflowstep_update"
}
