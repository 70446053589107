
import { useCallback, useEffect, useState } from 'react';
import { isObjectEmpty } from '../helpers/GeneralHelpers';

export const isValidEmail = (email) => {
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
	return emailRegex.test(email.trim());
};

export const isValidMaxLength = maxLength => value => value.length <= maxLength;
export const isValidMinLength = minLength => value => value.length >= minLength;
export const isNotEmpty = (value) => (!value || !value.trim());
export const isNotNumber = (value) => (!value || isNaN(value));
export const isInRange = (min, max) => value => {
	const number = parseFloat(value);
	return (number < min || number > max);
}

export const validateIsNumber = (value) => isNotNumber(value) ? 'This field must be a number' : '';
export const validateNumberInRange = (min, max) => value =>
	isInRange(min, max)(value) ? '' : `Value must be between ${min} and ${max}`;
export const validateNotEmpty = (value) => isNotEmpty(value) ? `This field is required` : '';
export const validateMinLength = (minLength) => value =>
	isValidMinLength(minLength)(value) ? '' : `This must be atleast ${minLength} characters`;
export const validateMaxLength = maxLength => value =>
	isValidMaxLength(maxLength)(value) ? '' : `This cannot be more than ${maxLength} characters`;
export const validateInList = list => (value) => {
	return !list.includes(value) ? `Please add ${value} to attributes before saving!` : '';
};
export const validateNotEmptyArray = (array) => Array.isArray(array) && !array.length ? 'This field is required' : '';

export const validateInMainListButNotSpecific = (mainList, specificList) => (value) => {
	if (mainList.includes(value) && !specificList.includes(value)) {
		return `This attribute is already present in another scope, please edit the scope to continue`;
	}
	return '';
};

export const validateEmail = (email) => {
	return !isValidEmail(email) ? `${email} is not a valid email address` : '';
};

export const isFormValid = (errors) => {
	return isObjectEmpty(errors);
}

export const hasNoErrors = (errors) => {
	return isObjectEmpty(errors);
}

// Uses an object of fieldValidators to set errors for a field in a form
export const setFieldErrors = (value, fieldName, formValidators, newFormErrors) => {
	let errors = getValidationErrors(value, formValidators[fieldName]);
	if (errors && errors.length > 0) {
		newFormErrors[fieldName] = errors;
	}
}

// Performs all validation functions on value and returns a list containing all errors
export function getValidationErrors(value, validators) {
	if (value === null)
		return [];
	let checkValue = value;
	return validators ? validators
		.map(validate => validate(checkValue))
		.filter(Boolean) : [];
}

// Used to generate a forms error handling structure dynamically
export function generateErrorStructure(config) {
	let errorStructure = {};

	for (const key in config) {
		if (typeof config[key] === 'object' && !Array.isArray(config[key])) {
			errorStructure[key] = {};
			for (const subKey in config[key]) {
				errorStructure[key][subKey] = [];
			}
		} else {
			errorStructure[key] = [];
		}
	}

	return errorStructure;
};

export function validateForm(formData, validators) {
	let errors = {};
	Object.keys(validators).forEach((field) => {
		setFieldErrors(formData[field], field, validators, errors);
	});
	return errors;
}

// Used to create a form field with a list of validation functions performed each time the value changes.
export const useFormField = (initialValue, validators = []) => {
	const [value, setValue] = useState(initialValue);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		const newErrors = getValidationErrors(value, validators);
		setErrors(newErrors);
	}, [value, validators]);

	return {
		value,
		errors,
		onChange: value => setValue(value),
		setValue: value => setValue(value)
	};
};

// custom hook for validating a form with errors.
export const useFormValidation = (formValidators) => {
	const [formErrors, setFormErrors] = useState({});

	const isFormValid = useCallback((formData) => {
		let newFormErrors = validateForm(formData, formValidators);
		setFormErrors(newFormErrors);
		return hasNoErrors(newFormErrors);
	}, [formValidators]);

	const resetFormErrors = useCallback(() => {
		setFormErrors({});
	}, []);

	return { formErrors, isFormValid, resetFormErrors };
};