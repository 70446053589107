import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { getDisplayLabel } from '../../helpers/GeneralHelpers.js';

class AutoTxtField extends React.Component {
	static defaultProps = {
		label: null,
		title: null,
		canEdit: true,
		errors: []
	};
	render() {
		const { id, label, title, options, value, canEdit, errors } = this.props;
		const displayLabel = getDisplayLabel(label, id);
		const shouldDisplayTitle = title !== null;

		return (
			<>
				{shouldDisplayTitle &&
				<Typography variant="body1" color="textSecondary" >
					{title}
				</Typography>
				}
				<Autocomplete
					readOnly={!canEdit}
					key={id}
					freeSolo={true}
					disablePortal
					id={id}
					options={options}
					value={value}
					onInputChange={(event, newValue) => {
						this.props.onChange(newValue, id);
					}}
					fullWidth
					renderInput={(params) =>
						<TextField
							{...params}
							label={displayLabel}
							error={errors.length > 0}
							helperText={errors.join(' ')}
						/>}
				/>
			</>
		);
	}
}

AutoTxtField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	title: PropTypes.string, // Displayed above the textbox
	value: PropTypes.string.isRequired,
	canEdit: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string)
};

export default AutoTxtField;