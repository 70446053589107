import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
	Alert,
	Button,
	Card,
	CardContent,
	Typography
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { IMAGE_TYPE } from '../../../constants/FileTypeConstants';
import SiteImagesUploadModal from '../../modals/FileUploadModal/SiteImagesUploadModal';
import { getSiteId } from '../../../api/configManager';
import EditMapMarkerModal from './EditMapMarkerModal';
import ImageMapElement from './ImageMapElement';
import ManageImageMapImage from './ManageImageMapImage';


/*
let DefaultIcon = L.icon({
	iconUrl: icon,
	iconRetinaUrl: iconRetina,
	shadowUrl: iconShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;
*/

const DEFAULT_COLOR = '#000000';

L.Icon.Default.mergeOptions({
	iconRetinaUrl: iconRetina,
	iconUrl: icon,
	shadowUrl: iconShadow,
});

const ImageMapEditor = ({ siteLocationId, mapData, onSave, onDeleteImage, isEditing = false }) => {
	const [elements, setElements] = useState(mapData.points);
	const [isEditMarkerOpen, setIsEditMarkerOpen] = useState(false);
	const [isNewMarker, setIsNewMarker] = useState(false);
	const [currentMarker, setCurrentMarker] = useState(null);
	const [isChanges, setIsChanges] = useState(false);

	const [openImageUpload, setOpenImageUpload] = useState(false);

	const featureGroupRef = useRef();

	const handleReset = () => {
		if (featureGroupRef.current) {
			featureGroupRef.current.clearLayers();
		}
	}

	useEffect(() => {
		console.log("ELEMENTS RESETTING");
		setElements(mapData.points);
		setIsChanges(false);
		setIsNewMarker(false);
		if (featureGroupRef.current) {
			featureGroupRef.current.clearLayers();
			mapData.points.forEach(addElementToFeatureGroup);
		}
	}, [mapData]);

	const addElementToFeatureGroup = (element) => {
		const colorProps = { color: element.color, fillColor: element.color, fillOpacity: 0.2 };
		let layer;
		switch (element.renderType) {
			case 'marker':
				layer = L.marker([element.positions[0].lat, element.positions[0].lng], { customId: element.id, layerType: element.renderType });
				break;
			case 'polygon':
				layer = L.polygon(element.positions.map(pos => [pos.lat, pos.lng]), colorProps, { customId: element.id, layerType: element.renderType });
				break;
			case 'rectangle':
				layer = L.rectangle(element.positions.map(pos => [pos.lat, pos.lng]), colorProps, { customId: element.id, layerType: element.renderType });
				break;
			case 'circle':
				layer = L.circle([element.positions[0].lat, element.positions[0].lng], { radius: element.radius, ...colorProps, customId: element.id, layerType: element.renderType });
				break;
			default:
				return;
		}
		if (featureGroupRef.current) {
			layer.addTo(featureGroupRef.current);
		}
	};

	const handleCreated = (e) => {
		const { layerType, layer } = e;
		const id = new Date().toISOString();
		layer.options.customId = id;
		layer.options.layerType = layerType;
		let newElement = {
			id,
			renderType: layerType,
			color: DEFAULT_COLOR,
			radius: 0
		};
		if (layerType === 'marker') {
			const { lat, lng } = layer.getLatLng();
			newElement.positions = [{ lat, lng, position: 0 }];
		} else if (layerType === 'polygon') {
			const latlngs = layer.getLatLngs()[0].map((latlng, index) => ({ lat: latlng.lat, lng: latlng.lng, position: index }));
			newElement.positions = latlngs;
		} else if (layerType === 'rectangle') {
			const latlngs = layer.getLatLngs()[0].map((latlng, index) => ({ lat: latlng.lat, lng: latlng.lng, position: index }));
			newElement.positions = latlngs;
		} else if (layerType === 'circle') {
			const { lat, lng } = layer.getLatLng();
			const radius = layer.getRadius();
			newElement.positions = [{ lat, lng, position: 0 }];
			newElement.radius = radius;
		}
		setCurrentMarker(newElement);
		setIsEditMarkerOpen(true);
		setIsNewMarker(true);
	};

	const handleEdited = (e) => {
		const layers = e.layers;
		console.log("EDITED:", e);
		setElements((prevElements) => {
			const updatedElements = [...prevElements];

			layers.eachLayer(layer => {
				const id = layer.options.customId;
				const layerType = layer.options.layerType;
				const index = updatedElements.findIndex(el => el.id === id);

				if (index !== -1) {
					if (layerType === 'marker') {
						const { lat, lng } = layer.getLatLng();
						updatedElements[index] = { ...updatedElements[index], positions: [{ lat, lng, position: 0 }] };
					} else if (layerType === 'polygon' || layerType === 'rectangle') {
						const latlngs = layer.getLatLngs()[0].map((latlng, idx) => ({ lat: latlng.lat, lng: latlng.lng, position: idx }));
						updatedElements[index] = { ...updatedElements[index], positions: latlngs };
					} else if (layerType === 'circle') {
						const { lat, lng } = layer.getLatLng();
						const radius = layer.getRadius();
						updatedElements[index] = { ...updatedElements[index], positions: [{ lat, lng, position: 0 }], radius };
					}
				} else {
					console.warn(`Element with ID ${id} not found.`);
				}
			});
			handleReset();
			//console.log("Updated Elements:", updatedElements);
			return updatedElements;
		});
		setIsChanges(true);
	};

	const handleDeleted = (e) => {
		const layers = e.layers;
		console.log("LAYERS deleted:", layers);
		const layerIdsToDelete = [];

		layers.eachLayer(layer => {
			layerIdsToDelete.push(layer.options.customId);
		});

		setElements((prevElements) => {
			console.log("OLD DELETED:", prevElements);
			return prevElements.filter(el => !layerIdsToDelete.includes(el.id));
		});
		setIsChanges(true);
		handleReset();
	};

	const handleSaveMarker = (element) => {
		console.log("NEW MARKER:", element);
		if (isNewMarker) {
			setElements((prevElements) => [...prevElements, element]);
			setIsNewMarker(false);
			handleReset();
		}
		else {
			setElements((prevElements) => prevElements.map((e) => e.id === element.id ? element : e));
		}
		setIsChanges(true);
	};

	const handleSaveMap = () => {
		let imageMap = { ...mapData, points: [...elements] };
		onSave(imageMap);
		setIsChanges(false);
	}

	const handleResetMarkers = () => {
		setElements(mapData.points);
		handleReset();
		setIsChanges(false);
	}

	const handleCloseEdit = () => {
		setIsEditMarkerOpen(false);
		handleReset();
	}

	const handleElementClick = (element) => {
		setCurrentMarker(element);
		setIsEditMarkerOpen(true);
	}

	const handleImageUpload = (success, imageData) => {
		if (!success)
			return;
	}

	const handleDeleteImage = () => {
		let imageMap = { ...mapData, id: null, url: null };
		onDeleteImage(imageMap);
	}

	console.log("DISPLAY ELEMENTS:", elements);

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Image Map</Typography>
				{isEditing &&
					<>
						<ManageImageMapImage
							imageId={mapData.id}
							url={mapData.url}
							siteLocationId={siteLocationId}
							onDeleteImage={handleDeleteImage}
						/>
						<Alert severity="info">Right click on points to edit them, be sure to save markers and changes before leaving this page</Alert>
					</>
				}
				<MapContainer center={[0, 0]} zoom={1} style={{ height: '600px', width: '100%' }}>
					<TileLayer
						url={`https://edocstorage.blob.core.windows.net/siteimages/${getSiteId()}/${mapData.id}/_siteTiles/tile_{z}_{x}-{y}.png`}
						maxZoom={3}
						tileSize={256}
						noWrap={true}
					/>
					<FeatureGroup ref={featureGroupRef}>
						{elements.map((element) => (
							<ImageMapElement key={Math.random()} element={element} onRightClick={handleElementClick} />
						))}
						{isEditing && (
							<EditControl
								position="topright"
								onCreated={(e) => handleCreated(e)}
								onEdited={(e) => handleEdited(e)}
								onDeleted={(e) => handleDeleted(e)}
								draw={{
									rectangle: true,
									polyline: false,
									polygon: true,
									circle: true,
									circlemarker: false,
								}}
							/>
						)}
					</FeatureGroup>

				</MapContainer>
				{isEditing && (
					<>
						<Button variant="contained" color="error" onClick={handleResetMarkers} disabled={!isChanges}>
							Reset Markers
						</Button>
						<Button variant="contained" color="primary" onClick={handleSaveMap} disabled={!isChanges}>
							Save Markers
						</Button>
						<EditMapMarkerModal
							open={isEditMarkerOpen}
							onClose={() => handleCloseEdit()}
							onSave={handleSaveMarker}
							currentMarker={currentMarker}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default ImageMapEditor;
