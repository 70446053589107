import { AllowedActions } from "../constants/Constants";
import moment from 'moment';
import 'moment/locale/en-gb';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../constants/Constants';

export const getRoleFromId = (roleId, roles) => {
	return roles.find(r => r.id === roleId)?.name;
}

export const convertProjectsToCheckboxList = (projects, documentsByProject) => {
	return projects.map(project => {
		const docList = (documentsByProject[project.id] || []).map(doc => ({
			id: doc.documentId,
			name: doc.name,
			disabled: 'disabled' in doc ? doc.disabled : false,
			info: 'info' in doc ? doc.info : ''
		}));

		return {
			id: project.id,
			name: project.name,
			subList: docList
		};
	});
}

export const convertUsersToCheckList = (users, filter = null, includeFilteredUsers = true) => {
	return users
		.filter(user => {
			if (!filter) return true;
			if (includeFilteredUsers) return filter[user.id];
			return !filter[user.id];
		})
		.map(user => ({
			id: user.id,
			name: user.name,
			info: user.email
		}));
}

export const filterRecursiveSubList = (dataList, filterText) => {
	if (!dataList || dataList.length === 0) return [];
	if (!filterText) return dataList;

	return dataList
		.map(data => {
			// If there's a sublist, try to filter it
			if (data.subList) {
				const filteredSubList = filterRecursiveSubList(data.subList, filterText);

				// If the parent matches the criteria but the sublist is empty after filtering,
				// retain the original sublist.
				if (filteredSubList.length === 0 && (data.name.toLowerCase().includes(filterText) ||
					data.info?.toLowerCase().includes(filterText))) {
					return data;
				}

				// Return a NEW object with the filtered sublist
				return { ...data, subList: filteredSubList };
			}
			// If no sublist, return the original item
			return data;
		})
		.filter(data => {
			const itemNameMatch = data.name.toLowerCase().includes(filterText);
			const itemInfoMatch = data.info?.toLowerCase().includes(filterText);
			const subListHasItems = data.subList && data.subList.length > 0;

			// Criteria for filtering the top-level items
			return itemNameMatch || itemInfoMatch || subListHasItems;
		});
}

function forEachCheckedDocumentUser(checkedDocuments, projectDocuments, func, shouldCopy = false) {
	// Deep copy project documents if we need to create a temporary version.
	const newProjectDocuments = shouldCopy ? JSON.parse(JSON.stringify(projectDocuments)) : projectDocuments;
	// Iterate through each project's documents.
	Object.values(newProjectDocuments).flat().forEach(document => {
		if (checkedDocuments[document.documentId]) {
			console.log("DOCUMENTS CHECKED:", document);
			// Iterate through each user associated with this document.
			document.teamMembers.forEach(func);
		}
	});
	return newProjectDocuments;
}

function filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, predicate) {
	let users = {};
	forEachCheckedDocumentUser(checkedDocuments, projectDocuments, (teamMember) => {
		if (predicate(teamMember)) {
			users[teamMember.userId] = true;
		}
	});
	return users;
}

export function getCheckedUsersWithRole(selectedRole, checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => teamMember.roles.includes(selectedRole));
}

export function getCheckedUsersWithAnyRole(checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => teamMember.roles.length > 0);
}

export function getCheckedUsersWithoutRole(selectedRole, checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => !teamMember.roles.includes(selectedRole));
}

export function getUsersWithChangedRole(selectedRole, checkedUsers, checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => checkedUsers[teamMember.userId] && teamMember.roles.includes(selectedRole));
}

export function getActiveCheckedUsers(checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => teamMember.hasExpired === false);
}

export function getCheckedDocUsers(checkedDocuments, projectDocuments) {
	return filterCheckedUsersByPredicate(checkedDocuments, projectDocuments, (teamMember) => {
		console.log("TEAM: ", teamMember);
		return teamMember.hasExpired === false;
	});
}

export function removeAccessForDocUsers(checkedUsers, checkedDocuments, projectDocuments) {
	const updatedProjectDocs = forEachCheckedDocumentUser(checkedDocuments, projectDocuments, (teamMember) => {
		if (checkedUsers[teamMember.userId]) {
			teamMember.hasExpired = true;
		}
	});
	return updatedProjectDocs;
}

export function assignRoleToCheckedUsers(selectedRole, checkedUsers, checkedDocuments, projectDocuments) {
	const updatedProjectDocs = forEachCheckedDocumentUser(checkedDocuments, projectDocuments, (teamMember) => {
		if (checkedUsers[teamMember.userId]) {
			// Check if the role already exists for the user, if not, add it
			if (!teamMember.roles.includes(selectedRole)) {
				teamMember.roles.push(selectedRole);
			}
		}
	}, true);
	return updatedProjectDocs;
}

export function removeRoleFromCheckedUsers(selectedRole, checkedUsers, checkedDocuments, projectDocuments) {
	const updatedProjectDocs = forEachCheckedDocumentUser(checkedDocuments, projectDocuments, (teamMember) => {
		if (checkedUsers[teamMember.userId]) {
			// Check if the role already exists for the user, if so, remove it
			if (teamMember.roles.includes(selectedRole)) {
				teamMember.roles = teamMember.roles.filter(role => role !== selectedRole);
			}
		}
	}, true);
	return updatedProjectDocs;
}

export function setRoleForCheckedUsers(selectedRole, checkedUsers, checkedDocuments, projectDocuments) {
	const updatedProjectDocs = forEachCheckedDocumentUser(checkedDocuments, projectDocuments, (teamMember) => {
		if (checkedUsers[teamMember.userId]) {
			teamMember.roles = [selectedRole];
		}
	}, true);
	return updatedProjectDocs;
}

// Bug where this doesn't currently add data for users not in the documents?
// Bug where it is not selecting users properly based on role when selecting multiple projects...
// i.e. Set role -> if user has only 1 role it should not show for that project.
// it also preticks users on Add role if 2 projects are selected but they are in just 1 of those projects.. not both.

function meetsRoleCriteria(userInDoc, action, roleId) {
	switch (action) {
		case AllowedActions.REMOVE_ROLE:
			return userInDoc && userInDoc.roles.includes(roleId);
		case AllowedActions.ADD_ROLE:
			return !userInDoc || !userInDoc.roles.includes(roleId);
		case AllowedActions.SET_ROLE:
			return !userInDoc || !(userInDoc.roles.length === 1 && userInDoc.roles.includes(roleId));
		default:
			return true;
	}
}

function doesUserHaveDocAccess(userInDoc) {
	return userInDoc && !userInDoc.hasExpired;
}

function isUserTeamMember(userInDoc) {
	console.log("USER TEAM: ", userInDoc);
	return userInDoc ? true : false;
}

function hasAnyRole(userInDoc) {
	return userInDoc && userInDoc.roles.length > 0;
}
/*
function acceptAll(userInDoc) {
	return true;
}*/

function getPreviewOutput(user, project, document) {
	return {
		userEmail: user.email,
		projectName: project.name,
		documentName: document.name
	};
}

// Used to loop through all selected Documents and users and perform a callback function
function processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, callbackDescriminator, callbackGetOutputData) {
	let output = [];
	const allDocuments = Object.values(documents).flat();
	const selectedDocIds = Object.keys(checkedDocuments).filter(docId => checkedDocuments[docId]);
	const selectedUserIds = Object.keys(checkedUsers).filter(userId => checkedUsers[userId]);

	selectedDocIds.forEach(docId => {
		const document = allDocuments.find(doc => doc.documentId === docId);
		if (!document) return;

		const project = projects.find(proj => proj.id === document.projectId);

		selectedUserIds.forEach(userId => {
			const user = users.find(u => u.id === userId);
			const userInDoc = document.teamMembers.find(du => du.userId === userId);

			// Checks if the callback function meets the criteria to add user to output list.
			if (callbackDescriminator(userInDoc)) {
				output.push(callbackGetOutputData(user, project, document));
			}
		});
	});

	return output;
}

function processPreviewItems(checkedUsers, checkedDocuments, documents, projects, users, callbackDescriminator) {
	return processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, callbackDescriminator, getPreviewOutput);
}

export function generatePreviewForRoles(checkedUsers, checkedDocuments, documents, projects, users, action, roleId) {
	return processPreviewItems(checkedUsers, checkedDocuments, documents, projects, users, (userInDoc) => meetsRoleCriteria(userInDoc, action, roleId));
}

export function generatePreviewForRemoveAccess(checkedUsers, checkedDocuments, documents, projects, users) {
	return processPreviewItems(checkedUsers, checkedDocuments, documents, projects, users, doesUserHaveDocAccess);
}

export function generatePreviewForSetAccessExpiration(checkedUsers, checkedDocuments, documents, projects, users) {
	return processPreviewItems(checkedUsers, checkedDocuments, documents, projects, users, isUserTeamMember);
}

export function generatePreviewForSetJobTitle(checkedUsers, checkedDocuments, documents, projects, users) {
	return processPreviewItems(checkedUsers, checkedDocuments, documents, projects, users, hasAnyRole);
}

export function generateChangeListForRoles(checkedUsers, checkedDocuments, documents, projects, users, action, roleId) {
	let roleOutputFormat = (user, project, document) => {
		return {
			documentId: document.documentId,
			roleId: roleId,
			userId: user.id,
			documentName: document.name,
		};
	}
	return processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, (userInDoc) => meetsRoleCriteria(userInDoc, action, roleId), roleOutputFormat);
}

export function generateChangeListForSetExpiration(checkedUsers, checkedDocuments, documents, projects, users, expiration) {
	let parsedDate = moment(expiration, DATE_FORMAT);
	let formattedExpirationDate = parsedDate.format(SERVER_DATE_FORMAT);
	let expirationOutputFormat = (user, project, document) => {
		return {
			documentId: document.documentId,
			expirationDate: formattedExpirationDate,
			userId: user.id,
			documentName: document.name,
		};
	}
	return processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, isUserTeamMember, expirationOutputFormat);
}

export function generateChangeListForSetRoleDescription(checkedUsers, checkedDocuments, documents, projects, users, description) {
	let descriptionOutputFormat = (user, project, document) => {
		return {
			documentId: document.documentId,
			description: description,
			userId: user.id,
			documentName: document.name,
		};
	}
	return processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, hasAnyRole, descriptionOutputFormat);
}

export function generateChangeListForRemoveAccess(checkedUsers, checkedDocuments, documents, projects, users) {
	let removeAccessOutputFormat = (user, project, document) => {
		const removeAccessArray = [];

		const teamMember = document.teamMembers.find(elem => elem.userId === user.id);
		if (!teamMember) return removeAccessArray;

		const roles = teamMember.roles;
		if (!roles.length) return removeAccessArray;

		roles.forEach(role => {
			removeAccessArray.push({
				documentId: document.documentId,
				userId: user.id,
				documentName: document.name,
				roleId: role,
			})
		})
		return removeAccessArray;
	}
	return processSelectedItems(checkedUsers, checkedDocuments, documents, projects, users, doesUserHaveDocAccess, removeAccessOutputFormat);
}

function createNewTeamMember(userId, roleId = null) {
	const teamMember = {
		"userId": userId,
		"lastLoginSent": "1900-01-01T00:00:00",
		"lastViewed": "1900-01-01T00:00:00",
		"treatAsContractor": false,
		"isPM": false,
		"roleDescription": null,
		"dateExpires": null,
		"hasExpired": false,
		"roles": []
	};

	if (roleId !== null) {
		teamMember.roles.push(roleId);
	}

	return teamMember;
}

export function addRolesToDocuments(changeList, documents) {
	const allDocuments = Object.values(documents).flat();
	changeList.forEach(change => {
		// Find the document in the documents array that matches the documentId in the changeList
		let document = allDocuments.find(doc => doc.documentId === change.documentId);

		if (document) {
			// Find the team member in the document's teamMembers array that matches the userId in the changeList
			let teamMember = document.teamMembers.find(member => member.userId === change.userId);

			if (teamMember) {
				// Add the roleId to the team member's roles array if it's not already there
				if (!teamMember.roles.includes(change.roleId)) {
					teamMember.roles.push(change.roleId);
				}
			}
			else {
				let newTeamMember = createNewTeamMember(change.userId, change.roleId);
				document.teamMembers.push(newTeamMember);
			}
		}
	});
}

export function deleteRolesFromDocuments(changeList, documents) {
	const allDocuments = Object.values(documents).flat();
	changeList.forEach(change => {
		// Find the document in the documents array that matches the documentId in the changeList
		let document = allDocuments.find(doc => doc.documentId === change.documentId);

		if (document) {
			// Find the team member in the document's teamMembers array that matches the userId in the changeList
			let teamMember = document.teamMembers.find(member => member.userId === change.userId);

			if (teamMember) {
				// Remove the roleId from the team member's roles array if it exists
				const roleIndex = teamMember.roles.indexOf(change.roleId);
				if (roleIndex > -1) {
					teamMember.roles.splice(roleIndex, 1);
				}
			}
		}
	});
}

export function setExpirationForTeamMembers(changeList, documents) {
	const allDocuments = Object.values(documents).flat();
	changeList.forEach(change => {
		// Find the document in the documents array that matches the documentId in the changeList
		let document = allDocuments.find(doc => doc.documentId === change.documentId);

		if (document) {
			// Find the team member in the document's teamMembers array that matches the userId in the changeList
			let teamMember = document.teamMembers.find(member => member.userId === change.userId);
			if (teamMember) {
				// Set the dateExpires for the team member
				teamMember.dateExpires = change.expirationDate;
			}
			else {
				// If the team member does not exist, create a new one with the dateExpires
				let newTeamMember = createNewTeamMember(change.userId);
				newTeamMember.dateExpires = change.expirationDate;
				document.teamMembers.push(newTeamMember);
			}
		}
	});
}

export function setRoleDescriptionForTeamMembers(changeList, documents) {
	const allDocuments = Object.values(documents).flat();
	changeList.forEach(change => {
		// Find the document in the documents array that matches the documentId in the changeList
		let document = allDocuments.find(doc => doc.documentId === change.documentId);

		if (document) {
			// Find the team member in the document's teamMembers array that matches the userId in the changeList
			let teamMember = document.teamMembers.find(member => member.userId === change.userId);

			if (teamMember) {
				// Set the dateExpires for the team member
				teamMember.roleDescription = change.description;
			}
			else {
				// If the team member does not exist, create a new one with the dateExpires
				let newTeamMember = createNewTeamMember(change.userId);
				newTeamMember.roleDescription = change.description;
				document.teamMembers.push(newTeamMember);
			}
		}
	});
}

// LOI Functions:
// Function to insert or update loiData
export function updateLoiData(loiCellUpdates, loiData) {
	if (loiCellUpdates.data.length === 0 && loiCellUpdates.deletes.length === 0) {
		return loiData; // No updates or deletes, return original data
	}

	let updatedLoiData = [...loiData]; // Create a copy of loiData

	// Process updates
	loiCellUpdates.data.forEach(update => {
		let foundIndex = updatedLoiData.findIndex(loi => loi.id === update.id);
		if (foundIndex !== -1) {
			// Update existing item in the copy
			updatedLoiData[foundIndex] = { ...updatedLoiData[foundIndex], ...update };
		} else {
			// Insert new item in the copy
			updatedLoiData.push(update);
		}
	});

	// Process deletions
	const idsToRemove = loiCellUpdates.deletes;
	if (idsToRemove.length > 0) {
		updatedLoiData  = updatedLoiData.filter(loi => !idsToRemove.includes(loi.id));
	}

	return updatedLoiData;
}

export function updateAttributeInList(updatedAttribute, attributes) {
	return attributes.map(attr => attr.id === updatedAttribute.id ? updatedAttribute : attr);
}