import React, { useContext, useMemo, useState } from 'react';
import { SiteDataContext, SiteSecurityContext } from '../../../contexts';
import { convertToTreeStructure } from '../../../helpers/GeneralHelpers';
import SBTreeDragNDropView from "./SBTreeDragNDropView";
import SBBasicTreeView from './SBBasicTreeView';
import AddTreeNodeModal from './AddTreeNodeModal';

const SiteLocationTreeView = ({ onSelectItem }) => {
	const { isSiteAdmin } = useContext(SiteSecurityContext);
	const { siteLocations, addSiteLocation, moveSiteLocation, deleteLocationBranch } = useContext(SiteDataContext);
	const [openAddNode, setOpenAddNode] = useState(false);
	const [selectedNodeId, setSelectedNodeId] = useState(null);

	const treeItems = useMemo(() => convertToTreeStructure(siteLocations, "id", "name", "locations"), [siteLocations]);

	const locationTreeMenuItems = [
		{
			name: "Add Child",
			action: (selectedId) => {
				setSelectedNodeId(selectedId);
				setOpenAddNode(true);
			}
		},
		{
			name: "Delete Branch",
			action: (selectedId) => {
				deleteLocationBranch(selectedId);
			}
		}
	];

	const handleMoveItem = (sourceNodeId, destinationNodeId, position) => {
		// Position = 'child' or 'sibling'
		moveSiteLocation(sourceNodeId, destinationNodeId);
	}

	const handleDialogClose = () => {
		setOpenAddNode(false);
		setSelectedNodeId(null);
	};

	const handleDialogSubmit = (newNodeLabel) => {
		addSiteLocation(selectedNodeId, newNodeLabel);
	};

	if (isSiteAdmin) {
		return (
			<>
				<SBTreeDragNDropView
					treeItems={treeItems}
					contextMenuItems={locationTreeMenuItems}
					onMoveItem={handleMoveItem}
					onSelectItem={onSelectItem}
				/>
				<AddTreeNodeModal
					open={openAddNode}
					onClose={handleDialogClose}
					onSubmit={handleDialogSubmit}
				/>
			</>
		);
	};

	return (
		<>
			<SBBasicTreeView
				treeItems={treeItems}
				onSelectItem={onSelectItem}
			/>
		</>
	);
};

export default SiteLocationTreeView;