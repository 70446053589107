import React, { useContext, useMemo } from 'react';
import { UserContext } from '../../../contexts';
import SelectableTable from './SelectableTable';

const columns = [
	{
		field: 'name',
		headerName: 'Name'
	},
	{
		field: 'email',
		headerName: 'Email'
	}
]

const MultiSelectUserTable = ({
	onChange,
	allowedIds,
}) => {
	const { users } = useContext(UserContext);
	const allowedUsers = useMemo(() => {
		return users.filter(user => allowedIds.includes(user.id));
	}, [users, allowedIds]);

	return (
		<SelectableTable
			data={allowedUsers}
			columns={columns}
			onChange={onChange}
			allowMultiple
		>
		</SelectableTable>
	);
};

export default MultiSelectUserTable;