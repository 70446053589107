import { getSelectedRowData } from './AgGridHelpers';
import { AttrConfigInputTypes } from '../../constants/LOIConstants';
import {
	CheckBoxCellEditor,
	UniclassCellEditor
} from '../../components/DataGridComponents/CellEditors';

function convertPascalToCamelCase(word) {
	return word.charAt(0).toLowerCase() + word.slice(1);
}

function getDynamicRowData(itemData, row) {
	Object.keys(itemData).forEach(field => {
		row.attributes[field] = itemData.hasOwnProperty(field) ? itemData[field] : '';
	});
}

function generateDynamicColDefs(data, baseColDefs) {
	const allKeys = new Set();
	data.forEach(item => {
		Object.keys(item.attributes).forEach(key => {
			allKeys.add(key);
		});
	});

	const columnDefs = Array.from(allKeys).map(key => ({
		headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
		field: key
	}));

	columnDefs.forEach(colDef => {
		baseColDefs.push(colDef);
	});

	return baseColDefs;
}

function buildRowClassifictionObject(classificationValue, loiClassifications, loiData) {
	if (classificationValue !== "NONE" && classificationValue.includes(':')) {
		let _code = classificationValue.split(":")[0].trim();
		let _description = classificationValue.split(":")[1].trim();
		let rowComonName = loiClassifications.find((classification) => classification.code === _code && classification.description === _description);
		let loiCommonName = rowComonName.commonName;
		let rowClassificiton = {};
		rowClassificiton.commonName = loiCommonName;
		rowClassificiton.code = _code;
		rowClassificiton.description = _code;
		rowClassificiton.isConfigured = false;
		let LOI = loiData.find((LOI) => LOI.classification === rowClassificiton.commonName);
		if (LOI)
		{
			rowClassificiton.isConfigured = true;
		}
		return rowClassificiton
	}
	return null;
}

function cellValidation(currentValue, LOI, attr) {
	let _ret = {}
	_ret.enabled = false;
	_ret.valid = true;
	_ret.reason = ""

	if (!LOI)
	{
		return _ret;
	}

	_ret.enabled = true;

	let _inputFormat = "";
	let _maxInputLength = "";

	if (attr.validation)
	{
		_inputFormat = attr.validation.inputFormat;
		_maxInputLength = attr.validation.maxInputLength;
	}
	//console.log("cellValidation", currentValue, LOI, attr, _inputFormat, _maxInputLength)
	if (currentValue && attr.renderType === 1 && (_maxInputLength !== "" || _maxInputLength !== 0) && currentValue.length > _maxInputLength)
	{
		_ret.valid = false
		_ret.reason = "value is longer then then max length of " + _maxInputLength;
		return _ret;
	}
	if (currentValue && _inputFormat !== "")
	{
		let _regex = new RegExp(_inputFormat);
		if (!_regex.test(currentValue))
		{
			_ret.valid = false
			_ret.reason = 'Specified format not matched: ' + _inputFormat;
		}
		return _ret;
	}

	if (LOI && LOI.required === true && attr.validation) {
		if (attr.validation.requiredValues && attr.validation.requiredValues.exclude)
		{
			let _format = attr.validation.requiredValues.excludeFormat;
			let _requiredVals = JSON.parse(attr.validation.requiredValues.exclude)
			let _val = requiredOptionalValidation(_format, _requiredVals,currentValue,true, false)
			_ret.valid = _val.valid;
			_ret.reason = _val.reason;
		}
		if (attr.validation.requiredValues && attr.validation.requiredValues.include)
		{
			let _format = attr.validation.requiredValues.includeFormat;
			let _requiredVals = JSON.parse(attr.validation.requiredValues.include)
			let _val = requiredOptionalValidation(_format, _requiredVals,currentValue,true, true)
			_ret.valid = _val.valid;
			_ret.reason = _val.reason;
		}
	}
	if (LOI && LOI.required === false && attr.validation) {
		if (attr.validation.optionalValues && attr.validation.optionalValues.exclude)
		{
			let _format = attr.validation.optionalValues.excludeFormat
			let _Optionalvals = JSON.parse(attr.validation.optionalValues.exclude)
			let _val = requiredOptionalValidation(_format, _Optionalvals, currentValue,false, false)
			_ret.valid = _val.valid;
			_ret.reason = _val.reason;
		}
		if (attr.validation.requiredValues && attr.validation.requiredValues.include)
		{
			let _format = attr.validation.optionalValues.includeFormat
			let _requiredVals = JSON.parse(attr.validation.optionalValues.include)
			let _val = requiredOptionalValidation(_format, _requiredVals, currentValue,false, true)
			_ret.valid = _val.valid;
			_ret.reason = _val.reason;
		}
	}
	return _ret
}

function requiredOptionalValidation(format, values, currentValue, required, include) {
	let _ret = {}
	_ret.valid = true;
	_ret.reason = ""

	let strReq = " Required ";
	if (!required)
	{
		strReq = " optional "
	}

	let strInclude = " must "
	if (!include)
	{
		strInclude = " must not "
	}

	if (format === 1 && values && values.length > 0 && values.find((v) => {  if (include) { return !v.includes(currentValue)} else { return v.includes(currentValue) } }))
	{
		_ret.valid = false;
		_ret.reason = "Value " + currentValue + " is " + strReq + "and" + strInclude  + "contain " + JSON.stringify(values);
	}
	if (format === 2 && values && values.length > 0 && values.find((v) => { if (include) {  return !v.startsWith(currentValue)} else { return v.startsWith(currentValue) }}))
	{
		_ret.valid = false;
		_ret.reason = "Value " + currentValue + " is " + strReq  + "and" + strInclude  +  "Startwith " + JSON.stringify(values);
	}
	if (format === 3 && values && values.length > 0 && values.find((v) => { if (include) {  return !v.endsWith(currentValue)} else {  return v.endsWith(currentValue) }}))
	{
		_ret.valid = false;
		_ret.reason = "Value " + currentValue + " is " + strReq  + " and " + strInclude  +  " endsWith " + JSON.stringify(values);
	}
	if (format === 4 && values && values.length > 0 && include === true && !values.includes(currentValue))
	{
		_ret.valid = false;
		_ret.reason = "Value " + currentValue + " is " + strReq  + " and " + strInclude  +  " equal " + JSON.stringify(values);
	}
	if (format === 4 && values && values.length > 0 && include === false && values.includes(currentValue))
	{
		_ret.valid = false;
		_ret.reason = "Value " + currentValue + " is " + strReq  + " and " + strInclude  +  " equal " + JSON.stringify(values);
	}
	return _ret;
}

export function generateDynamicColDefsFromAttrs(loiAttributes, loiClassifications, loiData) {
	return loiAttributes
		.filter(attr => attr.loiName !== null)
		.map((attr, index) => {
			if (attr.loiName === null)
				return null;
			// Initialize the column object
			let col = {
				headerName: attr.alias ? attr.alias : attr.name,
				field: `attributes.${convertPascalToCamelCase(attr.name)}`,
				width: 150,
				renderType: attr.renderType,
				order: attr.forceOrder ? attr.forceOrder : index + 3,
				cellEditor: "agTextCellEditor",
				tooltipValueGetter : (params) => {
					if (!params.data.rowClassificiton)
					{
						let rowClassificiton = params.data.attributes.classification ? params.data.attributes.classification : 'NONE';
						params.data.rowClassificiton = buildRowClassifictionObject(rowClassificiton, loiClassifications,loiData);
					}
					if (params.data.rowClassificiton)
					{
						let LOI = loiData.find((LOI) => LOI.classification === params.data.rowClassificiton.commonName && LOI.attributeId === attr.id);
						let _cellValidationResult = cellValidation(params.value, LOI, attr)
						return _cellValidationResult.reason ? _cellValidationResult.reason : '';
					}
					return '';
				},
				cellClass: params => {
					//FIRST NEED TO GET THE CURRENT ROWS CLASSIFICTION
					if (!params.data.rowClassificiton)
					{
						let rowClassificiton = params.data.attributes.classification ? params.data.attributes.classification : 'NONE';
						params.data.rowClassificiton = buildRowClassifictionObject(rowClassificiton, loiClassifications,loiData);
					}
					if (params.data.rowClassificiton)
					{
						let LOI = loiData.find((LOI) => LOI.classification === params.data.rowClassificiton.commonName && LOI.attributeId === attr.id);
						let _cellValidationResult = cellValidation(params.value, LOI, attr)
						if (_cellValidationResult && _cellValidationResult.enabled)
						{
							return _cellValidationResult.valid ? 'green' : 'red';
						}
					}
					return 'white';
				},
			};

			if (attr.renderType === AttrConfigInputTypes.PRESET_OPTIONS_DROPDOWN_LIST) {
				console.log("VALID:", attr.validation);
				let reqValues = JSON.parse(attr.validation.requiredValues.include);
				col.cellEditor = 'agRichSelectCellEditor';
				col.cellEditorParams = { values: reqValues ? reqValues : [] };
			}

			if (attr.renderType === AttrConfigInputTypes.CLASSIFICATION_LOOKUP) {
				col.cellEditor = UniclassCellEditor;
				col.cellEditorPopup = true;
				col.cellEditorParams = {
					setName: attr.selectedClassificationSetName,
					loiClassifications: loiClassifications || []
				};
			}

			return col;
		});
}

export function generateDefaultRowDataFromAttrs(loiAttributes) {
	let defaultRow = {
		attributes: {}
	}

	loiAttributes.forEach(attr => {
		let attrName = convertPascalToCamelCase(attr.name);
		defaultRow.attributes[attrName] = "TBC";
	});
	return defaultRow;
}

export function getBaseColDefForSpacesGrid(locationData) {
	if (locationData == null)
		return [];

	const floorsList = locationData.floors.map((f) => { return f.attributes.name });
	const zoneList = locationData.zones.map((z) => {
		return {
			id: z.attributes.name,
			name: z.attributes.name,
			info: z.attributes.description
		};
	});

	return [
		{ headerName: 'id', field: 'id', hide: true, order: 0 },
		{
			headerName: "Floor Name",
			field: "floorName",
			order: 1,
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: { values: floorsList },
		},
		{
			headerName: "Zones",
			field: "zones",
			order: 2,
			cellEditor: CheckBoxCellEditor,
			cellEditorParams: {
				options: zoneList,
			},
			cellEditorPopup: true,
			valueGetter: function (params) {
				if (!params?.data?.zones) {
					return [];
				}
				return params?.data?.zones;
			},
			valueSetter: params => {
				console.log("SETTING ZONES:", params);
				// Check if newValue is a string and not an array, then split by commas
				if (typeof params.newValue === 'string') {
					params.data.zones = params.newValue.split(',')
						.map(item => item.trim()) // Trim each item
						.filter(item => item);
				} else if (Array.isArray(params.newValue)) {
					// If newValue is already an array, use it as is
					params.data.zones = params.newValue;
				}
				return true;
			}
		}
	];
}

export function getRowDataForSpaceGrid(spacesData) {
	const rowData = spacesData.map(item => {
		const row = {
			id: item.id,
			floorName: item.floorName,
			zones: item.zones, // Assuming zones are an array of strings
			attributes: {}
		};
		getDynamicRowData(item.attributes, row);
		return row;
	});
	return rowData;
}

export function getBaseColDefForGrid() {
	return [
		{ headerName: 'id', field: 'id', hide: true, order: 0 },
	];
}

export function getRowDataForGrid(data) {
	const rowData = data.map(item => {
		const row = {
			id: item.id,
			attributes: {}
		};
		getDynamicRowData(item.attributes, row);
		return row;
	});
	return rowData;
}

export function getSelectedRowIds(gridRef) {
	return getSelectedRowData(gridRef, (data) => data.id);
}


// UTLITY FUNCTIONS:

// Updates spaces to ensure floorName is updated on any Floor change
// and Zones are updated accordingly
export function updateSpacesOnLocationChange(currentLocationsData, oldLocation, newLocation, locationType) {
	if (locationType !== "floors" && locationType !== "zones")
		return;

	currentLocationsData["spaces"] = currentLocationsData["spaces"].map(space => {
		if (locationType === "floors" && oldLocation.attributes.name === space.floorName) {
			return {
				...space,
				floorName: newLocation.attributes.name
			};
		}
		if (locationType === "zones") {
			return {
				...space,
				zones: space.zones.map(zone =>
					zone === oldLocation.attributes.name ? newLocation.attributes.name : zone
				)
			}
		}
		return space;
	});
}

// Removes any floors / zones from spaces that get deleted.
export function updateSpacesOnLocationDelete(currentLocationsData, idsToDelete, locationType) {
	if (locationType !== "floors" && locationType !== "zones")
		return;

	const namesToDeleteSet = new Set(
		currentLocationsData[locationType].filter(location => idsToDelete.includes(location.id))
			.map(location => location.attributes.name)
	);

	currentLocationsData["spaces"] = currentLocationsData["spaces"].map(space => {
		if (locationType === "floors" && namesToDeleteSet.has(space.floorName)) {
			return { ...space, floorName: "" };
		}
		if (locationType === "zones") {
			return {
				...space,
				zones: space.zones.filter(zone => !namesToDeleteSet.has(zone))
			};
		}
		return space;
	});
}
