import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormHelperText,
	Typography,
	Stack
} from '@mui/material';
import {
	BasicTxtField
} from '../../ui';
import { WorkflowsContext, HelpContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	title: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	description: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	durationDays: [Validation.isNotNumber],
	position: [Validation.isNotNumber],
	weighting: [Validation.isNotNumber]
}

const styles = {
	formHelperText: { margin: '0 14px' }
};

const WorkflowStepCreatePage = ({ setCreateStep, workflowData, getapis }) => {
	const [busy, setBusy] = useState(false);
	const [step, setStep] = useState({
		workFlowId: workflowData.id,
		title: '',
		description: '',
		durationDays: 0,
		colour: '#000000',
		isCommenting: false,
		isMilestone: false,
		isDisplayed: false,
		position: workflowData.steps.length + 1,
		isReportedProcess: false,
		isReportedContent: false,
		isAutomatedStepChange: false,
		forecastCompletionStartStep: false,
		forecastCompletionReportEnabled: false,
		weighting: 1,
	});
	const [formErrors, setFormErrors] = useState({});

	const { createWorkflowStep } = useContext(WorkflowsContext);
	const { helpTexts, loadHelpByTitles } = useContext(HelpContext);

	// useEffect(() => {
	// 	console.log('edited step', step);
	// }, [step])

	useEffect(() => {
		setBusy(true);

		const helpTitles = [
			'workflowStepDuration',
			'workflowStepColour',
			'workflowstepIsCommenting',
			'workflowStepDisplayed',
			'workflowStepOrder',
			'isReportedProcess',
			'isReportedContent',
			'isAutomatedStepChange',
			'forecastCompletionStartStep',
			'forecastCompletionReportEnabled',
			'stepWeighting'
		];

		async function getApis() {
			await loadHelpByTitles(helpTitles);
			setBusy(false);
		}

		getApis();
	}, [loadHelpByTitles])

	const handleDataChange = (propertyName, newValue) => {
		setStep(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const stepData = await createWorkflowStep(step);

		if (!stepData) {
			return setBusy(false);
		}
		else {
			setCreateStep(false);
			getapis();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(step.title, "title", formValidators, newFormErrors);
		Validation.setFieldErrors(step.description, "description", formValidators, newFormErrors);
		Validation.setFieldErrors(step.durationDays, "durationDays", formValidators, newFormErrors);
		Validation.setFieldErrors(step.position, "position", formValidators, newFormErrors);
		Validation.setFieldErrors(step.weighting, "weighting", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
							<Typography variant="body1">Create new workflow step</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<BasicTxtField
							id={"Title"}
							label="Title"
							value={step.title}
							onChange={(value) => handleDataChange("title", value)}
							fullWidth={true}
							errors={formErrors['title']}
							required
						/>
						<BasicTxtField
							id={"Description"}
							label="Description"
							value={step.description}
							onChange={(value) => handleDataChange("description", value)}
							fullWidth={true}
							errors={formErrors['description']}
							required
						/>
						<Divider />
						<Typography variant="body1">Configuration</Typography>
						<BasicTxtField
							id={"Duration days"}
							label="Duration days"
							value={step.durationDays}
							onChange={(value) => handleDataChange("durationDays", value)}
							fullWidth={true}
							helperText={helpTexts["workflowStepDuration"]}
							errors={formErrors['durationDays']}
							type="number"
							required
						/>
						<BasicTxtField
							id={"Colour"}
							label="Colour"
							value={step.colour}
							onChange={(value) => handleDataChange("colour", value)}
							fullWidth={true}
							helperText={helpTexts["workflowStepColour"]}
							type="color"
						/>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isCommenting}
									onChange={(value) => handleDataChange("isCommenting", value.target.checked)}
								/>
							}
							label="Is commenting"
						></FormControlLabel>
						<FormHelperText style={styles.formHelperText}>{helpTexts["workflowstepIsCommenting"]}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isMilestone}
									onChange={(value) => handleDataChange("isMilestone", value.target.checked)}
								/> }
							label="Is displayed"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["workflowStepDisplayed"]}</FormHelperText>
						<BasicTxtField
							id={"Order"}
							label="Order"
							value={step.position}
							onChange={(value) => handleDataChange("position", value)}
							fullWidth={true}
							helperText={helpTexts["workflowStepOrder"]}
							errors={formErrors['position']}
							type="number"
							required
						/>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isReportedProcess}
									onChange={(value) => handleDataChange("isReportedProcess", value.target.checked)}
								/> }
							label="Report process"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["isReportedProcess"]}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isReportedContent}
									onChange={(value) => handleDataChange("isReportedContent", value.target.checked)}
								/> }
							label="Report content"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["isReportedContent"]}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isAutomatedStepChange}
									onChange={(value) => handleDataChange("isAutomatedStepChange", value.target.checked)}
								/> }
							label="Is automated step change"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["isAutomatedStepChange"]}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.forecastCompletionStartStep}
									onChange={(value) => handleDataChange("forecastCompletionStartStep", value.target.checked)}
								/> }
							label="Forecast completion start step"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["forecastCompletionStartStep"]}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.forecastCompletionReportEnabled}
									onChange={(value) => handleDataChange("forecastCompletionReportEnabled", value.target.checked)}
								/> }
							label="Forecast completion report enabled"
						/>
						<FormHelperText style={styles.formHelperText}>{helpTexts["forecastCompletionReportEnabled"]}</FormHelperText>
						<BasicTxtField
							id={"Weighting"}
							label="Weighting"
							value={step.weighting}
							onChange={(value) => handleDataChange("weighting", value)}
							fullWidth={true}
							helperText={helpTexts["stepWeighting"]}
							type="number"
							errors={formErrors['weighting']}
							required
						/>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setCreateStep(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)

}
export default WorkflowStepCreatePage;