import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, ListItem, Button } from '@mui/material';

const NewUsersReviewList = ({
	users,
	onClose,
	onComplete,
	confirmMsg
}) => {
	return (
		<div>
			<Typography variant="h6">Please review your list of new users</Typography>
			<Typography variant="body1" color="textSecondary">
				{confirmMsg}
			</Typography>
			<List>
				{users.map((user, index) => (
					<ListItem key={index}>
						{user.email}
					</ListItem>
				))}
			</List>
			<div style={{ textAlign: 'right' }}>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<Button variant="contained" onClick={onComplete} style={{ marginLeft: '10px' }}>
					Confirm
				</Button>
			</div>
		</div>
	);
};

NewUsersReviewList.propTypes = {
	users: PropTypes.arrayOf(
		PropTypes.shape({
			email: PropTypes.string.isRequired
		})
	).isRequired,
	onClose: PropTypes.func,
	onComplete: PropTypes.func
};

export default NewUsersReviewList;