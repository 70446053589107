import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import { CRUD_ACTIONS } from '../../constants/ApiConstants';
import endpoints from '../endpoints';

// Documents //

export async function loadDocumentsBySite() {
	try {
		const documentsBySiteEndpoint = endpoints.documents.bySiteId();
		const data = await springboardApiRequest(documentsBySiteEndpoint, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching documents by site data:', error);
		throw new Error('Failed to fetch documents by site data.');
	}
}

export async function loadDocumentsByProject(projectId) {
	try {
		const documentsByProjectsEndpoint = endpoints.documents.byProjectId(projectId);
		const data = await springboardApiRequest(documentsByProjectsEndpoint, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching documents by project data:', error);
		throw new Error('Failed to fetch documents by project data.');
	}
}

export async function loadDocumentById(id) {
	try {
		const documentByIdEndpoint = endpoints.documents.byId(id);
		const data = await springboardApiRequest(documentByIdEndpoint, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document by id data:', error);
		throw new Error('Failed to fetch document by id data.');
	}
}

export async function loadAllowedResponsibleUsers(documentId) {
	try {
		const allowedResponsibleUsersEndpoint = endpoints.documents.getAllowedResponsibleUsersByDocId(documentId);
		const data = await springboardApiRequest(allowedResponsibleUsersEndpoint, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching allowed responsible users data:', error);
		throw new Error('Failed to fetch allowed responsible users data.');
	}
}


export async function loadMasterContentDocs() {
	try {
		const getMasterContentDocs = endpoints.documents.getMasterContentDocs();
		const data = await springboardApiRequest(getMasterContentDocs, "GET");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching master content docs data:', error);
		throw new Error('Failed to fetch master content docs data.');
	}
}

export async function updateDocument(document) {
	try {
		const customHeaders = { documentId: document.id };
		const data = await springboardApiRequest(endpoints.documents.update(), "POST", document, customHeaders);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating document data:', error);
		throw new Error('Failed to update document data.');
	}
}

export async function createDocument(document) {
	try {
		const data = await springboardApiRequest(endpoints.documents.create(), "POST", document);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating document data:', error);
		throw new Error('Failed to create document data.');
	}
}

export async function deleteDocument(id) {
	try {
		const request = {};
		const customHeaders = { documentId: id };
		const data = await springboardApiRequest(endpoints.documents.delete(id), "POST", request, customHeaders);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting document data:', error);
		throw new Error('Failed to delete document data.');
	}
}