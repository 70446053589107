import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Divider,
	Grid,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import {
	BasicTxtField,
	SelectDropdown,
} from '../../ui';
import { SiteDataContext } from '../../../contexts';

function ClassificationTemplateModal({ open, onApply, onCreate, onClose }) {
	const {
		siteClassificationTemplates,
	} = useContext(SiteDataContext);
	const [selectedTemplateId, setSelectedTemplateId] = useState(null);
	const [newTemplateName, setNewTemplateName] = useState("");

	const canApplyTemplate = () => {
		return selectedTemplateId !== null;
	}

	const canCreateTemplate = () => {
		return newTemplateName !== "";
	}

	const handleApplyTemplate = () => {
		onClose();
		let template = siteClassificationTemplates.find(template => template.id === selectedTemplateId);
		onApply(template);
	}

	const handleCreateTemplate = () => {
		onClose();
		onCreate(newTemplateName);
	}

	if (!open) {
		return null;
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle variant="h4">
				Template Settings
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant="h5">Apply a template</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">
							Apply a template of pre-configured sets/ groups/ classifications.<br />
							Warning: this will replace all the configured classification groups and sets within this project.
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<SelectDropdown
							id="classificationTemplate"
							label="Template"
							value={selectedTemplateId}
							onChange={setSelectedTemplateId}
							options={siteClassificationTemplates}
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<Button
							disabled={!canApplyTemplate()}
							variant="outlined"
							onClick={handleApplyTemplate}
							color="primary">
							Apply
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">Save current configuration as a template</Typography>
					</Grid>
					<Grid item xs={9}>
						<BasicTxtField
							id={"GroupName"}
							label="Group Name"
							value={newTemplateName}
							onChange={setNewTemplateName}
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<Button
							disabled={!canCreateTemplate()}
							variant="outlined"
							onClick={handleCreateTemplate}
							color="primary">
							Create
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default ClassificationTemplateModal;