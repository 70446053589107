import {
	getSelectedGridCellIds,
	getEntireSelectedRowGridCellIds
} from './AgGridHelpers';
import { WORKFLOW_ACTIONS } from '../../constants/WorkflowConstants';
import {
	GridWorkflowHeader,
} from '../../components/DataGridComponents';
import {
	AvatarCellRenderer,
	DateCellRenderer,
	CompanyCellRenderer
} from '../../components/DataGridComponents/CellRenderers';
import '../../components/DataGridContainers/customAgGrid.css';

function getBaseColumnDefs() {
	return [
		{ headerName: "TOC", field: "toc", width: 80 },
		{ headerName: "Title", field: "title", width: 250 },
		{ colId: "startDate", headerName: "Start", field: "startDate", width: 150, cellRenderer: DateCellRenderer },
		{ colId: "contractor", headerName: "Contractor", field: "contractor", width: 150, cellRenderer: CompanyCellRenderer}
	];
}

export function getColumnDefsForWorkflowGrid(workflowData) {
	let columnDefs = getBaseColumnDefs();
	// Generate AG-Grid column definitions based on titles
	if (workflowData && Array.isArray(workflowData.steps)) {
		workflowData.steps.forEach(workflowStep => {
			columnDefs.push({
				colId: workflowStep.id,
				headerName: workflowStep.title,
				field: workflowStep.title,
				width: 150,
				sortable: true,
				filter: true,
				headerComponent: GridWorkflowHeader,
				headerComponentParams: {
					days: workflowStep.durationDays,
					stepId: workflowStep.id,
					allowReviews: workflowStep.isCommenting,
					colour: workflowStep.colour,
					isStep: true
				},
				cellClass: params => params.data[workflowStep.title].cssClass || '',
				cellRenderer: AvatarCellRenderer,
				cellRendererParams: {
					allowReviews: workflowStep.isCommenting
				},
			});
		});
	}
	return columnDefs;
}


function getUserById(users, userId) {
	const user = users.find(user => user.id === userId);
	return user;
}

// Fetch the user responsible for the current step and section
function getResponsibleUserId(responsibilityData, sectionId, stepId) {
	let userResponsible = responsibilityData.find(item => item.stepId === stepId && item.sectionId === sectionId);
	return userResponsible ? userResponsible.userId : null;
}

function getReviewUserIds(reviewsData, sectionId, stepId) {
	let reviewUsers = reviewsData.find(item => item.stepId === stepId && item.sectionId === sectionId);
	return reviewUsers ? reviewUsers.users : [];
}

// Workflow GRID:
export function generateRowDataForWorkflowGrid(workflowData, sectionData, responsibilityData, reviewsData, usersData, defaultResponsibleUserId) {
	console.log("Section Data in grid:", sectionData);
	const defaultResponsibleUser = getUserById(usersData, defaultResponsibleUserId);
	return sectionData.map(section => {
		let currentStepFound = false;
		let stepsStatus = workflowData.steps.reduce((acc, step) => {
			// Fetch the user responsible for the current step and section
			const responsibleUserId = getResponsibleUserId(responsibilityData, section.id, step.id);
			const responsibleUser = getUserById(usersData, responsibleUserId);

			// Get all review users data.
			const reviewUserIds = getReviewUserIds(reviewsData, section.id, step.id);
			const reviewUsers = reviewUserIds.reduce((acc, userId) => {
				let user = getUserById(usersData, userId);
				if (user) {
					acc.push(user);
				}
				return acc;
			}, []);

			let cssClass = "complete-step";
			if (currentStepFound) {
				cssClass = "future-step";
			} else if (step.id === section.currentStepId) {
				cssClass = "current-step";
				currentStepFound = true;
			}
			acc[step.title] = { defaultResponsibleUser, responsibleUser, reviewUsers, cssClass };
			return acc;
		}, {});

		let startDate = section.startDate;

		return {
			rowId: section.id,
			toc: section.toc,
			title: section.title,
			startDate: startDate,
			contractor: section.contractor,
			...stepsStatus
		};
	});
}

export const checkSelectedWorkflowCells = (params) => {
	const selectedCellRanges = params.api.getCellRanges();
	let hasReviewUsers = false;
	let hasResponsibleUser = false;
	let allowReviews = false;

	selectedCellRanges.forEach(range => {
		range.columns.forEach(column => {
			const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
			const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

			if (column.colDef?.headerComponentParams?.allowReviews) {
				allowReviews = true;
			}

			// Search all selected cells to see if there are any review users or responsible users set.
			for (let i = startRowIndex; i <= endRowIndex; i++) {
				const rowNode = params.api.getDisplayedRowAtIndex(i);
				const cellData = rowNode.data[column.colDef.field];

				if (cellData?.reviewUsers?.length > 0) {
					hasReviewUsers = true;
				}
				if (cellData?.responsibleUser) {
					hasResponsibleUser = true;
				}
			}
		});
	});

	return { hasReviewUsers, hasResponsibleUser, allowReviews };
};

// FILTERING:
const IsUserFilterMatch = (user, filter) => {
	if (!user)
		return false;
	return user.name.toLowerCase().includes(filter) ||
		user.companyName.toLowerCase().includes(filter);
}

const IsUsersFilterMatch = (users, filter) => {
	if (!users)
		return false;
	return users.some(user => IsUserFilterMatch(user, filter));
};

export const checkRowFilter = (obj, filter) => {
	if (filter === "")
		return true;
	for (const key in obj) {
		if (!obj[key]) continue;

		const { defaultResponsibleUser, responsibleUser, reviewUsers } = obj[key];

		if (IsUserFilterMatch(defaultResponsibleUser, filter) ||
				IsUserFilterMatch(responsibleUser, filter) ||
				IsUsersFilterMatch(reviewUsers, filter)) {
			return true;
		}
	}
	return false;
};

const getWorkflowStepMenu = (params, onMenuAction, workflowData) => {
	let workflowCellIds = getSelectedWorkflowCellIds(params.api, workflowData);
	const { hasReviewUsers, hasResponsibleUser, allowReviews } = checkSelectedWorkflowCells(params);

	const customResponsibilItems = [
		{
			name: "- Set Responsible User -",
			cssClasses: ['workflow-context-menu'],
			disabled: true
		},
		'separator',
		{
			name: "Set",
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.SET_RESPONSIBILITY, workflowCellIds);
				console.log("Set responsibility clicked: ", workflowCellIds);
			}
		},
		{
			name: "Clear",
			disabled: !hasResponsibleUser,
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.CLEAR_RESPONSIBILITY, workflowCellIds);
			}
		}
	];

	const customReviewItems = [
		'separator',
		{
			name: "- Set Reviewers -",
			cssClasses: ['workflow-context-menu'],
			disabled: true
		},
		'separator',
		{
			name: "Add",
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.SET_REVIEWER, workflowCellIds);
				console.log("Add Reviewers clicked");
			}
		},
		{
			name: "Remove",
			disabled: !hasReviewUsers,
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.REMOVE_REVIEWERS, workflowCellIds);
			}
		},
	];

	let customItems = [...customResponsibilItems];
	if (allowReviews) {
		customItems = [...customItems, ...customReviewItems];
	}
	return customItems;
}

const getStartDateMenu = (params, onMenuAction) => {
	let cellIds = getSelectedGridCellIds(params.api);
	const customStartDateItems = [
		{
			name: "- Start Date -",
			cssClasses: ['workflow-context-menu'],
			disabled: true
		},
		'separator',
		{
			name: "Configure",
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.CONFIGURE_START_DATE, cellIds);
				console.log("configure start date clicked: ", cellIds);
			}
		},
	];

	return customStartDateItems;
}

const getContractorMenu = (params, onMenuAction) => {
	let cellIds = getSelectedGridCellIds(params.api);
	let customContractorItems = [
		{
			name: "- Contractor -",
			cssClasses: ['workflow-context-menu'],
			disabled: true
		},
		'separator',
		{
			name: "Configure",
			action: () => {
				onMenuAction(WORKFLOW_ACTIONS.CONFIGURE_CONTRACTOR, cellIds);
				console.log("configure contractor clicked: ", cellIds);
			}
		},
	];

	return customContractorItems;
}

export const getContextMenuItems = (params, onMenuAction, workflowData) => {
	console.log("COLS:", params.column.colDef);
	const isStartDate = params.column.colDef.field === "startDate";
	const isContractorCol = params.column.colDef.field === "contractor";
	const isStep = params.column.colDef.headerComponentParams?.isStep;

	if (isStep) {
		return getWorkflowStepMenu(params, onMenuAction, workflowData);
	}
	else if (isStartDate) {
		return getStartDateMenu(params, onMenuAction);
	}
	else if (isContractorCol) {
		return getContractorMenu(params, onMenuAction);
	}

	return [];
};

export function getSelectedWorkflowCellIds(gridApi, workflowData) {
	let cellIds = getSelectedGridCellIds(gridApi);
	return getWorkflowIdsFromSelectedCells(cellIds, workflowData);
}

export const getWorkflowIdsFromSelectedCells = (selectedCells, workflowData) => {
	return selectedCells.filter(cell => workflowData.steps.some(step => step.id === cell.colId));
};

// ### REVIEWER HELPER FUNCTIONS: ####
export const removeNonReviewableCells = (selectedCells, workflowData) => {
	return selectedCells.filter(cell => workflowData.steps.some(step => step.id === cell.colId && step.isCommenting));
}

export const getUniqueReviewerUserIdsForSelectedCells = (selectedCells, reviewsData) => {
	const userIdsSet = new Set();

	selectedCells.forEach(cell => {
		// Find the associated reviewer item
		const reviewer = reviewsData.find(reviewer => reviewer.stepId === cell.colId && reviewer.sectionId === cell.rowId);
		if (reviewer) {
			// Add all user IDs to the set to ensure uniqueness
			reviewer.users.forEach(userId => userIdsSet.add(userId));
		}
	});

	// Convert the set back to an array to get the unique user IDs
	return Array.from(userIdsSet);
};

export const calcReviewersToRemove = (selectedCells, reviewsData, users) => {
	const reviewersToRemove = [];

	selectedCells.forEach(cell => {
		// Find the associated reviewer item
		const reviewer = reviewsData.find(reviewer => reviewer.stepId === cell.colId && reviewer.sectionId === cell.rowId);

		let usersToRemove = [];

		if (reviewer) {
			// Filter users that are in the reviewer's users list
			console.log("REVIEWERS:", reviewer);
			console.log("USERS:", users);
			usersToRemove = users.filter(userId => reviewer.users.includes(userId));
			console.log("Removals:", usersToRemove);
		}

		if (usersToRemove.length > 0) {
			reviewersToRemove.push({
				stepId: cell.colId,
				sectionId: cell.rowId,
				users: usersToRemove
			});
		}
	});

	return reviewersToRemove;
};

export const removeReviewers = (existingReviewers, reviewersToRemove) => {
	console.log("REMOVING REVIEWERS:", reviewersToRemove);
	const updatedReviewers = existingReviewers.map(reviewer => {
		const toRemove = reviewersToRemove.find(r => r.stepId === reviewer.stepId && r.sectionId === reviewer.sectionId);

		if (toRemove) {
			const updatedUsers = reviewer.users.filter(userId => !toRemove.users.includes(userId));
			return { ...reviewer, users: updatedUsers };
		}

		return reviewer;
	}).filter(reviewer => reviewer.users.length > 0); // Remove reviewers with no users

	return updatedReviewers;
};


export const calcNewReviewers = (selectedCells, reviewsData, users) => {
	const newReviewers = [];

	selectedCells.forEach(cell => {
		// Find the associated reviewer item
		const reviewer = reviewsData.find(reviewer => reviewer.stepId === cell.colId && reviewer.sectionId === cell.rowId);

		let usersToAdd;

		if (reviewer) {
			// Filter new users that are not in the reviewer's users list
			usersToAdd = users.filter(user => !reviewer.users.includes(user.id)).map(user => user.id);
		} else {
			// If the reviewer is not found, add all new users
			usersToAdd = users.map(user => user.id);
		}

		if (usersToAdd.length > 0) {
			newReviewers.push({
				stepId: cell.colId,
				sectionId: cell.rowId,
				users: usersToAdd
			});
		}
	});

	return newReviewers;
};

export const mergeReviewers = (existingReviewers, newReviews) => {
	const updatedReviewers = [...existingReviewers];

	newReviews.forEach(newReview => {
		const existingReviewer = updatedReviewers.find(
			reviewer => reviewer.stepId === newReview.stepId && reviewer.sectionId === newReview.sectionId
		);

		if (existingReviewer) {
			// Merge the new users with the existing users, avoiding duplicates
			newReview.users.forEach(userId => {
				if (!existingReviewer.users.includes(userId)) {
					existingReviewer.users.push(userId);
				}
			});
		} else {
			// Add the new review entry to the reviewers array
			updatedReviewers.push(newReview);
		}
	});

	return updatedReviewers;
};

// ### Responsible User: ###

export const getSelectedResponsibleUsers = (selectedCells, responsibleData) => {
	const matchingResponsibilities = responsibleData.filter(responsibility =>
		selectedCells.some(cell =>
			responsibility.stepId === cell.colId && responsibility.sectionId === cell.rowId
		)
	);
	return matchingResponsibilities;
}

export const calcNewResponsibleUsers = (selectedCells, responsibleData, user) => {
	const newResponsibilities = [];

	selectedCells.forEach(cell => {
		// Find the associated responsibility item
		const responsibility = responsibleData.find(responsibility => responsibility.stepId === cell.colId && responsibility.sectionId === cell.rowId);

		let newResponsibleUser = {
			stepId: cell.colId,
			sectionId: cell.rowId,
			userId: user.id
		};
		if (responsibility) {
			// Overwrite the user if it's different
			if (responsibility.userId !== user.id) {
				newResponsibilities.push(newResponsibleUser);
			}
		} else {
			// If the responsibility is not found, add the new user
			newResponsibilities.push(newResponsibleUser);
		}
	});

	return newResponsibilities;
};


export const mergeResponsibleUsers = (existingResponsibleUsers, newResponsibleUsers) => {
	const updatedResponsibleUsers = [...existingResponsibleUsers];

	newResponsibleUsers.forEach(newResponsibleUser => {
		const existingResponsibleUser = updatedResponsibleUsers.find(
			responsibility => responsibility.stepId === newResponsibleUser.stepId && responsibility.sectionId === newResponsibleUser.sectionId
		);

		if (existingResponsibleUser) {
			// Replace the existing user with the new user
			existingResponsibleUser.userId = newResponsibleUser.userId;
		} else {
			// Add the new responsibility entry to the responsibilities array
			updatedResponsibleUsers.push(newResponsibleUser);
		}
	});

	return updatedResponsibleUsers;
};

// ## Set Start Date:

export const getSelectedSections = (selectedCells) => {
	const uniqueSectionIds = new Set(selectedCells.map(cell => cell.rowId));

	return Array.from(uniqueSectionIds);
};