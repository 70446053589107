import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const RequestSnackbar = ({ request }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (request && !request.loading) {
			setOpen(true);
		}
	}, [request]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<Alert onClose={handleClose} severity={request?.success ? 'success' : 'error'} sx={{ width: '100%' }}>
				{request?.message}
			</Alert>
		</Snackbar>
	);
};

export default RequestSnackbar;