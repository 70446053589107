import React from 'react';
import * as Api from '../api/AdminApi';
import apiRequestManager from '../api/Services/ApiRequestManager';
import { hasDataLoaded } from '../helpers/GeneralHelpers';
export const WorkflowsContext = React.createContext(null);

export class WorkflowsProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			linkedWorkflowIdToView: null,
			actionTemplates: [],
			stepChangeOptions: []
		};
	}

	getLinkedWorkflowIdToView = () => {
		return this.state.linkedWorkflowIdToView;
	}

	setLinkedWorkflowIdToView = (workflowId) => {
		this.setState({ linkedWorkflowIdToView: workflowId });
	}

	loadWorkflowsForSite = async (siteId) => {
		try {
			const workflows = await Api.loadWorkflowsForSite(siteId);
			return workflows;
		} catch (error) {
			console.error("Error loading workflows for site:", error);
		}
	}

	loadWorkflowById = async (id) => {
		try {
			const workflow = await Api.loadWorkflowById(id);
			return workflow;
		} catch (error) {
			console.error("Error loading workflow by id:", error);
		}
	}

	createWorkflow = async (workflowData) => {
		try {
			const workflow = await Api.createWorkflow(workflowData);
			return workflow;
		} catch (error) {
			console.error("Error creating workflow:", error);
		}
	}

	duplicateWorkflow = async (workflowData) => {
		try {
			const workflow = await Api.duplicateWorkflow(workflowData);
			return workflow;
		} catch (error) {
			console.error("Error duplicating workflow:", error);
		}
	}

	updateWorkflow = async (workflowData) => {
		try {
			const workflow = await Api.updateWorkflow(workflowData);
			return workflow;
		} catch (error) {
			console.error("Error updating workflow:", error);
		}
	}

	deleteWorkflowById = async (id) => {
		try {
			const workflow = await Api.deleteWorkflow(id);
			return workflow;
		} catch (error) {
			console.error(`Error deleting workflow by id ${id}:`, error);
		}
	}

	createWorkflowStep = async (workflowStepData) => {
		try {
			const workflowStep = await Api.createWorkflowStep(workflowStepData);
			return workflowStep;
		} catch (error) {
			console.error("Error creating workflow step:", error);
		}
	}

	updateWorkflowStep = async (workflowStepData) => {
		let requestId = apiRequestManager.addRequestToQueue("updateWorkflowStep");
		try {
			const workflowStep = await Api.updateWorkflowStep(workflowStepData);
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated workflow step");
			return workflowStep;
		} catch (error) {
			let errMsg = "Error updating workflow step:" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	deleteWorkflowStepById = async (id) => {
		try {
			const workflowStep = await Api.deleteWorkflowStep(id);
			return workflowStep;
		} catch (error) {
			console.error(`Error deleting workflow step by id ${id}:`, error);
		}
	}

	loadActionTemplates = async () => {
		if (hasDataLoaded(this.state.actionsTemplates))
			return this.state.actionTemplates;
		const actionTemplates = [
			{
				id: 0,
				name: 'Please select'
			},
			{
				id: 1,
				name: 'Update',
				subject: 'Section needs updating',
				message: 'This section requires updating based on comments provided by the Review Team'
			},
			{
				id: 2,
				name: 'Authoring',
				subject: 'Section needs completing',
				message: 'Please complete all inputs in accordance with the guidance provided'
			},
			{
				id: 3,
				name: 'Info required',
				subject: 'Section needs requested information',
				message: 'Please provide the information requested within this section'
			},
			{
				id: 4,
				name: 'QA check',
				subject: 'Section needs qa check',
				message: 'This section requires you to complete a thorough check prior to it being moved to the next workflow step'
			}
		];
		this.setState({ actionTemplates });
		return actionTemplates;
	}

	loadStepChangeOptions = async () => {
		if (hasDataLoaded(this.state.stepChangeOptions))
			return this.state.stepChangeOptions;
		const stepChangeOptions = [
			{
				id: 0,
				name: 'Please select'
			},
			{
				id: 1,
				name: 'All reviews closed'
			},
			{
				id: 2,
				name: 'Date due passed'
			}
		]
		this.setState({ stepChangeOptions });
		return stepChangeOptions;
	}

	render() {
		const workflowsContextValue = {
			actionTemplates: this.state.actionTemplates,
			stepChangeOptions: this.state.stepChangeOptions,
			loadWorkflowsForSite: this.loadWorkflowsForSite,
			loadWorkflowById: this.loadWorkflowById,
			createWorkflow: this.createWorkflow,
			duplicateWorkflow: this.duplicateWorkflow,
			updateWorkflow: this.updateWorkflow,
			deleteWorkflowById: this.deleteWorkflowById,
			createWorkflowStep: this.createWorkflowStep,
			updateWorkflowStep: this.updateWorkflowStep,
			deleteWorkflowStepById: this.deleteWorkflowStepById,
			loadActionTemplates: this.loadActionTemplates,
			loadStepChangeOptions: this.loadStepChangeOptions,
			setLinkedWorkflowIdToView: this.setLinkedWorkflowIdToView,
			getLinkedWorkflowIdToView: this.getLinkedWorkflowIdToView,
		};

		return (
			<WorkflowsContext.Provider value={workflowsContextValue}>
				{this.props.children}
			</WorkflowsContext.Provider>
		)
	}
}

export default WorkflowsProvider;