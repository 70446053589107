import React, { useState, useContext } from 'react';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Link,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { DocumentContext, WorkflowsContext } from '../../../contexts';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'Documents-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const DocumentsTableComponent = ({ documents, users, workflows, setEdit, setDocumentId, handleProjectChange, navigateTo }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [deleteDoc, setDeleteDoc] = useState({ id: '', name: '', project: '' });

	const { deleteDocumentById } = useContext(DocumentContext);
	const { setLinkedWorkflowIdToView } = useContext(WorkflowsContext);

	const findUser = (userId) => {
		if (userId == null) return '-';
		const user = users.find(({id}) => id === userId);
		return user ? user.name : '-';
	}

	const findWorkflow = (workflowId) => {
		const workflow = workflows.find(({id}) => id === workflowId);
		return workflow.title
	}

	const handleOpenDeleteDialog = (document) => {
  	setOpenDeleteConfirm(true);
		setDeleteDoc({ id: document.id, name: document.name, project: document.projectId });
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
		setDeleteDoc({ id: '', name: '', project: '' });
	};

	const handleSubmit = async () => {
		const documentData = await deleteDocumentById(deleteDoc.id);

		if (!documentData) {
			return setOpenDeleteConfirm(false)
		}
		else {
			setOpenDeleteConfirm(false);
			handleProjectChange(deleteDoc.project);
		}
	}

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'weightedValue',
			headerName: 'Weighting',
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: 'responsibleUserId',
			headerName: 'Responsible user',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return findUser(row.responsibleUserId);
			}
		},
		{
			field: 'workflowId',
			headerName: 'Workflow',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return findWorkflow(row.workflowId);
			},
			renderCell: (params) => (
				<Link
					component="button"
					onClick={() => { navigateTo('Workflows'); setLinkedWorkflowIdToView(params.row.workflowId) }}>
					{findWorkflow(params.row.workflowId)}
				</Link>
			)
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 160,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<SecurityButton
						featureName={SECURITY_FEATURES.DOCUMENT_PAGE_UPDATE}
						featureSecurityId={params.row.id}
						color="primary"
						onClick={() => {
							setEdit(true);
							setDocumentId(params.row.id);
						}}>
						Edit
					</SecurityButton>
					<SecurityButton
						featureName={SECURITY_FEATURES.DOCUMENT_PAGE_DELETE}
						featureSecurityId={params.row.id}
						color="primary"
						onClick={() => { handleOpenDeleteDialog(params.row); }}>
						Delete
					</SecurityButton>
				</ButtonGroup>
			)
		},
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={documents}
					columns={columns}
					rowHeight={42}
					autoHeight
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			</Box>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete document"
				message={`Please confirm that you would like to delete document ${deleteDoc.name}`}
				onConfirm={handleSubmit}
			/>
		</>
	);
};

export default DocumentsTableComponent;