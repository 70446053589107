import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { SiteDataContext } from '../../../contexts';

const SiteBrandingInfo = () => {
	const {
		siteBrandingImgSrc
	} = useContext(SiteDataContext);
	return (
		<div>
			<Typography variant="h5">Branding</Typography>
			<Typography variant="body1">Logo:</Typography>
			<img
				src={siteBrandingImgSrc}
				alt="Site Logo"
				style={{ width: 100, height: 100, margin: 5 }}
			/>
		</div>
	);
};

export default SiteBrandingInfo;