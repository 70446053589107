import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import FileTagsTableComponent from './FileTagsTableComponent';
import FileTagsModal from './FileTagsModal';
import * as Api from '../../../api/Admin/fileCategoriesApi';
import { SecurityButton } from '../../ui';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const FileTagsPage = ({ category, onUpdateCategoryTags }) => {
	const [tags, setTags] = useState(category.tags || []);
	const [tagEditModalOpen, setTagEditModalOpen] = useState(false);
	const [currentTag, setCurrentTag] = useState(null);

	useEffect(() => {
		setTags(category.tags || []);
	}, [category]);

	const handleEditTag = (tag) => {
		setCurrentTag(tag);
		setTagEditModalOpen(true);
	};


	const handleSaveTag = async (savedTag) => {
		let isNewTag = !savedTag.id;
		let serverTag = savedTag;
		let updatedTags = tags;
		try
		{
			setTagEditModalOpen(false);
			if (isNewTag) {
				serverTag = await Api.createFileTag({...savedTag, CategoryId: category.id});
				updatedTags =  [...tags, serverTag];
			}
			else {
				serverTag = await Api.updateFileTag(savedTag);
				updatedTags = tags.map(tag => (tag.id === serverTag.id ? serverTag : tag))
			}
			const numberOfActiveTags = updatedTags.filter(tag => !tag.deleted).length;
			const updatedCategory = { ...category, tags: updatedTags, numberOfActiveTags  };
			setTags(updatedTags);
			onUpdateCategoryTags(updatedCategory);
		}
		catch (error) {
		}
	};

	const handleClose = () => {
		setTagEditModalOpen(false);
	};

	if (!category.name) {
		return null;
	}

	return (
		<>
			<Typography variant={'h4'}>
				Category Name: {category.name}
			</Typography>
			<FileTagsTableComponent
				tags={tags}
				onEditTag={handleEditTag}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
				<SecurityButton
					featureName={SECURITY_FEATURES.FILE_TAGS_PAGE}
					variant="contained"
					color="primary"
					onClick={() => handleEditTag({})}
				>
					Add Tag
				</SecurityButton>
			</Box>
			<FileTagsModal
				open={tagEditModalOpen}
				onClose={handleClose}
				tag={currentTag || {}}
				onSave={handleSaveTag}
			/>
		</>
	);
};

export default FileTagsPage;
