import React from 'react';
import PropTypes from 'prop-types';
import { DATA_MASK_ALLOWED_FILES } from '../../../constants/FileTypeConstants';
import FileUploadModal from './FileUploadModal';
import { processDataMaskFile } from '../../../api/AdminApi';
import { getFileExtension } from '../../../helpers/GeneralHelpers';

function DataMaskFileUploadModal({ loiName, open, onClose, onComplete }) {

	const fileUploadComplete = async (fileData) => {
		console.log("FILE DATA:", fileData);
		fileData.extension = "." + getFileExtension(fileData.originalName);
		fileData.loiName = loiName;
		fileData.dataMaskLookupFile = fileData.originalName;
		let success = true;
		try
		{
			await processDataMaskFile(fileData);
		}
		catch (error) {
			// Report failure above?
			success = false;
			console.error("Error: Data mask upload failed!");
		}

		onClose();
		if (onComplete)
			onComplete(success, fileData.originalName);
	}

	return <FileUploadModal
		title={"Data Mask Lookup"}
		acceptedFileTypes={DATA_MASK_ALLOWED_FILES}
		open={open}
		onClose={onClose}
		processFileCallback={fileUploadComplete}
	/>
}

DataMaskFileUploadModal.propTypes = {
	loiName: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default DataMaskFileUploadModal;