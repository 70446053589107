import React, { useState, useContext } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	Stack
} from '@mui/material';
import {
	SelectDropdown,
	SecurityButton
} from '../../ui';
import { DocumentContext, ProjectContext, WorkflowsContext } from '../../../contexts/';
import DocumentsTableComponent from './DocumentsTableComponent';
import DocumentPage from './DocumentPage';
import DocumentCreatePage from './DocumentCreatePage';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const DocumentsPage = (props) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [selectedProjectId, setSelectedProjectId] = useState('');
	const [documents, setDocuments] = useState([]);
	const [busy, setBusy] = useState(false);
	const [edit, setEdit] = useState(false);
	const [create, setCreate] = useState(false);
	const [documentId, setDocumentId] = useState(null);
	const [users, setUsers] = useState([]);
	const [workflows, setWorkflows] = useState([]);

	const { projects } = useContext(ProjectContext);
	const { loadDocumentsByProject, loadResponsibleUsersById } = useContext(DocumentContext);
	const { loadWorkflowsForSite } = useContext(WorkflowsContext);

	const handleProjectChange = async (selectedId) => {
		setBusy(true);
		setSelectedProjectId(selectedId);

		const documentsData = await loadDocumentsByProject(selectedId);
		setDocuments(documentsData);

		const cleanDocumentsData = documentsData.filter(data => data.responsibleUserId !== null);
		const userIds = cleanDocumentsData.map(data => data.responsibleUserId);
		const usersData = await loadResponsibleUsersById(userIds);
		setUsers(usersData);

		const workflowsData = await loadWorkflowsForSite(siteId);
		setWorkflows(workflowsData);

		setBusy(false);
	};

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Documents</Typography>
				{!edit && !create &&
					<>
						<Typography variant="body1">Select a project</Typography>
						<SelectDropdown
							id={"Projects"}
							value={selectedProjectId}
							onChange={handleProjectChange}
							options={projects}
							fullWidth
						/>
					</>
				}
				{busy && <CircularProgress />}
				{!busy && !edit && !create && selectedProjectId !== '' &&
					<>
						<DocumentsTableComponent
							documents={documents}
							users={users}
							workflows={workflows}
							setEdit={setEdit}
							setDocumentId={setDocumentId}
							handleProjectChange={handleProjectChange}
							navigateTo={props.navigateTo}
						/>
						<SecurityButton
							featureName={SECURITY_FEATURES.DOCUMENT_PAGE_CREATE}
							featureSecurityId={selectedProjectId}
							variant="contained"
							color="primary"
							size="small"
							onClick={() => setCreate(true)}
							sx={{ display: 'flex', alignSelf: 'flex-end' }}>
							Create new document
						</SecurityButton>
					</>
				}
				{edit && <DocumentPage setEdit={setEdit} documentId={documentId} handleProjectChange={handleProjectChange} />}
				{create && <DocumentCreatePage setCreate={setCreate} handleProjectChange={handleProjectChange} projectId={selectedProjectId} />}
			</Stack>
		</Box>
	)
}
export default DocumentsPage;