import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { PaginatedTable } from '../../ui/';
import { LoadingModal } from '../../modals';
import * as Api from '../../../api/AdminApi';

const ClassificationCodesViewer = ({ open, onClose, groupId }) => {
	const [codes, setCodes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		const getCodes = async () => {
			if (groupId === '') {
				return;
			}
			setIsLoading(true);
			try {
				let newCodes = await Api.getClassificationCodesByGroupId(groupId);
				setCodes(newCodes);
			}
			catch (error) {
			}
			setIsLoading(false);
		}
		getCodes();
	}, [groupId]);

	if (isLoading) {
		return <LoadingModal title={"Loading Classification Codes"} open={open} onClose={onClose} />
	}
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Classification Codes</DialogTitle>
			<DialogContent>
				<PaginatedTable data={codes}>
					{(displayedCodes) => (
						<>
							<TableHead>
								<TableRow>
									<TableCell>Code</TableCell>
									<TableCell>Description</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{displayedCodes.map((row) => (
									<TableRow key={row.id}>
										<TableCell>{row.code}</TableCell>
										<TableCell>{row.description}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</>
					)}
				</PaginatedTable>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ClassificationCodesViewer;
