import React, { useState, useContext, useMemo, useEffect } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	Select,
	Stack
} from '@mui/material';
import {
	SelectDropdown,
	SecurityButton
} from '../../ui';
import { DocumentContext, ProjectContext } from '../../../contexts/';
import SharedLinksTableComponent from './SharedLinksTableComponent';
import SharedLinkEditModal from '../../modals/SharedLinkEditModal';
import SharedLinkSendModal from '../../modals/SharedLinkSendModal';
import { getSharedLinksByDocumentId } from '../../../api/Admin/sharedLinksApi';

const SharedLinksManagerPage = (props) => {
	const [selectedProjectId, setSelectedProjectId] = useState('');
	const [selectedDocumentId, setSelectedDocumentId] = useState('');
	const [documents, setDocuments] = useState([]);
	const [sharedLinks, setSharedLinks] = useState([]);
	const [busy, setBusy] = useState(false);
	const [editSharedLink, setEditSharedLink] = useState(false);
	const [sendSharedLink, setSendSharedLink] = useState(false);
	const [selectedSharedLinkId, setSelectedSharedLinkId] = useState('');

	const { projects } = useContext(ProjectContext);
	const { loadDocumentsByProject } = useContext(DocumentContext);

	const handleProjectChange = async (selectedId) => {
		setBusy(true);
		setSelectedProjectId(selectedId);
		setSelectedDocumentId('');
		setDocuments([]);

		const documentsData = await loadDocumentsByProject(selectedId);
		setDocuments(documentsData);

		setBusy(false);
	};

	const handleDocumentChange = async (selectedId) => {
		setBusy(true);

		setSelectedDocumentId(selectedId);
		const response = await getSharedLinksByDocumentId(selectedId);
		//const documentName = documents.find(doc => doc.id === selectedId).name;
		setSharedLinks(response);

		setBusy(false);
	};

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Shared links manager</Typography>
				<Typography variant="body1">Select a project</Typography>
				<SelectDropdown
					id={"Projects"}
					value={selectedProjectId}
					onChange={handleProjectChange}
					options={projects}
					fullWidth
				/>
				{documents.length > 0 &&
					<>
						<Typography variant="body1">Select a document</Typography>
						<SelectDropdown
							id={"Documents"}
							value={selectedDocumentId}
							onChange={handleDocumentChange}
							options={documents}
							fullWidth
							usePlaceholder={true}
							forceSelectSingleOption={false}
						/>
					</>
				}
				{!busy && selectedProjectId !== '' && !documents.length &&
					<Typography variant="body1">There are no documents associated with this project</Typography>
				}
				{!busy && selectedDocumentId !== '' &&
					<SharedLinksTableComponent
						sharedLinks={sharedLinks}
						setEditSharedLink={setEditSharedLink}
						setSendSharedLink={setSendSharedLink}
						documentId={selectedDocumentId}
						handleDocumentChange={handleDocumentChange}
						setSelectedSharedLinkId={setSelectedSharedLinkId}
					/>
				}
				{!busy && editSharedLink &&
					<SharedLinkEditModal
						open={editSharedLink}
						onClose={() => setEditSharedLink(false)}
						handleDocumentChange={handleDocumentChange}
						documentId={selectedDocumentId}
						sharedLinkData={sharedLinks.find(elem => elem.sharedLinkKey === selectedSharedLinkId)}
					/>
				}
				{!busy && sendSharedLink &&
					<SharedLinkSendModal
						open={sendSharedLink}
						onClose={() => setSendSharedLink(false)}
						documentId={selectedDocumentId}
						sharedLinkData={sharedLinks.find(elem => elem.sharedLinkKey === selectedSharedLinkId)}
					/>
				}
				{busy && <CircularProgress />}
			</Stack>
		</Box>
	)
}
export default SharedLinksManagerPage;