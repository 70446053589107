import React, { useMemo } from 'react';
import {
	getBaseColDefForGrid,
	getRowDataForGrid
} from '../../../helpers/AgGrid/LocationsGridHelpers';
import AgGridBaseLocationView from './AgGridBaseLocationView';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const AgGridFloorsView = ({ locationsData, loiAttributes, loiClassifications, performCRUDOperation, loiData }) => {
	const baseColDefs = useMemo(() => { return getBaseColDefForGrid() }, []);
	//console.log("Floor base col:", baseColDefs);
	return (
		<AgGridBaseLocationView
			locationData={locationsData.floors}
			loiAttributes={loiAttributes}
			loiClassifications={loiClassifications}
			performCRUDOperation={performCRUDOperation}
			structuralName={"Floor"}
			baseColDefs={baseColDefs}
			generateRowDataFunc={getRowDataForGrid}
			securityFeatureName={SECURITY_FEATURES.LOCATIONS_PAGE_FLOOR}
			loiData={loiData}
		/>
	);
};

export default AgGridFloorsView;