import moment from 'moment';
import 'moment/locale/en-gb';
import { EXPIRED_DATE_COLOR, SERVER_DATE_FORMAT, STR_DATE_FORMAT } from '../../constants/Constants';

function ServerDateDisplay({ date }) {
	if (date === null || date === "1900-01-01T00:00:00") {
		return <span style={{ color: EXPIRED_DATE_COLOR }}>Never</span>;
	}

	const momentDate = moment(date, SERVER_DATE_FORMAT);
	return (
		<>{momentDate.format(STR_DATE_FORMAT)}</>
	);
}

export default ServerDateDisplay;


