import React from 'react';
import {
	Box,
	Button,
	ButtonGroup,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'FileTagCategories-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const FileCategoriesTableComponent = ({ categories, onEditCategory, onEditTags }) => {
	const columns = [
		{
			field: 'name',
			headerName: 'Category name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'multiAssign',
			headerName: 'Multi-assign',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'multiFilter',
			headerName: 'Multi-filter',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'required',
			headerName: 'Required',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'numberOfActiveTags',
			headerName: 'Number of tags (Total/Active)',
			minWidth: 80,
			flex: 0.5,
			valueGetter: (value, row) => {
				return row.tags.length + ' / ' + row.numberOfActiveTags;
			}
		},
		{
			field: 'deleted',
			headerName: 'Deleted',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 240,
			flex: 1,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<SecurityButton
						featureName={SECURITY_FEATURES.FILE_TAGS_PAGE}
						color="primary"
						onClick={() => { onEditCategory(params.row) }}>
						Edit category
					</SecurityButton>
					<Button
						color="primary"
						onClick={() => { onEditTags(params.row) }}>
						View tags
					</Button>
				</ButtonGroup>
			)
		},
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={categories}
				columns={columns}
				rowHeight={42}
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
			/>
		</Box>
	);
};

export default FileCategoriesTableComponent;
