import React, { useState, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CheckBoxSubList from './CheckBoxSubList';
import { filterRecursiveSubList } from '../../helpers/SpringBoardDBHelpers';

/*
	Accepts:
	data - a list of items to display with:
		- id: id value that will be used to set checked items.
		- name: displayed value in the checkbox
		- info: secondary sub display value
		- subList: a list of id, name, info / subLists allowing almost unlimited depth.

	checkedItems - a dictionary with the key = item.id and true if selected / false if not selected
	Children - Can be used to display things such as a button
	onCheckedItemsChange - called when items change
*/

const checkAllChildren = (items, newCheckedItems, isChecked) => {
	for (let item of items) {
		if ('disabled' in item && item.disabled) return;
		newCheckedItems[item.id] = isChecked;
		if (item.subList) {
			checkAllChildren(item.subList, newCheckedItems, isChecked);
		}
	}
};

const CheckBoxList = ({ children, data, checkedItems, onCheckedItemsChange, id }) => {
	const [filterText, setFilterText] = useState('');
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const filteredList = useMemo(() => {
		//console.log("Filtering sub list " + id);
		console.log(data);
		return filterRecursiveSubList(data, filterText);
	}, [data, filterText]);

	const handleCheckboxChange = (event, subList) => {
		const { name, checked } = event.target;
		const newCheckedItems = { ...checkedItems };
		console.log("CHECKBOX EVENT", event);
		//console.log(event);
		newCheckedItems[name] = checked;

		if (subList) {
			checkAllChildren(subList, newCheckedItems, checked);
		}

		onCheckedItemsChange(newCheckedItems);
	};

	const handleSelectAllChange = () => {
		const newCheckedItems = { ...checkedItems };
		checkAllChildren(data, newCheckedItems, !selectAllChecked);
		setSelectAllChecked(!selectAllChecked);
		onCheckedItemsChange(newCheckedItems);
	};
	console.log("CheckBoxList render " + id);
	return (
		<>
			<TextField
				fullWidth
				style={{ maxWidth: '300px' }}
				label="Filter"
				value={filterText}
				onChange={(e) => setFilterText(e.target.value.toLowerCase())}
			/>
			<Box mt={2} />
			{children}
			<Box mt={2} />
			<FormControlLabel
				control={
					<Checkbox
						checked={selectAllChecked}
						onChange={handleSelectAllChange}
						name="selectAll"
						//checkedIcon={<CheckCircleRoundedIcon color="primary" />}
						//icon={<CancelIcon color="action" />}
					/>
				}
				label="Select All"
			/>

			<CheckBoxSubList
				subListData={filteredList}
				checkedItems={checkedItems}
				filterText={filterText}
				onChange={handleCheckboxChange}
			/>
		</>
	);
};

export default CheckBoxList;