import {
	getSelectedGridCellIds,
	getEntireSelectedRowGridCellIds
} from './AgGridHelpers';
import * as LOIConstants from '../../constants/LOIConstants';


// Accepts the loiNames by site ID and returns the found loiName matching to a project ID
export function findLOINameByProjectId(loiNames, projectId) {
	for (let loiName of loiNames) {
		const project = loiName.projects.find(p => p.id === projectId);
		if (project) {
			return {
				name: loiName.loiName,
				isProjectLevelLOI: project.isProjectLevelLOI
			};
		}
	}
	return null;
}

// LOI Grid:

export function getBaseColDefForLOIGrid() {
	return [
		{ headerName: "#", field: "rowNum", width: 80, pinned: true },
		{ headerName: "CommonName", field: "CommonName", width: 160, pinned: true },
		{ headerName: "Codes", field: "Codes", width: 350, pinned: true }
	];
}

// Helper function to decide if a column should be included
function shouldHideColumn(attr, filterCriteria, setFilter = false) {
	if (filterCriteria === "")
		return false;

	if (setFilter) {
		return (!attr.structuralName.toLowerCase().includes(filterCriteria)
			&& !filterCriteria.includes(attr.structuralName.toLowerCase()));
	}
	else {
		return (!attr.displayName.toLowerCase().includes(filterCriteria)
			&& !attr.structuralName.toLowerCase().includes(filterCriteria)
			&& !attr.alias?.toLowerCase().includes(filterCriteria));
	}
}

function shouldHideEmptyColumn(attr, colAttrCounts, viewAll) {
	if (viewAll === true)
		return false;
	let colId = attr.id;

	if (colAttrCounts.hasOwnProperty(colId)) {
		return colAttrCounts[colId] === 0;
	}

	return false;
}

export function getLOIColVisibility(loiAttrs, colAttrCounts, filterCriteria, viewAll, setName) {
	let columns = {
		visible: [],
		nonVisible: []
	};
	console.log("ATTRS:", loiAttrs);
	loiAttrs.forEach(attr => {
		if (shouldHideColumn(attr, setName, true) || shouldHideColumn(attr, filterCriteria) || shouldHideEmptyColumn(attr, colAttrCounts, viewAll))
			columns.nonVisible.push(attr.id);
		else
			columns.visible.push(attr.id);
	});
	return columns;
}

// Used to filter empty columns
export function getEmptyLOIColCount(loiAttrs, loiData) {
	const attributeCount = {};
	loiAttrs.forEach(attr => {
		attributeCount[attr.id] = 0;
	});

	// Count occurrences in loiData
	loiData.forEach(item => {
		if (item.attributeId && attributeCount.hasOwnProperty(item.attributeId)) {
			attributeCount[item.attributeId]++;
		}
	});
	console.log("ATTR COUNT:", attributeCount);
	return attributeCount;
}

// Used to filter empty rows
export function getLOIRowCount(loiClassifications, loiData) {
	console.log("ROW COUNT CLASS:", loiClassifications);
	console.log("ROW COUNT DATA: ", loiData);
	const rowLOICount = {};
	loiClassifications.forEach(classification => {
		rowLOICount[classification.commonName] = 0;
	});

	// Count occurrences in loiData
	loiData.forEach(item => {
		if (item.classification && rowLOICount.hasOwnProperty(item.classification)) {
			rowLOICount[item.classification]++;
		}
	});
	console.log("FLOOR ROW:", rowLOICount["Floor"]);
	console.log("ROW LOI COUNT:", rowLOICount);
	return rowLOICount;
}

function createColDefForAttr(attr, cellRenderer, configCellRenderer) {
	return {
		colId: attr.id,
		headerName: attr.displayName,
		field: attr.id,
		cellRenderer: cellRenderer,
		headerComponentParams: {
			group: attr.structuralName
		},

		floatingFilterComponent: configCellRenderer,
		suppressFloatingFilterButton: true,
		floatingFilterComponentParams: {
			attrId: attr.id,
			alias: attr.alias,
			clientReference: attr.clientReference
		},
	};
}

export function getLOIGridColumns(loiAttrs, cellRenderer, configCellRenderer, filterCriteria) {
	let columnDefs = getBaseColDefForLOIGrid();
	console.log("COL ATTR:", loiAttrs);
	if (!Array.isArray(loiAttrs)) {
		return columnDefs;
	}


	filterCriteria = filterCriteria.toLowerCase();

	let columnGroups = [];
	let lastGroupName = null;
	let currentGroup = null;

	loiAttrs.forEach(attr => {
		let groupName = attr.structuralName;

		// Create a new group if the groupName changes
		//if (groupName !== lastGroupName) {
		// CHANGED so that it sets ALL group headers to be above each attr header
		// this is a 'quick fix' authorised by Chris
		if (true) {
			if (currentGroup) {
				columnGroups.push(currentGroup); // push the previous group
			}
			currentGroup = {
				headerName: groupName,
				children: []
			};
		}

		let attrHeaderDef = createColDefForAttr(attr, cellRenderer, configCellRenderer);

		currentGroup.children.push(attrHeaderDef);

		lastGroupName = groupName;
	});

	if (currentGroup) {
		columnGroups.push(currentGroup); // push the last group
	}

	// Convert the groups map to an array
	let groupedColumns = Object.values(columnGroups);
	columnDefs = columnDefs.concat(groupedColumns);

	/*
	// TEMP OLD: Groups columns together by structuralName
	// Group columns by structuralName
	loiAttrs.forEach(attr => {
		if (!columnGroups[attr.structuralName]) {
			columnGroups[attr.structuralName] = {
				headerName: attr.structuralName,
				children: []
			};
		}
		let loiCell = {
			colId: attr.id,
			headerName: attr.commonName,
			field: attr.id,
			cellRenderer: cellRenderer,
			headerComponentParams: {
				group: attr.structuralName
			},

			floatingFilterComponent: configCellRenderer,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
				attrId: attr.id,
				alias: attr.alias,
				clientReference: attr.clientReference
			},
		};
		columnGroups[attr.structuralName].children.push(loiCell);
	});

	// Convert the groups map to an array
	let groupedColumns = Object.values(columnGroups);
	columnDefs = columnDefs.concat(groupedColumns);

	/*
	loiAttrs.forEach(attr => {
		let loiCell = {
			colId: attr.id,
			headerName: attr.name, // Use commonName as the header
			field: attr.id,
			cellRenderer: cellRenderer,
			headerComponentParams: {
				group: attr.structuralName
			},
			floatingFilterComponent: configCellRenderer,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
				attrId: attr.id,
				alias: attr.alias,
				clientReference: attr.clientReference
			},
		};
		columnDefs.push(loiCell);
	});*/

	console.log("Column defs:", columnDefs);
	return columnDefs;
}

function getLOIRowData(data) {
	return `R${data.required ? 1 : 0} - S${data.stage} - D${data.displayed ? 1 : 0}`;
}

export function getRowDataForLOIGrid(loiData, loiAttributes, loiClassifications) {
	if (!loiData || !loiClassifications || !loiAttributes) {
		// Handle the case where one or more inputs are null or undefined
		return [];
	}

	const classificationsDict = {};
	loiClassifications.forEach(item => {
		classificationsDict[item.commonName] = item;
	});

	const attributesDict = {};
	loiAttributes.forEach(item => {
		attributesDict[item.id] = item;
	});
	var start = Date.now();

	// Grouping classifications by commonName
	const groupedClassifications = loiClassifications.reduce((acc, classification) => {
		// Check if the commonName already exists in the accumulator
		if (!acc[classification.commonName]) {
			// Initialize the group with the first classification of this commonName
			acc[classification.commonName] = {
				commonName: classification.commonName,
				codesDescriptions: [classification.code + " " + classification.description],
				code: classification.code,
				setName: classification.setName
			};
		} else {
			// Add the current classification's code and description to the existing group
			acc[classification.commonName].codesDescriptions.push(classification.code + " " + classification.description);
		}
		return acc;
	}, {});

	var delta = Date.now() - start;
	start = Date.now();
	console.log("TOOK ", delta, " TO PROCESS ROW DATA 1");
	// Converting the grouped object back to an array of rows
	const rows = Object.values(groupedClassifications).map((group, index) => {
		const row = {
			rowId: group.commonName,
			rowNum: index + 1,
			setName: group.setName, // Used for filtering
			CommonName: group.commonName,
			Codes: group.codesDescriptions.join("; "), // Amalgamate codes and descriptions
			Code: group.code
		};

		/*
		// Adding default values for loiAttributes
		loiAttributes.forEach(attr => {
			row[attr.id] = ""; // Default value for non-existing data
		}); */

		return row;
	});

	// TODO: The above currently takes up to 600ms depending on how many attributes there are...
	// this is kind of slow and needs testing
	delta = Date.now() - start;
	start = Date.now();
	console.log("TOOK ", delta, " TO PROCESS ROW DATA 2");

	console.log("class loi:", loiClassifications);
	console.log("attr dict:", attributesDict);
	console.log("ROWS:", rows);
	// Create dictionary for fast lookup
	const rowIndices = {};
	rows.forEach((row, index) => {
		rowIndices[row.rowId] = index;
	});

	// Fill in the data from loiData
	loiData.forEach(data => {
		const rowIndex = rowIndices[data.classification];
		if (rowIndex !== undefined) {
			const attr = attributesDict[data.attributeId];
			if (attr) {
				rows[rowIndex][attr.id] = getLOIRowData(data);
			}
		}
	});
	delta = Date.now() - start;
	console.log("TOOK ", delta, " TO PROCESS ROW DATA 3");
	console.log("ROWS:", rows);

	return rows;
	/*
	Below uses dictionary for when IDS are sorted...
	// Initialize classification rows with all attribute columns set to default
	const classificationRows = {};
	let rowCount = 1;
	loiClassifications.forEach(classification => {
		const row = {
			rowId: rowCount++,
			CommonName: classification.CommonName,
			Codes: classification.Code + " " + classification.Description
		};
		loiAttributes.forEach(attr => {
			row[attr.commonName] = ""; // Default value for non-existing data
		});
		classificationRows[classification.id] = row;
	});
	console.log("class loi:", loiClassifications);
	console.log("Class rows:", classificationRows);

	// Fill in the data from loiData
	loiData.forEach(data => {
		const row = classificationRows[data.Classification];
		if (row) {
			const attr = attributesDict[data.AttributeId];
			if (attr) {
				row[attr.commonName] = data.Stage; // Set the stage value for the attribute
			}
		}
	});

	return Object.values(classificationRows);
	*/
}

export function getCellDataFromCellId(cellId, loiAttributes, loiData) {
	let attribute = loiAttributes.find(attr => attr.id === cellId.colId);
	// If attribute is null, then its not an attribute column so ignore.
	if (!attribute) {
		return null;
	}

	// loiData may or may not exist for this cell.
	let cellData = loiData.find(entry =>
		entry.classification === cellId.rowId
		&& entry.attributeId === attribute.id);

	let loiCellData = {
		id: cellData ? cellData.id : null,
		classification: cellId.rowId,
		attributeId: cellId.colId,
		group: attribute.structuralName,
		attribute: attribute.name,
		enabled: true,
		stage: cellData ? cellData.stage : attribute.default.stage,
		displayed: cellData ? cellData.displayed : attribute.default.displayed,
		required: cellData ? cellData.required : attribute.default.required,
	}
	return loiCellData;
}

function getCellDataFromCellIdList(cellIdList, loiAttributes, loiData) {
	let cellDataList = cellIdList.reduce((accumulator, cellId) => {

		let loiCellData = getCellDataFromCellId(cellId, loiAttributes, loiData);

		if (!loiCellData) {
			return accumulator;
		}

		accumulator.push(loiCellData);
		return accumulator;
	}, []);
	return cellDataList;
}

function countColsInCellData(cellDataList) {
	const uniqueColumns = new Set(cellDataList.map(item => item.attributeId));
	return uniqueColumns.size;
}

function countRowsInCellData(cellDataList) {
	const uniqueRows = new Set(cellDataList.map(item => item.classification));
	return uniqueRows.size;
}

// Gets all cell data for any selected cells
export function getSelectedLOICells(gridRef, loiAttributes, loiData) {
	let selectedCellIds = getSelectedGridCellIds(gridRef.current.api);
	console.log("SELECTED IDS:", selectedCellIds);

	return getCellDataFromCellIdList(selectedCellIds, loiAttributes, loiData);
}

//gridRef.current.api

// Gets all cell data for all selected rows
export function getEntireSelectedLOIRowCells(gridRef, loiAttributes, loiData) {
	let selectedCellIds = getEntireSelectedRowGridCellIds(gridRef.current.api);

	console.log("SELECTED IDS:", selectedCellIds);
	return getCellDataFromCellIdList(selectedCellIds, loiAttributes, loiData);
}

export function setSelectedLOICellsToDelete(selectedCells) {
	return selectedCells.reduce((acc, cell) => {
		// Only include items that have an ID
		if (cell.id !== null) {
			// Set 'enabled' to false and add to the accumulator
			acc.push({ ...cell, enabled: false });
		}
		return acc;
	}, []);
}

function areCellsEqual(cell1, cell2) {
	//console.log("Selected cell data:", cell1);
	//console.log("Pasted cell data:", cell2);
	// Both cells are empty
	if (cell1.id === null && cell2.id === null) {
		return true;
	} else if (cell1.id === null || cell2.id === null) {
		return false; // If only one id is null, they are not equal.
	}

	// Both cells have equal data
	return cell1.stage === cell2.stage &&
		cell1.displayed === cell2.displayed &&
		cell1.required === cell2.required;
}

function pasteCellDataIntoSelectedCells(selectedCells, cellData) {
	let totalRows = countRowsInCellData(cellData);
	let totalCols = countColsInCellData(cellData);
	let selTotalRows = countRowsInCellData(selectedCells);
	let selTotalCols = countColsInCellData(selectedCells);

	let pastedCells = [];

	// Iterate over the selected cells
	for (let rowIndex = 0; rowIndex < selTotalRows; rowIndex++) {
		for (let colIndex = 0; colIndex < selTotalCols; colIndex++) {
			// Calculate the corresponding index in cellData
			let cellDataIndex = (rowIndex % totalRows) * totalCols + (colIndex % totalCols);

			// Skip if cellDataIndex is out of bounds
			if (cellDataIndex >= cellData.length) continue;

			// Calculate the index in selectedCells
			let selectedCellsIndex = rowIndex * selTotalCols + colIndex;

			// Skip if selected cell doesn't exist
			if (!selectedCells[selectedCellsIndex]) continue;

			// Get the data from cellData
			let cellDataItem = cellData[cellDataIndex];

			// Skip if cells are equal
			if (areCellsEqual(selectedCells[selectedCellsIndex], cellDataItem)) continue;

			let pastedCell = selectedCells[selectedCellsIndex];

			pastedCell.stage = cellDataItem.stage;
			pastedCell.displayed = cellDataItem.displayed;
			pastedCell.required = cellDataItem.required;
			pastedCell.enabled = cellDataItem.id === null ? false : true;
			pastedCells.push(pastedCell);
		}
	}
	console.log("UPDATED CELLS:", pastedCells.length);
	return pastedCells;
}

export function pasteCellDataIntoSelectedRows(gridRef, cellData, loiAttributes, loiData) {
	let selectedCellIds = getEntireSelectedRowGridCellIds(gridRef.current.api);
	let selectedCells = getCellDataFromCellIdList(selectedCellIds, loiAttributes, loiData);
	return pasteCellDataIntoSelectedCells(selectedCells, cellData);
}

export function pasteCellDataIntoSelectedLOICells(gridRef, cellData, loiAttributes, loiData) {
	let selectedCellIds = getSelectedGridCellIds(gridRef.current.api);
	let selectedCells = getCellDataFromCellIdList(selectedCellIds, loiAttributes, loiData);
	return pasteCellDataIntoSelectedCells(selectedCells, cellData);
}

export function getChangedColumns(gridRef, attrs) {

	// Get current order of columns:
	const cols = gridRef.current.api.getAllGridColumns();
	const columnUpdates = cols.map((col, index) => {
		// Skips the first 3 columns as they are not attribute columns.
		const adjustedIndex = index - 3;
		return {
			colId: col.colId,
			order: adjustedIndex
		};
	}).filter(col => col.order > -1);

	let attrMap = new Map();
	attrs.forEach((item, index) => {
		// Store an object with index and additional attributes
		attrMap.set(item.id, {
			index: index,
			attr: item
		});
	});

	// Step 2 & 3: Compare with the second array and store changed items
	let changedCols = [];
	columnUpdates.forEach((item, index) => {
		if (attrMap.has(item.colId)) {

			let attrData = attrMap.get(item.colId);
			if (attrData.index !== index) {
				console.log("forceorder", attrData.attr.forceOrder, "new order:", index, "Old index:", attrData.index, "DATA: ", attrData.attr.id);
				changedCols.push({
					column: attrData.attr.structuralName + "#@#" + attrData.attr.name + "#@#" + attrData.attr.loiName,
					attributeId: item.colId,
					forceOrder: index,
					oldIndex: attrData.index,
				});
			}
		}
	});

	console.log("CHANGED COLS:", changedCols);
	return changedCols;
}

export function getSetNameOptionsFromClassifications(loiClassifications) {
	if (!loiClassifications) return [];

	// Extract unique setNames
	const uniqueSetNames = [...new Set(loiClassifications.map(item => item.setName))];

	// Sort the unique setNames alphabetically
	const sortedUniqueSetNames = uniqueSetNames.sort((a, b) => a.localeCompare(b));

	// Add the default top item "No filter"
	const setNameOptions = [LOIConstants.NO_SET_NAME_FILTER, ...sortedUniqueSetNames];

	return setNameOptions.map(name => ({
		id: name,
		name: name
	}));
}

function setStructuralName(name, structualNamesList) {
	if (typeof name === 'number') return structualNamesList[name].replace(/_/g, ' ');
	else return name;
}

export function getSetNameToStructuralNamesMap(loiClassifications, structualNamesList) {
	if (!loiClassifications) return [];
	const setNameStructuralNames = loiClassifications.map(item => ({ setName: item.setName, structuralNames: item.structuralNames }));
	let unique = [];
	setNameStructuralNames.forEach(obj => {
		if (!unique.some(item => item.setName === obj.setName && item.structuralNames === obj.structuralNames)) {
			unique.push(obj);
		}
	})
	const formatted = unique.map(item => ({
		setName: item.setName,
		structuralNames: item.structuralNames ? JSON.parse(item.structuralNames).map(name => setStructuralName(name, structualNamesList)).join(' ') : null
	}))
	return formatted;
}