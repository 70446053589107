import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';
import {
	SelectDropdown,
	SBDatePicker,
	LoadingSpinner
} from '../../ui/';
import CalculatedStartDatesTable from './CalculatedStartDatesTable';
import useFetchCalculatedStartDates from './useFetchCalculatedStartDates';
import { SERVER_DATE_FORMAT } from '../../../constants/Constants';


const ConfigureWorkflowDateModal = ({ open, onClose, onSave, steps, documentId }) => {
	const stepList = useMemo(() => {
		return steps.map(step => ({
			id: step.id,
			name: step.title
		}));
	}, [steps]);
	const [selectedStepId, setSelectedStepId] = useState('');
	const [dateDue, setDateDue] = useState('');
	const { loadingData, calculatedStartDates, error, fetchCalculatedStartDates } = useFetchCalculatedStartDates();

	const getCalculatedStartDate = async (stepId, date) => {
		if (stepId === '' || date === '')
			return;

		console.log("Fetch: ", fetchCalculatedStartDates);

		fetchCalculatedStartDates({ dateDue: date, stepId, documentId });
	}

	const handleUpdateStepId = (stepId) => {
		setSelectedStepId(stepId);
		getCalculatedStartDate(stepId, dateDue);
	}

	const handleUpdateDateDue = (date) => {
		setDateDue(date);
		getCalculatedStartDate(selectedStepId, date);
	}

	const handleConfirmDate = () => {
		if (!isValid())
			return;

		const startDate = calculatedStartDates[0]?.startDate;

		onClose();
		onSave(startDate);
	}

	const isValid = () => {
		return (selectedStepId !== '' && dateDue !== '' && !loadingData);
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h5">Configure Start Dates</DialogTitle>
			<DialogContent>
				<Stack sx={{ mt: 1, width: '300px' }} spacing={1}>
					<SelectDropdown
						id={"Workflow Step"}
						value={selectedStepId}
						options={stepList}
						onChange={handleUpdateStepId}
						fullWidth
					/>
					<SBDatePicker
						id="dateDue"
						label="Date Due"
						dateFormat={SERVER_DATE_FORMAT}
						onDateChange={handleUpdateDateDue}
						date={dateDue}
					/>
					<LoadingSpinner isLoading={loadingData} />
				</Stack>
				{!loadingData && <CalculatedStartDatesTable dates={calculatedStartDates} />}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button disabled={!isValid()} variant="contained" onClick={handleConfirmDate}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfigureWorkflowDateModal;
