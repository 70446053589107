import React, { useEffect, useContext, useRef, useState, useMemo, useCallback } from 'react';
import { Grid } from '@mui/material';
import AgGridBaseStyle from '../AgGridBaseStyle';
import { CRUD_ACTIONS } from '../../../constants/ApiConstants';
import {
	generateDynamicColDefsFromAttrs,
	generateDefaultRowDataFromAttrs,
	getSelectedRowIds
} from '../../../helpers/AgGrid/LocationsGridHelpers';
import { setValueAtPath, arraysAreEqual } from '../../../helpers/GeneralHelpers';
import { SiteSecurityContext } from '../../../contexts/SiteSecurityContext';
import ConfirmationDialog from '../../modals/ConfirmationDialog';

const AgGridBaseLocationView = ({
	locationData,
	loiAttributes,
	loiClassifications,
	loiData,
	performCRUDOperation,
	structuralName,
	generateRowDataFunc,
	baseColDefs,
	securityFeatureName
}) => {
	const { isFeatureAllowed } = useContext(SiteSecurityContext);

	// Filter loiAttributes based on structuralName
	const filteredLoiAttributes = useMemo(() => {
		return (loiAttributes?.filter(attribute => attribute.structuralName === structuralName)) || [];
	}, [loiAttributes, structuralName]);

	const [columnDefs, setColumnDefs] = useState([]);
	const [agGridData, setAgGridData] = useState([]);
	const [defaultNewRow, setDefaultNewRow] = useState({});
	const [openAddRowConfirm, setOpenAddRowConfirm] = useState(false);
	const gridRef = useRef();

	const featureAllowed = useMemo(() => {
		if (!securityFeatureName) return true;
		return isFeatureAllowed(securityFeatureName);
	}, [isFeatureAllowed, securityFeatureName])

	useEffect(() => {
		// Gets all relevant column definations for this agGrid instance
		// Check LocationsGridHelpers for reference.
		const dynamicColumnDefs = generateDynamicColDefsFromAttrs(filteredLoiAttributes, loiClassifications, loiData);
		const defaultRow = generateDefaultRowDataFromAttrs(filteredLoiAttributes);

		console.log("BASE COL:", baseColDefs);

		let columnDefs = [...baseColDefs, ...dynamicColumnDefs];
		columnDefs = columnDefs.sort(function (a, b) { return a.order - b.order });

		setDefaultNewRow(defaultRow);
		setColumnDefs(columnDefs);
	}, [baseColDefs, loiClassifications, filteredLoiAttributes, loiData]);

	useEffect(() => {
		const rowData = generateRowDataFunc(locationData);
		setAgGridData(rowData);
		if (!rowData.length && featureAllowed) setOpenAddRowConfirm(true);
	}, [locationData, generateRowDataFunc, featureAllowed]);

	const handleCloseAddRowDialog = () => {
		setOpenAddRowConfirm(false);
	};

	const handleSubmit = () => {
		performCRUDOperation(structuralName, CRUD_ACTIONS.CREATE, defaultNewRow).then(() => {
			setOpenAddRowConfirm(false);
		});
	}

	// Spaces Reqs:
	// Possible uniclass editor + DbNodeLookup editor?

	/*
	useEffect(() => {
		const processData = () => {
			console.log("Reprocessing Grid data!: ", locationData);
			// Gets all relevant column definations for this agGrid instance
			// Check LocationsGridHelpers for reference.
			const dynamicColumnDefs = generateDynamicColDefsFromAttrs(filteredLoiAttributes, loiClassifications);
			const defaultRow = generateDefaultRowDataFromAttrs(filteredLoiAttributes);

			console.log("BASE COL:", baseColDefs);

			let columnDefs = [...baseColDefs, ...dynamicColumnDefs];
			columnDefs = columnDefs.sort(function (a, b) { return a.order - b.order });

			const rowData = generateRowDataFunc(locationData);

			setDefaultNewRow(defaultRow);
			setColumnDefs(columnDefs);
			setAgGridData(rowData);
		};

		processData();
	}, [locationData, baseColDefs, generateRowDataFunc, loiClassifications, filteredLoiAttributes]);
	*/
	const getContextMenuItems = useCallback((params) => {
		if (!params.column) return [];
		const customItems = [
			'separator',
			{
				name: "Delete",
				action: () => {
					console.log("Delete Row clicked", params);
					const selectedRowIds = getSelectedRowIds(gridRef.current.api);
					console.log("ROWS to delete:", selectedRowIds);
					performCRUDOperation(structuralName, CRUD_ACTIONS.DELETE, selectedRowIds);
				}
			},
			{
				name: "Add Single",
				action: () => {
					console.log("Add Row clicked", params);
					performCRUDOperation(structuralName, CRUD_ACTIONS.CREATE, defaultNewRow);
				}
			},
		];
		return [...customItems];
	}, [defaultNewRow, performCRUDOperation, structuralName]);

	const gridOptions = useMemo(() => ({
		suppressScrollOnNewData: true,
		enableRangeSelection: true,
		onCellValueChanged: function (params) {
			console.log("CELL VALUE CHANGED!");
			console.log(params);
			if (Array.isArray(params.oldValue) && Array.isArray(params.newValue)) {
				if (arraysAreEqual(params.oldValue, params.newValue)) return;
			} else if (params.oldValue === params.newValue) {
				// If they are not arrays, or if one is an array and the other isn't,
				// fallback to simple comparison
				return;
			}

			setValueAtPath(params.data, params.column.colId, params.newValue);

			performCRUDOperation(structuralName, CRUD_ACTIONS.UPDATE, params.data);
		},
		suppressContextMenu: !featureAllowed
	}), [performCRUDOperation, structuralName, featureAllowed]);

	const defaultColDef = useMemo(() => ({
		editable: featureAllowed,
		sortable: true,
		filter: 'agSetColumnFilter',
	}), [featureAllowed]);
	return (
		<Grid item xs={12}>
			<AgGridBaseStyle
				nonAllowedHeight={"340px"}
				columnDefs={columnDefs}
				rowData={agGridData}
				gridOptions={gridOptions}
				defaultColDef={defaultColDef}
				ref={gridRef}
				getContextMenuItems={getContextMenuItems}
				reactiveCustomComponents={true}
				animateRows
			>
			</AgGridBaseStyle>
			<ConfirmationDialog
				open={openAddRowConfirm}
				onClose={handleCloseAddRowDialog}
				title={`Add new ${structuralName.toLowerCase()}`}
				message="There are currently no rows, would you like to add one?"
				onConfirm={handleSubmit}
			/>
		</Grid>
	);
};

export default AgGridBaseLocationView;