import React from 'react';
import { Card, CardHeader, CardContent, Stack, Typography } from '@mui/material';
import {
	BasicTxtField
} from '../../../ui';
import SiteBrandingForm from './SiteBrandingForm';

const LocationDetailsForm = ({ data, onChange }) => {
	return (
		<Card>
			<CardHeader
				title={<Typography variant="h6">General Information</Typography>}
			/>
			<CardContent>
				<Stack spacing={1} mt={1}>
					<SiteBrandingForm />
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Project Name"
						id="overrideProjectName"
						value={data.overrideProjectName}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Site Name"
						id="overrideSiteName"
						value={data.overrideSiteName}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Classification"
						id="classificationName"
						value={data.classificationName || ''}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Linear Units"
						id="linearUnits"
						value={data.linearUnits}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Area Units"
						id="areaUnits"
						value={data.areaUnits}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Volume Units"
						id="volumeUnits"
						value={data.volumeUnits}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Currency Units"
						id="currencyUnits"
						value={data.currencyUnits}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Area Measurement"
						id="areaMeasurement"
						value={data.areaMeasurement}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Description"
						id="description"
						value={data.description}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Project Description"
						id="projectDescription"
						value={data.projectDescription}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Site Description"
						id="siteDescription"
						value={data.siteDescription}
						onChange={onChange}
					/>
					<BasicTxtField
						fullWidth
						margin="normal"
						label="Map Marker"
						id="mapMarker"
						value={data.mapMarker}
						onChange={onChange}
					/>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default LocationDetailsForm;