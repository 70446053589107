import React from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel
} from '@mui/material';

const SiteLocationsComponent = ({ locations, document, handleDataChange }) => {

	const mapSiteLocations = (locations) => {
		return locations.map((location, index) => (
			<Box sx={{display: 'block', marginLeft: '30px' }} key={index}>
				<FormControlLabel
					control={
						<Checkbox
							size='small'
							checked={document.locationIds.includes(location.id)}
							onChange={(value) => {
								if (value.target.checked) {
									handleDataChange("locationIds", [...document.locationIds, location.id]);
								}
								else {
									const updatedLocationIds = document.locationIds.filter(id => id !== location.id);
									handleDataChange("locationIds", [...updatedLocationIds])
								}
							}}
						/> }
					label={location.name}
				/>
				{location.locations && <>{mapSiteLocations(location.locations)}</>}
			</Box>
		));
	}

	return (
		<>{mapSiteLocations(locations)}</>
	)
};

export default SiteLocationsComponent;