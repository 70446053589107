import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const ImageList = ({ images }) => (
	<Card>
		<CardContent>
			<Typography variant="h6">Images</Typography>
			<Box display="flex" flexWrap="wrap">
				{images.map((image) => (
					<img key={image.id} src={image.url} alt={image.title} style={{ width: 100, height: 100, margin: 5 }} />
				))}
			</Box>
		</CardContent>
	</Card>
);

export default ImageList;