import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../DataGridContainers/customAgGrid.css';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { UserContext, SiteSecurityContext } from '../../../contexts';
import {
	ReviewUserAvatar,
	ResponsibleUserAvatar
} from '../../DataGridComponents';
import { WorkflowStepEditModal } from '../../modals';
import { ApiStatusDisplay, BasicTxtField, SpacedGrid, ColoredSquare, SecurityButton } from '../../ui';
import { WorkflowGridColors } from '../../../constants/Constants';
import { NewUserEmailModal } from '../../modals/NewUserEmailModal';
import { CompanySearchModal } from '../../modals/CompanySearchModal';
import { ConfirmUserSelectModal } from '../../modals/';
import SectionTreeEditModal from './SectionTreeEditModal';
import ConfigureWorkflowDateModal from './ConfigureWorkflowDateModal';
import { WORKFLOW_ACTIONS } from '../../../constants/WorkflowConstants';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

import * as AgGridWorkflowHelpers from '../../../helpers/AgGrid/WorkflowGridHelpers';


// isCommenting = Enable reviews to be set...
// TODO:
// Also need a 'manage' page to add sections?

const WorkflowResponsibilityDetailPage = ({
	documentId,
	defaultResponsibleUserId,
	documentName,
	projectName,
	workflowData,
	sectionData,
	responsibilityData,
	reviewsData,
	sectionTreeData,
	onBackClick,
	onUpdateWorkflowStep,
	onUpdateReviewers,
	onUpdateResponsibleUsers,
	onClearResponsibleUsers,
	onRemoveReviewers,
	onUpdateReviewSections,
	onUpdateSectionsStartDate,
	onUpdateSectionContractor
}) => {
	const { users } = useContext(UserContext);
	const { isFeatureAllowed } = useContext(SiteSecurityContext);

	const [columnDefs, setColumnDefs] = useState([]);
	const [agGridData, setAgGridData] = useState([]);
	const [editStepData, setEditStepData] = useState({});
	const [selectedCells, setSelectedCells] = useState([]);
	const [selectedUserIds, setSelectedUserIds] = useState([]);

	// MODALS:
	const [openStepEdit, setOpenStepEdit] = useState(false);
	const [openNewReviewUsers, setOpenNewReviewUsers] = useState(false);
	const [openNewResponsibleUser, setOpenNewResponsibleUser] = useState(false);
	const [openConfirmRemoveReviewers, setOpenConfirmRemoveReviewers] = useState(false);
	const [openSectionTreeEdit, setOpenSectionTreeEdit] = useState(false);
	const [openConfigureDate, setOpenConfigureDate] = useState(false);
	const [openConfigureContractor, setOpenConfigureContractor] = useState(false);

	// ROW Filtering:
	const [rowFilterText, setRowFilterText] = useState("");
	const [debouncedRowFilterText, setDebouncedRowFilterText] = useState("");

	const gridRef = useRef();

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedRowFilterText(rowFilterText.toLowerCase());
		}, 1000);

		return () => {
			clearTimeout(handler);
		};
	}, [rowFilterText]);

	const featureAllowed = useMemo(() => {
		const featureName = 'workflowManagement_create_update_delete';
		return isFeatureAllowed(featureName);
	}, [isFeatureAllowed])

	const handleBackToWorkflow = () => {
		onBackClick();
	}

	const handleConfirmNewReviewers = (users) => {
		let allowedCells = AgGridWorkflowHelpers.removeNonReviewableCells(selectedCells, workflowData);
		let newReviewers = AgGridWorkflowHelpers.calcNewReviewers(allowedCells, reviewsData, users);
		onUpdateReviewers(newReviewers);
	}

	const handleConfirmNewResponsibleUser = (users) => {
		let user = users[0];
		let newResponsibleUsers = AgGridWorkflowHelpers.calcNewResponsibleUsers(selectedCells, responsibilityData, user);
		onUpdateResponsibleUsers(newResponsibleUsers);
	}

	const handleClearResponsibleUsers = (selCells) => {
		let responsibleUsersToClear = AgGridWorkflowHelpers.getSelectedResponsibleUsers(selCells, responsibilityData);
		onClearResponsibleUsers(responsibleUsersToClear);
	}

	const handleRemoveReviewers = (reviewUsers) => {
		console.log("Handle remove reviewers:", reviewUsers);
		let reviewersToRemove = AgGridWorkflowHelpers.calcReviewersToRemove(selectedCells, reviewsData, reviewUsers);
		onRemoveReviewers(reviewersToRemove);
	}

	const handleSetSectionsStartDate = (startDate) => {
		console.log("SELECTED CELLS:", selectedCells);
		const selectedSections = AgGridWorkflowHelpers.getSelectedSections(selectedCells);
		onUpdateSectionsStartDate(selectedSections, startDate);
	}

	const handleSetContractor = (companyId) => {
		const selectedSections = AgGridWorkflowHelpers.getSelectedSections(selectedCells);
		onUpdateSectionContractor(selectedSections, companyId);
	}

	const onMenuAction = (action, payload) => {
		switch (action) {
			case WORKFLOW_ACTIONS.SET_RESPONSIBILITY:
				setSelectedCells(payload);
				setOpenNewResponsibleUser(true);
				console.log("Set responsibility clicked: ", payload);
				break;
			case WORKFLOW_ACTIONS.CLEAR_RESPONSIBILITY:
				console.log("Clear responsibility clicked");
				handleClearResponsibleUsers(payload);
				break;
			case WORKFLOW_ACTIONS.SET_REVIEWER:
				setSelectedCells(payload);
				setOpenNewReviewUsers(true);
				console.log("Add Reviewers clicked");
				break;
			case WORKFLOW_ACTIONS.REMOVE_REVIEWERS:
				let reviewUserIds = AgGridWorkflowHelpers.getUniqueReviewerUserIdsForSelectedCells(payload, reviewsData);
				setSelectedUserIds(reviewUserIds);
				setSelectedCells(payload);
				setOpenConfirmRemoveReviewers(true);
				console.log("Remove Reviewers clicked");
				break;
			case WORKFLOW_ACTIONS.CONFIGURE_START_DATE:
				setSelectedCells(payload);
				setOpenConfigureDate(true);
				break;
			case WORKFLOW_ACTIONS.CONFIGURE_CONTRACTOR:
				setSelectedCells(payload);
				setOpenConfigureContractor(true);
				break;
			default:
				break;
		}
	};

	const getContextMenuItemsWrapper = (params) => {
		return AgGridWorkflowHelpers.getContextMenuItems(params, onMenuAction, workflowData);
	};

	const onGridReady = (params) => {
		// Auto-size all columns
		params.columnApi.autoSizeColumns(columnDefs.map(colDef => colDef.field));
	};

	useEffect(() => {
		const processColData = () => {
			let cols = AgGridWorkflowHelpers.getColumnDefsForWorkflowGrid(workflowData);
			setColumnDefs(cols);
		};

		processColData();
	}, [workflowData]);


	useEffect(() => {
		const processData = () => {
			console.log("NOW loading row data");
			const rowData = AgGridWorkflowHelpers.generateRowDataForWorkflowGrid(workflowData, sectionData, responsibilityData, reviewsData, users, defaultResponsibleUserId);
			setAgGridData(rowData);
		};

		processData();
	}, [workflowData, sectionData, responsibilityData, reviewsData, users, defaultResponsibleUserId]);

	const editWorkflowStepFunc = useCallback((stepId) => {
		const stepData = workflowData.steps.find(step => step.id === stepId);
		console.log("EDIT STEP FOUND", stepData);
		if (stepData) {
			setEditStepData(stepData);
			setOpenStepEdit(true);
		}
	}, [workflowData]);

	const isExternalFilterPresent = () => {
		console.log("TEST");
		return true;
	};

	const doesExternalFilterPass = useMemo(() => {
		return (node) => AgGridWorkflowHelpers.checkRowFilter(node.data, debouncedRowFilterText);
	}, [debouncedRowFilterText]);


	const gridOptions = React.useMemo(() => ({
		suppressScrollOnNewData: true,
		context: {
			editWorkflowStepFunc: editWorkflowStepFunc
		},
		enableRangeSelection: true,
		headerHeight: 40,
		suppressContextMenu: !featureAllowed,
	}), [editWorkflowStepFunc, featureAllowed]);

	return (
		<Grid container spacing={3}>

			{/* First Row */}
			<Grid item container spacing={2}>
				{/* Spacing of 2 for padding between columns in this row */}
				<Grid item xs={6}>
					{/* Content for the first column of the first row */}
					<Grid item>
						<Button variant="text" onClick={handleBackToWorkflow}>
							<strong>&lt; Back</strong>
						</Button>
					</Grid>
					<Grid item>
						<Typography variant="h4">
							Workflow Responsibility & Reviewers
						</Typography>
						<Typography variant="body1" color="textSecondary">
							Site name / {projectName} / {documentName}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					{/* Content for the second column of the first row */}
					<Typography variant="h6">
						Key
					</Typography>
					<Divider />
					<Box mt={2} />
					<Grid container>
						<Grid item container xs={6} spacing={1} direction="column" alignItems="flex-start">
							{/* Content for the first column inside the second column of the first row */}
							<SpacedGrid>
								<ResponsibleUserAvatar>&nbsp;</ResponsibleUserAvatar>
								<Typography variant="body2" color="textSecondary">Responsible User</Typography>
							</SpacedGrid>
							<SpacedGrid>
								<ReviewUserAvatar>&nbsp;</ReviewUserAvatar>
								<Typography variant="body2" color="textSecondary">Review User</Typography>
							</SpacedGrid>
						</Grid>
						<Grid item container xs={6} spacing={1} direction="column" alignItems="flex-start">
							{/* Content for the second column inside the second column of the first row */}
							<SpacedGrid>
								<ColoredSquare color={WorkflowGridColors.CURRENT_STEP} />
								<Typography variant="body2" color="textSecondary">
									Section is in this step
								</Typography>
							</SpacedGrid>
							<SpacedGrid>
								<ColoredSquare color={WorkflowGridColors.COMPLETE_STEP} />
								<Typography variant="body2" color="textSecondary">
									Step is in the past / completed
								</Typography>
							</SpacedGrid>
							<SpacedGrid>
								<ColoredSquare color={WorkflowGridColors.FUTURE_STEP} />
								<Typography variant="body2" color="textSecondary">
									Step is in the future
								</Typography>
							</SpacedGrid>
							<SpacedGrid>
								<WarningAmberOutlinedIcon color="warning" />
								<Typography variant="body2" color="textSecondary">
									Review Disabled
								</Typography>
							</SpacedGrid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* Second Row */}
			<Grid item xs={12} style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
				<BasicTxtField
					label="Filter users / companies"
					value={rowFilterText}
					onChange={setRowFilterText}
					placeholder="Enter filter text"
					width={300}
					style={{ marginRight: '16px' }} // Adding some space between the text field and the buttons
				/>
				<Box display="flex" justifyContent="flex-end" flexGrow={1}>
					<SecurityButton
						featureName={SECURITY_FEATURES.WORKFLOW_RESPONSIBILITY_PAGE}
						featureSecurityId={documentId}
						size="large"
						variant="contained"
						onClick={() => { setOpenSectionTreeEdit(true) }}
					>
						Manage
					</SecurityButton>
					<IconButton color="default" size="large">
						<DownloadIcon />
					</IconButton>
					<IconButton color="default" size="large">
						<FilterAltIcon />
					</IconButton>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<ApiStatusDisplay />
			</Grid>

			<Grid item xs={12}>
				{/* Content for the third row */}
				<div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
					<AgGridReact
						rowData={agGridData}
						columnDefs={columnDefs}
						gridOptions={gridOptions}
						getContextMenuItems={getContextMenuItemsWrapper}
						isExternalFilterPresent={isExternalFilterPresent}
						doesExternalFilterPass={doesExternalFilterPass}
						ref={gridRef}
					/>
				</div>
			</Grid>
			<WorkflowStepEditModal
				open={openStepEdit}
				onClose={() => setOpenStepEdit(false)}
				onSave={onUpdateWorkflowStep}
				stepData={editStepData}
				workflowId={workflowData.id}
			/>
			<NewUserEmailModal
				title={"Set Reviewers"}
				confirmMsg={"The following users will be added as reviewers"}
				open={openNewReviewUsers}
				onClose={() => setOpenNewReviewUsers(false)}
				onConfirmAddUsers={handleConfirmNewReviewers}
				allowUserSelection
			/>
			<NewUserEmailModal
				title={"Set Responsible User"}
				confirmMsg={"The following user will be added as the responsible user"}
				open={openNewResponsibleUser}
				onClose={() => setOpenNewResponsibleUser(false)}
				onConfirmAddUsers={handleConfirmNewResponsibleUser}
				allowUserSelection
				allowMultipleUsers={false}
			/>
			<ConfirmUserSelectModal
				title="Remove Reviewers"
				open={openConfirmRemoveReviewers}
				onClose={() => setOpenConfirmRemoveReviewers(false)}
				onSave={handleRemoveReviewers}
				initialIds={selectedUserIds}
			/>
			<SectionTreeEditModal
				sectionTree={sectionTreeData}
				open={openSectionTreeEdit}
				onClose={() => setOpenSectionTreeEdit(false)}
				onSave={onUpdateReviewSections}
			/>
			<ConfigureWorkflowDateModal
				documentId={documentId}
				open={openConfigureDate}
				onClose={() => setOpenConfigureDate(false)}
				onSave={handleSetSectionsStartDate}
				steps={workflowData.steps}
			/>
			<CompanySearchModal
				open={openConfigureContractor}
				onClose={() => setOpenConfigureContractor(false)}
				onConfirmCompany={handleSetContractor}
			/>
		</Grid>
	);
}

export default WorkflowResponsibilityDetailPage;