import React, { useMemo, useState } from 'react';
import { AutoTxtField } from '../../ui';

const UniclassCellEditor = ({ initialValue, onValueChange, setName, loiClassifications, ...props }) => {
	const [classificationValue, setClassificationValue] = useState(initialValue);
	const filteredClassifications = useMemo(() => {
		return loiClassifications.filter(item => item.setName === setName);
	}, [loiClassifications, setName]);
	const classificationOptions = useMemo(() => {
		return filteredClassifications.map(item => `${item.code} : ${item.description}`);
	}, [filteredClassifications]);



	const handleChange = (newClassification) => {
		// Update the checked state based on changes
		setClassificationValue(newClassification);

		onValueChange(newClassification);
	};

	return (
		<div style={{ backgroundColor: 'white', width: '210px' }}>
			<AutoTxtField
				id="autotxt"
				label="Choose Classification"
				options={classificationOptions}
				value={classificationValue}
				onChange={handleChange}
			/>
		</div>
	);
};

export default UniclassCellEditor;