import React, { useMemo } from 'react';
import {
	Grid,
	Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import {
	GridDivider
} from '../../../ui';
import DataConcatenatePartAdder from './DataConcatenatePartAdder';

function DataConcatenateConfig({ dataConfigArr, onDataChange }) {
	const currentConfig = useMemo(() => dataConfigArr.join('\n'), [dataConfigArr]);
	return (
		<>
			<Grid item xs={6}>
				<TextareaAutosize
					label="Current Config"
					variant="outlined"
					value={currentConfig}
					disabled
					minRows={4}
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Your Current Config
				</Typography>
			</Grid>

			<GridDivider />

			<Grid item xs={6}>
				<DataConcatenatePartAdder onAddPart={onDataChange} />
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Optional - Leave unset if you dont not want this feature,
					when the option is set the selected attribute is used
					to update this attribute (like an excel vlookup)
				</Typography>
			</Grid>
			<GridDivider />
		</>
	);
}

export default DataConcatenateConfig;