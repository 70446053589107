import React, { useContext } from 'react';
import {
	TableCell,
	TableRow,
} from '@mui/material';
import { UserContext } from '../../../contexts';
import { SortableTable } from '..';

const columns = [
	{
		field: 'name',
		headerName: 'Name'
	},
	{
		field: 'email',
		headerName: 'Email'
	}
]


const SingleSelectUserTable = ({ onUserClick }) => {
	const { users } = useContext(UserContext);
	const handleUserClick = (user) => {
		if (onUserClick) {
			onUserClick(user);
		}
	};

	return (
		<SortableTable data={users} columns={columns}>
			{(displayedUsers) => (
				<>
					{displayedUsers.map((user) => (
						<TableRow
							key={user.id}
							onClick={() => handleUserClick(user)}
							style={{ cursor: 'pointer' }}
						>
							<TableCell>{user.name}</TableCell>
							<TableCell>{user.email}</TableCell>
						</TableRow>
					))}
				</>
			)}
		</SortableTable>
	);
};

export default SingleSelectUserTable;