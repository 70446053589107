import React from 'react';
import * as Api from '../api/AdminApi';
import apiRequestManager from '../api/Services/ApiRequestManager';
import { UserContext } from './UserContext';
export const WorkflowResponsibilityContext = React.createContext(null);

export class WorkflowResponsibilityProvider extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		this.state = {
			workflowsByDoc: {},
			sectionsByDoc: {},
			responsibilityByDoc: {},
			reviewsByDoc: {},
		};
	}

	// #####################
	// WORKFLOW API
	// #####################

	loadWorkflowForDoc = async (docId) => {
		try {
			console.log("workflow load");
			const workflow = await Api.loadWorkflowForDoc(docId);
			this.setState(prevState => ({
				workflowsByDoc: {
					...prevState.workflowsByDoc,
					[docId]: workflow
				}
			}));
			return workflow;
		} catch (error) {
			console.error("Error loading workflow:", error);
		}
	}

	loadSectionsForDoc = async (docId) => {
		try {
			console.log("Document section load");
			const docSection = await Api.loadSectionsForDoc(docId);
			const companyIds = docSection.reduce((acc, item) => {
				if (item.contractor)
					acc.push(item.contractor);
				return acc;
			}, []);
			await this.context.loadCompaniesByIds(companyIds);
			//console.log(docSection);
			this.setState(prevState => ({
				sectionsByDoc: {
					...prevState.sectionsByDoc,
					[docId]: docSection
				}
			}));
			return docSection;
		} catch (error) {
			console.error("Error loading document section:", error);
		}
	}

	loadResponsibilityForDoc = async (docId) => {
		try {
			console.log("Document responsibility load");
			const docResponsibility = await Api.loadResponsibilityForDoc(docId);
			const userIds = docResponsibility.reduce((acc, item) => {
				acc.push(item.userId);
				return acc;
			}, []);
			//console.log(docResponsibility);
			await this.context.loadUsersById(userIds);
			this.setState(prevState => ({
				responsibilityByDoc: {
					...prevState.responsibilityByDoc,
					[docId]: docResponsibility
				}
			}));
			return docResponsibility;
		} catch (error) {
			console.error("Error loading document responsibility:", error);
		}
	}

	loadReviewersForDoc = async (docId) => {
		try {
			console.log("Document reviews load");
			const docReviews = await Api.loadReviewersForDoc(docId);
			// Extract all users into a single list
			const userIds = docReviews.reduce((acc, item) => {
				return acc.concat(item.users);
			}, []);

			//console.log(docReviews);
			console.log("BEFORE LOAD USERS");
			await this.context.loadUsersById(userIds);
			console.log("After LOAD USERS");
			this.setState(prevState => ({
				reviewsByDoc: {
					...prevState.reviewsByDoc,
					[docId]: docReviews
				}
			}));
			return docReviews;
		} catch (error) {
			console.error("Error loading document reviews:", error);
		}
	}

	loadWorkflowDataForDoc = async (docId) => {
		const [workflow, sections, responsibility, reviewers, sectionTree] = await Promise.all([
			this.loadWorkflowForDoc(docId),
			this.loadSectionsForDoc(docId),
			this.loadResponsibilityForDoc(docId),
			this.loadReviewersForDoc(docId),
			this.getSectionTreeByDocId(docId)
		]);

		return {
			workflow,
			sections,
			responsibility,
			reviewers,
			sectionTree
		};
	}

	createWorkflowReviewers = async (newReviewers) => {
		let requestId = apiRequestManager.addRequestToQueue("createWorkflowReviewers");
		try {
			const workflowReviewers = await Api.createWorkflowReviewers(newReviewers);
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated workflow reviewers");
			return workflowReviewers;
		} catch (error) {
			let errMsg = "Error updating workflow reviewers:" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	createWorkflowResponsibleUsers = async (newResponsibleUsers) => {
		let requestId = apiRequestManager.addRequestToQueue("createWorkflowResponsibleUsers");
		try {
			const workflowResponsibleUsers = await Api.createWorkflowResponsibleUsers(newResponsibleUsers);
			//Successfully updated workflow responsible users
			apiRequestManager.setRequestSuccess(requestId, "Successfully updated workflow responsible users");
			return workflowResponsibleUsers;
		} catch (error) {
			let errMsg = "Error updating workflow responsible users:" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	clearWorkflowResponsibleUsers = async (oldResponsibleUsers) => {
		let requestId = apiRequestManager.addRequestToQueue("clearWorkflowResponsibleUsers");
		try {
			const workflowResponsibleUsers = await Api.clearWorkflowResponsibleUsers(oldResponsibleUsers);
			apiRequestManager.setRequestSuccess(requestId, "Successfully cleared workflow responsible users");
			return workflowResponsibleUsers;
		} catch (error) {
			let errMsg = "Error clearing workflow responsible users:" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	removeWorkflowReviewers = async (oldReviewers) => {
		let requestId = apiRequestManager.addRequestToQueue("createWorkflowReviewers");
		try {
			const workflowReviewers = await Api.removeWorkflowReviewers(oldReviewers);
			apiRequestManager.setRequestSuccess(requestId, "Successfully removed workflow reviewers");
			return workflowReviewers;
		} catch (error) {
			let errMsg = "Error removing workflow reviewers:" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	getSectionTreeByDocId = async (docId) => {
		try {
			console.log("Document section tree load");
			const sectionTree = await Api.getSectionTreeByDocId(docId);
			return sectionTree;
		} catch (error) {
			console.error("Error loading document section tree:", error);
		}
	}

	setReviewSections = async (docId, sectionIds) => {
		let reqSectionTree = {
			documentId: docId,
			Ids: sectionIds
		};
		let requestId = apiRequestManager.addRequestToQueue("setReviewSections");
		try {
			const reviewSections = await Api.setReviewSections(reqSectionTree);
			apiRequestManager.setRequestSuccess(requestId, "Successfully set review sections");
			return reviewSections;
		} catch (error) {
			let errMsg = "Error setting review sections" + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	setSectionsStartDate = async (docId, sectionIds, startDate) => {
		let reqSetSectionDate = {
			documentId: docId,
			sectionsIds: sectionIds,
			startDate: startDate
		};
		let requestId = apiRequestManager.addRequestToQueue("setSectionsStartDate");
		try {
			const sections = await Api.setSectionsStartDate(reqSetSectionDate);
			if (sections.data.length < 1 && sections.errors.length > 0) {
				throw new Error(sections.errors[0].message);
			}
			apiRequestManager.setRequestSuccess(requestId, "Successfully set sections start date");
			return sections;
		} catch (error) {
			let errMsg = "Error setting sections start date " + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	setSectionsContractor = async (docId, sectionIds, companyId) => {
		let reqSetContractor = {
			documentId: docId,
			SectionContractors: sectionIds.map((id) => ({
				SectionId: id,
				CompanyId: companyId
			}))
		};
		let requestId = apiRequestManager.addRequestToQueue("setSectionsContractor");
		try {
			const sections = await Api.setSectionsContractor(reqSetContractor);
			if (sections.data.length < 1 && sections.errors.length > 0) {
				throw new Error(sections.errors[0].message);
			}
			apiRequestManager.setRequestSuccess(requestId, "Successfully set sections contractors");
			return sections;
		} catch (error) {
			let errMsg = "Error setting sections contractors " + error.message;
			console.error(errMsg);
			apiRequestManager.setRequestFailure(requestId, errMsg);
		}
	}

	render() {
		const workflowContextValue = {
			workflowsByDoc: this.state.workflowsByDoc,
			sectionsByDoc: this.state.sectionsByDoc,
			responsibilityByDoc: this.state.responsibilityByDoc,
			reviewsByDoc: this.state.reviewsByDoc,
			loadWorkflowForDoc: this.loadWorkflowDataForDoc,
			createWorkflowReviewers: this.createWorkflowReviewers,
			createWorkflowResponsibleUsers: this.createWorkflowResponsibleUsers,
			clearWorkflowResponsibleUsers: this.clearWorkflowResponsibleUsers,
			removeWorkflowReviewers: this.removeWorkflowReviewers,
			setReviewSections: this.setReviewSections,
			setSectionsStartDate: this.setSectionsStartDate,
			setSectionsContractor: this.setSectionsContractor
		};

		return (
			<WorkflowResponsibilityContext.Provider value={workflowContextValue}>
				{this.props.children}
			</WorkflowResponsibilityContext.Provider>
		);
	}
}

export default WorkflowResponsibilityProvider;