import { Typography } from '@mui/material';

const CheckBoxLabel = ({item}) => {
	return (
		<div>
			<Typography variant="body1">{item.name}</Typography>
			{item.info && <Typography variant="body2" color="secondary">{item.info}</Typography>}
		</div>
	);
}

export default CheckBoxLabel;