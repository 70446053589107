import React, { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Stack,
	Typography
} from '@mui/material';
import { SiteDataContext } from '../../../contexts';
import {
	ArrayTextArea,
	SBCheckBoxField,
	SelectDropdown,
	BasicTxtField,
} from '../../../components/ui/';

const navigationList = [
	{ id: 0, name: "Default" },
	{ id: 1, name: "Widget" }
];

const reportVersionList = [
	{ id: 0, name: "Default" },
	{ id: 1, name: "By Step Duration" },
	{ id: 1, name: "By Step Weighting" }
];

const EditSiteModal = ({ open, onClose, initialData }) => {
	const [formData, setFormData] = useState(initialData);
	const {
		uniclassVersions,
		updateSite
	} = useContext(SiteDataContext);

	const handleDataChange = (newValue, propertyName) => {
		setFormData(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSave = async () => {
		onClose();
		await updateSite(formData);
	};

	if (!open)
		return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h4">
				Edit Site
			</DialogTitle>
			<DialogContent>
				<Stack sx={{ mb: 3 }} direction='column' spacing={1}>
					<Divider />
					<Typography variant="h5">Site Details</Typography>
					<BasicTxtField
						id="billingAddress"
						label="Billing Address"
						value={formData.billingAddress}
						onChange={handleDataChange}
						fullWidth
					/>
					<SBCheckBoxField
						id="isLocked"
						label="Is Locked"
						checked={formData.isLocked}
						onChange={handleDataChange}
					/>
					<SelectDropdown
						id="uniclass2DefaultVersion"
						label="Uniclass2 Default Version"
						value={formData.uniclass2DefaultVersion}
						onChange={handleDataChange}
						options={uniclassVersions}
						fullWidth
					/>
					<Divider />
					<Typography variant="h5">Tags (One Per Line)</Typography>
					<ArrayTextArea
						label="Tags"
						variant="outlined"
						value={formData.tags}
						onChange={(value) => handleDataChange(value, "tags")}
						placeholder="Enter tags"
						minRows={4}
					/>
					<Divider />
					<Typography variant="h5">Branding</Typography>
					<Typography variant="body1">Logo:</Typography>
					<img src={formData.siteBranding} alt={"Site Logo"} style={{ width: 100, height: 100, margin: 5 }} />
					<Divider />
					<Typography variant="h5">Navigation</Typography>
					<SelectDropdown
						id="defaultNavigationProcess"
						label="Default Navigation Process"
						value={formData.defaultNavigationProcess}
						onChange={handleDataChange}
						options={navigationList}
						usePlaceholder={true}
						fullWidth
					/>
					<Divider />
					<Typography variant="h5">Reporting Version</Typography>
					<SelectDropdown
						id="reportingVersion"
						label="Reporting Version"
						value={formData.reportingVersion}
						onChange={handleDataChange}
						options={reportVersionList}
						usePlaceholder={true}
						fullWidth
					/>
					<Divider />
					<Typography variant="h5">Site Master</Typography>
					<Typography>
						Master Site ID:
					</Typography>
					<Typography>
						{formData.masterSiteId}
					</Typography>
					<Typography>
						Master Site Name:
					</Typography>
					<Typography>
						{formData.masterSiteName}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined" color="primary" size="small">
					Cancel
				</Button>
				<Button onClick={handleSave} variant="contained" color="primary" size="small">
					Update
				</Button>
			</DialogActions>
		</Dialog>

	);
};

export default EditSiteModal;
