
export const NO_SET_NAME_FILTER = "No Filter";

export const LOIStageList = [
	{
		id: 0,
		name: "0 - Strategic Definition"
	},
	{
		id: 1,
		name: "1 - Preparation and Brief"
	},
	{
		id: 2,
		name: "2 - Concept Design"
	},
	{
		id: 3,
		name: "3 - Spatial Coordination"
	},
	{
		id: 4,
		name: "4 - Technical Design"
	},
	{
		id: 5,
		name: "5 - Construction"
	},
	{
		id: 6,
		name: "6 - Handover and Close Out"
	},
	{
		id: 7,
		name: "7 - In Use"
	}
];



export const AttrConfigInputTypes = {
	PLAIN_TEXT: 1,
	FORMATTED_TEXT_HTML: 12,
	SPACE_LOOKUP: 2,
	CLASSIFICATION_LOOKUP: 8,
	COMPANY_LOOKUP: 11,
	AUTOCOMPLETE_SEARCH: 19,
	PRESET_OPTIONS_DROPDOWN_LIST: 20,
	DATE: 21,
	EMAIL: 22,
	DATA_LOOKUP_NODE: 25,
	DATA_MASKED_INPUT: 28,
	DATA_CONCATENATE: 29,
	TAGS: 30,
	FILE_TYPE_GROUP: 31,
	FILE_STATUS: 32,
	FILE_SUITABILITY: 33,
	EVIDENCE_REF: 34
};

export const AttrConfigInputTypeList = [
	{
		id: AttrConfigInputTypes.PLAIN_TEXT,
		name: "Plain Text"
	},
	{
		id: AttrConfigInputTypes.FORMATTED_TEXT_HTML,
		name: "Formatted Text (HTML)"
	},
	{
		id: AttrConfigInputTypes.SPACE_LOOKUP,
		name: "Space Lookup"
	},
	{
		id: AttrConfigInputTypes.CLASSIFICATION_LOOKUP,
		name: "Classification Lookup"
	},
	{
		id: AttrConfigInputTypes.COMPANY_LOOKUP,
		name: "Company Lookup"
	},
	{
		id: AttrConfigInputTypes.AUTOCOMPLETE_SEARCH,
		name: "Autocomplete (Search)"
	},
	{
		id: AttrConfigInputTypes.PRESET_OPTIONS_DROPDOWN_LIST,
		name: "Preset Options (Dropdown List)"
	},
	{
		id: AttrConfigInputTypes.DATE,
		name: "Date"
	},
	{
		id: AttrConfigInputTypes.EMAIL,
		name: "Email"
	},
	{
		id: AttrConfigInputTypes.DATA_LOOKUP_NODE,
		name: "Data Lookup Node"
	},
	{
		id: AttrConfigInputTypes.DATA_MASKED_INPUT,
		name: "Data Masked Input"
	},
	{
		id: AttrConfigInputTypes.DATA_CONCATENATE,
		name: "Data Concatenate"
	},
	{
		id: AttrConfigInputTypes.TAGS,
		name: "Tags"
	},
	{
		id: AttrConfigInputTypes.FILE_TYPE_GROUP,
		name: "File Type Group"
	},
	{
		id: AttrConfigInputTypes.FILE_STATUS,
		name: "File Status"
	},
	{
		id: AttrConfigInputTypes.FILE_SUITABILITY,
		name: "File Suitability"
	},
	{
		id: AttrConfigInputTypes.EVIDENCE_REF,
		name: "Evidence Ref"
	}
];

export const AttrConfigFormFilterDict = {
	"maskedDataConfig" : [
		AttrConfigInputTypes.DATA_LOOKUP_NODE,
		AttrConfigInputTypes.DATA_MASKED_INPUT,
		AttrConfigInputTypes.DATA_CONCATENATE
	],
	"inputFormat" : [AttrConfigInputTypes.PLAIN_TEXT, AttrConfigInputTypes.DATE],
	"maxLength" : [AttrConfigInputTypes.PLAIN_TEXT],
	"allowedUnits" : [AttrConfigInputTypes.PLAIN_TEXT],
	"selectedClassificationSetName" : [AttrConfigInputTypes.CLASSIFICATION_LOOKUP],
	"requiredinclude" : [
		AttrConfigInputTypes.PLAIN_TEXT,
		AttrConfigInputTypes.AUTOCOMPLETE_SEARCH,
		AttrConfigInputTypes.PRESET_OPTIONS_DROPDOWN_LIST,
		AttrConfigInputTypes.DATE,
		AttrConfigInputTypes.EMAIL,
		AttrConfigInputTypes.DATA_MASKED_INPUT,
	],
	"requiredexclude" : [AttrConfigInputTypes.PLAIN_TEXT, AttrConfigInputTypes.DATA_MASKED_INPUT,],
	"optionalinclude" : [
		AttrConfigInputTypes.PLAIN_TEXT,
		AttrConfigInputTypes.AUTOCOMPLETE_SEARCH,
		AttrConfigInputTypes.PRESET_OPTIONS_DROPDOWN_LIST,
		AttrConfigInputTypes.DATE,
		AttrConfigInputTypes.EMAIL,
		AttrConfigInputTypes.DATA_MASKED_INPUT,
	],
	"optionalexclude" : [AttrConfigInputTypes.PLAIN_TEXT, AttrConfigInputTypes.DATA_MASKED_INPUT,],
};