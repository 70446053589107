import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import moment from 'moment';
import SBDatePicker from './SBDatePicker'; // Adjust the import path as necessary
import { SERVER_DATE_FORMAT } from '../../constants/Constants';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const getCorrectDateRange = (fromDate, toDate, maxDayDiff, dateFormat, dateSetMode) => {
	moment.locale('en-gb');
	let fromMoment = fromDate ? moment(fromDate, dateFormat).utcOffset(0) : null;
	let toMoment = toDate ? moment(toDate, dateFormat).utcOffset(0) : null;

	if (fromMoment && toMoment) {
		// Swap dates if toMoment is before fromMoment
		if (toMoment.isBefore(fromMoment, 'day')) {
			[fromMoment, toMoment] = [toMoment, fromMoment];
		}

		// Adjust fromMoment or toMoment if the difference is more than maxDayDiff days
		if (maxDayDiff !== -1 && toMoment.diff(fromMoment, 'days') > maxDayDiff) {
			if (dateSetMode === "TO") {
				fromMoment = toMoment.clone().subtract(maxDayDiff, 'days');
			}
			else {
				toMoment = fromMoment.clone().add(maxDayDiff, 'days');
			}
		}
	}
	let toString = toMoment ? toMoment.utc().format(dateFormat) : null;
	let fromString = fromMoment ? fromMoment.utc().format(dateFormat) : null;
	console.log("toString:", toString);

	return { fromString, toString };
}

const getDefaultDateRange = (dateFormat) => {
	const today = moment().utcOffset(0);
	let oneWeekAgo = moment().utcOffset(0).subtract(7, 'days');

	// Check if subtracting 7 days goes into the previous month
	if (oneWeekAgo.month() !== today.month()) {
		oneWeekAgo = moment().utcOffset(0).startOf('month');
	}

	return {
		fromString: oneWeekAgo.format(dateFormat),
		toString: today.format(dateFormat)
	};
};

const SBDateRangePicker = ({
	 fromDate,
	 toDate,
	 setToFromDate,
	 maxDayDiff = -1,
	 dateFormat = SERVER_DATE_FORMAT
}) => {


	const handleFromDateChange = (newFromDate) => {
		const { fromString, toString } = getCorrectDateRange(newFromDate, toDate, maxDayDiff, dateFormat, "FROM");
		setToFromDate(fromString, toString);
	};

	const handleToDateChange = (newToDate) => {
		const { fromString, toString } = getCorrectDateRange(fromDate, newToDate, maxDayDiff, dateFormat, "TO");
		setToFromDate(fromString, toString);
	};

	useEffect(() => {
		if (!fromDate && !toDate) {
			const { fromString, toString } = getDefaultDateRange(dateFormat);
			setToFromDate(fromString, toString);
		}
	}, [fromDate, toDate, setToFromDate, dateFormat]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Stack direction="row" spacing={1} alignItems="center">
					<SBDatePicker
						label="From Date"
						date={fromDate}
						onDateChange={handleFromDateChange}
						dateFormat={dateFormat}
					/>
					<ArrowRightAltIcon />
					<SBDatePicker
						label="To Date"
						date={toDate}
						onDateChange={handleToDateChange}
						dateFormat={dateFormat}
					/>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default SBDateRangePicker;
