import React, { useState, useContext, useEffect } from 'react';
import {
	Button,
	CircularProgress,
	Stack,
} from '@mui/material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import {
	SBCheckBoxField,
} from '../ui';
import { updateSharedLink } from '../../api/Admin/sharedLinksApi';

const SharedLinkEditModal = ({ open, onClose, handleDocumentChange, documentId, sharedLinkData }) => {
	const [busy, setBusy] = useState(false);
	const [sharedLink, setSharedLink] = useState({
		id: sharedLinkData.sharedLinkKey,
		isSecure: sharedLinkData.isSecured
	});

	const handleDataChange = (newValue, propertyName) => {
		setSharedLink(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		setBusy(true);
		const newSharedLinkData = await updateSharedLink(sharedLink, documentId);

		if (!newSharedLinkData) {
			return onClose();
		}
		else {
			onClose();
			handleDocumentChange(documentId);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" >
			{!busy &&
				<>
					<DialogTitle variant="h4">Edit shared link</DialogTitle>
					<DialogContent>
						<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
							<SBCheckBoxField
								id="isSecure"
								label="Secured"
								checked={sharedLink.isSecure}
								onChange={handleDataChange}
								canEdit={true}
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>
							Cancel
						</Button>
						<Button variant="contained" color="primary" onClick={handleSubmit}>
							Save
						</Button>
					</DialogActions>
				</>
			}
			{busy && <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></DialogContent>}
		</Dialog>
	)

}
export default SharedLinkEditModal;