import React from 'react';
import PropTypes from 'prop-types';
import { AllowedActions } from '../../../constants/Constants';

const actionColors = {
	[AllowedActions.REMOVE_ACCESS]: 'red',
	[AllowedActions.REMOVE_ROLE]: 'red',
	[AllowedActions.SET_ACCESS_EXPIRATION]: 'green',
	[AllowedActions.ADD_ROLE]: 'green',
	[AllowedActions.SET_ROLE]: 'green',
	[AllowedActions.SET_ROLE_DESCRIPTION]: 'green',
};

const actionTexts = {
	[AllowedActions.REMOVE_ACCESS]: "Remove Access",
	[AllowedActions.SET_ACCESS_EXPIRATION]: "Set Access Expiration",
	[AllowedActions.SET_ROLE_DESCRIPTION]: "Set Role Description",
	[AllowedActions.ADD_ROLE]: "Add Role",
	[AllowedActions.SET_ROLE]: "Set Role",
	[AllowedActions.REMOVE_ROLE]: "Remove Role"
};

class PreviewChangesScreen extends React.Component {
	render() {
		const { changesList, selectedAction, secondaryActionText } = this.props;
		const actionColor = actionColors[selectedAction] || 'black';
		const actionDescription = actionTexts[selectedAction] || 'Unknown Action';


		return (
			<div className="preview-changes-screen">
				<h2>Here are you changes</h2>
				<h4><b style={{ color: actionColor }}>{actionDescription}</b> {secondaryActionText}</h4>
				<ul>
					{changesList.map((item, index) => (
						<li key={index}>
							{item.userEmail} | {item.projectName} |
							<strong> Document Name:</strong> {item.documentName}
						</li>
					))}
				</ul>
			</div>
		);
	}
}

PreviewChangesScreen.propTypes = {
	selectedAction: PropTypes.string.isRequired,
	secondaryActionText: PropTypes.string,
	changesList: PropTypes.arrayOf(PropTypes.shape({
		userEmail: PropTypes.string.isRequired,
		projectName: PropTypes.string.isRequired,
		documentName: PropTypes.string.isRequired,
	}))
};

export default PreviewChangesScreen;