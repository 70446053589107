import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { getDisplayLabel } from '../../helpers/GeneralHelpers.js';

const MAX_DISPLAY_LEN = 100;

class SelectDropdown extends React.Component {
	static defaultProps = {
		label: null,
		multiple: false,
		canEdit: true,
		usePlaceholder: true,
		width: 250,
		fullWidth: false,
		setToDefault: false,
		forceSelectSingleOption: true,
		size: 'small',
		errors: []
	};

	isValueEmpty(value) {
		return (value === null || value === '' || (Array.isArray(value) && value.length === 0));
	}

	updateValueIfSingleOption() {
		const { id, options, forceSelectSingleOption, multiple, value, onChange } = this.props;

		if (!forceSelectSingleOption) {
			return;
		}

		if (options.length === 1 && this.isValueEmpty(value)) {
			onChange(multiple ? [options[0].id] : options[0].id, id);
		}
	}

	updateInvalidValueToDefault() {
		const { id, setToDefault, multiple, options, value, onChange } = this.props;

		if (!setToDefault || options.length === 0)
			return;
		const isInvalidValue = this.isValueEmpty(value);
		// Check if the initial value is null, empty, or an empty array
		if (isInvalidValue) {
			// Automatically set the value to the id of the first option
			onChange(multiple ? [options[0].id] : options[0].id, id);
		}
	}

	componentDidMount() {
		this.updateInvalidValueToDefault();
		this.updateValueIfSingleOption();
	}

	componentDidUpdate(prevProps) {
		// Check if the value prop has changed and is now empty or null
		if (prevProps.options !== this.props.options) {
			this.updateInvalidValueToDefault();
			this.updateValueIfSingleOption();
		}
		if (prevProps.value !== this.props.value) {
			this.updateInvalidValueToDefault();
			this.updateValueIfSingleOption();
		}
	}

	processMultipleSelections = (selected, options) => {
		if (selected.includes('all_items')) {
			const allOptionIds = options.map(item => item.id);
			const allOptionsAreSelected = allOptionIds.every(id => selected.includes(id));
			selected = allOptionsAreSelected ? [] : allOptionIds;
		}
		return selected.filter(item => item !== 'placeholder');
	}

	handleOnChange = (event) => {
		const { id, options, onChange, multiple } = this.props;
		let selected = event.target.value;
		if (multiple) {
			selected = this.processMultipleSelections(selected, options);
		}

		onChange(selected, id);
	}

	renderSelectedValues = (selected) => {
		const { id, label, options } = this.props;
		// Normalize to an array
		const normalizedSelection = Array.isArray(selected) ? selected : [selected];

		if (normalizedSelection.length === 0 || normalizedSelection[0] === 'placeholder') {
			const displayLabel = getDisplayLabel(label, id);
			return `Please Select ${displayLabel}`;
		}

		// Map the selected project IDs to their corresponding names
		const selectedNames = normalizedSelection.map(id => {
			const option = options.find(proj => proj.id === id);
			return option ? option.name : undefined;
		}).filter(Boolean);

		let selectDisplay = selectedNames.join(', ');
		const trimmedDisplay = selectDisplay.length > MAX_DISPLAY_LEN ? selectDisplay.substring(0, MAX_DISPLAY_LEN - 3) + '...' : selectDisplay;

		return trimmedDisplay;
	}

	getSelectedValue() {
		const { usePlaceholder, options, value } = this.props;
		if (usePlaceholder) {
			// Check if value is a string or an array and has a length
			if (typeof value === 'string' || Array.isArray(value)) {
				return value.length ? value : ['placeholder'];
			}
			const existsInOptions = options.some(option => option.id === value);
			return existsInOptions ? value : ['placeholder'];
		}
		return value;
	}

	render() {
		const { id, options, label, multiple, canEdit, usePlaceholder, fullWidth, width, size } = this.props;
		const displayLabel = getDisplayLabel(label, id);
		//console.log("RENDERING:", JSON.stringify(displayLabel, 4));
		//console.log("OPTIONS:",  JSON.stringify(options, 4));
		const value = this.getSelectedValue();
		let labelId = id + "-label";
		let minWidth = width - 50;
		return (
			<FormControl fullWidth={fullWidth} size={size} style={fullWidth ? {} : { minWidth: minWidth + 'px', maxWidth: width + 'px' }}>
				<InputLabel id={labelId}>{displayLabel}</InputLabel>
				<Select
					disabled={!canEdit}
					multiple={multiple}
					labelId={labelId}
					id={id}
					value={value}
					input={<OutlinedInput label={displayLabel} />}
					onChange={this.handleOnChange}
					renderValue={this.renderSelectedValues}
					fullWidth={fullWidth}
				>
					{usePlaceholder && <MenuItem disabled value="placeholder">
						Please Select {displayLabel}
					</MenuItem>}
					{multiple && <MenuItem key="all" value="all_items">Select All</MenuItem>}
					{options.map(item => (
						<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}
}

SelectDropdown.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string)
	]).isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]).isRequired,
		name: PropTypes.string.isRequired
	})).isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	multiple: PropTypes.bool,
	canEdit: PropTypes.bool,
	usePlaceholder: PropTypes.bool,
	errors: PropTypes.arrayOf(PropTypes.string),
	width: PropTypes.number,
	fullWidth: PropTypes.bool,
	size: PropTypes.string,
	setToDefault: PropTypes.bool // Used to automatically set value to the first item in the list if invalid value supplied
};

export default SelectDropdown;





