import React, { useContext, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getSiteId } from '../../../../api/configManager';
import { SiteDataContext } from '../../../../contexts';
import { IMAGE_TYPE } from '../../../../constants/FileTypeConstants';
import SiteImagesUploadModal from '../../../modals/FileUploadModal/SiteImagesUploadModal';

const SiteBrandingForm = () => {
	const [openChangeImage, setOpenChangeImage] = useState(false);
	const {
		siteBrandingImgSrc
	} = useContext(SiteDataContext);
	return (
		<Stack spacing={2} alignItems="flex-start">
			<Typography variant="h5">Branding Logo:</Typography>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => setOpenChangeImage(true)}
				startIcon={<AddIcon />}
			>
				Change Image
			</Button>
			<img
				src={siteBrandingImgSrc}
				alt="Site Logo"
				style={{ width: 100, height: 100, margin: 5 }}
			/>
			<SiteImagesUploadModal
				open={openChangeImage}
				onClose={() => setOpenChangeImage(false)}
				siteLocationId={getSiteId()}
				imageType={IMAGE_TYPE.SiteBranding}
			/>
		</Stack>
	);
};

export default SiteBrandingForm;