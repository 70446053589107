import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import { CRUD_ACTIONS } from '../../constants/ApiConstants';
import endpoints from '../endpoints';

// API For:
// - Workflows
// - Workflow responsibilities

export async function loadWorkflowsForSite(siteId) {
	try {
		const getWorkflowsBySiteReq = endpoints.workflows.getWorkflowsBySiteId(siteId);
		const data = await springboardApiRequest(getWorkflowsBySiteReq, "GET");
		downloadJsonData(data.data, "loadWorkflowsForSite");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching workflow by site data:', error);
		throw new Error('Failed to fetch workflow by site data.');
	}
}


// Workflows //

export async function loadWorkflowById(id) {
	try {
		const getWorkflowById = endpoints.workflows.getWorkflowsById(id);
		const data = await springboardApiRequest(getWorkflowById, "GET");
		downloadJsonData(data.data, "loadWorkflowById");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching workflow by id data:', error);
		throw new Error('Failed to fetch workflow by id data.');
	}
}

export async function createWorkflow(workflow) {
	try {
		const data = await springboardApiRequest(endpoints.workflows.create(), "POST", workflow);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating workflow data:', error);
		throw new Error('Failed to create workflow data.');
	}
}

export async function duplicateWorkflow(workflow) {
	try {
		const data = await springboardApiRequest(endpoints.workflows.duplicate(), "POST", workflow);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while duplicating workflow data:', error);
		throw new Error('Failed to duplicate workflow data.');
	}
}

export async function updateWorkflow(workflow) {
	try {
		const data = await springboardApiRequest(endpoints.workflows.update(), "POST", workflow);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating workflow data:', error);
		throw new Error('Failed to update workflow data.');
	}
}

export async function deleteWorkflow(id) {
	try {
		var request = {}
		const data = await springboardApiRequest(endpoints.workflows.delete(id), "POST", request);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting workflow data:', error);
		throw new Error('Failed to delete workflow data.');
	}
}

export async function createWorkflowStep(workflowStep) {
	try {
		const data = await springboardApiRequest(endpoints.workflowSteps.create(), "POST", workflowStep);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating workflow step data:', error);
		throw new Error('Failed to create workflow step data.');
	}
}

export async function updateWorkflowStep(workflowStep) {
	try {
		const data = await springboardApiRequest(endpoints.workflowSteps.update(), "POST", workflowStep);
		return data?.data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating workflow step data:', error);
		throw new Error('Failed to update workflow step data.');
	}
}

export async function deleteWorkflowStep(id) {
	try {
		var request = {}
		const data = await springboardApiRequest(endpoints.workflowSteps.delete(id), "POST", request);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting workflow step data:', error);
		throw new Error('Failed to delete workflow step data.');
	}
}


// ############## Project Workflows: ########################

export async function loadWorkflowForDoc(docId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadWorkflowForDoc.json');
		}
		const getWorkflowsReq = endpoints.workflows.getWorkflowsByDocId(docId);
		let data = await springboardApiRequest(getWorkflowsReq, "GET");
		console.log("workflow DATA:", data);
		downloadJsonData(data.data, "loadWorkflowForDoc");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document workflow data:', error);
		throw new Error('Failed to fetch document workflow data.');
	}
}

export async function loadSectionsForDoc(docId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadSectionsForDoc.json');
		}

		const getSectionsReq = endpoints.workflows.getSectionsByDocId(docId);
		let data = await springboardApiRequest(getSectionsReq, "GET");
		downloadJsonData(data.data, "loadSectionsForDoc");
		console.log("sections DATA:", data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document sections data:', error);
		throw new Error('Failed to fetch document sections data.');
	}
}

export async function loadResponsibilityForDoc(docId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadResponsibilityForDoc.json');
		}

		const getResponsibilitiesReq = endpoints.workflows.getResponsibilitiesByDocId(docId);
		let data = await springboardApiRequest(getResponsibilitiesReq, "GET");
		console.log("Responsibility DATA:", data);
		downloadJsonData(data.data, "loadResponsibilityForDoc");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document responsibility data:', error);
		throw new Error('Failed to fetch document responsibility data.');
	}
}

export async function loadReviewersForDoc(docId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadReviewersForDoc.json');
		}

		const getReviewersReq = endpoints.workflows.getReviewersByDocId(docId);
		let data = await springboardApiRequest(getReviewersReq, "GET");
		console.log("Reviewer DATA:", data);
		downloadJsonData(data.data, "loadReviewersForDoc");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document reviews data:', error);
		throw new Error('Failed to fetch document reviews data.');
	}
}


// Workflow Responsibility:
export async function createWorkflowReviewers(reviewers) {
	try {
		if (DEV_TEST_MODE) {
			return reviewers;
		}
		const customHeaders = { documentId: reviewers.documentId };
		let data = await springboardApiRequest(endpoints.workflows.createReviewers(), "POST", reviewers, customHeaders);
		console.log("Create reviewers DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while creating reviewers data:', error);
		throw new Error('Failed to create reviewers data.');
	}
}

export async function createWorkflowResponsibleUsers(responsibleUsers) {
	try {
		if (DEV_TEST_MODE) {
			return responsibleUsers;
		}
		const customHeaders = { documentId: responsibleUsers.documentId };
		let data = await springboardApiRequest(endpoints.workflows.createResponsibleUsers(), "POST", responsibleUsers, customHeaders);
		console.log("Create responsibleUsers DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while creating responsibleUsers data:', error);
		throw new Error('Failed to create responsibleUsers data.');
	}
}

export async function clearWorkflowResponsibleUsers(responsibleUsers) {
	try {
		if (DEV_TEST_MODE) {
			return responsibleUsers;
		}
		const customHeaders = { documentId: responsibleUsers.documentId };
		let data = await springboardApiRequest(endpoints.workflows.clearResponsibleUsers(), "POST", responsibleUsers, customHeaders);
		console.log("Clear responsibleUsers DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while clearing responsibleUsers data:', error);
		throw new Error('Failed to clear responsibleUsers data.');
	}
}

export async function removeWorkflowReviewers(reviewers) {
	try {
		if (DEV_TEST_MODE) {
			return reviewers;
		}
		const customHeaders = { documentId: reviewers.documentId };
		let data = await springboardApiRequest(endpoints.workflows.removeReviewers(), "POST", reviewers, customHeaders);
		console.log("Remove reviewers DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while removing reviewers data:', error);
		throw new Error('Failed to remove reviewers data.');
	}
}


export async function getSectionTreeByDocId(docId) {
	try {
		const data = await springboardApiRequest(endpoints.workflowSections.getSectionTreeByDocId(docId), "GET");
		downloadJsonData(data.data, "getSectionTreeByDocId");
		return data.data;
	} catch (error) {
		console.error('Error while loading section tree data:', error);
		throw new Error('Failed loading section tree data.');
	}
}

export async function setReviewSections(reviewSections) {
	try {
		if (DEV_TEST_MODE) {
			return reviewSections;
		}
		const customHeaders = { documentId: reviewSections.documentId };
		let data = await springboardApiRequest(endpoints.workflowSections.setReviewSections(), "POST", reviewSections, customHeaders);
		console.log("Set review Sections DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while setting review sections data:', error);
		throw new Error('Failed to set review sections data.');
	}
}

export async function getCalculatedStartDates(request) {
	try {
		const { dateDue, stepId, documentId } = request;
		const data = await springboardApiRequest(endpoints.workflows.calculateGivenStartDate(dateDue, stepId, documentId), "GET");
		downloadJsonData(data.data, "getCalculatedStartDates");
		return data.data;
	} catch (error) {
		console.error('Error while loading calculated start dates:', error);
		throw new Error('Failed loading calculated start dates.');
	}
}

export async function setSectionsStartDate(sectionsDate) {
	try {
		if (DEV_TEST_MODE) {
			return sectionsDate;
		}
		const customHeaders = { documentId: sectionsDate.documentId };
		let data = await springboardApiRequest(endpoints.workflowSections.setSectionsStartDate(), "POST", sectionsDate, customHeaders);
		console.log("Set section Start Date DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while setting section Start Date data:', error);
		throw new Error('Failed to set section Start Date data.');
	}
}

export async function setSectionsContractor(sectionsContractors) {
	try {
		if (DEV_TEST_MODE) {
			return sectionsContractors;
		}
		const customHeaders = { documentId: sectionsContractors.documentId };
		let data = await springboardApiRequest(endpoints.workflowSections.setSectionsContractor(), "POST", sectionsContractors, customHeaders);
		console.log("Set section contractor DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while setting section contractor data:', error);
		throw new Error('Failed to set section contractor data.');
	}
}

