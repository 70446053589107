import React from 'react';
import * as Api from '../api/AdminApi';
export const AdminsContext = React.createContext(null);

export class AdminsProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	loadAdminsForSite = async (siteId) => {
		try {
			const admins = await Api.loadAdminsForSite(siteId);
			return admins;
		} catch (error) {
			console.error("Error loading admins for site:", error);
		}
	}

	loadAdminsById = async (userIds) => {
		try {
			const uniqueUserIds = Array.from(new Set(userIds));
			const userData = await Api.loadUsers(uniqueUserIds);
			return userData;
		} catch (error) {
			console.error(`Error loading admins by id ${userIds}:`, error);
		}
	}

	createAdmins = async (adminsData) => {
		try {
			const admins = await Api.createAdmins(adminsData);
			return admins;
		} catch (error) {
			console.error("Error creating admins:", error);
		}
	}

	deleteAdmins = async (adminIds) => {
		try {
			const admins = await Api.deleteAdmins(adminIds);
			return admins;
		} catch (error) {
			console.error("Error deleting admins:", error);
		}
	}

	swapMaster = async (userId) => {
		try {
			const master = await Api.swapMaster(userId);
			return master;
		} catch (error) {
			console.error("Error swapping master admin:", error);
		}
	}

	render() {
		const adminsContextValue = {
			loadAdminsForSite: this.loadAdminsForSite,
			loadAdminsById: this.loadAdminsById,
			createAdmins: this.createAdmins,
			deleteAdmins: this.deleteAdmins,
			swapMaster: this.swapMaster,
		};

		return (
			<AdminsContext.Provider value={adminsContextValue}>
				{this.props.children}
			</AdminsContext.Provider>
		)
	}
}

export default AdminsProvider;