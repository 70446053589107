import React, { useState } from 'react';
import {
	Box,
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
	Stack
} from '@mui/material';
import { searchUsers } from '../../../api/AdminApi';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import UserTableComponent from './UserTableComponent';

const UserLookupPage = (props) => {
	const [busy, setBusy] = useState(false);
	const [email, setEmail] = useState('');
	const [user, setUser] = useState([]);
	const [searched, setSearched] = useState(false);

	const handleSubmit = async () => {
		setBusy(true);

		const searchUsersData = await searchUsers([email]);
		setUser(searchUsersData.length ? searchUsersData[0].users : []);

		setBusy(false);
		setSearched(true);
	};

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">User Lookup</Typography>
				<Box className="form-container">
					<FormControl
						variant="outlined"
						size="small"
						sx={{ width: '100%' }}
					>
						<OutlinedInput
							id="user-lookup"
							type="text"
							placeholder="Enter a user email address"
							value={email}
							onChange={(event) => setEmail(event.target.value.trim())}
							onKeyDown={(event) => { if (event.key === 'Enter') handleSubmit() }}
							endAdornment={
								<>
									<InputAdornment position="end">
										<IconButton
											aria-label="clear form"
											onClick={() => setEmail('')}
											edge="end"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
									<InputAdornment position="end">
										<IconButton
											aria-label="search for user"
											onClick={() => handleSubmit()}
											edge="end"
											color="primary"
										>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								</>
							}
						/>
					</FormControl>
				</Box>
				{busy && <CircularProgress />}
				{!busy && searched && <UserTableComponent user={user} />}
			</Stack>
		</Box>
	)
}
export default UserLookupPage;