import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_ALLOWED_FILES, IMAGE_TYPE } from '../../../constants/FileTypeConstants';
import FileUploadModal from './FileUploadModal';
import { SiteDataContext } from '../../../contexts';
import { getFileExtension } from '../../../helpers/GeneralHelpers';

const MAX_SITE_IMAGE_UPLOAD = 10;

function SiteImagesUploadModal({ siteLocationId, imageType, open, onClose, onComplete }) {
	const {
		processSiteImage,
		processImageMapImage,
		processSiteBrandingImage
	} = useContext(SiteDataContext);
	const maxFiles = imageType === IMAGE_TYPE.Siteimages ? MAX_SITE_IMAGE_UPLOAD : 1;

	const processUploadedImage = async (fileData) => {
		console.log("FILE DATA:", fileData);
		fileData.extension = "." + getFileExtension(fileData.originalName);
		fileData.siteLocationId = siteLocationId;
		fileData.imageType = imageType;

		let success = true;
		let imageData = null;
		try {
			if (imageType === IMAGE_TYPE.Siteimages) {
				imageData = await processSiteImage(fileData);
			}
			else if (imageType === IMAGE_TYPE.LocationImageMap) {
				imageData = await processImageMapImage(fileData);
			}
			else if (imageType === IMAGE_TYPE.SiteBranding) {
				imageData = await processSiteBrandingImage(fileData);
			}
			else {
				throw Error("Invalid image type set!");
			}
		}
		catch (error) {
			// Report failure above?
			success = false;
			console.error("Error: Image upload failed!: ", error);
		}

		if (onComplete)
			onComplete(success, imageData);
	}

	const imageUploadComplete = async (fileData) => {
		onClose();

		if (maxFiles === 1) {
			await processUploadedImage(fileData);
			return;
		}

		for (const img of fileData) {
			await processUploadedImage(img);
		}
	};

	return <FileUploadModal
		title={"Site Image Upload"}
		acceptedFileTypes={IMAGE_ALLOWED_FILES}
		open={open}
		onClose={onClose}
		processFileCallback={imageUploadComplete}
		maxFiles={maxFiles}
		resetOnOpen
	/>
}

SiteImagesUploadModal.propTypes = {
	siteLocationId: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default SiteImagesUploadModal;