import React from 'react';
import { Button, IconButton, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

function LOIDataCellRenderer(props) {
	if (!props.data || !props.column) {
		console.error("Invalid props in LOIDataCellRenderer:", props);
	}
	const data = props.value;
	const cellId = {
		rowId: props.data?.rowId,
		colId: props.column?.colId
	};

	const handleAddClick = () => {
		// Pass cellId to the addFunction
		props.context.addLOIfunc(cellId);
	};

	if (!data) {
		return (
			<IconButton
				onClick={handleAddClick}
				disabled={!props.column?.colDef.editable}
			>
				<AddCircleIcon />
			</IconButton>
		);
	}
	return (
		<Button onClick={handleAddClick} disabled={!props.column?.colDef.editable}>
			<Typography variant="body2">
				{data}
			</Typography>
		</Button>
	);
}

export default LOIDataCellRenderer;