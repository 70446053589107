import React, { useState, useContext, useMemo, useEffect } from 'react';
import {
	Box,
	Button,
	ButtonGroup,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { UserModalTrigger } from '../../modals';
import { UserContext } from '../../../contexts/';
import { deleteSharedLinkById } from '../../../api/Admin/sharedLinksApi';
import { SharedLinkType } from '../../../constants/Constants';


function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'SharedLinks-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const SharedLinksTableComponent = ({ sharedLinks, setEditSharedLink, setSendSharedLink, documentId, handleDocumentChange, setSelectedSharedLinkId }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [deleteSharedLink, setDeleteSharedLink] = useState({ id: '' });

	const { users, loadUsersById } = useContext(UserContext);

	useEffect(() => {
		const uniqueUserIds = [...new Set(sharedLinks.map(link => link.createdById))];
		loadUsersById(uniqueUserIds);
	}, [loadUsersById, sharedLinks]);


	const userIdToNameMap = useMemo(() => users.reduce((map, user) => {
		map[user.id] = user.name;
		return map;
	}, {}), [users]);

	const handleOpenDeleteDialog = (sharedLink) => {
		setOpenDeleteConfirm(true);
		setDeleteSharedLink({ id: sharedLink.sharedLinkKey })
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
	};

	const handleSubmitDelete = async () => {
		const sharedLinkData = await deleteSharedLinkById(deleteSharedLink.id, documentId);

		if (!sharedLinkData) {
			return setOpenDeleteConfirm(false);
		}
		else {
			setOpenDeleteConfirm(false);
			handleDocumentChange(documentId);
		}
	}

	const columns = [
		{
			field: 'targetData',
			headerName: 'Target',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'type',
			headerName: 'Link type',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return SharedLinkType[row.type];
			}
		},
		{
			field: 'isSecured',
			headerName: 'Secured',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'userId',
			headerName: 'Created by',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return userIdToNameMap[row.createdById];
			},
			renderCell: (params) => (
				<UserModalTrigger userId={params.row.createdById} />
			)
		},
		{
			field: 'dateCreated',
			headerName: 'Date created',
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row) => {
				return new Date(row.dateCreated).toLocaleString({}, {dateStyle: 'short'});
			}
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			minWidth: 240,
			flex: 1,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<ButtonGroup variant="outlined" size="small">
					<Button
						color="primary"
						onClick={() => { setEditSharedLink(true); setSelectedSharedLinkId(params.row.sharedLinkKey) }}>
						Edit
					</Button>
					<Button
						color="primary"
						onClick={() => { handleOpenDeleteDialog(params.row) }}>
						Delete
					</Button>
					<Button
						color="primary"
						onClick={() => { setSendSharedLink(true); setSelectedSharedLinkId(params.row.sharedLinkKey) }}>
						Resend
					</Button>
				</ButtonGroup>
			)
		},
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={sharedLinks}
					columns={columns}
					rowHeight={42}
					autoHeight
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
					getRowId={row => row.sharedLinkKey}
				/>
			</Box>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete shared link"
				message="Please confirm that you would like to delete this shared link"
				onConfirm={handleSubmitDelete}
			/>
		</>
	);
};

export default SharedLinksTableComponent;