import { DOCUMENT_FEATURES } from '../../constants/SiteSecurityConstants';

// Function to check if there is a matching role between two sets of roles
const checkRoleMatch = (myRoles, globalRoles) => {
	const globalRoleIds = globalRoles.map(role => role.roleId);
	return myRoles.some(roleId => globalRoleIds.includes(roleId));
};

// Function to create a dictionary that maps document IDs to whether the user has access based on role matching
const createDocumentRoleMatchDictionary = (mySecurityData, securityItem) => {
	const docIdAccess = {};

	mySecurityData.forEach(sec => {
		docIdAccess[sec.documentId] = checkRoleMatch(sec.roles, securityItem.roles);
	});

	return docIdAccess;
};

// Function to create a dictionary that maps project IDs to whether the user has access based on role matching
const createProjectRoleMatchDictionary = (myProjectRoles, securityItem) => {
	const projectIdAccess = {};

	Object.keys(myProjectRoles).forEach(projectId => {
		const projectRoles = myProjectRoles[projectId];
		//console.log("PROJECT ROLES:", projectRoles);
		projectIdAccess[projectId] = checkRoleMatch(projectRoles, securityItem.roles);
	});

	return projectIdAccess;
};

// Function to create a dictionary of roles for each projectId from security data
const createProjectRolesDictionary = (mySecurityData) => {
	const myProjectRolesDictionary = {};

	// Create unique roles for each projectId
	mySecurityData.forEach(item => {
		const projectId = item.projectId;
		if (!myProjectRolesDictionary[projectId]) {
			myProjectRolesDictionary[projectId] = new Set();
		}
		item.roles.forEach(role => {
			myProjectRolesDictionary[projectId].add(role);
		});
	});

	// Convert sets to arrays
	Object.keys(myProjectRolesDictionary).forEach(projectId => {
		myProjectRolesDictionary[projectId] = Array.from(myProjectRolesDictionary[projectId]);
	});

	return myProjectRolesDictionary;
};

// Create a dictionary indicating which features a user has access to. The values will be either true/false
// or another dictionary mapping projectIds/documentIds to true/false based on user access.
// Each nested dictionary will indicate user access for the corresponding project/document.
export const createFeatureAccessDictionary = (globalSecurity, mySecurityData, isSiteAdmin) => {
	const featureAccessDictionary = {};
	const myProjectRolesDictionary = createProjectRolesDictionary(mySecurityData);

	globalSecurity.forEach(securityItem => {
		const featureName = securityItem.feature?.trim();

		if (!featureName || securityItem.namespace !== "workspace") {
			return;
		}

		if (isSiteAdmin) {
			// Site admins are automatically granted access
			featureAccessDictionary[featureName] = true;
		} else if (securityItem.siteLevelAdminOnly) {
			// User is NOT site admin, this feature is only for site admins so auto reject
			featureAccessDictionary[featureName] = false;
		} else if (DOCUMENT_FEATURES.includes(featureName)) {
			// DOCUMENT LEVEL SECURITY
			featureAccessDictionary[featureName] = createDocumentRoleMatchDictionary(mySecurityData, securityItem);
		} else {
			// PROJECT LEVEL SECURITY
			featureAccessDictionary[featureName] = createProjectRoleMatchDictionary(myProjectRolesDictionary, securityItem);
		}
	});

	return featureAccessDictionary;
};
