import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import PreviewChangesModal from './PreviewChangesModal';

const PreviewChangesBtn = (props) => {
	const [open, setOpen] = useState(false);

	const handleSaveClick = () => {
		setOpen(false);
		props.onSaveChanges();
	}

	const handleOpenModal = () => {
		props.onPreviewClick();
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	return (
		<>
			<Box sx={{ position: 'relative' }}>
				<Button onClick={handleOpenModal} variant="contained">
					Preview Changes
				</Button>
			</Box>
			<PreviewChangesModal
				open={open}
				onClose={handleCloseModal}
				onSaveClick={handleSaveClick}
			>
				{props.children}
			</PreviewChangesModal>
		</>
	);
};

PreviewChangesBtn.propTypes = {
	onPreviewClick: PropTypes.func.isRequired,
	children: PropTypes.node,
};

export default PreviewChangesBtn;