import React, { useState } from 'react';
import {
	Button,
	Box,
	Divider,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import {
	SecurityButton
} from '../../ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StructuralNamesDisplay from './StructuralNamesDisplay';
import ClassificationGroupEditor from './ClassificationGroupEditor';
import ClassificationCodesViewer from './ClassificationCodesViewer';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function groupByClassificationName(classifications) {
	return classifications.reduce((acc, classification) => {
		// Initialize the group if it doesn't already exist
		if (!acc[classification.name]) {
			acc[classification.name] = {
				items: [],
				name: classification.name
			};
		}
		// Add the current classification to the group
		acc[classification.name].items.push(classification);
		return acc;
	}, {});
}



function ClassificationGroupsDisplay({ projectClassifications, onEditGroup, onAddGroup, onDeleteGroup }) {
	const groupedClassifications = groupByClassificationName(projectClassifications);
	const [editingProjectClassification, setEditingProjectClassification] = useState({});
	const [openGroupEditor, setOpenGroupEditor] = useState(false);
	const [editMode, setEditMode] = useState(true);
	const [openCodesViewer, setOpenCodesViewer] = useState(false);
	const [groupCodeViewId, setGroupCodeViewId] = useState('');

	const handleEditClick = (projectClassificationId) => {
		setEditMode(true);
		setOpenGroupEditor(true);
		let selectedProjectClassification = projectClassifications.find(item => item.id === projectClassificationId);
		setEditingProjectClassification(selectedProjectClassification);
	}

	const handleAddSetClick = (groupName) => {
		setEditMode(false);
		setOpenGroupEditor(true);
		setEditingProjectClassification({ name: groupName });
	}

	const handleAddNewGroupClick = () => {
		setEditMode(false);
		setOpenGroupEditor(true);
		setEditingProjectClassification({});
	}

	const handleDeleteClick = (projectClassificationId) => {
		onDeleteGroup(projectClassificationId);
	}

	const handleSaveClick = (classGroup) => {
		if (editMode) {
			onEditGroup(classGroup);
		}
		else {
			onAddGroup(classGroup);
		}
	}

	const handleCloseEdit = () => {
		setOpenGroupEditor(false);
	}

	const handleOpenViewGroup = (groupId) => {
		setOpenCodesViewer(true);
		setGroupCodeViewId(groupId);
	}

	return (
		<>
			{Object.entries(groupedClassifications).map(([name, group], index) => (
				<React.Fragment key={name}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4">{name}</Typography>
						<Typography variant="caption">Classification Group</Typography>
					</Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={10}>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography variant="body1">Classification set(s)</Typography>
							<SecurityButton
								featureName={SECURITY_FEATURES.PROJECT_CLASSIFICATIONS_PAGE}
								color="primary"
								onClick={() => handleAddSetClick(group.name)}
								sx={{ minWidth: 'auto' }}
							>
								<AddCircleIcon />
							</SecurityButton>
						</Stack>
					</Grid>
					{group.items.map((classification, subIndex) => (
						<React.Fragment key={subIndex}>
							<Grid item xs={2}></Grid>
							<Grid item xs={10} container spacing={1}>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
										<Typography variant="h5">{classification.groupName}</Typography>
										<Box sx={{ flexGrow: 1 }} /> {/* This box acts as a spacer */}
										<SecurityButton
											featureName={SECURITY_FEATURES.PROJECT_CLASSIFICATIONS_PAGE}
											color="error"
											onClick={() => handleDeleteClick(classification.id)}
											sx={{ minWidth: 'auto' }}
										>
											<DeleteIcon />
										</SecurityButton>
										<SecurityButton
											featureName={SECURITY_FEATURES.PROJECT_CLASSIFICATIONS_PAGE}
											onClick={() => handleEditClick(classification.id)}
											sx={{ minWidth: 'auto', color: 'grey' }}
										>
											<EditIcon />
										</SecurityButton>
										<Button variant="outlined" onClick={() => handleOpenViewGroup(classification.groupId)}>
											View
										</Button>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<StructuralNamesDisplay selectedStructuralNames={classification.structuralNames} />
								</Grid>

							</Grid>
						</React.Fragment>
					))}
				</React.Fragment>
			))}
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<SecurityButton
					featureName={SECURITY_FEATURES.PROJECT_CLASSIFICATIONS_PAGE}
					variant="outlined"
					onClick={handleAddNewGroupClick}
					color="primary">
					Add Classification Group
				</SecurityButton>
			</Grid>

			<ClassificationGroupEditor
				open={openGroupEditor}
				projectClassification={editingProjectClassification}
				onSave={handleSaveClick}
				onClose={handleCloseEdit}
			/>
			<ClassificationCodesViewer
				open={openCodesViewer}
				onClose={() => setOpenCodesViewer(false)}
				groupId={groupCodeViewId}
			/>
		</>
	);
}

export default ClassificationGroupsDisplay;