import React, { useContext, useState } from 'react';
import { Box, Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { UserContext } from '../../contexts';

const UserModalTrigger = ({ userId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { userIdMap } = useContext(UserContext);
	const user = userIdMap[userId];

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	if (!user) return <span>{userId}</span>;

	return (
		<>
			<Button variant="text" color="primary" onClick={openModal} size="small" startIcon={user.isMe && (
				<Tooltip title="Me" arrow sx={{ verticalAlign: 'middle' }}>
					<PersonIcon />
				</Tooltip>
			)}>
				{user.name}
			</Button>
			<Dialog open={isOpen} onClose={closeModal} maxWidth="xs">
				<DialogTitle variant="h4">{user.name}</DialogTitle>
				<DialogContent>
					<Box mb={2}>
						<Typography variant="h5" gutterBottom>User Information</Typography>
						<Typography variant="body1"><strong>Name:</strong> {user.name}</Typography>
						<Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
						<Typography variant="body1"><strong>Status:</strong> Offline</Typography>
					</Box>
					<Divider />
					<Box mt={2}>
						<Typography variant="h5" gutterBottom>Company Information</Typography>
						<Typography variant="body1"><strong>Company:</strong> {user.companyName}</Typography>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UserModalTrigger;
