import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';

export async function loadStructuralNames(projectId) {
	try {
		console.log("Loading structuralNames");
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadStructuralNames.json');
		}

		const structuralNamesEndpoint = endpoints.platformConfig.getStructuralNames();

		console.log("Loading projects from: ", structuralNamesEndpoint);
		let data = await springboardApiRequest(structuralNamesEndpoint, "GET");
		downloadJsonData(data.data, "loadStructuralNames");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching structuralNames data:', error);
		throw new Error('Failed to fetch structuralNames data.');
	}
}

export async function loadLinkTypes(projectId) {
	try {
		console.log("Loading GetLinkTypes");
		if (DEV_TEST_MODE) {
			return loadJsonFile('GetLinkTypes.json');
		}

		const GetLinkTypesEndpoint = endpoints.platformConfig.getLinkTypes();

		console.log("Loading GetLinkTypes from: ", GetLinkTypesEndpoint);
		let data = await springboardApiRequest(GetLinkTypesEndpoint, "GET");
		downloadJsonData(data.data, "GetLinkTypes");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching linkTypes data:', error);
		throw new Error('Failed to fetch linkTypes data.');
	}
}

export async function getMySites() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getMySites.json');
		}
		const getSitesEndpoint = endpoints.site.getMySites();
		let data = await springboardApiRequest(getSitesEndpoint, "GET");
		console.log("Get My Sites result:", data);
		downloadJsonData(data.data, "getMySites");
		return data.data;
	}
	catch (error) {
		// Handle error here
		console.error('Error while getting my site data', error);
		throw new Error('Failed to get site data');
	}
}

export async function getUniclassVersions() {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.getUniclassVersions(), "GET");
		downloadJsonData(data.data, "getUniclassVersions");
		return data.data;
	} catch (error) {
		console.error('Error while loading uniclass versions:', error);
		throw new Error('Failed loading uniclass versions data.');
	}
}

