import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { RequestSnackbar } from './';
import apiRequestManager from '../../api/Services/ApiRequestManager';

const DisplayLoadingPercent = ({ percent }) => {
	if (percent === 0)
		return null;

	// Multiply by 100, floor it, then divide by 100 to round down to two decimal places
	const roundedPercent = Math.floor(percent * 100) / 100;

	return (
		<>
			{roundedPercent}%
		</>
	);
}

const ApiStatusDisplay = () => {
	const [requestQueue, setRequestQueue] = useState(apiRequestManager.requestQueue);

	useEffect(() => {
		const unsubscribe = apiRequestManager.subscribe(setRequestQueue);

		// Cleanup on unmount
		return () => unsubscribe();
	}, []);

	const sumOfLoadingProgress = requestQueue.reduce((sum, request) => {
		if (request.loading) {
			return sum + request.progress;
		}
		return sum;
	}, 0);

	const isLoading = requestQueue.some(request => request.loading);
	const nonLoadingRequest = requestQueue.find(request => !request.loading);

	console.log("Loading req:", isLoading);
	console.log("REQ QUEUE:", requestQueue);

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
				<Typography variant="h6" sx={{ marginLeft: 2 }}>
					Loading... <DisplayLoadingPercent percent={sumOfLoadingProgress} />
				</Typography>
			</Backdrop>
			{nonLoadingRequest && <RequestSnackbar request={nonLoadingRequest} />}
		</div>
	);
};

export default ApiStatusDisplay;