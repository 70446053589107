import React, { useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Paper, TextField, Typography, useTheme } from '@mui/material';

function VirtualisedList({ items, value, onChange, title, itemSize = 50, height = 200 }) {
	const theme = useTheme();
	const [searchTerm, setSearchTerm] = useState('');

	// Filter items based on search term
	const filteredItems = useMemo(() => {
		return items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
	}, [items, searchTerm]);

	const Row = ({ index, style }) => (
		<Paper
			style={{
				...style,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: value === filteredItems[index].id ? theme.palette.action.selected : '',
				cursor: 'pointer',
			}}
			onClick={() => onChange(filteredItems[index].id)}
		>
			{filteredItems[index].name}
		</Paper>
	);

	return (
		<>
			<Typography variant="h6">
				{title}
			</Typography>
			<TextField
				fullWidth
				variant="outlined"
				placeholder="Site Name Search..."
				value={searchTerm}
				onChange={e => setSearchTerm(e.target.value)}
				style={{ marginBottom: '1rem' }}
			/>
			<List
				height={height}
				width="100%"
				itemSize={itemSize}
				itemCount={filteredItems.length}
				style={{ border: '1px solid #e0e0e0' }}
			>
				{Row}
			</List>
		</>
	);
}

export default VirtualisedList;