import React from 'react';
import { ButtonBase, Grid, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

function AttributeConfigCellRenderer(props) {
	const handleEditClick = () => {
		props.context.editAttrConfigFunc(props.attrId);
	};
	return (
		<ButtonBase onClick={handleEditClick} disabled={!props.column?.colDef.editable} style={{ width: '100%', textAlign: 'left' }}>
			<Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
				<Grid item xs={3}>
					<SettingsIcon style={{ color: !props.column?.colDef.editable ? 'rgba(0, 0, 0, 0.26)' : 'inherit' }} />
				</Grid>
				<Grid container item xs={9}>
					<Grid item xs={12}>
						<Typography variant="caption">
							Alias: {props.alias}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="caption">
							ClientRef: {props.clientReference}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</ButtonBase>
	);
}

export default AttributeConfigCellRenderer;