import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, List, ListItem, ListItemText, Typography } from '@mui/material';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	height: '150px'
};

const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

function CustomDropzone({ onFileUpload, acceptedFileTypes }) {
	const [files, setFiles] = useState([]);

	const onDrop = useCallback(acceptedFiles => {
		setFiles(acceptedFiles);
		if (onFileUpload) {
			onFileUpload(acceptedFiles);
		}
	}, [onFileUpload]);

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({
		onDrop,
		accept: acceptedFileTypes
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);


	return (
		<Paper variant="outlined" style={{ padding: '20px', textAlign: 'center' }}>
			<div {...getRootProps({style})}>
				<input {...getInputProps()} />
				<Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
			</div>
			{/*<List>
				{files.map(file => (
					<ListItem key={file.path}>
						<ListItemText primary={file.name} secondary={`${file.size} bytes`} />
					</ListItem>
				))}
			</List>
				*/}
		</Paper>
	);
}

export default CustomDropzone;