import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { SecurityButton } from '../ui';

// If open is set, then the dialog will be controlled by its Parent
// otherwise, this component will handle this with a button.
function MultiStepModal({
	children,
	onModalComplete,
	buttonText,
	btnVariantStyle,
	title,
	btnColor = "primary",
	btnStyle,
	open,
	onClose,
	featureName
}) {
	const [currentStep, setCurrentStep] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (open !== undefined && !onClose) {
			console.warn("Warning: onClose must be set if open is set");
		}
	}, [open, onClose]);

	const totalSteps = React.Children.count(children);

	const handleNext = () => {
		if (currentStep < totalSteps - 1) {
			// Move to the next step if not the last one
			setCurrentStep(currentStep + 1);
		} else {
			// Call parent to inform them last operation was completed
			onModalComplete();
			// Close the modal if it's the last step
			handleClose();
		}
	};

	const handleReset = () => {
		setCurrentStep(0);
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		handleReset();
		if (onClose) {
			onClose();
		}
	};

	const childElements = React.Children.toArray(children);
	const CurrentComponent = React.cloneElement(childElements[currentStep], {
		onComplete: handleNext,
		onReset: handleReset,
		onClose: handleClose
	});

	return (
		<>
			{open === undefined && ( // Only render the button if open is not defined
				featureName ?
					<SecurityButton
						variant={btnVariantStyle}
						color={btnColor}
						size="small"
						onClick={handleOpen}
						sx={btnStyle}
					>
						{buttonText}
					</SecurityButton> :
					<Button
						variant={btnVariantStyle}
						color={btnColor}
						size="small"
						onClick={handleOpen}
						sx={btnStyle}
					>
						{buttonText}
					</Button>
			)}
			<Dialog open={open !== undefined ? open : isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle variant="h5">
					{title}
				</DialogTitle>
				<DialogContent>{CurrentComponent}</DialogContent>
			</Dialog>
		</>
	);
}

export default MultiStepModal;