import React from 'react';
import {
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const SecurityRolesTable = ({ roles, securityRoles, onEdit, onDelete }) => {
	return (
		<List sx={{ width: '100%', maxWidth: '360px' }} dense={true}>
			{roles.map((role) => (
				<ListItem
					key={role.id}
					disableGutters
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					secondaryAction={
						<>
							<IconButton
								aria-label="edit"
								color="primary"
								onClick={() => onEdit(role)}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								aria-label="delete"
								color="primary"
								disabled={securityRoles.includes(role.id)}
								onClick={() => onDelete(role)}
							>
								<DeleteIcon />
							</IconButton>
						</>
					}
				>
					<ListItemText primary={role.name} />
				</ListItem>
			))}
		</List>
	);
};

export default SecurityRolesTable;