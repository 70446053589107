import React, { useState, useContext, useEffect } from 'react';
import {
	Alert,
	Button,
	CircularProgress,
	Stack,
} from '@mui/material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import {
	BasicTxtField,
	CustomCKEditor,
	SBCheckBoxField,
	SelectDropdown,
} from '../ui';
import * as Validation from '../../services/Validation';
import { shareLinkWithUsers } from '../../api/Admin/sharedLinksApi';

const formValidators = {
	emails: [Validation.validateNotEmptyArray],
}

const SharedLinkSendModal = ({ open, onClose, documentId, sharedLinkData }) => {
	const [busy, setBusy] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [sharedLink, setSharedLink] = useState({
		id: sharedLinkData.sharedLinkKey,
		emails: [],
	});

	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		resetFormErrors();
	}, [sharedLink, resetFormErrors]);

	// useEffect(() => {
	// 	console.log('edited form', sharedLink);
	// }, [sharedLink])

	const handleDataChange = (newValue, propertyName) => {
		setSharedLink(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid(sharedLink)) return;

		setBusy(true);
		setSuccess(false);
		setError(false);

		const cleanEmails = sharedLink.emails.filter(elem => elem);
		setSharedLink(prevData => ({ ...prevData, emails: cleanEmails }));

		const newSharedLinkData = await shareLinkWithUsers(sharedLink, documentId);

		if (newSharedLinkData) {
			setBusy(false);
			setSuccess(true);
		}
		else {
			setBusy(false);
			setError(true);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" >
			{!busy &&
				<>
					<DialogTitle variant="h4">Send shared link</DialogTitle>
					<DialogContent>
						<Stack sx={{ mb: 3 }} direction='column' spacing={3} style={{ paddingTop: '8px' }}>
							<BasicTxtField
								id="emails"
								label="Email address(es) to send to"
								value={sharedLink.emails.join('\n')}
								onChange={(value) => handleDataChange(value.split('\n'), "emails")}
								fullWidth
								multiline
								rows="4"
								helperText="One per line. If secured user must be in the project team"
								errors={formErrors['emails']}
								required
							/>
							{success &&
								<Alert severity="success" sx={{ width: '100%' }}>Shared link successfully sent</Alert>
							}
							{error &&
								<Alert severity="error" sx={{ width: '100%' }}>Shared link failed to send</Alert>
							}
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>
							Cancel
						</Button>
						<Button variant="contained" color="primary" onClick={handleSubmit}>
							Send
						</Button>
					</DialogActions>
				</>
			}
			{busy &&
				<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</DialogContent>
			}
		</Dialog>
	)

}
export default SharedLinkSendModal;