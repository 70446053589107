import React from 'react';
import {
	Grid,
} from '@mui/material';

import IncludeExcludeConfigurator from './IncludeExcludeConfigurator';

function ValidationValueConfigurator({ validationType, selectedTypeId, label, values, onChange }) {
	const handleChange = (id, value) => {
		const newValues = { ...values, [id]: value };
		const fieldName = `${validationType}Values`;
		console.log("fieldName:", fieldName, "new values:", newValues);
		onChange(newValues, fieldName);
	}
	return (
		<>
			<Grid item xs={12} sm={1}></Grid>
			<IncludeExcludeConfigurator
				id="include"
				validationType={validationType}
				selectedTypeId={selectedTypeId}
				label={`${label} Include`}
				matchModeValue={values.includeFormat}
				textValue={values.include}
				onChange={handleChange}
			/>
			<IncludeExcludeConfigurator
				id="exclude"
				validationType={validationType}
				selectedTypeId={selectedTypeId}
				label={`${label} Exclude`}
				matchModeValue={values.excludeFormat}
				textValue={values.exclude}
				onChange={handleChange}
			/>
			<Grid item xs={12} sm={1}></Grid>
		</>
	);
}

export default ValidationValueConfigurator;