import React from 'react';
import { Box, Button, Link } from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'User-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const UserTableComponent = ({ user }) => {
	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'companyName',
			headerName: 'Company',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'companyTelephone',
			headerName: 'Company tel.',
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'lastLoginDate',
			headerName: 'Last login',
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row) => {
				if (!row.lastLoginDate) return '';
				return new Date(row.lastLoginDate).toLocaleString({}, {dateStyle: 'short', timeStyle: 'short'});
			}
		},
		{
			field: 'lastActivityDate',
			headerName: 'Last activity',
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row) => {
				if (!row.lastActivityDate) return '';
				return new Date(row.lastActivityDate).toLocaleString({}, {dateStyle: 'short', timeStyle: 'short'});
			}
		},
		{
			field: 'isApproved',
			headerName: 'Approved',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'isOnLine',
			headerName: 'Active',
			minWidth: 80,
			flex: 0.5,
			type: 'boolean'
		},
		{
			field: 'id',
			headerName: 'Actions',
			type: 'actions',
			minWidth: 80,
			flex: 0.5,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<Button
					color="primary"
					variant="outlined"
					size="small"
					component={Link}
					href={'https://edocs-site-passport.azurewebsites.net/admin/EditUserPartial/' + params.row.email}
					target="_blank"
				>
					Edit
				</Button>
			)
		},
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={user}
				columns={columns}
				rowHeight={42}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
				autoHeight
			/>
		</Box>
	);
};

export default UserTableComponent;