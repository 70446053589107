import React from 'react';
import { Grid } from '@mui/material';

function SpacedGrid({ children }) {
	return (
		<Grid item container spacing={1} alignItems="center">
			{React.Children.map(children, (child, index) => (
				<Grid item key={index}>
					{child}
				</Grid>
			))}
		</Grid>
	);
}

export default SpacedGrid;