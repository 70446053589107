import React from 'react';
import { Button, Grid, Card, CardHeader, CardContent, Stack, Typography } from '@mui/material';
import { getSiteId } from '../../../api/configManager';
import { SecurityButton, ImageMapEditor } from '../../ui';
import QuickLinksTable from './QuickLinksTable';
import ImageList from './ImageList';
import AddressCard from './AddressCard';
import PanoramaTable from './PanoramaTable';
import SiteBrandingInfo from './SiteBrandingInfo';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

function LocationDetails({ location, onEdit, onEditSite }) {
	let locProperties = location.locationProperties;

	let isRoot = location.id === getSiteId();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Stack direction='row' spacing={1}>
					<SecurityButton
						featureName={SECURITY_FEATURES.ADMIN_ONLY}
						variant="contained"
						color="primary"
						onClick={onEdit}>
						Edit
					</SecurityButton>
					{isRoot &&
						<SecurityButton
							featureName={SECURITY_FEATURES.ADMIN_ONLY}
							variant="contained"
							color="primary"
							onClick={onEditSite}>
							Edit Site
						</SecurityButton>
					}
				</Stack>
			</Grid>
			<Grid container item xs={12} md={6} spacing={1}>
				<Grid item xs={12}>
					<Card>
						<CardHeader
							title={<Typography variant="h6">General Information</Typography>}
						/>
						<CardContent>
							<Typography variant="body1"><strong>Name:</strong> {location.name}</Typography>
							<Typography variant="body1"><strong>Project Name:</strong> {locProperties.overrideProjectName}</Typography>
							<Typography variant="body1"><strong>Site Name:</strong> {locProperties.overrideSiteName}</Typography>
							<Typography variant="body1"><strong>Classification:</strong> {locProperties.classificationName}</Typography>
							<Typography variant="body1"><strong>Linear Units:</strong> {locProperties.linearUnits}</Typography>
							<Typography variant="body1"><strong>Area Units:</strong> {locProperties.areaUnits}</Typography>
							<Typography variant="body1"><strong>Volume Units:</strong> {locProperties.volumeUnits}</Typography>
							<Typography variant="body1"><strong>Currency Units:</strong> {locProperties.currencyUnits}</Typography>
							<Typography variant="body1"><strong>Area Measurement:</strong> {locProperties.areaMeasurement}</Typography>
							<Typography variant="body1"><strong>Description:</strong> {locProperties.description}</Typography>
							<Typography variant="body1"><strong>Project Description:</strong> {locProperties.projectDescription}</Typography>
							<Typography variant="body1"><strong>Site Description:</strong> {locProperties.siteDescription}</Typography>
							<Typography variant="body1"><strong>Path:</strong> Demonstration Building &gt; {locProperties.name}</Typography>
							<Typography variant="body1"><strong>Map Marker:</strong> {locProperties.mapMarker}</Typography>
							{isRoot && <SiteBrandingInfo />}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<ImageList images={locProperties.images} />
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6} spacing={1}>
				<Grid item xs={12}>
					<AddressCard address={locProperties.address} />
				</Grid>
				<Grid item xs={12}>
					<QuickLinksTable quickLinks={locProperties.quickLinks} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ImageMapEditor mapData={locProperties.imageMap} />
			</Grid>
			<Grid item xs={12}>
				<PanoramaTable panoramas={locProperties.panoramas} />
			</Grid>
		</Grid>
	)
};

export default LocationDetails;