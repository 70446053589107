import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Grid,
	FormControlLabel,
	Typography,
	Stack,
	Switch,
} from '@mui/material';
import {
	ArrayStringTextArea,
	BasicTxtField,
	CustomCKEditor,
	FilterFormComponent,
	SelectDropdown,
	SBCheckBoxField,
} from '../../ui';
import ValidationValueConfigurator from './ValidationValueConfigurator';
import {
	AttrConfigInputTypeList,
	AttrConfigFormFilterDict
} from '../../../constants/LOIConstants';
import DataConfigBuilder from './DataConfigBuilder/DataConfigBuilder';
import { LOIContext } from '../../../contexts/';

function AttributeConfigModal({ initialData, open, onClose, selectedLOI, setNameList }) {
	const [attrConfigData, setAttrConfigData] = useState(initialData);
	const [attrConfigValidation, setAttrConfigValidation] = useState(initialData?.validation);
	const { updateAttrConfigData } = useContext(LOIContext);

	useEffect(() => {
		let newData = JSON.parse(JSON.stringify(initialData));
		console.log("NEW CONFIG DATA:", newData);
		setAttrConfigData(newData);
		setAttrConfigValidation(newData?.validation);
	}, [initialData]);

	const handleDataChange = (newValue, propertyName) => {
		console.log("DATA CHANGE:", propertyName, " VALUE: ", newValue);
		setAttrConfigData(prevConfigData => ({ ...prevConfigData, [propertyName]: newValue }));
	};

	const handleValidationChange = (newValue, propertyName) => {
		setAttrConfigValidation(prevConfigValidation => ({ ...prevConfigValidation, [propertyName]: newValue }));
	};

	const handleSaveClick = () => {
		onClose();
		let updatedAttrData = {
			...attrConfigData,
			validation: { ...attrConfigValidation }
		};
		updateAttrConfigData(updatedAttrData, selectedLOI);
		//props.onSaveClick();
	};

	if (!attrConfigData) {
		return null;
	}

	//console.log("Attr config", attrConfigData);
	//console.log("Attr config valid", attrConfigValidation);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xl"
		>
			<DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
				{attrConfigData.name}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Stack spacing={1}>
							<Typography variant="h6">
								Details
							</Typography>
							<BasicTxtField
								id="alias"
								value={attrConfigData.alias}
								onChange={handleDataChange}
								placeholder="Enter alias"
								fullWidth
							/>
							<BasicTxtField
								id="clientReference"
								label="Client Reference"
								value={attrConfigData.clientReference}
								onChange={handleDataChange}
								placeholder="Enter client reference"
								fullWidth
							/>
							<Divider />
							<Typography variant="h6">
								Input Type and Format
							</Typography>
							<SelectDropdown
								id={"renderType"}
								label={"Input Type"}
								value={attrConfigData.renderType}
								options={AttrConfigInputTypeList}
								onChange={handleDataChange}
								fullWidth
							/>
							<FilterFormComponent
								selectedId={attrConfigData.renderType}
								allowedIds={AttrConfigFormFilterDict["maskedDataConfig"]}
							>
								<DataConfigBuilder
									initialData={attrConfigData.attributeLookupConfig}
									selectedLOI={selectedLOI}
									onApplyConfig={(value) => handleDataChange(value, "attributeLookupConfig")}
								/>
							</FilterFormComponent>
							<FilterFormComponent
								selectedId={attrConfigData.renderType}
								allowedIds={AttrConfigFormFilterDict["inputFormat"]}
							>
								<BasicTxtField
									id="inputFormat"
									label="Format"
									value={attrConfigValidation.inputFormat}
									onChange={handleValidationChange}
									placeholder="Enter format"
									fullWidth
								/>
							</FilterFormComponent>
							<FilterFormComponent
								selectedId={attrConfigData.renderType}
								allowedIds={AttrConfigFormFilterDict["maxLength"]}
							>
								<BasicTxtField
									id="maxInputLength"
									label="Max length"
									value={attrConfigValidation.maxInputLength}
									onChange={handleValidationChange}
									placeholder="Enter max length"
									fullWidth
								/>
							</FilterFormComponent>
							<FilterFormComponent
								selectedId={attrConfigData.renderType}
								allowedIds={AttrConfigFormFilterDict["allowedUnits"]}
							>
								<ArrayStringTextArea
									label="Allowed Units"
									variant="outlined"
									value={attrConfigValidation.allowedUnits}
									onChange={(value) => handleValidationChange(value, "allowedUnits")}
									placeholder="Enter allowed units"
									minRows={4}
								/>
							</FilterFormComponent>
							<FilterFormComponent
								selectedId={attrConfigData.renderType}
								allowedIds={AttrConfigFormFilterDict["selectedClassificationSetName"]}
							>
								<SelectDropdown
									id={"selectedClassificationSetName"}
									label={"Set Name"}
									value={attrConfigData.selectedClassificationSetName}
									options={setNameList}
									onChange={handleDataChange}
									fullWidth
								/>
							</FilterFormComponent>
							<ArrayStringTextArea
								label="Ghost Text"
								variant="outlined"
								value={attrConfigData.attributeLookupConfig}
								onChange={(value) => handleDataChange(value, "attributeLookupConfig")}
								minRows={4}
							/>
							<Divider />
						</Stack>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container spacing={1}>
							<ValidationValueConfigurator
								selectedTypeId={attrConfigData.renderType}
								validationType={"required"}
								label={"Required"}
								values={attrConfigValidation.requiredValues}
								onChange={handleValidationChange}
							/>
							<Divider />
							<ValidationValueConfigurator
								selectedTypeId={attrConfigData.renderType}
								validationType={"optional"}
								label={"Optional"}
								values={attrConfigValidation.optionalValues}
								onChange={handleValidationChange}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Stack spacing={1}>
							<Typography variant="h6">
								Guidance
							</Typography>
							<CustomCKEditor
								id="guidance"
								data={attrConfigData.guidance}
								onChange={handleDataChange}
							/>
							<Divider />
							<Typography variant="h6">
								Options
							</Typography>
							<SBCheckBoxField
								id="enforceValidInput"
								label="Prevent Save if Invalid"
								checked={attrConfigValidation.enforceValidInput}
								onChange={handleValidationChange}
							/>
							<SBCheckBoxField
								id="excludeFromCObie"
								label="Exclude from COBie"
								checked={attrConfigData.excludeFromCObie}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="checkUnique"
								label="Check Unique"
								checked={attrConfigValidation.checkUnique}
								onChange={handleValidationChange}
							/>
							<SBCheckBoxField
								id="visibleOnLoad"
								label="Visible on load"
								checked={attrConfigData.visibleOnLoad}
								onChange={handleDataChange}
							/>
							<SBCheckBoxField
								id="showInComponentViewer"
								label="Show in Component Viewer"
								checked={attrConfigData.showInComponentViewer}
								onChange={handleDataChange}
							/>

						</Stack>
					</Grid>
				</Grid>

			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSaveClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Update
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default AttributeConfigModal;
/*
<Grid item xs={12} sm={4}>
	<Grid container spacing={1}>
		<Grid item xs={12} sm={1}></Grid>
		<Grid item xs={12} sm={5} container>
			<Typography variant="h6">{label}</Typography>
			<Stack spacing={1}>
				<SelectDropdown
					id={`matchMode-${id}`}
					label="Match Mode"
					value={matchModeValue}
					options={matchModeList} // Assume this is a prop or imported
					onChange={(value) => onChange(`${id}Format`, value)} // Assume this function is passed as a prop or defined
					fullWidth
					setToDefault={false}
				/>
				<ArrayTextArea
					label={`Match ${id}`}
					variant="outlined"
					value={textValue}
					onChange={(value) => onChange(id, value)}
					minRows={4}
					placeholder={`Enter ${label}`}
				/>
			</Stack>
		</Grid>
		<Grid item xs={12} sm={1}></Grid>
		<Grid item xs={12} sm={5} container>
			<Typography variant="h6">{label}</Typography>
			<Stack spacing={1}>
				<SelectDropdown
					id={`matchMode-${id}`}
					label="Match Mode"
					value={matchModeValue}
					options={matchModeList} // Assume this is a prop or imported
					onChange={(value) => onChange(`${id}Format`, value)} // Assume this function is passed as a prop or defined
					fullWidth
					setToDefault={false}
				/>
				<ArrayTextArea
					label={`Match ${id}`}
					variant="outlined"
					value={textValue}
					onChange={(value) => onChange(id, value)}
					minRows={4}
					placeholder={`Enter ${label}`}
				/>
			</Stack>
		</Grid>
	</Grid>
</Grid>*/