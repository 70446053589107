import { useState, useCallback } from 'react';

// A custom hook that abstracts the loading and error handling logic
export function useDataLoader() {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const loadData = useCallback(async (asyncFunc, ...params) => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await asyncFunc(...params);
			return data; // Return the fetched data for further use
		} catch (e) {
			setError(e);
			return null;
		} finally {
			setIsLoading(false);
		}
	}, []);

	return { isLoading, error, loadData };
}