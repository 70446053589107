import { Typography } from '@mui/material';

const TwoLineText = ({ children }) => {
	return (
		<Typography sx={{
			lineHeight: '1.5em',
			height: '3em', // 2x line-height
			overflow: 'hidden',
			whiteSpace: 'pre-line',
			display: '-webkit-box',
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
		}} variant="h6" >
			{children}
		</Typography >
	);
}

export default TwoLineText;