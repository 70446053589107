import { DEV_TEST_MODE, loadJsonFile, downloadJsonData, getFakeQueueIdObj } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';


// Classifications:
export async function getClassificationsByProjectId(projectId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getClassificationsByProjectId.json');
		}
		const classificationsEndpoint = endpoints.projectClassifications.getByProjectId(projectId);
		let data = await springboardApiRequest(classificationsEndpoint, "GET");
		console.log("Project Classifications DATA:", data);
		downloadJsonData(data.data, "getClassificationsByProjectId");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching Project Classifications data :', error);
		throw new Error('Failed to update Project Classifications data');
	}
}

export async function getClassificationCodesByGroupId(groupId) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getClassificationCodesByGroupId.json');
		}
		let data = await springboardApiRequest(endpoints.projectClassifications.getCodesByGroupId(groupId), "GET");
		console.log("Classification Codes DATA:", data);
		downloadJsonData(data.data, "getClassificationCodesByGroupId");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching classification codes data :', error);
		throw new Error('Failed to update classification codes data');
	}
}

export async function updateProjectClassification(projectClassification) {
	try {
		console.log("Update Project Classification: ", projectClassification);
		if (DEV_TEST_MODE) {
			return projectClassification;
		}

		const projectClassificationEndpoint = endpoints.projectClassifications.update();
		let data = await springboardApiRequest(projectClassificationEndpoint, "POST", projectClassification);
		console.log("Update Project Classification DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while updating Project Classification data:', error);
		throw new Error('Failed to update Project Classification data.');
	}
}

export async function createProjectClassification(projectClassification) {
	try {
		console.log("Create Project Classification: ", projectClassification);
		if (DEV_TEST_MODE) {
			return projectClassification;
		}

		const projectClassificationEndpoint = endpoints.projectClassifications.create();
		let data = await springboardApiRequest(projectClassificationEndpoint, "POST", projectClassification);
		console.log("Create Project Classification DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while creating Project Classification data:', error);
		throw new Error('Failed to create Project Classification data.');
	}
}

export async function deleteProjectClassification(projectClassification) {
	try {
		console.log("Delete Project Classification: ", projectClassification);
		if (DEV_TEST_MODE) {
			return projectClassification;
		}

		const projectClassificationEndpoint = endpoints.projectClassifications.delete(projectClassification.id);
		let data = await springboardApiRequest(projectClassificationEndpoint, "POST", projectClassification);
		console.log("Delete Project Classification DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while deleting Project Classification data:', error);
		throw new Error('Failed to delete Project Classification data.');
	}
}

export async function saveProjectConfigAsTemplate(templateData) {
	try {
		console.log("Save Project Config As template: ", templateData);
		if (DEV_TEST_MODE) {
			return templateData;
		}

		const endpoint = endpoints.siteConfig.saveProjectConfigAsTemplate();
		let data = await springboardApiRequest(endpoint, "POST", templateData);
		console.log("saveProjectConfigAsTemplate DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while performing action saveProjectConfigAsTemplate:', error);
		throw new Error('Failed to saveProjectConfigAsTemplate.');
	}
}

export async function applyProjectClassificationConfig(templateData) {
	try {
		console.log("applyProjectClassificationConfig: ", templateData);
		if (DEV_TEST_MODE) {
			return true;
		}

		const endpoint = endpoints.siteConfig.applyProjectClassificationConfig();
		let data = await springboardApiRequest(endpoint, "POST", templateData);
		console.log("applyProjectClassificationConfig DATA:", data.data);
		return data.data;
	} catch (error) {
		console.error('Error while performing action applyProjectClassificationConfig:', error);
		throw new Error('Failed to applyProjectClassificationConfig.');
	}
}


export async function loadAllClassifications() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadAllClassifications.json');
		}
		const classificationsEndpoint = endpoints.siteConfig.getAllClassifications();
		let data = await springboardApiRequest(classificationsEndpoint, "GET");
		console.log("Classifications DATA:", data);
		downloadJsonData(data.data, "loadAllClassifications");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching Classifications data :', error);
		throw new Error('Failed to update Classifications data');
	}
}

export async function loadAllClassificationTemplates() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadAllClassificationTemplates.json');
		}
		const classificationsEndpoint = endpoints.siteConfig.getAllTemplates();
		let data = await springboardApiRequest(classificationsEndpoint, "GET");
		console.log("Classification Templates DATA:", data);
		downloadJsonData(data.data, "loadAllClassificationTemplates");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching Classification Templates data :', error);
		throw new Error('Failed to update Classification Templates data');
	}
}
