import { deleteBranch } from "./AdminApi";

const apiMessages = {
	fileCategories: {
		get: {
			success: "Successfully fetched file categories",
			error: "Error while fetching file categories"
		},
		create: {
			success: "Successfully created file category",
			error: "Error while creating file category"
		},
		update: {
			success: "Successfully updated file category",
			error: "Error while updating file category"
		}
	},
	fileTags: {
		create: {
			success: "Successfully created file tag",
			error: "Error while creating file tag"
		},
		update: {
			success: "Successfully updated file tag",
			error: "Error while updating file tag"
		}
	},
	file: {
		processImageMapImage:
		{
			success: "Image map has been added to a process queue",
			error: "Error while deleting site image"
		},
		processSiteImage:
		{
			success: "Successfully uploaded site images",
			error: "Error while uploading site images"
		},
		processSiteBrandingImage:
		{
			success: "Successfully uploaded site branding image",
			error: "Error while uploading site branding image"
		},
		deleteSiteImage:
		{
			success: "Successfully deleted site image",
			error: "Error while deleting site image"
		},
		deleteImageMapImage:
		{
			success: "Successfully deleted image map image",
			error: "Error while deleting image map image"
		}
	},
	site: {
		update: {
			success: "Successfully updated site data",
			error: "Error while updating site data"
		}
	},
	siteLocations: {
		addLocation: {
			success: "Successfully added site location",
			error: "Error while adding site location"
		},
		deleteBranch: {
			success: "Successfully removed site location branch",
			error: "Error while removing site location branch"
		},
		moveLocation: {
			success: "Successfully moved site location branch",
			error: "Error while moving site location branch"
		},
		createQuickLink: {
			success: "Successfully created Quicklink",
			error: "Error while creating Quicklink"
		},
		updateQuickLink: {
			success: "Successfully updated Quicklink",
			error: "Error while updating Quicklink"
		},
		deleteQuickLink:
		{
			success: "Successfully deleted Quicklink",
			error: "Error while deleting Quicklink"
		},
		updateLocation:
		{
			success: "Successfully updated site location data!",
			error: "Error while updating a site location"
		},
		updateImageMapPoints:
		{
			success: "Successfully updated map points data",
			error: "Error while updating mappoint data"
		}
	},
	documentTeamMembers:
	{
		addRoles:
		{
			success: "Successfully added roles to users for document",
			error: "Error while adding roles to users for document"
		},
		removeRoles:
		{
			success: "Successfully removed roles for users for document",
			error: "Error while removing roles for users for document"
		},
		setAccessExpiration:
		{
			success: "Successfully set access expiration for users for document",
			error: "Error while setting access expiration for users for document"
		},
		setRoleDescription:
		{
			success: "Successfully set role description for users for document",
			error: "Error while setting role description for users for document"
		},
		removeAccess:
		{
			success: "Successfully removed access for users for document",
			error: "Error while removing access for users for document"
		}
	}
};

export default apiMessages;