import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@mui/material';


function PreviewChangesModal(props) {

	const handleSaveClick = () => {
		props.onSaveClick();
	};

	return (
		<Dialog
			open={props.open}
		  onClose={props.onClose}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle>{"Preview Changes"}</DialogTitle>
			<DialogContent>
				{props.children}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={props.onClose}
					color="error">
					Cancel
				</Button>
				<Button
					onClick={handleSaveClick}
					variant="contained"
					color={"success"}
					autoFocus>
					Confirm Changes
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PreviewChangesModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSaveClick: PropTypes.func.isRequired,
	children: PropTypes.node
};


export default PreviewChangesModal;