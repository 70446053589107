import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormHelperText,
	Typography,
	Stack,
} from '@mui/material';
import {
	SelectDropdown,
	BasicTxtField,
	CustomCKEditor,
} from '../../ui';
import { WorkflowsContext, HelpContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	title: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	description: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	durationDays: [Validation.isNotNumber],
	position: [Validation.isNotNumber],
	weighting: [Validation.isNotNumber]
}

const styles = {
	formHelperText: { margin: '0 14px' }
};

const WorkflowStepEditPage = ({ setEditStep, workflowData, getapis, stepData }) => {
	console.log('stepData', stepData);
	console.log('workflowData', workflowData);

	const [busy, setBusy] = useState(false);
	const [step, setStep] = useState({
		workFlowId: workflowData.id,
		id: stepData.id,
		title: stepData.title,
		description: stepData.description,
		generateStepAction: stepData.generateStepAction,
		durationDays: stepData.durationDays,
		colour: stepData.colour,
		isCommenting: stepData.isCommenting,
		isMilestone: stepData.isMilestone,
		isDisplayed: stepData.isDisplayed,
		position: stepData.position,
		isReportedProcess: stepData.isReportedProcess,
		isReportedContent: stepData.isReportedContent,
		isAutomatedStepChange: stepData.isAutomatedStepChange,
		automatedStepChangeOption: stepData.automatedStepChangeOption,
		forecastCompletionStartStep: stepData.forecastCompletionStartStep,
		forecastCompletionReportEnabled: stepData.forecastCompletionReportEnabled,
		weighting: stepData.weighting,
		forceShowGuidance: stepData.forceShowGuidance,
		actionTemplate: 0,
		notificationTitle: stepData.notificationTitle,
		notificationMessage: stepData.notificationMessage,
	});
	const [formErrors, setFormErrors] = useState({});
	const [actionTemplates, setActionTemplates] = useState([]);
	const [stepChangeOptions, setStepChangeOptions] = useState([]);
	const [help, setHelp] = useState({});

	const { updateWorkflowStep, loadActionTemplates, loadStepChangeOptions } = useContext(WorkflowsContext);
	const { loadHelpByTitles } = useContext(HelpContext);

	// useEffect(() => {
	// 	console.log('edited step', step);
	// }, [step])

	useEffect(() => {
		setBusy(true);

		const helpTitles = [
			'GenerateStepAction',
			'workflowStepActionSubject',
			'workflowStepActionMessage',
			'workflowStepDuration',
			'workflowStepColour',
			'workflowstepIsCommenting',
			'workflowStepDisplayed',
			'workflowStepOrder',
			'IsReportedProcess',
			'IsReportedContent',
			'isAutomatedStepChange',
			'forecastCompletionStartStep',
			'forecastCompletionReportEnabled',
			'forceGuidance',
			'stepWeighting'
		];

		async function getApis() {
			const responses = await Promise.all([
				loadActionTemplates(),
				loadStepChangeOptions(),
				loadHelpByTitles(helpTitles),
			]);
			console.log("LOADING TEMPLATES");
			const actionTemplatesData = await responses[0];
			const stepChangeOptionsData = await responses[1];
			const helpData = await responses[2];

			setActionTemplates(actionTemplatesData);
			setStepChangeOptions(stepChangeOptionsData);
			setHelp({
				generateStepAction: getHelpText(helpData, 'GenerateStepAction'),
				workflowStepActionSubject: getHelpText(helpData, 'workflowStepActionSubject'),
				workflowStepActionMessage: getHelpText(helpData, 'workflowStepActionMessage'),
				workflowStepDuration: getHelpText(helpData, 'workflowStepDuration'),
				workflowStepColour: getHelpText(helpData, 'workflowStepColour'),
				workflowstepIsCommenting: getHelpText(helpData, 'workflowstepIsCommenting'),
				workflowStepDisplayed: getHelpText(helpData, 'workflowStepDisplayed'),
				workflowStepOrder: getHelpText(helpData, 'workflowStepOrder'),
				isReportedProcess: getHelpText(helpData, 'IsReportedProcess'),
				isReportedContent: getHelpText(helpData, 'IsReportedContent'),
				isAutomatedStepChange: getHelpText(helpData, 'isAutomatedStepChange'),
				forecastCompletionStartStep: getHelpText(helpData, 'forecastCompletionStartStep'),
				forecastCompletionReportEnabled: getHelpText(helpData, 'forecastCompletionReportEnabled'),
				forceGuidance: getHelpText(helpData, 'forceGuidance'),
				stepWeighting: getHelpText(helpData, 'stepWeighting'),
			});

			setBusy(false);
		}

		getApis();
	}, [loadActionTemplates, loadStepChangeOptions, loadHelpByTitles]);

	const getHelpText = (helpData, target) => {
		const match = helpData.find(({ title }) => title === target);
		if (match) return match.helpText;
		else return null;
	};

	const handleDataChange = (propertyName, newValue) => {
		if (propertyName === 'actionTemplate') {
			setStep(prevData => ({
				...prevData,
				notificationTitle: actionTemplates[newValue].subject,
				notificationMessage: actionTemplates[newValue].message
			})
			);
		}
		if (propertyName === 'isAutomatedStepChange' && newValue === false) {
			setStep(prevData => ({
				...prevData,
				automatedStepChangeOption: 0,
			})
			);
		}

		setStep(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const stepData = await updateWorkflowStep(step);

		if (!stepData) {
			return setBusy(false);
		}
		else {
			setEditStep(false);
			getapis();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(step.title, "title", formValidators, newFormErrors);
		Validation.setFieldErrors(step.description, "description", formValidators, newFormErrors);
		Validation.setFieldErrors(step.durationDays, "durationDays", formValidators, newFormErrors);
		Validation.setFieldErrors(step.position, "position", formValidators, newFormErrors);
		Validation.setFieldErrors(step.weighting, "weighting", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3, '.ck-editor': { mt: 2, mb: 1 }, '.ck-editor__editable': { minHeight: '150px' } }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between" alignItems="flex-end">
							<Typography variant="body1">Edit workflow step</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<BasicTxtField
							id={"Title"}
							label="Title"
							value={step.title}
							onChange={(value) => handleDataChange("title", value)}
							fullWidth={true}
							errors={formErrors['title']}
							required
						/>
						<BasicTxtField
							id={"Description"}
							label="Description"
							value={step.description}
							onChange={(value) => handleDataChange("description", value)}
							fullWidth={true}
							errors={formErrors['description']}
							required
						/>
						<Divider />
						<Typography variant="body1">Action configuration</Typography>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.generateStepAction}
									onChange={(value) => handleDataChange("generateStepAction", value.target.checked)}
								/>}
							label="Generate workflow action step"
						/>
						<FormHelperText style={styles.formHelperText}>{help.generateStepAction}</FormHelperText>
						<SelectDropdown
							id={"Action templates"}
							value={step.actionTemplate}
							onChange={(value) => handleDataChange("actionTemplate", value)}
							options={actionTemplates}
							usePlaceholder={false}
							fullWidth={true}
						/>
						<BasicTxtField
							id={"Action subject"}
							label="Action subject"
							value={step.notificationTitle || ''}
							onChange={(value) => handleDataChange("notificationTitle", value)}
							fullWidth={true}
							helperText={help.workflowStepActionSubject}
						/>
						<CustomCKEditor
							id="notificationMessage"
							data={step.notificationMessage}
							onChange={(data, id) => handleDataChange("notificationMessage", data)}
						/>
						<FormHelperText style={styles.formHelperText}>{help.workflowStepActionMessage}</FormHelperText>
						<Divider />
						<Typography variant="body1">Setup configuration</Typography>
						<BasicTxtField
							id={"Duration days"}
							label="Duration days"
							value={step.durationDays}
							onChange={(value) => handleDataChange("durationDays", value)}
							fullWidth={true}
							helperText={help.workflowStepDuration}
							errors={formErrors['durationDays']}
							type="number"
							required
						/>
						<BasicTxtField
							id={"Colour"}
							label="Colour"
							value={step.colour}
							onChange={(value) => handleDataChange("colour", value)}
							fullWidth={true}
							helperText={help.workflowStepColour}
							type="color"
						/>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isCommenting}
									onChange={(value) => handleDataChange("isCommenting", value.target.checked)}
								/>}
							label="Is commenting"
						/>
						<FormHelperText style={styles.formHelperText}>{help.workflowstepIsCommenting}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isMilestone}
									onChange={(value) => handleDataChange("isMilestone", value.target.checked)}
								/>}
							label="Is displayed"
						/>
						<FormHelperText style={styles.formHelperText}>{help.workflowStepDisplayed}</FormHelperText>
						<BasicTxtField
							id={"Order"}
							label="Order"
							value={step.position}
							onChange={(value) => handleDataChange("position", value)}
							fullWidth={true}
							helperText={help.workflowStepOrder}
							errors={formErrors['position']}
							type="number"
							required
						/>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isReportedProcess}
									onChange={(value) => handleDataChange("isReportedProcess", value.target.checked)}
								/>}
							label="Report process"
						/>
						<FormHelperText style={styles.formHelperText}>{help.isReportedProcess}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isReportedContent}
									onChange={(value) => handleDataChange("isReportedContent", value.target.checked)}
								/>}
							label="Report content"
						/>
						<FormHelperText style={styles.formHelperText}>{help.isReportedContent}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.isAutomatedStepChange}
									onChange={(value) => handleDataChange("isAutomatedStepChange", value.target.checked)}
								/>}
							label="Is automated step change"
						/>
						<FormHelperText style={styles.formHelperText}>{help.isAutomatedStepChange}</FormHelperText>
						{step.isAutomatedStepChange && <SelectDropdown
							id={"Automated step change on..."}
							value={step.automatedStepChangeOption || 0}
							onChange={(value) => handleDataChange("automatedStepChangeOption", value)}
							options={stepChangeOptions}
							usePlaceholder={false}
							fullWidth={true}
						/>}
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.forecastCompletionStartStep}
									onChange={(value) => handleDataChange("forecastCompletionStartStep", value.target.checked)}
								/>}
							label="Forecast completion start step"
						/>
						<FormHelperText style={styles.formHelperText}>{help.forecastCompletionStartStep}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.forecastCompletionReportEnabled}
									onChange={(value) => handleDataChange("forecastCompletionReportEnabled", value.target.checked)}
								/>}
							label="Forecast completion report enabled"
						/>
						<FormHelperText style={styles.formHelperText}>{help.forecastCompletionReportEnabled}</FormHelperText>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={step.forceShowGuidance}
									onChange={(value) => handleDataChange("forceShowGuidance", value.target.checked)}
								/>}
							label="Force guidance to be displayed"
						/>
						<FormHelperText style={styles.formHelperText}>{help.forceGuidance}</FormHelperText>
						<BasicTxtField
							id={"Weighting"}
							label="Weighting"
							value={step.weighting}
							onChange={(value) => handleDataChange("weighting", value)}
							fullWidth={true}
							helperText={help.weighting}
							type="number"
							errors={formErrors['stepWeighting']}
							required
						/>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setEditStep(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)

}
export default WorkflowStepEditPage;