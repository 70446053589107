

// Facilities are only ever one layer deep in the site location tree
export const getFacilitiesList = (siteLocations) => {
	const facilityList = []
	siteLocations.forEach(location => {
		facilityList.push({
			id: location.id,
			name: location.name
		});
		location.locations.forEach(child => {
			facilityList.push({
				id: child.id,
				name: child.name
			});
		});
	});
	return facilityList;
}

// Finds the relevant deeply nested site location in tree
function findSiteLocationById(siteLocations, id) {
	for (const item of siteLocations) {
		if (item.id === id) {
			return item;
		}

		if (item.locations && item.locations.length > 0) {
			const found = findSiteLocationById(item.locations, id);
			if (found) {
				return found;
			}
		}
	}
	return null;
}

const updateSubformList = (formList, updatedItem) => {
	return formList.map(item => (item.id === updatedItem.id ? updatedItem : item));
}

const deleteFromSubformList = (formList, id) => {
	return formList.filter(item => item.id === id);
}

const deleteIdsFromSubformList = (formList, ids) => {
	return formList.filter((item) => !ids.includes(item.id));
}

const addToSubformList = (formList, newItem) => {
	return [...formList, { ...newItem}];
}

const deleteImageMapImageHelper = (subform) => {
	return {
		...subform,
		id: "00000000-0000-0000-0000-000000000000",
		url: null
	}
}

const updateLocationSubform = (siteLocations, siteLocationId, subFormName, updateFunc, newSubFormItem) => {
	// Deep copy to ensure immutability
	const newSiteLocations = JSON.parse(JSON.stringify(siteLocations));
	const itemToUpdate = findSiteLocationById(newSiteLocations, siteLocationId);

	if (itemToUpdate) {
		itemToUpdate.locationProperties = {
			...itemToUpdate.locationProperties,
			[subFormName]: updateFunc(itemToUpdate.locationProperties[subFormName], newSubFormItem)
		}
	}
	return newSiteLocations;
};

export const createQuickLink = (siteLocations, siteLocationId, quickLink) => {
	return updateLocationSubform(siteLocations, siteLocationId, "quickLinks", addToSubformList, quickLink);
}

export const updateQuickLinks = (siteLocations, siteLocationId, quickLink) => {
	return updateLocationSubform(siteLocations, siteLocationId, "quickLinks", updateSubformList, quickLink);
}

export const deleteQuickLink = (siteLocations, siteLocationId, quickLinkId) => {
	return updateLocationSubform(siteLocations, siteLocationId, "quickLinks", deleteFromSubformList, quickLinkId);
}

export const addSiteImage = (siteLocations, siteLocationId, imageData) => {
	return updateLocationSubform(siteLocations, siteLocationId, "images", addToSubformList, imageData);
}

export const deleteSiteImages = (siteLocations, siteLocationId, imageIds) => {
	return updateLocationSubform(siteLocations, siteLocationId, "images", deleteIdsFromSubformList, imageIds);
}

export const deleteImageMapImage = (siteLocations, siteLocationId) => {
	return updateLocationSubform(siteLocations, siteLocationId, "images", deleteImageMapImageHelper);
}