
export const DEV_TEST_MODE = false;//process.env.REACT_APP_TEST_MODE === "dev";
export const DEV_SIM_LOAD_SERVER_RESPONSE_TIME = 50;
export const DEV_SIM_POST_SERVER_RESPONSE_TIME = 1000;


// ## DEVELOPER TESTING:
export async function loadJsonFile(filename) {
	let response = await fetch(`${filename}`);
	if (!response.ok) {
		throw new Error('Network response was not ok');
	}
	await simulateServerDelay(DEV_SIM_LOAD_SERVER_RESPONSE_TIME);
	let data = await response.json();
	console.log(data);
	return data;
}

export async function simulateServerDelay(delayTime) {
	return await new Promise((resolve) => setTimeout(resolve, delayTime));
}

// Used for Developer testing
export function getFakeQueueIdObj() {
	return {
		queueId: "testID"
	}
}

// Function used to temporarily save server JSON data for offline use.
export function downloadJsonData(theData, filename) {
	return; // ignore
	let jsonData = JSON.stringify(theData, null, 2); // Convert it to JSON
	let blob = new Blob([jsonData], { type: "application/json" });

	let a = document.createElement('a');
	a.href = URL.createObjectURL(blob);
	a.download = `${filename}.json`;
	a.click();
}
