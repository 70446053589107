import React, { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { LOIContext, SiteDataContext } from '../../contexts';
import * as LOIGridHelpers from '../../helpers/AgGrid/LOIGridHelpers';
import {
	LOIDataCellRenderer,
	AttributeConfigCellRenderer
} from '../DataGridComponents/CellRenderers';
import { AttributeConfigModal } from '../modals/AttributeConfigModal/';
import AddEditLOIModal from '../modals/AddEditLOIModal';
import BulkAddLOIModal from '../modals/BulkAddLOIModal/BulkAddLOIModal';
import { isEmpty } from '../../helpers/GeneralHelpers';
import AgGridBaseStyle from './AgGridBaseStyle';
import {
	SelectDropdown,
	LoadingSpinner
} from '../ui';
import * as LOIConstants from '../../constants/LOIConstants';

// TODO:
// Ensure data is sent to add function about what the row /col is...
// On click on add, open up modal or similar with data to be filled out.
// Once save clicked, call function in db provider and update LOI data accordingly.
// Next, can work on edit attribute?
// Create cell renderer for the attribute header
// Add callback function on click
// load up edit attribute modal or similar on click of this.

// SITE LOI:
// Create list of all projects that have isSiteLOI and only use that
// When project is selected, find relevant LOI name in list
// If site is selected, filter sublist to only include LOI's with that project
// if only 1 is relevant, then load it, else, wait for selection? or load first?

var appStart = Date.now();

const ATTR_CONFIG_HEADER_HEIGHT = 40;

const classList = [
	{
		id: "1",
		name: "SR"
	},
	{
		id: "2",
		name: "PR"
	},
	{
		id: "3",
		name: "XR"
	}
];
const AgGridLOIView = ({ loiData, loiAttributes, loiClassifications, selectedLOI, updateColumnOrder, isProjectLOI, featureAllowed }) => {
	const { updateLOIData, loadLoiMaskedDataConfig } = useContext(LOIContext);
	const { structuralNames } = useContext(SiteDataContext);
	const setNameList = useMemo(() => {
		return LOIGridHelpers.getSetNameOptionsFromClassifications(loiClassifications);
	}, [loiClassifications]);
	const structNamesList = useMemo(() => {
		return Object.values(structuralNames).map(value => ({ id: value, name: value }));
	}, [structuralNames]);
	const setNameToStructuralNamesMap = useMemo(() => {
		return LOIGridHelpers.getSetNameToStructuralNamesMap(loiClassifications, structuralNames);
	}, [loiClassifications, structuralNames]);

	// GRID DATA:
	const [columnDefs, setColumnDefs] = useState([]);
	const [agGridData, setAgGridData] = useState([]);
	const [gridReady, setGridReady] = useState(false);

	// MODALS:
	const [editLoiCellData, setEditLoiCellData] = useState({});
	const [openLOIModal, setOpenLOIModal] = useState(false);
	const [openAttrConfig, setOpenAttrConfig] = useState(false);
	const [attrConfigData, setAttrConfigData] = useState(null);
	const [bulkAddData, setBulkAddData] = useState([]);
	const [openBulkAdd, setOpenBulkAdd] = useState(false);

	// FILTERING:
	// Col Filtering
	const [colLOICounts, setColLOICounts] = useState({});
	const [colFilterText, setColFilterText] = useState("");
	const [debouncedColFilterText, setDebouncedColFilterText] = useState("");
	const [viewAll, setViewAll] = useState(false);
	const [setName, setSetName] = useState('')

	// Row Filtering
	const [rowLOICounts, setRowLOICounts] = useState({});
	const [rowFilterText, setRowFilterText] = useState("");
	const [debouncedRowFilterText, setDebouncedRowFilterText] = useState("");
	const [hideEmptyRows, setHideEmptyRows] = useState(false);
	const [setNameFilter, setSetNameFilter] = useState("");

	const gridRef = useRef();

	const [copiedCellData, setCopiedCellData] = useState([]);

	// Delays input change of column/row filter to not overload the AGGrid.
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedColFilterText(colFilterText.toLowerCase());
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [colFilterText]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedRowFilterText(rowFilterText.toLowerCase());
		}, 1000);

		return () => {
			clearTimeout(handler);
		};
	}, [rowFilterText]);

	useEffect(() => {
		if (gridRef.current) {
			console.log("SET ROW FILTER");
			gridRef.current.api?.onFilterChanged();
		}
	}, [debouncedRowFilterText, gridRef]);

	const onColumnMoved = useCallback((event) => {
		if (event.type !== "columnMoved")
			return;
		if (event.finished === false)
			return;

		const columnUpdates = LOIGridHelpers.getChangedColumns(gridRef, loiAttributes);

		updateColumnOrder(columnUpdates);
		console.log('Column moved', event);
	}, [updateColumnOrder, loiAttributes]);

	const editAttrConfigBtn = useCallback((attrId) => {
		loadLoiMaskedDataConfig(selectedLOI);
		const attrData = loiAttributes.find(attr => attr.id === attrId);
		console.log("EDIT ATTR DATA:", loiAttributes);
		console.log("EDIT ATTR FOUND", attrData);
		if (attrData) {
			setAttrConfigData(attrData);
			setOpenAttrConfig(true);
		}
	}, [loiAttributes, loadLoiMaskedDataConfig, selectedLOI]);

	const addNewLOI = useCallback((cellId) => {
		if (!cellId.rowId || !cellId.colId) {
			console.error("Invalid cellId:", cellId);
			return;
		}
		const loiCellData = LOIGridHelpers.getCellDataFromCellId(cellId, loiAttributes, loiData);
		console.log("CELL DATA FOUND:", loiCellData);
		if (!loiCellData)
			return;
		setEditLoiCellData(loiCellData);
		console.log("ADD NEW LOI Clicked!");
		console.log("edit cell data:", loiCellData);
		setOpenLOIModal(true);
	}, [loiAttributes, loiData]);

	const closeLOIModal = () => {
		setOpenLOIModal(false);
	}

	const handleFilterBySetName = (setName) => {
		if (setName === LOIConstants.NO_SET_NAME_FILTER)
			setName = ""
		setSetNameFilter(setName);
		gridRef.current.api.onFilterChanged();

		if (setName === "") return setSetName('');
		let matchSetNameStructuralNames = setNameToStructuralNamesMap.find(elem => elem.setName === setName);
		if (matchSetNameStructuralNames && matchSetNameStructuralNames.structuralNames) {
			setSetName(matchSetNameStructuralNames.structuralNames.toLowerCase());
		}
		else {
			setSetName('');
		}
	}

	const handleHideEmptyRows = () => {
		console.log("GRID REF:", gridRef);
		console.log("Handle hide empty rows");
		setHideEmptyRows(!hideEmptyRows);
		gridRef.current.api.onFilterChanged();
	}

	const isExternalFilterPresent = () => {
		console.log("TEST");
		return true;
	};

	const getContextMenuItems = (params) => {
		console.log("COPIED CELL DATA:", copiedCellData);
		const shouldDisablePaste = copiedCellData.length === 0;
		const customItems = [
			{
				name: "Add",
				action: () => {
					console.log("Add Bulk clicked");
					let selectedCellData = LOIGridHelpers.getSelectedLOICells(gridRef, loiAttributes, loiData);
					console.log("SELECTED CELLS:", selectedCellData);
					if (selectedCellData.length > 0) {
						setBulkAddData(selectedCellData);
						setOpenBulkAdd(true);
					}
				}
			},
			{
				name: 'Copy',
				subMenu: [
					{
						name: 'Copy current selection',
						action: function () {
							console.log('Copy current selection');
							let selectedCellData = LOIGridHelpers.getSelectedLOICells(gridRef, loiAttributes, loiData);
							setCopiedCellData(selectedCellData);
						}
					},
					{
						name: 'Copy selected rows',
						action: function () {
							console.log("COPY SELECTED ROWS");
							let cellData = LOIGridHelpers.getEntireSelectedLOIRowCells(gridRef, loiAttributes, loiData);
							setCopiedCellData(cellData);
						}
					}
				]
			},
			{
				name: 'Paste',
				disabled: shouldDisablePaste,
				subMenu: [
					{
						name: 'Paste current selection',
						action: function () {
							console.log('PASTE INTO CURRENT SELECTION');
							let pastedCellData = LOIGridHelpers.pasteCellDataIntoSelectedLOICells(gridRef, copiedCellData, loiAttributes, loiData);
							setCopiedCellData([]);
							updateLOIData(pastedCellData, selectedLOI);
						}
					},
					{
						name: 'Paste into selected rows',
						action: function () {
							console.log("PASTE INTO SELECTED ROWS");
							let pastedCellData = LOIGridHelpers.pasteCellDataIntoSelectedRows(gridRef, copiedCellData, loiAttributes, loiData);
							setCopiedCellData([]);
							updateLOIData(pastedCellData, selectedLOI);
						}
					}
				]
			},
			'separator',
			{
				name: "Delete",
				action: () => {
					console.log("Delete Bulk clicked");
					let selectedCellData = LOIGridHelpers.getSelectedLOICells(gridRef, loiAttributes, loiData);
					if (selectedCellData.length > 0) {
						selectedCellData = LOIGridHelpers.setSelectedLOICellsToDelete(selectedCellData);
						console.log("SELECTED DELETE CELLS:", selectedCellData);
						updateLOIData(selectedCellData, selectedLOI);
					}
				}
			},
		];

		return [...customItems];
	};

	const doesExternalFilterPass = useMemo(() => {
		const checkSetNameFilter = setNameFilter ? node => node.data.setName === setNameFilter : () => true;
		const checkRowFilter = node => node.data.Codes.toLowerCase().includes(debouncedRowFilterText);
		const checkIsEmptyRow = node => hideEmptyRows && rowLOICounts[node.data.CommonName] === 0;

		return node => !checkIsEmptyRow(node) && checkRowFilter(node) && checkSetNameFilter(node);
	}, [debouncedRowFilterText, hideEmptyRows, setNameFilter, rowLOICounts]);

	// Ensures viewport of selection is visible when grid refreshes...
	useEffect(() => {
		if (gridRef.current && gridRef.current.api) {
			console.log("Performing viewport change 1 - ");
			const cells = gridRef.current.api.getCellRanges();
			if (cells.length === 0)
				return;
			let selectedRowIndex = cells[0].startRow.rowIndex;
			gridRef.current.api.ensureIndexVisible(selectedRowIndex);
			console.log();
		}
	}, [agGridData]);

	// Change column visibility based on viewAll button and column filter
	useEffect(() => {
		if (gridReady && loiAttributes && gridRef.current && gridRef.current.api) {

			let columns = LOIGridHelpers.getLOIColVisibility(loiAttributes, colLOICounts, debouncedColFilterText, viewAll, setName);
			console.log("changing grid col visibility", columns);
			console.log("set non visible columns");
			gridRef.current.api.setColumnsVisible(columns.nonVisible, false);
			console.log("set visible columns");
			gridRef.current.api.setColumnsVisible(columns.visible, true);
			//gridRef.current.api.refreshHeader();

			console.log("App execute visible: ", Date.now() - appStart);
		}
	}, [debouncedColFilterText, gridRef, loiAttributes, colLOICounts, viewAll, gridReady, setName]);

	const gridOptions = React.useMemo(() => ({
		suppressScrollOnNewData: true,
		floatingFiltersHeight: ATTR_CONFIG_HEADER_HEIGHT,
		rowHeight: 33,
		context: {
			addLOIfunc: addNewLOI,
			editAttrConfigFunc: editAttrConfigBtn
		},
		enableRangeSelection: true,
		onColumnMoved: onColumnMoved,
		suppressContextMenu: !featureAllowed,
	}), [editAttrConfigBtn, addNewLOI, onColumnMoved, featureAllowed]);

	useEffect(() => {
		const processColData = () => {
			if (!loiAttributes)
				return;
			console.log("Reprocessing Grid Column data!");
			const columnDefinitions = LOIGridHelpers.getLOIGridColumns(loiAttributes, LOIDataCellRenderer, AttributeConfigCellRenderer, "");
			setColumnDefs(columnDefinitions);
		};
		processColData();
	}, [loiAttributes]);

	useEffect(() => {
		const processRowData = () => {
			if (!loiData || !loiAttributes || !loiClassifications)
				return;

			console.log("Reprocessing Grid Row data!");
			const colCounts = LOIGridHelpers.getEmptyLOIColCount(loiAttributes, loiData);
			const rowCounts = LOIGridHelpers.getLOIRowCount(loiClassifications, loiData);
			const rowData = LOIGridHelpers.getRowDataForLOIGrid(loiData, loiAttributes, loiClassifications);

			setAgGridData(rowData);
			setColLOICounts(colCounts);
			setRowLOICounts(rowCounts);
		};
		processRowData();
	}, [loiData, loiAttributes, loiClassifications]);

	const onGridReady = (params) => {
		console.log("GRID READY 1:", gridReady);
		setGridReady(true);
	};
	const defaultColDefs = useMemo(() => ({
		sortable: true,
		filter: 'agSetColumnFilter',
		floatingFilter: true,
		floatingFilterComponentParams: {
			suppressFilterButton: true,
		},
		editable: featureAllowed,
	}), [featureAllowed]);
	console.log("App execute: ", Date.now() - appStart);
	//console.log("AG GRID DATA:", agGridData);
	//console.log("LOI DATA:", loiData);
	//console.log("CLASSIF DATA:", loiClassifications);
	//console.log("ATTR DATA LOI GRID: ", loiAttributes);
	if (isEmpty(agGridData)) {
		console.log("Empty grid data!");
		return (<LoadingSpinner isLoading={true} />)
	}
	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item xs={1}>
				<SelectDropdown
					id={"Set Name"}
					value={setNameFilter}
					onChange={handleFilterBySetName}
					options={setNameList}
					fullWidth
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					size="small"
					label="Filter Rows"
					variant="outlined"
					value={rowFilterText}
					onChange={(e) => setRowFilterText(e.target.value)}
					placeholder="Enter filter text"
					fullWidth
				/>
			</Grid>
			<Grid item xs={3}>
				<FormControlLabel
					control={
						<Switch
							checked={hideEmptyRows}
							onChange={(event) => handleHideEmptyRows()}
							color="primary"
							size="medium"
						/>
					}
					label="View Rows In Use"
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					size="small"
					label="Filter Columns"
					variant="outlined"
					value={colFilterText}
					onChange={(e) => setColFilterText(e.target.value)}
					placeholder="Enter filter text"
					fullWidth
				/>
			</Grid>
			<Grid item xs={2}>
				<FormControlLabel
					control={
						<Switch
							checked={!viewAll}
							onChange={() => setViewAll(!viewAll)}
							color="primary"
							size="medium"
						/>
					}
					label="View Columns In Use"
				/>
			</Grid>
			<AgGridBaseStyle
				nonAllowedHeight={"260px"}
				ref={gridRef}
				columnDefs={columnDefs}
				defaultColDef={defaultColDefs}
				rowData={agGridData}
				onGridReady={onGridReady}
				gridOptions={gridOptions}
				isExternalFilterPresent={isExternalFilterPresent}
				doesExternalFilterPass={doesExternalFilterPass}
				animateRows
				getContextMenuItems={getContextMenuItems}
			>
			</AgGridBaseStyle>
			{openAttrConfig && (
				<AttributeConfigModal
					open={openAttrConfig}
					onClose={() => setOpenAttrConfig(false)}
					initialData={attrConfigData}
					selectedLOI={selectedLOI}
					loiAttributes={loiAttributes}
					setNameList={isProjectLOI ? structNamesList : setNameList}
				/>
			)}
			{openLOIModal && (
				<AddEditLOIModal
					open={openLOIModal}
					onClose={closeLOIModal}
					initialData={editLoiCellData}
					selectedLOI={selectedLOI}
				/>
			)}
			{openBulkAdd && (
				<BulkAddLOIModal
					open={openBulkAdd}
					onClose={() => setOpenBulkAdd(false)}
					initialData={bulkAddData}
					selectedLOI={selectedLOI}
				/>
			)}
		</Grid>
	);
};

export default AgGridLOIView;