import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormHelperText,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {
	InputError,
	SBDatePicker,
	SBCheckBoxField,
	SelectDropdown,
	BasicTxtField,
} from '../../../components/ui/';
import { HelpContext, LOIContext, ProjectContext, SiteDataContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { convertArrayToSelectList, isObjectEmpty } from '../../../helpers/GeneralHelpers';
import { SERVER_DATE_FORMAT } from '../../../constants/Constants';

const formValidators = {
	name: [Validation.validateNotEmpty],
	startDate: [Validation.validateNotEmpty],
	loiName: [Validation.validateNotEmpty]
}

const styles = {
	formHelperText: { margin: '0 14px' }
};

const ProjectEditPage = ({ setEdit, getApis, projectId }) => {
	const [busy, setBusy] = useState(false);
	const [project, setProject] = useState({
		name: '',
		reference: '',
		clientReference: '',
		tags: [],
		weightedValue: 0,
		isClosed: false,
		isHosted: false,
		dateHosted: '',
		isDeleted: false,
		startDate: '',
		plannedCompletion: '',
		loiName: '',
		hideUnusedClassifications: false,
		fileDetailLevel: 0,
		linkTypeSet: '',
		taskVersion: 0,
		systemMaintenanceVersion: 0,
		currentRIBAstage: 0,
		facilityId: '',
		redirectInReviewTasks: false,
		handOverDate: '',
	});
	const [formErrors, setFormErrors] = useState({});

	const { helpTexts, loadHelpByTitles } = useContext(HelpContext);
	const { loadLOINames, loiNamesSelectList } = useContext(LOIContext);
	const { loadProjectById, updateProject } = useContext(ProjectContext);
	const {
		loadFileDetailLevels,
		fileDetailLevels,
		loadLinkTypes,
		linkTypes,
		loadTaskVersions,
		taskVersions,
		loadSystemMaintenanceVersions,
		systemMaintenanceVersions,
		loadRIBAStages,
		RIBAWorkStages,
		loadSiteLocations,
		facilityList,
	} = useContext(SiteDataContext);

	// useEffect(() => {
	// 	console.log('edited project', project);
	// }, [project])

	useEffect(() => {
		setBusy(true);

		const helpTitles = [
			'projectClosed',
			'projectHosted',
			'projectHostedEndDate',
			'projectDeleted',
			'projectStartDate',
			'PCDate',
			'siteLOIName',
			'filterdLOIList',
			'fileDetailLevel',
			'fileTypeGroup',
			'taskProcess',
			'maintenanceProcess',
			'RIBAStage',
			'facility',
			'redirectReviewTasks',
			'projectHandOverDate',
		];

		async function getApis() {
			const responses = await Promise.all([
				loadProjectById(projectId),
				loadHelpByTitles(helpTitles),
				loadLOINames(),
				loadFileDetailLevels(),
				loadLinkTypes(),
				loadTaskVersions(),
				loadSystemMaintenanceVersions(),
				loadRIBAStages(),
				loadSiteLocations(),
			]);
			const projectData = await responses[0];

			setProject(projectData);
			setBusy(false);
		}

		getApis();
	}, [
		loadProjectById,
		projectId,
		loadHelpByTitles,
		loadLOINames,
		loadFileDetailLevels,
		loadLinkTypes,
		loadTaskVersions,
		loadSystemMaintenanceVersions,
		loadRIBAStages,
		loadSiteLocations,
	]);

	const flatFileDetailLevels = useMemo(() => {
		const fileDetailLevelsflat = [];
		let accumulatedLabels = [];

		for (const key in fileDetailLevels) {
			accumulatedLabels = [...accumulatedLabels, ...fileDetailLevels[key]];
			const label = accumulatedLabels.join(", ");
			fileDetailLevelsflat.push({
				id: parseInt(key, 10),
				name: label || "None" // Use "None" if the label is empty
			});
		}

		return fileDetailLevelsflat;
	}, [fileDetailLevels]);

	const linkTypesSelectList = useMemo(() => convertArrayToSelectList(linkTypes), [linkTypes]);

	const handleDataChange = (propertyName, newValue) => {
		setProject(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const projectData = await updateProject(project);

		if (!projectData) {
			return setBusy(false);
		}
		else {
			setEdit(false);
			getApis();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(project.name, "name", formValidators, newFormErrors);
		Validation.setFieldErrors(project.startDate || '', "startDate", formValidators, newFormErrors);
		Validation.setFieldErrors(project.loiName || '', "loiName", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
			<Box className="form-container">
				<Stack sx={{ mb: 3, }} direction='column' spacing={3}>
					<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
						<Typography variant="body1">Edit project</Typography>
						<Typography variant="body2">* Required field</Typography>
					</Stack>
					<BasicTxtField
						id={"name"}
						label="Name"
						value={project.name}
						onChange={(value) => handleDataChange("name", value)}
						fullWidth={true}
						errors={formErrors['name']}
						required
					/>
					<BasicTxtField
						id={"reference"}
						label="Reference"
						value={project.reference}
						onChange={(value) => handleDataChange("reference", value)}
						fullWidth={true}
					/>
					<BasicTxtField
						id={"clientReference"}
						label="Client reference"
						value={project.clientReference}
						onChange={(value) => handleDataChange("clientReference", value)}
						fullWidth={true}
					/>
					<TextField
						id={"apiTags"}
						label="API tags"
						value={project.tags.join('\n')}
						onChange={(value) => handleDataChange("tags", value.target.value.split('\n'))}
						multiline
						rows={4}
						helperText="One per line"
					/>
					<BasicTxtField
						id={"productWeighting"}
						label="Product weighting"
						value={project.weightedValue}
						onChange={(value) => handleDataChange("weightedValue", value)}
						fullWidth={true}
						type="number"
					/>
					<Divider />
					<Typography variant="body1">Status</Typography>
					<SBCheckBoxField
						id="closed"
						label="Closed"
						checked={project.isClosed}
						onChange={(value) => handleDataChange("isClosed", value)}
						helperText={helpTexts["projectClosed"]}
					/>
					<SBCheckBoxField
						id="hosted"
						label="Hosted"
						checked={project.isHosted}
						onChange={(value) => handleDataChange("isHosted", value)}
						helperText={helpTexts["projectHosted"]}
					/>
					<SBDatePicker
						id="endOfHosting"
						label="End of hosting"
						dateFormat={SERVER_DATE_FORMAT}
						onDateChange={(value) => handleDataChange("dateHosted", value)}
						date={project.dateHosted}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["projectHostedEndDate"]}</FormHelperText>
					<SBCheckBoxField
						id="deleted"
						label="Deleted"
						checked={project.isDeleted}
						onChange={(value) => handleDataChange("isDeleted", value)}
						helperText={helpTexts["projectDeleted"]}
					/>
					<Divider />
					<Typography variant="body1">Dates</Typography>
					<SBDatePicker
						id="startDate"
						label="Start date"
						dateFormat={SERVER_DATE_FORMAT}
						onDateChange={(value) => handleDataChange("startDate", value)}
						date={project.startDate}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["projectStartDate"]}</FormHelperText>
					<InputError field="Start date" errors={formErrors["startDate"]} />
					<SBDatePicker
						id="pcDate"
						label="PC date"
						dateFormat={SERVER_DATE_FORMAT}
						onDateChange={(value) => handleDataChange("plannedCompletion", value)}
						date={project.plannedCompletion}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["PCDate"]}</FormHelperText>
					<Divider />
					<Typography variant="body1">Configuration</Typography>
					<SelectDropdown
						id="levelOfInformationName"
						label="Level of Information Name"
						value={project.loiName}
						onChange={(value) => handleDataChange("loiName", value)}
						options={loiNamesSelectList}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["siteLOIName"]}</FormHelperText>
					<InputError field="Level of Information Name" errors={formErrors["loiName"]} />
					<SBCheckBoxField
						id="filteredLOIList"
						label="Filtered LOI List"
						checked={project.hideUnusedClassifications}
						onChange={(value) => handleDataChange("hideUnusedClassifications", value)}
						helperText={helpTexts["filterdLOIList"]}
					/>
					<SelectDropdown
						id="fileDetailLevel"
						label="File detail level"
						value={project.fileDetailLevel}
						onChange={(value) => handleDataChange("fileDetailLevel", value)}
						options={flatFileDetailLevels}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["fileDetailLevel"]}</FormHelperText>
					<SelectDropdown
						id="fileTypeGroup"
						label="File Type Group"
						value={project.linkTypeSet}
						onChange={(value) => handleDataChange("linkTypeSet", value)}
						options={linkTypesSelectList}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["fileTypeGroup"]}</FormHelperText>
					<SelectDropdown
						id="taskProcess"
						label="Task Process"
						value={project.taskVersion}
						onChange={(value) => handleDataChange("taskVersion", value)}
						options={systemMaintenanceVersions}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["taskProcess"]}</FormHelperText>
					<SelectDropdown
						id="maintenanceProcess"
						label="Maintenance Process"
						value={project.systemMaintenanceVersion}
						onChange={(value) => handleDataChange("systemMaintenanceVersion", value)}
						options={taskVersions}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["maintenanceProcess"]}</FormHelperText>
					<SelectDropdown
						id="validationStage"
						label="Validation Stage"
						value={project.currentRIBAstage}
						onChange={(value) => handleDataChange("currentRIBAstage", value)}
						options={RIBAWorkStages}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["RIBAStage"]}</FormHelperText>
					<SelectDropdown
						id="facility"
						label="Facility"
						value={project.facilityId}
						onChange={(value) => handleDataChange("facilityId", value)}
						options={facilityList}
						usePlaceholder={true}
						fullWidth={true}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["facility"]}</FormHelperText>
					<SBCheckBoxField
						id="redirectReviewTasks"
						label="Redirect review tasks"
						checked={project.redirectInReviewTasks}
						onChange={(value) => handleDataChange("redirectInReviewTasks", value)}
						helperText={helpTexts["redirectReviewTasks"]}
					/>
					<SBDatePicker
						id="informationHandoverDate"
						label="Information handover date"
						dateFormat={SERVER_DATE_FORMAT}
						onDateChange={(value) => handleDataChange("handOverDate", value)}
						date={project.handOverDate}
					/>
					<FormHelperText style={styles.formHelperText}>{helpTexts["projectHandOverDate"]}</FormHelperText>
				</Stack>
				<Stack direction='row' spacing={2} justifyContent="flex-end">
					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={() => setEdit(false)}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={() => handleSubmit()}>
						Submit
					</Button>
				</Stack>
			</Box>
			}
		</>
	);
}

export default ProjectEditPage;