

export const FILE_TYPE = {
	EXCEL: "application/vnd.ms-excel",
	EXCEL_OPEN_XML: "application/vnd.openxmlformats-",
	EXCEL_XLSX: "officedocument.spreadsheetml.sheet",
	PNG: "image/png",
	JPG: "image/jpeg"
}

export const DATA_MASK_ALLOWED_FILES = {
	[FILE_TYPE.EXCEL]: [".xls", ".xlsx"]
}

export const IMAGE_ALLOWED_FILES = {
	[FILE_TYPE.PNG]: [".png"],
	[FILE_TYPE.JPG]: [".jpg", ".jpeg"]
}

export const IMAGE_TYPE = {
	Siteimages: 0,
	LocationImageMap: 1,
	SiteBranding: 2
};