import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	IconButton,
	LinearProgress,
	Paper,
	Typography
} from '@mui/material';
import {
	FileUploadButton,
	CustomDropzone,
	FileSizeFormatter
} from '../../ui';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { uploadFile } from '../../../api/AdminApi';


function FileUploadModal({ title, open, onClose, acceptedFileTypes, processFileCallback, maxFiles = 1, resetOnOpen = false }) {
	const [uploadedFiles, setUploadedFiles] = useState([]);

	// Resets uploaded files if required
	useEffect(() => {
		if (!resetOnOpen)
			return;
		setUploadedFiles([]);
	}, [resetOnOpen, open]);

	const handleSelectFiles = (files) => {
		console.log("File selected:", files);

		const newFiles = Array.from(files).map(file => ({
			name: file.name,
			size: file.size,
			fileData: file,
			progress: 0, // Initialize progress at 0
		}));

		setUploadedFiles(currentFiles => {
			// Combine existing files and new files
			const allFiles = [...newFiles, ...currentFiles];
			// Slice to get only up to maxFiles
			return allFiles.slice(0, maxFiles);
		});
	}

	const simulateFileUpload = (fileIndex) => {
		const interval = setInterval(() => {
			setUploadedFiles(currentFiles => {
				const newFiles = [...currentFiles];
				const progress = newFiles[fileIndex].progress + 10;
				newFiles[fileIndex].progress = progress > 100 ? 100 : progress;
				return newFiles;
			});

			if (uploadedFiles[fileIndex] && uploadedFiles[fileIndex].progress >= 100) {
				clearInterval(interval);
			}
		}, 500);
	};

	const handleUploadClick = async () => {
		try {
			const updatedFiles = [...uploadedFiles]; // Copy of the uploadedFiles state
			const fileDataArray = [];

			for (let fileIndex = 0; fileIndex < uploadedFiles.length; fileIndex++) {
				const fileData = await uploadFile(uploadedFiles[fileIndex].fileData, (progressPercent) => {
					setUploadedFiles(currentFiles => {
						const newFiles = [...currentFiles];
						newFiles[fileIndex].progress = Math.floor(progressPercent * 100) / 100;
						return newFiles;
					});
				});

				updatedFiles[fileIndex].fileData = fileData;
				fileDataArray.push(fileData);
			}

			setUploadedFiles(updatedFiles); // Update the state once all files are uploaded

			if (processFileCallback !== null) {
				processFileCallback(maxFiles === 1 ? fileDataArray[0] : fileDataArray);
			}

			console.log('Upload success:', fileDataArray);
		} catch (error) {
			// Handle any errors
			console.error('Upload failed:', error);
		}
	};

	const handleRemoveFile = (indexToRemove) => {
		setUploadedFiles(currentFiles => currentFiles.filter((_, index) => index !== indexToRemove));
	};

	const canUploadFile = uploadedFiles.length > 0;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle variant="h5">
				File Upload - {title}
			</DialogTitle>
			<DialogContent>
				<CustomDropzone
					onFileUpload={handleSelectFiles}
					acceptedFileTypes={acceptedFileTypes}
				/>
				<Typography variant="caption">Max files allowed: {maxFiles}</Typography>
				{uploadedFiles.map((file, index) => (
					<Grid item xs={12} key={index}>
						<Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
							<IconButton
								size="small"
								onClick={() => handleRemoveFile(index)}
								sx={{ position: 'absolute', top: 8, right: 8 }}
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="body1">{file.name}</Typography>
							<Typography variant="body2" color="text.secondary">
								Size: <FileSizeFormatter sizeInBytes={file.size} />
							</Typography>
							<Box sx={{ mt: 2 }}>
								<LinearProgress variant="determinate" value={file.progress} />
								<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
									Upload Progress: {file.progress}%
								</Typography>
							</Box>
						</Paper>
					</Grid>
				))}
				{canUploadFile && (
					<Button
						variant="outlined"
						onClick={handleUploadClick}
						color="primary">
						Upload
					</Button>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					style={{ marginRight: '8px' }}
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<FileUploadButton
					onFileSelect={handleSelectFiles}
					acceptedFileTypes={acceptedFileTypes}
				>
					<AddIcon />
					Add Files
				</FileUploadButton>
			</DialogActions>
		</Dialog >
	);
}

export default FileUploadModal;