import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';


const PanoramaTable = ({ panoramas }) => {
	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Panoramas</Typography>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Title</TableCell>
								<TableCell>Path</TableCell>
								<TableCell>View</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{panoramas.map((panorama) => {
								const url = `https://edocs-site-panorama-viewer.azurewebsites.net/tour_editor.html#/pano/${panorama.id}?Path=${panorama.url}`;
								return (
									<TableRow key={panorama.id}>
										<TableCell>{panorama.id}</TableCell>
										<TableCell>{panorama.title}</TableCell>
										<TableCell>{panorama.url}</TableCell>
										<TableCell><a href={url} target="_blank" rel="noopener noreferrer">View</a></TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
}

export default PanoramaTable;
