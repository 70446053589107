import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const styles = {
	formHelperText: { margin: '0 14px' }
};

// Quill toolbar configuration
let quillConfig = {
	toolbar: {
		container: [
			[{ 'font': [] }, { 'size': [] }],
			['bold', 'italic', 'underline', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			[{ 'align': [] }],
			['link', 'image', 'video'],
			['clean'],
			[{ 'color': [] }, { 'background': [] }]
		],
		handlers: {
		}
	}
};

const CustomCKEditor = ({ id, data, onChange, helperText }) => {

	const handleModelChange = (content, delta, source, editor) => {
		onChange(editor.getHTML(), id);
	};

	return (
		<>
			<Box>
				<ReactQuill
					value={data}
					onChange={handleModelChange}
					modules={quillConfig}
					formats={[
						'font', 'size', 'bold', 'italic', 'underline', 'blockquote',
						'list', 'bullet', 'align', 'link', 'image', 'video', 'color', 'background'
					]}  // Formats available in the editor
					className="custom-quill-editor"
				/>
			</Box>
			{helperText &&
				<FormHelperText style={styles.formHelperText}>
					{helperText}
				</FormHelperText>
			}
		</>
	);
};

export default CustomCKEditor;