import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import CheckBoxList from '../CheckBoxList';
import * as SpringDB from '../../helpers/SpringBoardDBHelpers';
import { transformArrToIdChecklist } from '../../helpers/GeneralHelpers';
import {
	ApiStatusDisplay,
	SelectDropdown,
	StepperManager,
	TwoLineText
} from '../ui';
import {
	PreviewChangesBtn,
	PreviewChangesScreen
} from '../modals/PreviewChangesModal';
import { AllowedActions } from '../../constants/Constants.js';
import { NewUserEmailModal } from '../modals/NewUserEmailModal/';
import DataLoadHandler from '../containers/DataLoadHandler';
import UserSubActionManager from '../UserSubActionManager';
import { UserContext, ProjectContext, SiteSecurityContext } from '../../contexts/';

const allowedActionsList = [
	{
		id: AllowedActions.REMOVE_ACCESS,
		name: "Remove Access"
	},
	{
		id: AllowedActions.SET_ACCESS_EXPIRATION,
		name: "Set Access expiration"
	},
	{
		id: AllowedActions.ADD_ROLE,
		name: "Add Role"
	},
	{
		id: AllowedActions.REMOVE_ROLE,
		name: "Remove Role"
	},
	{
		id: AllowedActions.SET_ROLE_DESCRIPTION,
		name: "Set Role Description"
	}
];

const Stages = {
	SELECT_ACTION: 0,
	SELECT_SUB_ACTION: 1,
	SELECT_DOCS: 2,
	SELECT_USERS: 3,
	PREVIEW_CHANGES: 4
}

const STAGE_NAMES = ['Choose Action', 'Choose Subaction', 'Select Documents', 'Select Users', 'Confirm'];

/*
 TODO (Bugs):
 - Selecting add role sometimes filters too many users if they already have a role in 1 document but not in another.
 - Add/set role currently can only add on users who are already in that document
 - On set role, color differences shown can sometimes display weird artifacts
	 e.g. Document Editor -> Document Admin = Document EAdmitnor
 - Set access expiration not implemented
 - Remove access currently has no visual indicator in ag grid
*/

// Remove access - Only show people with access
// Remove role - Only show people who HAVE that role...
// Add role - Only show people WITHOUT that role...
// - Therefore, must still check user docs but only add if it doesn't exist...
// SET role - Only show people who don't have only that role.

// Add back button for site manage
// Add different view for agGrid.
// Get data loading correctly.

const UserSiteManagePage = (props) => {
	const [selectedAction, setSelectedAction] = useState("");
	const [selectedSubAction, setSelectedSubAction] = useState("");
	const [secondaryActionText, setSecondaryActionText] = useState("");
	const [previewChangesList, setPreviewChangesList] = useState([]);
	const [checkedDocuments, setCheckedDocuments] = useState({});
	const [selectedProjectIds, setSelectedProjectIds] = useState([]);
	const [checkedUsers, setCheckedUsers] = useState({});
	const { users, roles, addUsersToSite } = useContext(UserContext);
	const {
		projects,
		documentsTeamsByProject,
		loadedDocTeamsProjectIds,
		loadDocumentsTeamsByProjectId,
		addRoleToUsers,
		deleteRoleFromUsers,
		setExpirationDateForUsers,
		setRoleDescriptionForUsers,
		setRemoveAccessForUsers
	} = useContext(ProjectContext);
	const { isFeatureAllowed } = useContext(SiteSecurityContext);
	const [tempDocumentsByProject, setTempDocumentsByProject] = useState(JSON.parse(JSON.stringify(documentsTeamsByProject)));
	const [projectCheckList, setProjectCheckList] = useState(SpringDB.convertProjectsToCheckboxList(projects, documentsTeamsByProject));
	const [userCheckList, setUserCheckList] = useState(SpringDB.convertUsersToCheckList(users));
	const [currentStage, setCurrentStage] = useState(0);

	useEffect(() => {
		Object.entries(documentsTeamsByProject).forEach(([key, value]) => {
			value.map(doc => {
				const featureAllowed = isFeatureAllowed('documentTeamMember_create_update_delete', doc.documentId);
				if (featureAllowed) return Object.assign(doc, { disabled: false, info: '' })
				else return Object.assign(doc, { disabled: true, info: 'Insufficient permissions' })
			})
		})

		setProjectCheckList(SpringDB.convertProjectsToCheckboxList(projects, documentsTeamsByProject));
		setTempDocumentsByProject(JSON.parse(JSON.stringify(documentsTeamsByProject)));
	}, [projects, documentsTeamsByProject, isFeatureAllowed]);

	// useEffect for handling user updates
	useEffect(() => {
		setUserCheckList(SpringDB.convertUsersToCheckList(users));
	}, [users]);

	const handleBackToUsers = () => {
		props.navigateTo("users");
	}

	const isSubActionRequired = (selectedAction) => {
		console.log("Selected action: " + selectedAction);
		// List of actions for which subAction isn't required
		const actionsWithoutSubActions = [AllowedActions.REMOVE_ACCESS];

		return !actionsWithoutSubActions.includes(selectedAction);
	}

	const selectCurrentStage = useCallback((state) => {
		//const { selectedAction, selectedSubAction, selectedProjectIds, checkedDocuments, checkedUsers } = state;
		const isActionSelected = allowedActionsList.some(action => action.id === selectedAction);

		if (!isActionSelected) {
			return Stages.SELECT_ACTION;
		}

		console.log("Stage 2");
		// User has not selected a sub action.
		if (selectedSubAction === "" && isSubActionRequired(selectedAction)) {
			return Stages.SELECT_SUB_ACTION;
		}

		console.log("Stage 3");
		const anyDocsChecked = Object.entries(checkedDocuments)
			.some(([key, value]) => !selectedProjectIds.includes(key) && value === true);

		if (!anyDocsChecked) {
			return Stages.SELECT_DOCS;
		}
		console.log("Stage 4");
		console.log('checkedUsers:', checkedUsers);
		const anyUsersChecked = Object.values(checkedUsers).some(value => value === true);
		console.log('anyUsersChecked:', anyUsersChecked);
		if (!anyUsersChecked) {
			return Stages.SELECT_USERS;
		}
		console.log("Stage 5");
		return Stages.PREVIEW_CHANGES;
	}, [selectedAction, selectedSubAction, selectedProjectIds, checkedDocuments, checkedUsers]);

	useEffect(() => {
		setCurrentStage(selectCurrentStage());
	}, [selectCurrentStage]);

	const updateCheckedUsers = (action, subAction, checkedDocs) => {
		let selAction = action ? action : selectedAction;
		let selSubAction = subAction ? subAction : selectedSubAction;
		let selCheckedDocs = checkedDocs ? checkedDocs : checkedDocuments;
		let updatedCheckedUsers, updatedUsersCheckList = {};
		console.log("UPDATED CHECKED USERS");
		switch (selAction) {
			case AllowedActions.REMOVE_ACCESS:
				updatedCheckedUsers = SpringDB.getActiveCheckedUsers(selCheckedDocs, tempDocumentsByProject);
				//updatedUsersCheckList = SpringDB.convertUsersToCheckList(this.props.users, updatedCheckedUsers);
				updatedUsersCheckList = SpringDB.convertUsersToCheckList(users);
				setCheckedUsers(updatedCheckedUsers);
				setUserCheckList(updatedUsersCheckList);
				break;
			case AllowedActions.REMOVE_ROLE:
				updatedCheckedUsers = SpringDB.getCheckedUsersWithRole(selSubAction, selCheckedDocs, tempDocumentsByProject);
				updatedUsersCheckList = SpringDB.convertUsersToCheckList(users, updatedCheckedUsers);
				setCheckedUsers(updatedCheckedUsers);
				setUserCheckList(updatedUsersCheckList);
				break;
			case AllowedActions.ADD_ROLE:
				updatedCheckedUsers = SpringDB.getCheckedUsersWithRole(selSubAction, selCheckedDocs, tempDocumentsByProject);
				//updatedUsersCheckList = SpringDB.convertUsersToCheckList(this.props.users, updatedCheckedUsers, false);
				updatedUsersCheckList = SpringDB.convertUsersToCheckList(users);
				setCheckedUsers(updatedCheckedUsers);
				setUserCheckList(updatedUsersCheckList);
				break;
			case AllowedActions.SET_ACCESS_EXPIRATION:
			case AllowedActions.SET_ROLE_DESCRIPTION:
				updatedCheckedUsers = SpringDB.getCheckedUsersWithAnyRole(selCheckedDocs, tempDocumentsByProject);
				console.log(updatedCheckedUsers);
				updatedUsersCheckList = SpringDB.convertUsersToCheckList(users, updatedCheckedUsers);
				setUserCheckList(updatedUsersCheckList);
				break;
			default:
				break;
		}
	}

	const handlePreviewChanges = () => {
		let updatedProjectDocs = documentsTeamsByProject;
		let previewChangesList = [];
		let secondaryActionText = "";
		console.log("PREVIEW¬!!!");
		switch (selectedAction) {
			case AllowedActions.SET_ROLE_DESCRIPTION:
				secondaryActionText = selectedSubAction;
				previewChangesList = SpringDB.generatePreviewForSetJobTitle(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users);
				break;
			case AllowedActions.SET_ACCESS_EXPIRATION:
				secondaryActionText = selectedSubAction;
				previewChangesList = SpringDB.generatePreviewForSetAccessExpiration(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users);
				break;
			case AllowedActions.REMOVE_ACCESS:
				//updatedProjectDocs = SpringDB.removeAccessForDocUsers(checkedUsers, checkedDocuments, documentsByProject);
				previewChangesList = SpringDB.generatePreviewForRemoveAccess(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users);
				break;
			case AllowedActions.REMOVE_ROLE:
				//updatedProjectDocs = SpringDB.removeRoleFromCheckedUsers(selectedSubAction, checkedUsers, checkedDocuments, documentsByProject);
				previewChangesList = SpringDB.generatePreviewForRoles(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedAction, selectedSubAction);
				secondaryActionText = SpringDB.getRoleFromId(selectedSubAction, roles);
				break;
			case AllowedActions.SET_ROLE:
				//updatedProjectDocs = SpringDB.setRoleForCheckedUsers(selectedSubAction, checkedUsers, checkedDocuments, documentsByProject);
				secondaryActionText = SpringDB.getRoleFromId(selectedSubAction, roles);
				previewChangesList = SpringDB.generatePreviewForRoles(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedAction, selectedSubAction);
				break;
			case AllowedActions.ADD_ROLE:
				//updatedProjectDocs = SpringDB.assignRoleToCheckedUsers(selectedSubAction, checkedUsers, checkedDocuments, documentsByProject);
				secondaryActionText = SpringDB.getRoleFromId(selectedSubAction, roles);
				previewChangesList = SpringDB.generatePreviewForRoles(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedAction, selectedSubAction);
				break;
			default:
				break;
		}
		setTempDocumentsByProject(updatedProjectDocs);
		setPreviewChangesList(previewChangesList);
		setSecondaryActionText(secondaryActionText);
	}

	const handleConfirmChanges = () => {
		console.log("Confirm changes!");
		let actualChangeList = [];
		switch (selectedAction) {
			case AllowedActions.REMOVE_ROLE:
				actualChangeList = SpringDB.generateChangeListForRoles(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedAction, selectedSubAction);
				deleteRoleFromUsers(actualChangeList);
				break;
			case AllowedActions.ADD_ROLE:
				actualChangeList = SpringDB.generateChangeListForRoles(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedAction, selectedSubAction);
				addRoleToUsers(actualChangeList);
				break;
			case AllowedActions.SET_ACCESS_EXPIRATION:
				actualChangeList = SpringDB.generateChangeListForSetExpiration(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedSubAction);
				setExpirationDateForUsers(actualChangeList);
				break;
			case AllowedActions.SET_ROLE_DESCRIPTION:
				actualChangeList = SpringDB.generateChangeListForSetRoleDescription(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users, selectedSubAction);
				setRoleDescriptionForUsers(actualChangeList);
				break;
			case AllowedActions.REMOVE_ACCESS:
				actualChangeList = SpringDB.generateChangeListForRemoveAccess(checkedUsers, checkedDocuments, documentsTeamsByProject, projects, users);
				setRemoveAccessForUsers(actualChangeList);
				break;
			default:
				break;
		}

		console.log(actualChangeList);
	}

	const handleAddNewUsers = (newUsers) => {
		addUsersToSite(newUsers);
		switch (selectedAction) {
			case AllowedActions.ADD_ROLE:
			case AllowedActions.SET_ACCESS_EXPIRATION:
			case AllowedActions.SET_ROLE_DESCRIPTION:
				// Selects new users in user check list if relevant action is selected.
				let newCheckedUsers = transformArrToIdChecklist(newUsers);
				setCheckedUsers(prevCheckedUsers => {
					return { ...prevCheckedUsers, ...newCheckedUsers };
				});
				setCurrentStage(Stages.PREVIEW_CHANGES);
				break;
			default:
				break;
		}

	}

	const handleSetCheckedUsers = (updatedCheckedUsers) => {
		console.log("CHECKED USERS:", updatedCheckedUsers);
		setCheckedUsers(updatedCheckedUsers);
		setCurrentStage(Stages.PREVIEW_CHANGES);
	}

	const handleSetCheckedDocuments = (updatedCheckedDocs) => {
		const validIds = new Set(projects.map(project => project.id));

		// Filter the keys from the first object to get only those that are valid project Ids
		const updatedSelectedProjectIds = Object.keys(updatedCheckedDocs)
			.filter(key => updatedCheckedDocs[key] === true && validIds.has(key));

		setSelectedProjectIds(updatedSelectedProjectIds);
		setCheckedDocuments(updatedCheckedDocs);
		updateCheckedUsers(null, null, updatedCheckedDocs);
	}

	const handleSetAction = (action) => {
		setSelectedAction(action);
		setSelectedSubAction("");
		updateCheckedUsers(action, "", null);
	}

	const handleSetSubAction = (subAction) => {
		setSelectedSubAction(subAction);
		updateCheckedUsers(null, subAction, null);
	}
	console.log(checkedDocuments);
	console.log("SELECTED SUB ACTION:");
	console.log(selectedSubAction);
	return (
		<div>
			<Grid container direction="column" spacing={3}>
				<Grid item />
				{/* First Row */}
				<Grid item container justify="left">
					<Button variant="text" onClick={handleBackToUsers}>
						<strong>&lt; Back to users</strong>
					</Button>
				</Grid>

				{/* Second Row */}
				<Grid item container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="h4" component="h2">
							Users / Manage
						</Typography>
						<Typography variant="body1" color="textSecondary">
							Add and edit users for one or many projects at a time.
						</Typography>
					</Grid>
				</Grid>
				{/* Third Row  */}
				<Grid item container spacing={2} xs={12}>
					<ApiStatusDisplay />
				</Grid>

				{/* Fourth Row */}
				<Grid item container spacing={2} xs={12}>
					<StepperManager steps={STAGE_NAMES} activeStep={currentStage} />
				</Grid>

				{/* Fifth Row */}
				<Grid item container spacing={2}>
					<Grid item container spacing={1} xs={12} sm={12} md={12} lg={4}>
						{/* First Column Action */}
						<Grid item xs={12} sm={6}>
							<TwoLineText>
								Action
							</TwoLineText>
							<SelectDropdown
								id={"action"}
								value={selectedAction}
								options={allowedActionsList}
								onChange={handleSetAction}
								fullWidth
							/>
						</Grid>
						{currentStage >= Stages.SELECT_SUB_ACTION && (
							<Grid item xs={12} sm={6}>
								{/* Second Column Do this */}
								<TwoLineText>
									Do this
								</TwoLineText>
								<UserSubActionManager
									action={selectedAction}
									roles={roles}
									selectedSubAction={selectedSubAction}
									onSubActionChange={handleSetSubAction}
								/>
							</Grid>
						)}
					</Grid>
					{currentStage >= Stages.SELECT_DOCS && (
						<Grid item xs={12} sm={4} md={4} lg={3}>
							{/* Third Column In these projects */}
							<TwoLineText>
								In these projects / documents
							</TwoLineText>

							<CheckBoxList
								id={"projects-list"}
								data={projectCheckList}
								checkedItems={checkedDocuments}
								onCheckedItemsChange={handleSetCheckedDocuments}
							>
								<DataLoadHandler
									loadedIds={loadedDocTeamsProjectIds}
									idsToLoad={selectedProjectIds}
									loadFunction={loadDocumentsTeamsByProjectId}
								/>
							</CheckBoxList>
						</Grid>
					)}
					{currentStage >= Stages.SELECT_USERS && (
						<Grid item xs={8} sm={4} md={4} lg={3}>
							{/* Fourth Column with these users */}
							<TwoLineText>
								With these users
							</TwoLineText>
							<CheckBoxList
								id={"users-list"}
								data={userCheckList}
								checkedItems={checkedUsers}
								onCheckedItemsChange={handleSetCheckedUsers}
							>
								<NewUserEmailModal onConfirmAddUsers={handleAddNewUsers} />
							</CheckBoxList>
						</Grid>
					)}
					{currentStage >= Stages.SELECT_USERS && (
						<Grid item xs={4} sm={4} md={4} lg={2}>
							{/* Fifth Column preview changes */}
							<TwoLineText>
								&nbsp;
							</TwoLineText>
							<PreviewChangesBtn
								onPreviewClick={handlePreviewChanges}
								onSaveChanges={handleConfirmChanges}
							>
								<PreviewChangesScreen
									selectedAction={selectedAction}
									secondaryActionText={secondaryActionText}
									changesList={previewChangesList}
								/>
							</PreviewChangesBtn>
						</Grid>
					)}
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
		</div>
	);
};

UserSiteManagePage.propTypes = {
	navigateTo: PropTypes.func.isRequired
};

export default UserSiteManagePage;