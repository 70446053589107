import React, { useState, useContext, useEffect } from 'react';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Tooltip,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
	SecurityButton
} from '../../ui';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import { AdminsContext, UserContext } from '../../../contexts';
import { NewUserEmailModal } from '../../modals/NewUserEmailModal';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';
import ConfirmationDialog from '../../modals/ConfirmationDialog';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';
import { UserModalTrigger } from '../../modals';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'Administrators-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const AdminsTableComponent = ({ admins, adminsDetails, getapis, setBusy }) => {
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [removeAdmin, setRemoveAdmin] = useState({ id: '', user: '' });

	const { deleteAdmins, swapMaster } = useContext(AdminsContext);
	const { loadUsersById } = useContext(UserContext);

	useEffect(() => {
		const uniqueUserIds = [...new Set(admins.map(admin => admin.userId))];
		loadUsersById(uniqueUserIds);
	}, [loadUsersById, admins]);

	const findAdmin = (userId, key) => {
		if (userId == null) return '-';
		const admin = adminsDetails.find(({id}) => id === userId);
		return admin ? admin[key] : '-';
	}

	const handleOpenDeleteDialog = (admin) => {
  	setOpenDeleteConfirm(true);
		const name = findAdmin(admin.userId, 'name');
		setRemoveAdmin({ id: admin.userId, user: name });
	};

	const handleRemove = async () => {
		const adminData = await deleteAdmins({userIds: [removeAdmin.id]});

		if (adminData) {
			setOpenDeleteConfirm(false);
			getapis();
		}
		else {
			setOpenDeleteConfirm(false);
		}
	}

	const handleConfirmChangeSiteAdmin = async (newAdminUsers) => {
		setBusy(true);
		const userId = newAdminUsers[0].id;
		const swapMasterData = await swapMaster({userId: userId});
		if (swapMasterData) getapis();
		else setBusy(false);
	}

	const columns = [
		{
			field: 'user',
			headerName: 'User',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return findAdmin(row.userId, 'name');
			},
			renderCell: (params) => (
				<UserModalTrigger userId={params.row.userId} />
			)
		},
		{
			field: 'type',
			headerName: 'Role',
			width: 80,
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<Stack direction='row' spacing={1}>
					{findAdmin(params.row.userId, 'isMe') === true && <Box><Tooltip title="Me" arrow sx={{ verticalAlign: 'middle' }}><PersonIcon /></Tooltip></Box>}
					{params.row.type === 'MasterAdmin' && <Box><Tooltip title="Master administrator" arrow sx={{ verticalAlign: 'middle' }}><KeyIcon /></Tooltip></Box>}
				</Stack>
			)
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return findAdmin(row.userId, 'email');
			}
		},
		{
			field: 'company',
			headerName: 'Company',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return findAdmin(row.userId, 'companyName');
			}
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => (
				<>
					{params.row.type === 'SiteAdmin' ?
						<SecurityButton
							featureName={SECURITY_FEATURES.ADMINS_PAGE_EDIT}
							color="primary"
							variant="outlined"
							size="small"
							onClick={() => { handleOpenDeleteDialog(params.row, 'remove') }}>
							Remove
						</SecurityButton> :
						<NewUserEmailModal
							title={"Change administrator"}
							btnText={"Change"}
							btnVariantStyle={"outlined"}
							btnStyle={{ display: 'inline-flex' }}
							confirmMsg={"The following user will be changed to site administrator"}
							onConfirmAddUsers={handleConfirmChangeSiteAdmin}
							featureName={SECURITY_FEATURES.ADMINS_PAGE_SWAP}
						/>
					}
				</>
			)
		},
	]

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<DataGrid
					rows={admins}
					columns={columns}
					rowHeight={42}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10
							}
						}
					}}
					pageSizeOptions={[5, 10, 25, 50]}
					disableRowSelectionOnClick
					sx={{ border: 'none' }}
					slots={{
						toolbar: CustomToolbar,
					}}
					autoHeight
				/>
			</Box>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={() => { setOpenDeleteConfirm(false) }}
				title="Remove Site Administrator"
				message={`Please confirm that you would like to remove ${removeAdmin.user}`}
				onConfirm={handleRemove}
			/>
		</>
	);
};

export default AdminsTableComponent;