import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import LoadingPage from '../LoadingPage';
import FileCategoriesTableComponent from './FileCategoriesTableComponent';
import FileCategoryModal from './FileCategoryModal';
import FileTagsPage from './FileTagsPage';
import * as Api from '../../../api/AdminApi';
import { ApiStatusDisplay, SecurityButton } from '../../ui';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const FileCategoriesPage = () => {
	const [loadingData, setLoadingData] = useState(false);
	const [categories, setCategories] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [currentCategory, setCurrentCategory] = useState(null);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setLoadingData(true);
		try {
			const response = await Api.getFileCategories();
			setCategories(response);
		} catch (error) {
			console.error('Error fetching data', error);
		}
		setLoadingData(false);
	};

	const handleEditTags = (category) => {
		setCurrentCategory(category);
	}

	const handleEditCategory = (category) => {
		setCurrentCategory(category);
		setModalOpen(true);
	};

	const handleCloseCategory = () => {
		setModalOpen(false);
	};

	const handleSave = async (savedCategory) => {
		const newCategory = !savedCategory.id;
		let serverCategory;
		handleCloseCategory();
		try
		{
			if (newCategory) {
				serverCategory = await Api.createFileCategory(savedCategory);
				setCategories([...categories, serverCategory]);
			} else {
				serverCategory = await Api.updateFileCategory(savedCategory);
				const updatedCategories = categories.map(category =>
					category.id === savedCategory.id ? serverCategory : category
				);
				setCategories(updatedCategories);
			}
		}
		catch (error) {
		}
	};

	const handleUpdateCategoryTags = (updatedCategory) => {
		const updatedCategories = categories.map(category =>
			category.id === updatedCategory.id ? updatedCategory : category
		);
		setCategories(updatedCategories);
	};

	if (loadingData) {
		return <LoadingPage />
	}

	return (
		<>
			<ApiStatusDisplay />
			<Typography variant={'h4'}>
				File Tag Categories
			</Typography>
			<FileCategoriesTableComponent
				categories={categories}
				onEditCategory={handleEditCategory}
				onEditTags={handleEditTags}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
				<SecurityButton
					featureName={SECURITY_FEATURES.FILE_TAGS_PAGE}
					variant="contained"
					color="primary"
					onClick={() => handleEditCategory({})}
				>
					Add Category
				</SecurityButton>
			</Box>
			<FileTagsPage
				category={currentCategory || {}}
				onUpdateCategoryTags={handleUpdateCategoryTags}
			/>
			<FileCategoryModal
				open={modalOpen}
				onClose={handleCloseCategory}
				currentCategory={currentCategory || {}}
				onSave={handleSave}
			/>
		</>
	);
};

export default FileCategoriesPage;