import { IconButton, Grid, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { DaysDisplay } from '../ui';

function GridWorkflowHeader(props) {
	const handleEditClick = () => {
		props.context.editWorkflowStepFunc(props.stepId);
	};
	return (
		<Grid container
			justifyContent="space-between"
			alignItems="center"
			style={{ width: '100%', borderLeft: `4px solid ${props.colour}` }}
		>
			<Grid item xs={3}>
				<IconButton
					onClick={handleEditClick}
				>
					<SettingsIcon />
				</IconButton>
			</Grid>
			<Grid item xs={8}>
				<div className="ag-header-cell-text">{props.displayName}</div>
				<div className="ag-header-subTitle"><DaysDisplay days={props.days} /></div>
			</Grid>
		</Grid>
	);
}

export default GridWorkflowHeader;