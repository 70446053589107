import React from 'react';
import { useDragLayer } from 'react-dnd';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import CancelIcon from '@mui/icons-material/Cancel';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
};

const getItemStyles = (initialOffset, currentOffset) => {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const TreeDragLayer = ({ dropPosition }) => {
	const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) {
		return null;
	}

	let IconComponent;
	if (dropPosition === 'child') {
		IconComponent = AddIcon;
	} else if (dropPosition === 'sibling') {
		IconComponent = ListIcon;
	} else {
		IconComponent = CancelIcon;
	}

	return (
		<div style={layerStyles}>
			<div style={getItemStyles(initialOffset, currentOffset)}>
				<div className="drag-preview">
					<IconComponent style={{ marginRight: 8 }} />
					{item.label}
				</div>
			</div>
		</div>
	);
};

export default TreeDragLayer;
