import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function CustomTabPanel(props) {
	const { children, value, index } = props;

	if (value !== index) {
		return null;
	}

	return (
		<Box sx={{ p: 2 }}>
			{children}
		</Box>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default CustomTabPanel;