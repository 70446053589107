import React, { useContext, useState } from 'react';
import {
	Card,
	CardContent,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	IconButton,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { SiteDataContext } from '../../../../contexts';
import EditQuickLinksModal from './EditQuickLinksModal';


const QuickLinksForm = ({ siteLocationId, quickLinks, onChange }) => {
	const [open, setOpen] = useState(false);
	const [currentLink, setCurrentLink] = useState({});
	const {
		createSiteLocationQuickLink,
		updateSiteLocationQuickLink,
		deleteSiteLocationQuickLink
	} = useContext(SiteDataContext);

	const handleOpen = (link) => {
		setCurrentLink(link);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = async (quickLink) => {
		const isNewLink = !quickLink.id;
		if (isNewLink) {
			let newQuickLink = await createSiteLocationQuickLink({ siteLocationId, ...quickLink });
			if (newQuickLink) {
				onChange([...quickLinks, newQuickLink]);
			}
		}
		else {
			let updatedQuickLink = await updateSiteLocationQuickLink(quickLink);
			if (updatedQuickLink)
				onChange(quickLinks.map(link => (link.id === updatedQuickLink.id ? updatedQuickLink : link)));
		}
		handleClose();
	};

	const handleDelete = async (id) => {
		let response = await deleteSiteLocationQuickLink({ siteLocationId, id });
		if (response)
			onChange(quickLinks.filter(link => link.id !== id));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Quick Links</Typography>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Position</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>URL</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{quickLinks.map((link) => (
								<TableRow key={link.id}>
									<TableCell>{link.number}</TableCell>
									<TableCell>{link.description}</TableCell>
									<TableCell>{link.url}</TableCell>
									<TableCell>
										<IconButton onClick={() => handleOpen(link)}>
											<EditIcon />
										</IconButton>
										<IconButton onClick={() => handleDelete(link.id)}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Button
					variant="contained"
					color="primary"
					startIcon={<AddIcon />}
					onClick={() => handleOpen({})}
				>
					Add Link
				</Button>
				<EditQuickLinksModal
					open={open}
					onClose={handleClose}
					currentLink={currentLink}
					onSave={handleSave}
				/>
			</CardContent>
		</Card>
	);
};

export default QuickLinksForm;
