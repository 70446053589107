import React, { Component } from 'react';
import CircularProgressWithLabel from "../ui/CircularProgressWithLabel";
import DataLoadProfiler from '../../services/DataLoadProfiler';

class DataLoadHandler extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingProgress: 0,
			isDataLoading: false
		};

		this.documentLoadProfiler = new DataLoadProfiler(this.handleProgressUpdate);
		this.documentLoadProfiler.loadFunction = this.props.loadFunction.bind(this);
	}

	componentDidMount() {
		// Start loading documents
		this.loadData(this.props.idsToLoad);
	}

	componentDidUpdate(prevProps) {
		// Check if selectedIds have changed
		if (this.props.idsToLoad !== prevProps.idsToLoad) {
			this.loadData(this.props.idsToLoad);
		}
	}

	handleProgressUpdate = (progress) => {
		this.setState({
			loadingProgress: progress,
		});
		if (this.documentLoadProfiler.getQueueSize() === 0) {
			this.setState({ isDataLoading: false });
		}
	};

	async loadData(idsToLoad) {
		console.log("LOADING IDS")
		console.log(idsToLoad);
		const unloadedIds = idsToLoad.filter(id => id !== '' && !this.props.loadedIds.includes(id));

		// If there's nothing to load, return early.
		if (unloadedIds.length === 0) return;

		for (let id of unloadedIds) {
			this.documentLoadProfiler.addToQueue(id);
		}
		if (this.documentLoadProfiler.getQueueSize() > 0) {
			this.setState({ isDataLoading: true });
		}
	}

	render() {
		const { isDataLoading, loadingProgress } = this.state;
		if (isDataLoading) {
			return <CircularProgressWithLabel value={loadingProgress} />;
		}

		return this.props.children;
	}
}

export default DataLoadHandler;