import React, { useState, useEffect } from 'react';
import {
	Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack
} from '@mui/material';
import * as Validation from '../../../services/Validation';
import {
	BasicTxtField,
	SBCheckBoxField,
} from '../../../components/ui/';

const formValidators = {
	name: [Validation.validateNotEmpty, Validation.validateMaxLength(50)],
	description: [Validation.validateNotEmpty, Validation.validateMaxLength(50)]
}

const initialValue = {
	name: "",
	description: ""
}

const FileTagsModal = ({ open, onClose, tag, onSave }) => {
	const [editedTag, setEditedTag] = useState({...initialValue, ...tag});
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedTag({...initialValue, ...tag});
		resetFormErrors();
	}, [tag, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedTag({ ...editedTag, [propertyName]: newValue });
	};

	const handleSave = () => {
		if (!isFormValid(editedTag)) return;
		onSave(editedTag);
	};

	const newTag = !tag.id;
	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>Edit Tag</DialogTitle>
			<DialogContent>
				<Stack spacing={1} mt={1}>
					<BasicTxtField
						id="name"
						label="Tag Name"
						value={editedTag?.name || ''}
						onChange={handleDataChange}
						errors={formErrors['name']}
						fullWidth
					/>
					<BasicTxtField
						id="description"
						value={editedTag?.description || ''}
						onChange={handleDataChange}
						errors={formErrors['description']}
						fullWidth
					/>
					{!newTag &&
						<SBCheckBoxField
							id="deleted"
							label="Deleted"
							checked={editedTag?.deleted || false}
							onChange={handleDataChange}
						/>
					}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSave} color="primary" variant="contained">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FileTagsModal;