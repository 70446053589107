import React, { useMemo } from 'react';
import {
	getBaseColDefForSpacesGrid,
	getRowDataForSpaceGrid
} from '../../../helpers/AgGrid/LocationsGridHelpers';
import AgGridBaseLocationView from './AgGridBaseLocationView';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';

const AgGridSpacesView = ({ locationsData, loiAttributes, loiClassifications, performCRUDOperation, loiData }) => {
	const baseColDefs = useMemo(() => {
		return getBaseColDefForSpacesGrid(locationsData)
	}, [locationsData.zones, locationsData.floors]);
	return (
		<AgGridBaseLocationView
			locationData={locationsData.spaces}
			loiAttributes={loiAttributes}
			loiClassifications={loiClassifications}
			performCRUDOperation={performCRUDOperation}
			structuralName={"Space"}
			baseColDefs={baseColDefs}
			generateRowDataFunc={getRowDataForSpaceGrid}
			securityFeatureName={SECURITY_FEATURES.LOCATIONS_PAGE_SPACE}
			loiData={loiData}
		/>
	);
};

export default AgGridSpacesView;