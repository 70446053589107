import React from 'react';
import { Button } from '@mui/material';

const NextButton = (props) => {
	const { onClick, disabled } = props;

	return (
		<div style={{ textAlign: 'right' }}>
			<Button
				variant="contained"
				color="secondary"
				onClick={onClick}
				disabled={disabled}
				style={{ paddingLeft: '54px', paddingRight: '54px', marginLeft: '10px' }}
			>
				NEXT &gt;
			</Button>
		</div>
	);
}

export default NextButton;