import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Button,
	CircularProgress,
	Grid,
	Typography,
} from '@mui/material';
import {
	SelectDropdown,
	GridDivider
} from '../../../ui';
import { LOIContext } from '../../../../contexts';
import DataLookupConfig from './DataLookupConfig';
import MaskedInputConfig from './MaskedInputConfig';
import DataConcatenateConfig from './DataConcatenateConfig';

const dataConfigInputTypes = {
	DATA_LOOKUP: "DataLookupLOI",
	MASKED_LOOKUP: "maskedInput",
	DATA_CONCATENATE: "dataConcatenate"
};

const dataInputTypeList = [
	{
		id: dataConfigInputTypes.DATA_LOOKUP,
		name: "Data Lookup Input"
	},
	{
		id: dataConfigInputTypes.MASKED_LOOKUP,
		name: "Masked Input"
	},
	{
		id: dataConfigInputTypes.DATA_CONCATENATE,
		name: "Data Concatenate"
	}
];

function DataConfigBuilder({ initialData, onApplyConfig, selectedLOI }) {
	const { loiDataConfigByName } = useContext(LOIContext);
	const [dataConfigArr, setDataConfigArr] = useState(Array(7).fill(""));
	const [dataInputType, setDataInputType] = useState("");
	/*const [sheetName, setSheetName] = useState("");
	const [displayColName, setDisplayColName] = useState("");
	const [lookupColName, setLookupColName] = useState("");
	const [updatedBy, setUpdatedBy] = useState("");
	const [renderType, setRenderType] = useState("");
	const [ghostScript, setGhostScript] = useState("");*/

	const resetDataConfig = useCallback(() => {
		if (initialData == null) {
			return;
		}
		let dataConfig = initialData ? JSON.parse(initialData) : Array(7).fill("");
		console.log("initial data config", dataConfig);
		if (dataConfig[0].length > 0) {
			let dataInput = dataConfig[0];
			setDataInputType(dataInput);
		}
		setDataConfigArr(dataConfig);
	}, [initialData])

	useEffect(() => {
		resetDataConfig();
	}, [initialData, resetDataConfig]);

	const handleSelectDataInputType = (type) => {
		setDataInputType(type);
		var newConfigArr = [];
		if (type === dataConfigInputTypes.DATA_LOOKUP) {
			newConfigArr = Array(7).fill("");
			newConfigArr[0] = type;
			// 1 = sheetName
			// 2 = displayColName
			// 3 = lookupColName
			// 4 = updatedBy e.g. attributeId
			// 5 = renderType
			// 6 = Ghostscript
		}
		else if (type === dataConfigInputTypes.MASKED_LOOKUP) {
			newConfigArr = Array(4).fill("");
			newConfigArr[0] = type;
			// 1 = Seperator
			// 2 = format
			// 3 = Ghostscript
		}
		else if (type === dataConfigInputTypes.DATA_CONCATENATE) {
			newConfigArr = Array(1).fill("");
			newConfigArr[0] = type;
			// 1+ - any length of concatenations
		}
		setDataConfigArr(newConfigArr);
	}

	const handleDataChange = (index, value) => {
		let newDataConfig = [...dataConfigArr];
		newDataConfig[index] = value;
		setDataConfigArr(newDataConfig);
	};

	const handleAddDataPart = (value) => {
		let newDataConfig = [...dataConfigArr, value];
		setDataConfigArr(newDataConfig);
	}

	const handleApplyChanges = () => {
		console.log("Config arr:", dataConfigArr);
		console.log("ARR TEST:", dataConfigArr.join('\n'));
		if (dataInputType !== "") {
			// Apply a stringified list
			onApplyConfig(JSON.stringify(dataConfigArr));
		}
	}

	const handleReset = () => {
		resetDataConfig();
	}

	console.log("Selected LOI data config builder", selectedLOI);

	console.log("DATA CONFIG ARR:", dataConfigArr);
	console.log("LOI DATA CONFIG:", loiDataConfigByName[selectedLOI]);

	if (!loiDataConfigByName[selectedLOI]) {
		return (
			<CircularProgress />
		);
	}

	return (
		<Grid item xs={12} container spacing={1}>
			<Grid item xs={12}>
				<Typography variant={"h6"}>
					Data Config Builder
				</Typography>
			</Grid>
			<GridDivider />
			<Grid item xs={6}>
				<SelectDropdown
					id={"dataInputType"}
					label={"Masked Input Type"}
					value={dataInputType}
					options={dataInputTypeList}
					onChange={(value) => handleSelectDataInputType(value)}
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Typography variant={"body"}>
					Type of input you wish to build
				</Typography>
			</Grid>
			<GridDivider />
			{dataInputType === dataConfigInputTypes.DATA_LOOKUP && (
				<DataLookupConfig
					dataConfigArr={dataConfigArr}
					onDataChange={handleDataChange}
					selectedLOI={selectedLOI}
				/>
			)}
			{dataInputType === dataConfigInputTypes.MASKED_LOOKUP && (
				<MaskedInputConfig
					dataConfigArr={dataConfigArr}
					onDataChange={handleDataChange}
				/>
			)}
			{dataInputType === dataConfigInputTypes.DATA_CONCATENATE && (
				<DataConcatenateConfig
					dataConfigArr={dataConfigArr}
					onDataChange={handleAddDataPart}
				/>
			)}
			<Grid item xs={12}>
				<Button variant="contained" color={"error"} onClick={handleReset} style={{ marginRight: '8px' }}>
					Reset
				</Button>
				<Button variant="contained" onClick={handleApplyChanges}>Apply</Button>
			</Grid>

		</Grid>
	);
}

export default DataConfigBuilder;