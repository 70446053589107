// Tree.js
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import '../SBTreeCore.css';

const SBBasicTreeView = ({ treeItems, onSelectItem }) => {
	const [selectedId, setSelectedId] = useState(null);

	const handleSelectItem = (nodeId) => {
		onSelectItem(nodeId);
		setSelectedId(nodeId);
	}

	const renderTree = (nodes, level = 0) => {
		return (
			<ul>
				{nodes.map((node) => (
					<li key={node.id}>
						<div
							style={{ paddingLeft: level * 10 }}
							className={`tree-node ${selectedId === node.id ? 'selected' : ''}`}
							onClick={() => handleSelectItem(node.id)}
						>
							<div className="tree-node-content">
								{node.children && node.children.length > 0 ? (
									<ExpandMoreIcon style={{ marginRight: 5 }} />
								) : (
									<ExpandLessIcon style={{ marginRight: 5, visibility: 'hidden' }} />
								)}
								<div className="tree-node-label">{node.label}</div>
							</div>
						</div>
						{node.children && (
							<div className="tree-node-children">
								{renderTree(node.children, level + 1)}
							</div>
						)}
					</li>
				))}
			</ul>
		);
	};

	return <div className="tree">{renderTree(treeItems)}</div>;
};

export default SBBasicTreeView;
