import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';


export async function loadProjects() {
	try {
		console.log("Loading Projects");
		if (DEV_TEST_MODE) {
			return loadJsonFile('get-projects-scoped.json');
		}

		const projectsEndpoint = endpoints.projects.bySiteId(getSiteId());

		console.log("Loading projects from: ", projectsEndpoint);
		let data = await springboardApiRequest(projectsEndpoint, "GET");
		downloadJsonData(data.data, "get-projects-scoped");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching project data:', error);
		throw new Error('Failed to fetch project data.');
	}
}

export async function loadProjectById(projectId) {
	try {
		console.log("Loading Projects");
		if (DEV_TEST_MODE) {
			return loadJsonFile('projectById.json');
		}

		const projectsEndpoint = endpoints.projects.byProjectId(projectId);

		console.log("Loading projects from: ", projectsEndpoint);
		let data = await springboardApiRequest(projectsEndpoint, "GET");
		downloadJsonData(data.data, "projectById");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching project data:', error);
		throw new Error('Failed to fetch project data.');
	}
}

export async function deleteProject(id) {
	try {
		const request = {};
		const data = await springboardApiRequest(endpoints.projects.delete(id), "POST", request);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting project data:', error);
		throw new Error('Failed to delete project data.');
	}
}

export async function createProject(project) {
	try {
		const data = await springboardApiRequest(endpoints.projects.create(), "POST", project);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating project data:', error);
		throw new Error('Failed to create project data.');
	}
}

export async function updateProject(project) {
	try {
		const data = await springboardApiRequest(endpoints.projects.update(), "POST", project);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating project data:', error);
		throw new Error('Failed to update project data.');
	}
}