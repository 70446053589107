

function mapNamesById(arr) {
	return arr.reduce((acc, item) => {
		acc[item.id] = item.name;
		return acc;
	}, {});
}

export function getSelectedDocuments(selectedProjects, documentsTeamsByProject) {
	if (selectedProjects === null)
		selectedProjects = Object.keys(documentsTeamsByProject);
	return selectedProjects.reduce((accumulator, projectId) => {
		// Filter out only the documents that match the current projectId
		const matchingDocuments = (documentsTeamsByProject[projectId] || []).filter(doc => doc.projectId === projectId);
		return accumulator.concat(matchingDocuments);
	}, []);
}

// USERS GRID

function calculateRowSpanForEmail(params) {
	if (params.data) {
		let nextRowData = params.api.getDisplayedRowAtIndex(params.node.rowIndex + 1)?.data;

		// If the next row's email is undefined, start calculating rowSpan
		if (nextRowData && nextRowData.email === undefined) {
			let rowSpan = 1;

			// Continue the loop while the email remains undefined
			while (true) {
				nextRowData = params.api.getDisplayedRowAtIndex(params.node.rowIndex + rowSpan)?.data;
				if (!nextRowData || nextRowData.email !== undefined) {
					// Break out of the loop when we encounter a row that is not undefined or has a defined email
					break;
				}
				rowSpan++;
			}
			return rowSpan;
		}
	}

	return 1; // Default rowSpan for other rows
}

export function getBaseColumnDefsForUserGrid() {
	return [
		{
			headerName: "Email",
			field: "email",
			// TODO: calculateRowSpan used to spread email over multiple rows - issues with sorting?
			//rowSpan: calculateRowSpanForEmail,
			cellClassRules: {
				'cell-span': (params) => {
					return params.value !== undefined;
				}
			},
			sortable: true,
		},
		{ headerName: "Name", field: "name", sortable: true },
		{ headerName: "Project Name", field: "projectName", sortable: true }
	];
}

export function getUniqueDocumentHeaders(documents) {
	const uniqueDocumentHeaders = new Set();
	documents.forEach(doc => {
		uniqueDocumentHeaders.add(doc.name);
	});
	return uniqueDocumentHeaders;
}

export function addDocumentColumns(columnDefs, uniqueDocumentHeaders) {
	uniqueDocumentHeaders.forEach(docName => {
		columnDefs.push({ headerName: docName, field: `document_${docName}` });
	});
	return columnDefs;
}

export function generateRowDataForUserGrid(users, documents, projects, uniqueDocumentHeaders, callbackProcessUser) {
	const rowData = [];
	const projectNamesById = mapNamesById(projects);

	users.forEach(user => {
		// Create an object to hold rows indexed by project
		const userRowsByProject = {};
		documents.forEach(doc => {
			const userInDoc = doc.teamMembers.find(u => u.userId === user.id);

			if (userInDoc) {
				const projectName = projectNamesById[doc.projectId];

				// If the user-row for this project hasn't been created yet, create it
				if (!userRowsByProject[projectName]) {
					userRowsByProject[projectName] = {
						// Set undefined for 2+ rows of same user for rowSpan grouping.
						//email: firstUserRow ? user.email : undefined,
						email: user.email,
						name: user.name,
						projectName: projectName
					};

					// Initialize all document fields to empty
					uniqueDocumentHeaders.forEach(docName => {
						userRowsByProject[projectName][`document_${docName}`] = '';
					});
				}

				userRowsByProject[projectName][`document_${doc.name}`] = callbackProcessUser(userInDoc);
			}
		});

		// Push each user row for projects into the final rowData
		Object.values(userRowsByProject).forEach(row => {
			rowData.push(row);
		});
	});

	return rowData;
}

export function generateRowDataForUserGridRoles(users, documents, projects, roles, uniqueDocumentHeaders) {
	const roleNamesById = mapNamesById(roles);
	const processUserForRoles = (user) => {
		return user.roles.map(roleId => roleNamesById[roleId] || 'Unnamed role');
	}
	return generateRowDataForUserGrid(users, documents, projects, uniqueDocumentHeaders, processUserForRoles);
}

export function generateRowDataForUserGridReport(users, documents, projects, roles, uniqueDocumentHeaders) {
	return generateRowDataForUserGrid(users, documents, projects, uniqueDocumentHeaders, (user) => user);
}
