import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { SiteDataContext } from '../../../contexts';
import { LoadingPage } from '../';
import { ApiStatusDisplay, SecurityButton } from '../../ui';
import { SiteLocationTreeView } from '../../ui/TreeViews';
import { SECURITY_FEATURES } from '../../../constants/SiteSecurityConstants';
import LocationDetails from './LocationDetails';
import EditSiteLocation from './EditSiteLocation';
import EditSiteModal from './EditSiteModal';

function findById(data, id) {
	for (const item of data) {
		if (item.id === id) {
			return item;
		}
		if (item.locations && item.locations.length > 0) {
			const found = findById(item.locations, id);
			if (found) {
				return found;
			}
		}
	}
	return null;
}

function SitePage() {
	const [selectedLocationId, setSelectedLocationId] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [openEditSite, setOpenEditSite] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		currentSiteData,
		siteLocations,
		loadSiteLocations,
		loadCurrentSite,
		loadUniclassVersions
	} = useContext(SiteDataContext);

	useEffect(() => {
		async function loadData() {
			setIsLoading(true);
			await Promise.all([
				loadSiteLocations(),
				loadCurrentSite(),
				loadUniclassVersions()
			]);
			setIsLoading(false);
		}
		loadData();
	}, [loadSiteLocations, loadCurrentSite, loadUniclassVersions]);

	useEffect(() => {
		if (!selectedLocationId)
			return;

		let foundItem = findById(siteLocations, selectedLocationId);
		console.log("FOUND ITEM:", foundItem);
		setSelectedLocation(foundItem);
	}, [selectedLocationId, siteLocations]);

	const handleItemSelection = (locationId) => {
		setIsEditing(false);
		setSelectedLocationId(locationId);
	}

	if (isLoading) {
		return <LoadingPage />
	}

	const isValidLocation = selectedLocation && selectedLocation.locationProperties;
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant={"h4"}>
					Site Config
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<ApiStatusDisplay />
			</Grid>
			<Grid item xs={3}>
				<Box border={1} borderColor="grey.300" p={2} height="100vh">
					<SiteLocationTreeView
						onSelectItem={handleItemSelection}
					/>
				</Box>
			</Grid>
			<Grid item xs={9}>
				<Box
					border={1}
					borderColor="grey.300"
					p={2}
					sx={{
						minHeight: '100vh',
						flexGrow: 1,
					}}
				>
					{isValidLocation ? (
						isEditing ? (
							<EditSiteLocation location={selectedLocation} onSave={() => setIsEditing(false)} />
						) : (
							<LocationDetails
								location={selectedLocation}
								onEdit={() => setIsEditing(true)}
								onEditSite={() => setOpenEditSite(true)}
							/>
						)
					) : (
						<Alert severity="info">No valid location selected</Alert>
					)}
				</Box>
				<EditSiteModal
					open={openEditSite}
					onClose={() => setOpenEditSite(false)}
					initialData={currentSiteData}
				/>
			</Grid>
		</Grid>
	);
}
//<LocationDetails location={selectedLocation} />
export default SitePage;