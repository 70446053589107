import React, { useContext } from 'react';
import { UserContext } from '../../../contexts';

function CompanyCellRenderer(props) {
	const { companyIdMap } = useContext(UserContext);
	const companyId = props.value;
	if (!companyId)
		return null;
	const companyName = companyIdMap[companyId]?.name;
	return (
		<>
			{companyName}
		</>
	);
}

export default CompanyCellRenderer;