import { diffChars } from 'diff';




export function detectCellChanges(dataOld, dataNew) {
	const changes = {};

	if (!dataNew)
		return {};

	dataOld.forEach((row, rowIndex) => {
		if (!changes[rowIndex]) {
			changes[rowIndex] = {};
		}

		Object.keys(row).forEach((key) => {
			const oldVal = row[key];
			const newVal = dataNew[rowIndex] ? dataNew[rowIndex][key] : null;

			if (oldVal !== newVal) {
				changes[rowIndex][key] = {
					old: oldVal,
					new: newVal
				};
			}
		});
	});

	return changes;
}

export function detectCellTextChanges(dataOld, dataNew) {
	const changes = {};

	dataOld.forEach((row, rowIndex) => {
		if (!changes[rowIndex]) {
			changes[rowIndex] = {};
		}

		Object.keys(row).forEach((key) => {
			const oldVal = row[key];
			const newVal = dataNew[rowIndex] ? dataNew[rowIndex][key] : null;

			if (oldVal !== newVal) {
				const diffs = diffChars(oldVal, newVal);
				changes[rowIndex][key] = diffs;
			}
		});
	});

	return changes;
}

export function getSelectedRowData(gridApi, dataSelectorFunc) {
	const cells = gridApi.getCellRanges();

	if (!cells || cells.length === 0)
		return [];

	let rowData = [];
	for (const cell of cells) {

		let startRow = cell.startRow.rowIndex;
		let endRow = cell.endRow.rowIndex;
		if (startRow > endRow) {
			[startRow, endRow] = [endRow, startRow]; // Swap to ensure start is less than end
		}

		for (let i = startRow; i <= endRow; i++) {
			const row = gridApi.getDisplayedRowAtIndex(i);
			rowData.push(dataSelectorFunc(row.data));
		}
	}
	return rowData;
}

export function getSelectedRowIds(gridApi) {
	return getSelectedRowData(gridApi, (data) => data.rowId);
}

export function getSelectedGridCellIds(gridApi) {
	const cellRanges = gridApi.getCellRanges();

	if (!cellRanges || cellRanges.length === 0)
		return [];

	let selectedCellIds = [];

	for (const cellRange of cellRanges) {
		let startRow = cellRange.startRow.rowIndex;
		let endRow = cellRange.endRow.rowIndex;
		if (startRow > endRow) {
			[startRow, endRow] = [endRow, startRow]; // Swap to ensure start is less than end
		}

		let columns = cellRange.columns;

		for (let i = startRow; i <= endRow; i++) {
			const row = gridApi.getDisplayedRowAtIndex(i);
			const rowId = row.data.rowId;

			columns.forEach(column => {
				selectedCellIds.push({
					rowId: rowId,
					colId: column.colId
				});
			});
		}
	}

	return selectedCellIds;
}

// Gets entire row of columns regardless of which selection has been made
export function getEntireSelectedRowGridCellIds(gridApi) {
	let rowIds = getSelectedRowIds(gridApi);

	let centerColumns = gridApi.getDisplayedCenterColumns();
	if (!centerColumns || centerColumns.length === 0)
		return [];

	let selectedCellIds = rowIds.map(rowId => {
		return centerColumns.map(column => {
			return {
				rowId: rowId,
				colId: column.colId
			}
		});
	}).flat();
	return selectedCellIds;
}