import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Typography,
	TextField,
} from '@mui/material';
import {
	SelectDropdown,
	InputError
} from '../../ui';
import { LOIContext, ProjectContext } from '../../../contexts';
import SelectableLoaderModal from '../SelectableLoaderModal';
import ErrorModal from '../ErrorModal';
import FileTableDisplay from './FileTableDisplay';
import DataMaskFileUploadModal from '../FileUploadModal/DataMaskFileUploadModal';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';
import UploadStatusDisplay from './UploadStatusDisplay';

const formValidators = {
	selectedFileId: [Validation.validateNotEmpty],
	newLOIName: [Validation.validateMinLength(2), Validation.validateMaxLength(255)],
}

const MAX_WIDTH = "xl";
const MODAL_TITLE = "Import LOI"

const SCOPE_LIST = [
	{
		id: "SITE",
		name: "SITE"
	},
	{
		id: "Project",
		name: "Project"
	},
]

function ImportLOI({ projectId, open, onClose }) {
	const {
		loiImportOptionsByProjectId,
		loadLoiImportOptions,
		loiNamesSelectList,
		importLOI,
	} = useContext(LOIContext);
	const { projects } = useContext(ProjectContext);

	const [formErrors, setFormErrors] = useState({});
	const [newLOIName, setNewLOIName] = useState("");
	const [selectedProjectId, setSelectedProjectId] = useState(projectId === "SITE" ? "" : projectId);
	const [selectedScope, setSelectedScope] = useState("SITE");
	const [selectedDataMaskId, setSelectedDataMaskId] = useState("");
	const [selectedFileId, setSelectedFileId] = useState("");
	const [loiCopyOptions, setLoiCopyOptions] = useState("");
	const [openFileUpload, setOpenFileUpload] = useState(false);
	const [fileUploadState, setFileUploadState] = useState({ success: null, filename: '' });

	const importOptions = loiImportOptionsByProjectId[selectedProjectId];

	useEffect(() => {
		if (!open) {
			setFileUploadState({ success: null, filename: '' });
		}
	}, [open]);

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(newLOIName, "newLOIName", formValidators, newFormErrors);
		Validation.setFieldErrors(selectedFileId, "selectedFileId", formValidators, newFormErrors);

		console.log("FORM ERRORS:", newFormErrors);
		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	const handleProcessClick = () => {
		if (!isFormValid())
			return;

		const selectedFile = importOptions.files.find(file => file.id === selectedFileId);

		const loiUpload = {
			loiId: importOptions.uploadId,
			DataMaskLookupFile: null,
			SelectedAIDPId: selectedFile?.id,
			SelectedAIDPDocumentId: selectedFile?.documentId,
			NewLOIName: newLOIName,
			CopyConfigFrom: loiCopyOptions,
			ProjectId: selectedScope === "SITE" ? null : selectedProjectId
		};
		console.log("SAVE IMPORT LOI CLICKED");
		onClose();
		importLOI(loiUpload);
	};

	const handleSelectProject = (chosenProjectId) => {
		setSelectedProjectId(chosenProjectId);
		loadLoiImportOptions(chosenProjectId);
		setSelectedFileId("");
	}

	const handleUploadComplete = (success, filename) => {
		setFileUploadState({ success, filename });
	}

	const dataLoading = (!importOptions) && selectedProjectId !== "";
	const dataLoaded = importOptions ? true : false;
	const shouldDisableDataMaskUpload = newLOIName.length === 0;
	console.log("IMPORT: ", importOptions);

	if (!dataLoaded) {
		return <SelectableLoaderModal
			maxWidth={"xs"}
			title={MODAL_TITLE}
			open={open}
			onClose={onClose}
			loading={dataLoading}
		>
			<SelectDropdown
				id={"project"}
				value={selectedProjectId}
				onChange={handleSelectProject}
				options={projects}
				fullWidth
			/>
		</SelectableLoaderModal>
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth={MAX_WIDTH}
		>
			<DialogTitle variant="h5">
				{MODAL_TITLE}
			</DialogTitle>
			<DialogContent>
				<br />
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<SelectDropdown
							id={"project"}
							value={selectedProjectId}
							onChange={handleSelectProject}
							options={projects}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectDropdown
							id={"scope"}
							value={selectedScope}
							onChange={setSelectedScope}
							options={SCOPE_LIST}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<TextField
							size="small"
							label="New LOI Name"
							variant="outlined"
							value={newLOIName}
							onChange={(e) => setNewLOIName(e.target.value)}
							placeholder="Enter New LOI Name"
							fullWidth
						/>
						<InputError
							field="New LOI Name"
							errors={formErrors["newLOIName"]}
						/>
					</Grid>
					<Grid item xs={4}>
						<SelectDropdown
							id={"loiOptions"}
							label={"LOI to copy options from"}
							value={loiCopyOptions}
							options={loiNamesSelectList}
							onChange={setLoiCopyOptions}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="body">
							Data Mask Lookup File (Optional)&nbsp;
						</Typography>
						<Button
							disabled={shouldDisableDataMaskUpload}
							variant="contained"
							onClick={() => setOpenFileUpload(true)}
							color="primary">
							Upload
						</Button>
						<UploadStatusDisplay
							success={fileUploadState.success}
							filename={fileUploadState.filename}
						/>
						<DataMaskFileUploadModal
							loiName={newLOIName}
							open={openFileUpload}
							onClose={() => setOpenFileUpload(false)}
							onComplete={handleUploadComplete}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body">
							Click a row to select it!
						</Typography>
						<FileTableDisplay
							files={importOptions.files}
							selectedFileId={selectedFileId}
							setSelectedFileId={setSelectedFileId}
						/>
						<InputError
							field="Selected File"
							errors={formErrors["selectedFileId"]}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleProcessClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Process
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default ImportLOI;