import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

function StepperManager({ steps, activeStep }) {
	return (
		<Box sx={{ width: '100%' }}>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label, index) => (
					<Step key={index} color={"primary"}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}

StepperManager.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeStep: PropTypes.number.isRequired,
};

export default StepperManager;