import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	ButtonGroup,
	TableRow,
	TableCell,
} from '@mui/material';
import SortableTable from './SortableTable';

const SelectableTable = ({
	data,
	onChange,
	columns,
	selectedItems = [],
	allowMultiple = false,
	rowRenderer = null
}) => {
	const [internalSelectedItems, setInternalSelectedItems] = useState(selectedItems);

	// This useEffect creates an infinite loop when removing users
	// useEffect(() => {
	// 	setInternalSelectedItems(selectedItems);
	// }, [selectedItems]);

	const handleItemClick = (item) => {
		let newSelectedItems;

		if (allowMultiple) {
			const isSelected = internalSelectedItems.includes(item.id);
			if (isSelected) {
				newSelectedItems = internalSelectedItems.filter(itemId => itemId !== item.id);
			} else {
				newSelectedItems = [...internalSelectedItems, item.id];
			}
		} else {
			newSelectedItems = [item.id];
		}

		setInternalSelectedItems(newSelectedItems);
		if (onChange) {
			onChange(allowMultiple ? newSelectedItems : newSelectedItems[0]);
		}
	};

	const handleSelectAll = () => {
		const allItemIds = data.map(item => item.id);
		setInternalSelectedItems(allItemIds);
		if (onChange) {
			onChange(allItemIds);
		}
	};

	const handleDeselectAll = () => {
		setInternalSelectedItems([]);
		if (onChange) {
			onChange([]);
		}
	};

	return (
		<>
			{allowMultiple && (
				<ButtonGroup variant="outlined" size="small">
					<Button onClick={handleSelectAll}>Select All</Button>
					<Button onClick={handleDeselectAll}>Deselect All</Button>
				</ButtonGroup>
			)}
			<SortableTable data={data} columns={columns}>
				{(displayedItems) => (
					<>
						{displayedItems.map((item) => {
							const isSelected = internalSelectedItems.includes(item.id);
							return (
								<TableRow
									key={item.id}
									onClick={() => handleItemClick(item)}
									style={{ cursor: 'pointer', backgroundColor: isSelected ? 'lightblue' : 'white' }}
								>
									{rowRenderer
										? rowRenderer(item, isSelected)
										: columns.map((column) => (
											<TableCell key={column.field}>{item[column.field]}</TableCell>
										))}
								</TableRow>
							);
						})}
					</>
				)}
			</SortableTable>
		</>
	);
};

SelectableTable.propTypes = {
	selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	onChange: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		field: PropTypes.string.isRequired,
		headerName: PropTypes.string.isRequired,
	})).isRequired,
	allowMultiple: PropTypes.bool,
};

export default SelectableTable;
