import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './customAgGrid.css';
import {
	getSelectedDocuments,
	getBaseColumnDefsForUserGrid,
	getUniqueDocumentHeaders
} from '../../helpers/AgGrid/UserGridHelpers';
import { UserContext, ProjectContext } from '../../contexts/';

const AgGridUserView = ({ generateRowDataFunction, cellStyleFunction, cellRendererFunction, selectedProjects, valueFormatterFunction }) => {
	const [columnDefs, setColumnDefs] = useState([]);
	const [agGridData, setAgGridData] = useState([]);
	const { users, roles } = useContext(UserContext);
	const { projects, documentsTeamsByProject } = useContext(ProjectContext);

	useEffect(() => {
		const processData = () => {
			console.log("Reprocessing Grid data!");
			const documents = getSelectedDocuments(selectedProjects, documentsTeamsByProject);
			let columnDefs = getBaseColumnDefsForUserGrid();
			const uniqueDocumentHeaders = getUniqueDocumentHeaders(documents);
			const rowData = generateRowDataFunction(users, documents, projects, roles, uniqueDocumentHeaders);

			uniqueDocumentHeaders.forEach(docName => {
				columnDefs.push({
					headerName: docName,
					field: `document_${docName}`,
					width: 240,
					cellStyle: cellStyleFunction,
					cellRenderer: cellRendererFunction,
					autoHeight: true,
					sortable: true,
					valueFormatter: valueFormatterFunction,
				});
			});
			setColumnDefs(columnDefs);
			setAgGridData(rowData);
		};

		processData();
	}, [documentsTeamsByProject, projects, selectedProjects, roles, users, generateRowDataFunction, cellStyleFunction, cellRendererFunction, valueFormatterFunction]);
	const gridOptions = {
		suppressScrollOnNewData: true,
		enableSorting: true,
	};
	return (
		<Grid item xs={12}>
			<div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
				<AgGridReact
					columnDefs={columnDefs}
					rowData={agGridData}
					{...gridOptions}
				>
				</AgGridReact>
			</div>
		</Grid>
	);
};

export default AgGridUserView;