import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormGroup,
	Typography,
	TextField,
	Stack
} from '@mui/material';
import {
	SelectDropdown,
	BasicTxtField,
	InputError
} from '../../ui';
import { DocumentContext, ProjectContext, SiteDataContext } from '../../../contexts';
import SiteLocationsComponent from './SiteLocationsComponent';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	name: [Validation.validateNotEmpty],
	locationIds: [Validation.validateNotEmptyArray],
	weightedValue: [Validation.isNotNumber]
}

const DocumentPage = ({ documentId, setEdit, handleProjectChange }) => {
	const params = new URLSearchParams(window.location.search);
	const siteId = params.get('S');

	const [busy, setBusy] = useState(true);
	const [document, setDocument] = useState(null);
	const [workflows, setWorkflows] = useState([]);
	const [responsibleUsers, setResponsibleUsers] = useState([]);
	const [masterDocs, setMasterDocs] = useState([]);
	const [formErrors, setFormErrors] = useState({});

	const {
		projects
	} = useContext(ProjectContext);
	const {
		loadDocumentById,
		loadUsersResponsibleForDocument,
		loadWorkflowsForSite,
		loadMasterContentDocs,
		updateDocument
	} = useContext(DocumentContext);

	const {
		siteLocations,
		loadSiteLocations
	} = useContext(SiteDataContext);


	useEffect(() => {
		setBusy(true);

		async function getApis() {
			const responses = await Promise.all([
				loadDocumentById(documentId),
				loadWorkflowsForSite(siteId),
				loadUsersResponsibleForDocument(documentId),
				loadMasterContentDocs(),
				loadSiteLocations()
			]);
			const documentData = await responses[0];
			const workflowsData = await responses[1];
			const responsibleUsersData = await responses[2];
			const masterDocsData = await responses[3];

			setDocument(documentData);

			const renamedWorkflows = workflowsData.map(({ title, id }) => ({ name: title, id: id }));
			setWorkflows(renamedWorkflows);

			const renamedResponsibleUsers = responsibleUsersData.map(({ email, id }) => ({ name: email, id: id }));
			setResponsibleUsers(renamedResponsibleUsers);

			setMasterDocs(masterDocsData);

			setBusy(false);
		}

		getApis();
	}, [
		documentId,
		loadDocumentById,
		loadWorkflowsForSite,
		siteId,
		loadUsersResponsibleForDocument,
		loadMasterContentDocs,
		loadSiteLocations
	])

	// useEffect(() => {
	// 	console.log('edited document', document);
	// }, [document])

	const handleDataChange = (propertyName, newValue) => {
		setDocument(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const documentData = await updateDocument(document);

		if (!documentData) {
			return setBusy(false);
		}
		else {
			setEdit(false);
			handleProjectChange(document.projectId);
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(document.name, "name", formValidators, newFormErrors);
		Validation.setFieldErrors(document.locationIds, "locationIds", formValidators, newFormErrors);
		Validation.setFieldErrors(document.weightedValue, "weightedValue", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
							<Typography variant="body1">Edit document</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<BasicTxtField
							id={"Document name"}
							label="Document name"
							value={document.name}
							onChange={(value) => handleDataChange("name", value)}
							fullWidth={true}
							errors={formErrors['name']}
							required
						/>
						<TextField
							id={"API tags"}
							label="API tags"
							value={document.tags.join('\n')}
							onChange={(value) => handleDataChange("tags", value.target.value.split('\n'))}
							multiline
							rows={4}
							helperText="One per line"
						/>
						<BasicTxtField
							id={"Document weighting"}
							label="Document weighting"
							value={document.weightedValue}
							onChange={(value) => handleDataChange("weightedValue", value)}
							fullWidth={true}
							helperText="Range to be from 1 to 999"
							errors={formErrors['weightedValue']}
							type="number"
							required
						/>
						<SelectDropdown
							id={"Projects"}
							value={document.projectId}
							onChange={(value) => handleDataChange("projectId", value)}
							options={projects}
							fullWidth={true}
						/>
						<SelectDropdown
							id={"Workflow"}
							value={document.workflowId}
							onChange={(value) => handleDataChange("workflowId", value)}
							options={workflows}
							fullWidth={true}
						/>
						<SelectDropdown
							id={"Default responsibility"}
							value={document.responsibleUserId}
							onChange={(value) => handleDataChange("responsibleUserId", value)}
							options={responsibleUsers}
							fullWidth={true}
						/>
						<SelectDropdown
							id={"Master content location"}
							value={document.masterDocumentId}
							onChange={(value) => handleDataChange("masterDocumentId", value)}
							options={masterDocs}
							fullWidth={true}
						/>
						<FormControlLabel
							control={
								<Checkbox
									size='small'
									checked={document.excludeFromReporting}
									onChange={(value) => handleDataChange("excludeFromReporting", value.target.checked)}
								/> }
							label="Remove from reporting"
						/>
						<Divider />
						<Typography variant="body1">Tag locations to document</Typography>
						<FormGroup errors={formErrors['locationIds']}>
							<SiteLocationsComponent locations={siteLocations} document={document} handleDataChange={handleDataChange} />
							<InputError field="Locations" errors={formErrors["locationIds"]} />
						</FormGroup>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setEdit(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)
}
export default DocumentPage;