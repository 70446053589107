import React from 'react';
import * as Api from '../api/AdminApi';
export const DocumentContext = React.createContext(null);

export class DocumentProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			documents: []
		};
	}

	loadDocumentsBySite = async () => {
		try {
			const documents = await Api.loadDocumentsBySite();
			this.setState({ documents: documents })
			return documents;
		} catch (error) {
			console.error('Error loading documents by site', error);
		}
	}

	loadDocumentsByProject = async (projectId) => {
		try {
			const documents = await Api.loadDocumentsByProject(projectId);
			return documents;
		} catch (error) {
			console.error(`Error loading documents by project ${projectId}:`, error);
		}
	}

	loadDocumentById = async (id) => {
		try {
			const document = await Api.loadDocumentById(id);
			return document;
		} catch (error) {
			console.error(`Error loading document by id ${id}:`, error);
		}
	}

	loadUsersResponsibleForDocument = async (documentId) => {
		try {
			const users = await Api.loadAllowedResponsibleUsers(documentId);
			return users;
		} catch (error) {
			console.error(`Error loading responsible users for document ${documentId}:`, error);
		}
	}

	loadMasterContentDocs = async () => {
		try {
			const documents = await Api.loadMasterContentDocs();
			return documents;
		} catch (error) {
			console.error("Error loading master content documents:", error);
		}
	}

	loadResponsibleUsersById = async (userIds) => {
		try {
			const uniqueUserIds = Array.from(new Set(userIds));
			const userData = await Api.loadUsers(uniqueUserIds);
			return userData;
		} catch (error) {
			console.error(`Error loading responsible users by id ${userIds}:`, error);
		}
	}

	updateDocument = async (documentData) => {
		try {
			const document = await Api.updateDocument(documentData);
			return document;
		} catch (error) {
			console.error("Error updating document:", error);
		}
	}

	createDocument = async (documentData) => {
		try {
			const document = await Api.createDocument(documentData);
			return document;
		} catch (error) {
			console.error("Error creating document:", error);
		}
	}

	deleteDocumentById = async (id) => {
		try {
			const document = await Api.deleteDocument(id);
			return document;
		} catch (error) {
			console.error(`Error deleting document by id ${id}:`, error);
		}
	}

	loadWorkflowsForSite = async (siteId) => {
		try {
			const workflows = await Api.loadWorkflowsForSite(siteId);
			return workflows;
		} catch (error) {
			console.error("Error loading workflows for site:", error);
		}
	}

	render() {
		const documentContextValue = {
			cachedDocuments: this.state.documents,
			loadDocumentsBySite: this.loadDocumentsBySite,
			loadDocumentsByProject: this.loadDocumentsByProject,
			loadDocumentById: this.loadDocumentById,
			loadUsersResponsibleForDocument: this.loadUsersResponsibleForDocument,
			loadWorkflowsForSite: this.loadWorkflowsForSite,
			loadMasterContentDocs: this.loadMasterContentDocs,
			loadResponsibleUsersById: this.loadResponsibleUsersById,
			updateDocument: this.updateDocument,
			createDocument: this.createDocument,
			deleteDocumentById: this.deleteDocumentById,
		}

		return (
			<DocumentContext.Provider value={documentContextValue}>
				{this.props.children}
			</DocumentContext.Provider>
		);
	}
}

export default DocumentProvider;