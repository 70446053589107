import React, { createContext, useState, useEffect, useCallback } from 'react';
import * as Api from '../api/AdminApi';
import * as SecurityDBHelp from '../helpers/Database/SiteSecurityDBHelpers';
export const SiteSecurityContext = createContext();

export const SiteSecurityProvider = ({ children }) => {
	const [dataLoaded, setDataLoaded] = useState(false);
	const [securityLoaded, setSecurityLoaded] = useState(false);
	const [mySecurity, setMySecurity] = useState({});
	const [whoIAm, setWhoIAm] = useState({});
	const [siteRoles, setSiteRoles] = useState([]);
	const [globalSecurityRoles, setGlobalSecurityRoles] = useState([]);
	const [globalSecuritySettings, setGlobalSecuritySettings] = useState([]);
	const [featureAccessDictionary, setFeatureAccessDictionary] = useState({});

	const isUserSiteAdmin = useCallback(() => {
		return whoIAm.isSiteAdmin;
	}, [whoIAm]);

	const isUserMasterAdmin = useCallback(() => {
		return whoIAm.isMasterAdmin;
	}, [whoIAm]);

	const loadRoles = useCallback(async () => {
		try {
			let rolesData = await Api.loadRoles();
			setSiteRoles(rolesData);
			return rolesData;
		} catch (error) {
			console.error("Error loading roles:", error);
		}
	}, []);

	const loadGlobalSecurity = useCallback(async () => {
		try {
			let securityRoles = await Api.getSecurityRolesBySiteId();
			let globalSecurity = await Api.getGlobalSecurity();
			globalSecurity.forEach(sec => {
				const appliedRoles = securityRoles.filter(role => role.securityId === sec.id);
				sec.roles = appliedRoles;
				// Role Names used for security page.
				sec.roleNames = appliedRoles.map(role => { return role.name }).join(', ');
			});
			setGlobalSecuritySettings(globalSecurity);
			setGlobalSecurityRoles(securityRoles);
			return globalSecurity;
		} catch (error) {
			console.error("Error loading security settings:", error);
		}
	}, []);

	const loadMySecurity = useCallback(async () => {
		try {
			const mySecurity = await Api.getMySecurity();
			// Transform security data to simplify.
			const transformedMySecurity = mySecurity.map(item => ({
				documentId: item.documentId,
				name: item.name,
				projectId: item.projectId,
				roles: item.teamMembers.flatMap(member => member.roles)
			}));
			setMySecurity(transformedMySecurity);
			return transformedMySecurity;
		} catch (error) {
			console.error(`Error loading my security:`, error);
		}
	}, []);

	const loadWhoIAm = useCallback(async () => {
		try {
			const whoIAmData = await Api.getWhoIAm();
			console.log("WHO AM I:", whoIAmData);
			setWhoIAm(whoIAmData);
			return whoIAmData;
		} catch (error) {
			console.error('Error loading who i am', error);
		}
	}, []);

	const loadAllSecurity = useCallback(async () => {
		const [whoIAmData, mySecurityData, roles, globalSecurity] = await Promise.all([
			loadWhoIAm(),
			loadMySecurity(),
			loadRoles(),
			loadGlobalSecurity(),
		]);

		setSecurityLoaded(false);

		const isSiteAdmin = whoIAmData.isSiteAdmin;
		console.log("IS SITE ADMIN:", isSiteAdmin);

		const featureAccessDictionary = SecurityDBHelp.createFeatureAccessDictionary(globalSecurity, mySecurityData, isSiteAdmin);

		setSecurityLoaded(true);
		setFeatureAccessDictionary(featureAccessDictionary);

		console.log("FEATURE DICTIONARY:", featureAccessDictionary);
	}, [loadWhoIAm, loadMySecurity, loadRoles, loadGlobalSecurity]);

	useEffect(() => {
		console.log("LOADING SECURITY, data loaded:", dataLoaded);
		if (!dataLoaded) {
			setDataLoaded(true);
			loadAllSecurity();
		}
	}, [dataLoaded, loadAllSecurity]);

	const isFeatureAllowed = useCallback((featureName, featureSecurityId) => {
		console.log("FEATURE TEST 1");
		if (!securityLoaded)
			return false;
		if (isUserSiteAdmin() || isUserMasterAdmin())
			return true;
		console.log("FEATURE TEST 2");
		const featureAccessDict = featureAccessDictionary;
		const featureAccess = featureAccessDict[featureName];

		if (!featureAccess)
			return false;

		if (featureSecurityId === undefined) {
			console.warn("Warning: Feature security ID Not defined for feature:", featureName);
			return false;
		}

		return featureAccess[featureSecurityId] === true;
	}, [securityLoaded, featureAccessDictionary, isUserSiteAdmin, isUserMasterAdmin]);

	const securityContextValue = {
		isSiteAdmin: isUserSiteAdmin(),
		securityLoaded: securityLoaded,
		isFeatureAllowed: isFeatureAllowed
	};

	return (
		<SiteSecurityContext.Provider value={securityContextValue}>
			{children}
		</SiteSecurityContext.Provider>
	);
};
