
import Swal from 'sweetalert2'
import { getAPIKey, getSiteId } from './configManager';
import { toCamelCase, generateRandomID } from "../helpers/GeneralHelpers";

const APP_ID = `${process.env.REACT_APP_APP_ID}`;
const MAX_UPLOAD_RETRIES = 5;

export async function springboardApiRequest(endpoint, method, data = null, customHeaders = null) {
	try {
		validateMethodReq(method);
		const myHeaders = getApiHeaders(customHeaders);
		var requestOptions = getRequestOptions(method, myHeaders, data);

		const rawResponse = await fetch(endpoint, requestOptions);
		console.log("RESPONSE RAW");
		console.log(rawResponse);

		// Check for success status codes before processing the response
		if (!rawResponse.ok) {
			let error = await getApiErrorMessage(rawResponse);
			throw new Error(error);
		}
		// Added for testing...
		const jsonResponse = await rawResponse.json();
		console.log("JSON Response: ", jsonResponse);
		const response = toCamelCase(jsonResponse);
		console.log("Server Response:");
		console.log(response);
		return response.result;
	} catch (error) {
		Swal.fire({
			title: 'Error!',
			text: error,
			icon: 'error',
			confirmButtonText: 'Ok'
		});
		console.error("Error with endpoint: ", endpoint);
		console.error(`Error while performing api request Method: ${method} error: ${error}`);
		throw error;
	}
}

export async function springboardUploadFileRequest(endpoint, fileInfo, progressCallback = null) {
	const chunkSize = 5000000; // Size of each file chunk
	const totalChunks = Math.ceil(fileInfo.size / chunkSize);
	let currentChunk = 0;

	const fileNameWithExtension = fileInfo.name;
	const uploadTime = new Date().toISOString();
	const fileId = generateRandomID(30) + "_" + fileNameWithExtension;

	console.log("FILE DATA:", fileInfo);

	const arrayBuffer = await fileInfo.arrayBuffer();

	while (currentChunk < totalChunks) {
		const start = currentChunk * chunkSize;
		const end = Math.min(start + chunkSize, fileInfo.size);
		const chunk = arrayBuffer.slice(start, end); // Get the current chunk


		console.log("chunk:", chunk);
		// Prepare form data
		const params = {
			name: fileId,
			originalName: fileNameWithExtension,
			Chunk: currentChunk.toString(),
			Chunks: totalChunks.toString(),
			dateUploadStart: uploadTime,
		};
		currentChunk++;
		const queryString = generateQueryString(params);
		const url = `${endpoint}?${queryString}`;

		let formData = new FormData();
		//formData.append("file", chunk, fileNameWithExtension);
		// const arrayBuffer = await chunk.arrayBuffer();
		const byteArray = new Uint8Array(chunk);
		formData.append("file", chunk);

		for (let [key, value] of formData.entries()) {
			console.log(key, value);
		}
		const completedChunksProgress = (currentChunk - 1) / totalChunks * 100;
		// Attempt to upload the chunk with progress reporting
		let success = await uploadChunkWithRetryAndProgress(url, byteArray, (progress) => {
			if (progressCallback !== null) {
				const currentChunkProgress = (progress / 100) / totalChunks * 100;
				progressCallback(currentChunkProgress + completedChunksProgress);
			}
			console.log(`Uploading chunk ${currentChunk}/${totalChunks}: ${progress}%`);
		});

		console.log("Upload complete: ", success);
		if (!success) {
			throw new Error(`Upload of chunk ${currentChunk} for file ${fileNameWithExtension} failed.`);
		}
	}

	console.log(`File ${fileNameWithExtension} uploaded successfully.`);
	// Return data used to process file.
	return {
		name: fileId,
		originalName: fileNameWithExtension,
		dateUploadStart: uploadTime,
	};
}

async function uploadChunkWithRetryAndProgress(endpoint, formData, onProgress) {
	const initialDelay = 500; // milliseconds
	let retries = 0;
	let success = false;

	while (retries < MAX_UPLOAD_RETRIES && !success) {
		success = await new Promise((resolve) => {
			const xhr = getUploadHeaders(endpoint);

			xhr.upload.onprogress = function (event) {
				if (event.lengthComputable) {
					const percentComplete = Math.round((event.loaded / event.total) * 100);
					onProgress(percentComplete);
				}
			};

			xhr.onload = function () {
				if (xhr.status === 200) {
					resolve(true);
				} else {
					resolve(false);
				}
			};

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) { // Done
					if (xhr.status === 500) { // Internal Server Error
						console.log('Server returned an error:', xhr);
					} else if (xhr.status >= 200 && xhr.status < 300) {
						// Handle success
						console.log('Success:', xhr.responseText);
					}
				}
			};

			xhr.onerror = function () {
				console.error('Network error');
				resolve(false);
			};

			xhr.send(formData);
		});

		if (!success) {
			// Exponential backoff
			const delay = Math.pow(2, retries) * initialDelay;
			console.log("Failure retry in:", delay);
			await new Promise(resolve => setTimeout(resolve, delay));
			retries++;
		}
	}

	return success;
}

function validateMethodReq(method) {
	const validMethods = ['GET', 'POST'];
	if (!validMethods.includes(method.toUpperCase())) {
		throw new Error(`Invalid method: ${method}. Must be one of ${validMethods.join(", ")}`);
	}
}

function getUploadHeaders(endpoint) {
	const xhr = new XMLHttpRequest();
	xhr.open("POST", endpoint);

	xhr.setRequestHeader("Content-Type", "multipart/form-data");
	xhr.setRequestHeader("X-Version", "1.0");
	xhr.setRequestHeader("Authorization", `Digest username="${APP_ID}" realm="_root" password="${getAPIKey()}"`);
	return xhr;
}

function getApiHeaders(customHeaders) {
	const myHeaders = new Headers();
	myHeaders.append("X-Version", "1.0");
	myHeaders.append("Authorization", `Digest username="${APP_ID}" realm="_root" password="${getAPIKey()}"`);
	myHeaders.append("siteId", `${getSiteId()}`);
	if (customHeaders && customHeaders.documentId) {
		myHeaders.append("documentId", customHeaders.documentId);
	}
	return myHeaders;
}

function getRequestOptions(method, headers, data) {
	let options = {
		method: method,
		headers: headers,
		redirect: 'follow'
	};

	if (method.toUpperCase() === 'POST') {
		if (data === null)
			throw new Error("Warning: POST method attempted but data is null!");
		options.body = JSON.stringify(data);
		options.headers.append("Content-Type", "application/json");
	}

	return options;
}

async function getApiErrorMessage(rawResponse) {
	const responseText = await rawResponse.text();
	try {
		// Get server response error:
		const errorResponse = JSON.parse(responseText);
		if (errorResponse.hasError) {
			return errorResponse.error.message
		}
	} catch (parseError) {
		// Handle the case where the text is not valid JSON
		console.error('Error parsing response:', parseError);
		return `HTTP error, status = ${rawResponse.status} ${rawResponse.statusText}`;
	}
}

function generateQueryString(params) {
	return Object.keys(params)
		.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join('&');
}


