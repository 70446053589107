import React from 'react';
import apiRequestManager from '../api/Services/ApiRequestManager';
import * as Api from '../api/AdminApi';
import { hasDataLoaded, sortArrByProperty } from '../helpers/GeneralHelpers';
export const UserContext = React.createContext(null);

export class UserProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			companies: [],
			companyIdMap: {},
			users: [],
			userIdMap: {},
			roles: [],
			securityRoles: [],
			rolesLoaded: false,
		};
	}

	componentDidMount() {
		this.performInitialDataLoad();
	}

	performInitialDataLoad = async () => {
		if (!this.state.rolesLoaded) {
			await this.loadRoles();
			await this.loadSecurityRolesBySiteId();
			this.setState({ rolesLoaded: true });
		}
	}

	addCompaniesToSite = (companiesToAdd) => {
		if (companiesToAdd.length === 0)
			return;
		this.setState(prevState => {
			// Filtering out new companies whose ids are already in the state
			const filteredNewCompanies = companiesToAdd.filter(newCompany => {
				return !prevState.companies.some(existingCompany => existingCompany.id === newCompany.id);
			});
			const updatedCompanies = [...prevState.companies, ...filteredNewCompanies];
			const companyIdMap = updatedCompanies.reduce((acc, company) => {
				acc[company.id] = company;
				return acc;
			}, {});
			return {
				companies: sortArrByProperty(updatedCompanies, "name"),
				companyIdMap: companyIdMap
			};
		});
	}

	loadCompaniesByIds = async (companyIdsToLoad) => {
		try {
			const uniqueCompanyIds = [...new Set(companyIdsToLoad)];
			// Filter out companies already loaded
			console.log("STATE:", this.state);
			const newCompanyIds = uniqueCompanyIds.filter(companyId => {
				return !this.state.companies.some(company => company.id === companyId);
			});
			// if any companies are not loaded, request from server.
			if (newCompanyIds.length > 0) {
				let companiesData = await Api.loadCompaniesByIds(newCompanyIds);
				this.addCompaniesToSite(companiesData);
			}
		} catch (error) {
			console.error("Error loading companies:", error);
		}
	}

	searchCompanyByName = async (name) => {
		try {
			let companiesFound = await Api.searchCompanyByName(name);
			this.addCompaniesToSite(companiesFound);
			console.log("FOUND COMPANY:", companiesFound);
			return companiesFound;
		} catch (error) {
			console.error("Error during company search", error);
		}
	}

	addUsersToSite = (usersData) => {
		this.setState(prevState => {
			// Filtering out new users whose userIds are already in the state
			const filteredNewUsers = usersData.filter(newUser => {
				return !prevState.users.some(existingUser => existingUser.id === newUser.id);
			});
			console.log("FILTERED MEMBERS:");
			console.log(filteredNewUsers);

			// Combining existing users and new filtered users
			const updatedUsers = [...prevState.users, ...filteredNewUsers];

			// Creating a dictionary with user IDs as keys and user objects as values
			const userIdMap = updatedUsers.reduce((acc, user) => {
				acc[user.id] = user;
				return acc;
			}, {});

			return {
				users: sortArrByProperty(updatedUsers, "name"),
				userIdMap: userIdMap
			};
		});
	}

	loadUsersById = async (userIdsToLoad) => {
		try {
			console.log("LOAD USERS:", userIdsToLoad);
			// Ensure passed userIds are unique:
			const uniqueUserIds = [...new Set(userIdsToLoad)];
			// Filter out users already loaded
			console.log("STATE:", this.state);
			const newUserIds = uniqueUserIds.filter(userId => {
				return !this.state.users.some(user => user.id === userId);
			});
			// if any users are not loaded, request from server.
			if (newUserIds.length > 0) {
				let usersData = await Api.loadUsers(newUserIds);
				this.addUsersToSite(usersData);
			}
		} catch (error) {
			console.error("Error loading users:", error);
		}
	}

	// ROLES:
	loadRoles = async () => {
		try {
			let rolesData = await Api.loadRoles();
			this.setState({ roles: rolesData });
		} catch (error) {
			console.error("Error loading roles:", error);
		}
	}

	loadSecurityRolesBySiteId = async () => {
		try {
			let response = await Api.getSecurityRolesBySiteId();
			this.setState({ securityRoles: Array.from(new Set(response.map(({ roleId }) => roleId))) });
		} catch (error) {
			console.error("Error loading security roles:", error);
		}
	}

	editSiteRole = async (updatedRole) => {
		try {
			const response = await Api.updateRole(updatedRole);
			this.setState((prevState) => ({
				roles: prevState.roles.map((role) =>
					role.id === response.data[0].id ? response.data[0] : role
				)
			}));
		} catch (error) {
			console.error("Error updating role:", error);
		}
	}

	addSiteRole = async (role) => {
		try {
			const response = await Api.createRole(role);
			this.setState((prevState) => ({
				roles: [...prevState.roles, response.data[0]]
			}));
		} catch (error) {
			console.error("Error creating role:", error);
		}
	}

	updateSiteRole = async (role) => {
		const isNewRole = !role.id;
		try {
			if (isNewRole) {
				await this.addSiteRole(role);
			}
			else {
				await this.editSiteRole(role);
			}
		} catch (error) {
			console.error("Error loading roles:", error);
		}
	}

	deleteSiteRole = async (id) => {
		try {
			const response = await Api.deleteRole(id);
			if (!response) return;

			this.setState((prevState) => ({
				roles: prevState.roles.filter(role => role.id !== id)
			}));
		} catch (error) {
			console.error("Error deleting role:", error);
		}
	}

	editSiteRoleMatrix = async (updatedRole) => {
		try {
			const response = await Api.updateRoleMatrix(updatedRole);
			return response;
		} catch (error) {
			console.error("Error updating role matrix:", error);
		}
	}

	exportSecurity = async () => {
		try {
			const response = await Api.exportSecurity();
			return response;
		} catch (error) {
			console.error("Error exporting security:", error);
		}
	}

	render() {
		const userContextValue = {
			companies: this.state.companies,
			companyIdMap: this.state.companyIdMap,
			users: this.state.users,
			userIdMap: this.state.userIdMap,
			roles: this.state.roles,
			securityRoles: this.state.securityRoles,
			loadCompaniesByIds: this.loadCompaniesByIds,
			searchCompanyByName: this.searchCompanyByName,
			addUsersToSite: this.addUsersToSite,
			loadUsersById: this.loadUsersById,
			updateSiteRole: this.updateSiteRole,
			deleteSiteRole: this.deleteSiteRole,
			editSiteRoleMatrix: this.editSiteRoleMatrix,
			exportSecurity: this.exportSecurity,
			loadSecurityRolesBySiteId: this.loadSecurityRolesBySiteId
		};

		return (
			<UserContext.Provider value={userContextValue}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}

export default UserProvider;