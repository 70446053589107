import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import {
	BasicTxtField,
	ImageMapEditor
} from '../../../ui';
import { SiteDataContext } from '../../../../contexts';
import LocationDetailsForm from './LocationDetailsForm';
import AddressForm from './AddressForm';
import ImageListForm from './ImageListForm';
import QuickLinksForm from './QuickLinksForm';

const EditSiteLocation = ({ location, onSave }) => {
	const [id, setId] = useState(location?.id);
	const [name, setName] = useState(location?.name);
	const [locationProperties, setLocationProperties] = useState(location?.locationProperties);
	const {
		updateSiteLocation,
		updateSiteLocationData,
		updateSiteLocationImageMapPoints
	} = useContext(SiteDataContext);

	// Keep this local cache for now
	// Update data in context but maybe prevent update here unless id changes?
	useEffect(() => {
		console.log("EDIT LOCATION:", location);
		if (!location)
			return;
		// The only way this location changes is if it is edited here
		// so for now reject changes from same id
		if (location.id === id)
			return;
		setId(location.id);
		setName(location.name);
		setLocationProperties(location.locationProperties);
	}, [location, id]);

	const handlePropertiesChange = (newValue, propertyName) => {
		console.log("PROP:", propertyName, " VALUE:", newValue);
		setLocationProperties(prevProps => ({ ...prevProps, [propertyName]: newValue }));
	};

	const handleSubFormChange = (subForm) => (newValue, propertyName) => {
		console.log("TEST", propertyName);
		setLocationProperties((prevProps) => ({
			...prevProps,
			[subForm]: {
				...prevProps[subForm],
				[propertyName]: newValue,
			},
		}));
	};

	const handleSave = () => {
		console.log('Saved data:', locationProperties);
		let siteData = { id, name, locationProperties: locationProperties };
		onSave();
		updateSiteLocationData(siteData);
		//updateSiteLocation(siteData);
	};

	const handleSaveImageMap = (imageMap) => {
		updateSiteLocationImageMapPoints(imageMap);
		handlePropertiesChange(imageMap, "imageMap");
	}

	const handleDeleteImageMapImage = (imageMapData) => {
		handlePropertiesChange(imageMapData, "imageMap");
	}

	const handleAddSiteImage = (newImage) => {
		setLocationProperties((prevProps) => ({
			...prevProps,
			images: [...(prevProps.images || []), newImage],
		}));
	}


	console.log("location:", name);
	console.log("Properties:", locationProperties);
	if (locationProperties == null) {
		return null;
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Button variant="contained" color="primary" onClick={handleSave}>
					Save
				</Button>
			</Grid>
			<Grid container item xs={12} md={6} spacing={1}>
				<Grid item xs={12}>
					<Paper sx={{ padding: 2, marginBottom: 2 }}>
						<BasicTxtField
							id="name"
							label="Name"
							value={name}
							onChange={setName}
							fullWidth
						/>
					</Paper>
					<LocationDetailsForm data={locationProperties} onChange={handlePropertiesChange} />
				</Grid>
				<Grid item xs={12}>
					<ImageListForm
						siteLocationId={id}
						images={locationProperties.images}
						onChange={(images) => handlePropertiesChange(images, "images")}
						onAddImage={handleAddSiteImage}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6} spacing={1}>
				<Grid item xs={12}>
					<AddressForm
						data={locationProperties.address}
						onChange={handleSubFormChange("address")}
					/>
				</Grid>
				<Grid item xs={12}>
					<QuickLinksForm
						siteLocationId={id}
						quickLinks={locationProperties.quickLinks}
						onChange={(links) => handlePropertiesChange(links, "quickLinks")}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ImageMapEditor
					siteLocationId={id}
					mapData={locationProperties.imageMap}
					onSave={handleSaveImageMap}
					onDeleteImage={handleDeleteImageMapImage}
					isEditing
				/>
			</Grid>
		</Grid>
	);
};

export default EditSiteLocation;