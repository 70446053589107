import React, { useEffect, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './SBTree.css';

const ItemTypes = {
	NODE: 'node',
};

// Ensure the dragged node is not dropped on its own children
const isChildOfDraggedNode = (node, draggedNode) => {
	if (!draggedNode.children) return false;
	if (draggedNode.children.some(child => child.id === node.id)) return true;
	return draggedNode.children.some(child => isChildOfDraggedNode(node, child));
};

const TreeNode = ({
	node,
	level,
	selectedId,
	setSelectedId,
	onMoveNode,
	hoveredNodeId,
	setHoveredNodeId,
	dropPosition,
	setDropPosition,
	setBoundingRect,
	onContextMenu,
}) => {
	const [{ isDragging }, dragRef, preview] = useDrag({
		type: ItemTypes.NODE,
		item: { id: node.id, label: node.label, children: node.children },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [{ isOver, canDrop }, dropRef] = useDrop({
		accept: ItemTypes.NODE,
		canDrop: (item, monitor) => {
			// Ensure the dragged node is not dropped on itself
			if (node.id === item.id) return false;
			return !isChildOfDraggedNode(node, item);
		},
		drop: (item, monitor) => {
			const offset = monitor.getClientOffset();
			const boundingRect = nodeRef.current.getBoundingClientRect();
			const position = offset.y < boundingRect.top + boundingRect.height / 2 ? 'child' : 'sibling';
			onMoveNode(item.id, node.id, position);
			setHoveredNodeId(null);
			setDropPosition(null);
			setBoundingRect(null);
		},
		hover: (item, monitor) => {
			const offset = monitor.getClientOffset();
			const boundingRect = nodeRef.current.getBoundingClientRect();
			const position = offset.y < boundingRect.top + boundingRect.height / 2 ? 'child' : 'sibling';
			setHoveredNodeId(node.id);
			if (dropPosition !== position && monitor.canDrop()) {
				setDropPosition(position);
				setBoundingRect(boundingRect);
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	const nodeRef = useRef(null);

	useEffect(() => {
		preview(getEmptyImage(), {
			captureDraggingState: true,
		});
	}, [preview]);

	const classNames = useMemo(() => {
		let classes = 'tree-node';
		if (selectedId === node.id) classes += ' selected';
		if (isOver && canDrop) {
			classes += dropPosition === 'child' ? ' hovered-child' : ' hovered-sibling';
		}
		if (isDragging) classes += ' dragging';
		return classes;
	}, [selectedId, node.id, isOver, canDrop, dropPosition, isDragging]);

	return (
		<li>
			<div
				ref={(ref) => {
					dragRef(ref);
					dropRef(ref);
					nodeRef.current = ref;
				}}
				className={classNames}
				onClick={() => setSelectedId(node.id)}
				onContextMenu={(event) => onContextMenu(event, node)}
				style={{ marginLeft: level * 10 }}
				aria-selected={selectedId === node.id}
			>
				<div className="tree-node-content">
					{node.children && node.children.length > 0 ? (
						<ExpandMoreIcon style={{ marginRight: 5 }} />
					) : (
						<ExpandLessIcon style={{ marginRight: 5, visibility: 'hidden' }} />
					)}
					<div className="tree-node-label">{node.label}</div>
				</div>
			</div>
			{node.children && (
				<div className="tree-node-children">
					{node.children.map(childNode => (
						<TreeNode
							key={childNode.id}
							node={childNode}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							onMoveNode={onMoveNode}
							hoveredNodeId={hoveredNodeId}
							setHoveredNodeId={setHoveredNodeId}
							dropPosition={dropPosition}
							setDropPosition={setDropPosition}
							setBoundingRect={setBoundingRect}
							onContextMenu={onContextMenu}
						/>
					))}
				</div>
			)}
		</li>
	);
};

export default TreeNode;