import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { SBCheckBoxField } from '../../../components/ui/';
import { UserContext } from '../../../contexts';

const RoleCheckboxes = ({ selectedRoles, onRoleChange }) => {
	const isRoleChecked = (roleId) => selectedRoles.some(role => role.roleId === roleId);
	const { roles } = useContext(UserContext);

	const handleRoleChange = (checked, roleId, roleName) => {
		let updatedRoles;
		if (checked) {
			updatedRoles = [...selectedRoles, { roleId: roleId, name: roleName }];
		} else {
			updatedRoles = selectedRoles.filter((role) => role.roleId !== roleId);
		}
		onRoleChange(updatedRoles);
	};

	return (
		<Stack spacing={0}>
			{roles.map((role) => (
				<SBCheckBoxField
					key={role.id}
					id={role.id}
					label={role.name}
					checked={isRoleChecked(role.id)}
					onChange={(checked) => handleRoleChange(checked, role.id, role.name)}
				/>
			))}
		</Stack>
	);
};

export default RoleCheckboxes;