import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stack,
	Typography
} from '@mui/material';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';
import { BasicTxtField, SBCheckBoxField } from '../../../components/ui/';
import RoleCheckboxes from './RoleCheckboxes';

const formValidators = {
	feature: [Validation.validateNotEmpty, Validation.validateMaxLength(100)]
};

const initialValue = {
	roles: []
};

const SecurityEditModal = ({ open, onClose, currentSecurity, onSave }) => {
	const [editedSecurity, setEditedSecurity] = useState({ ...initialValue, ...currentSecurity });
	const { formErrors, isFormValid, resetFormErrors } = Validation.useFormValidation(formValidators);

	useEffect(() => {
		setEditedSecurity({ ...initialValue, ...currentSecurity });
		resetFormErrors();
	}, [currentSecurity, resetFormErrors]);

	const handleDataChange = (newValue, propertyName) => {
		setEditedSecurity({ ...editedSecurity, [propertyName]: newValue });
	};

	const handleSaveLocal = () => {
		if (!isFormValid(editedSecurity)) return;
		onClose();
		onSave(editedSecurity);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle variant="h4">
				Edit Site Security Roles
			</DialogTitle>
			<DialogContent>
				<Stack spacing={0} mt={2}>
					<BasicTxtField
						id="feature"
						label="Feature Name"
						value={editedSecurity.feature}
						onChange={handleDataChange}
						errors={formErrors['feature']}
						fullWidth
						disabled={true}
					/>
					<Typography variant="h4">Allows Roles</Typography>
					<RoleCheckboxes
						selectedRoles={editedSecurity.roles}
						onRoleChange={(roles) => handleDataChange(roles, "roles")}
					/>
					<Typography variant="h4">Drop Tree Cache</Typography>
					<SBCheckBoxField
						id="preRestTreeCache"
						label="Reset Tree Cache Before Function"
						checked={editedSecurity.preRestTreeCache}
						onChange={handleDataChange}
						disabled={true}
					/>
					<SBCheckBoxField
						id="postRestTreeCache"
						label="Reset Tree Cache After Function"
						checked={editedSecurity.postRestTreeCache}
						onChange={handleDataChange}
						disabled={true}
					/>
					<Typography variant="h4">Drop Task Cache</Typography>
					<SBCheckBoxField
						id="preRestTaskCache"
						label="Reset Task Cache Before Function"
						checked={editedSecurity.preRestTaskCache}
						onChange={handleDataChange}
						disabled={true}
					/>
					<SBCheckBoxField
						id="postRestTaskCache"
						label="Reset Task Cache After Function"
						checked={editedSecurity.postRestTaskCache}
						onChange={handleDataChange}
						disabled={true}
					/>
					<Typography variant="h4">
						Site level Admin Only
					</Typography>
					<SBCheckBoxField
						id="isSiteLevel"
						label="Site Level Admin"
						checked={editedSecurity.isSiteLevel}
						onChange={handleDataChange}
						disabled={true}
					/>
					<SBCheckBoxField
						id="siteLevelAdminOnly"
						label="Site Level Admin Only"
						checked={editedSecurity.siteLevelAdminOnly}
						onChange={handleDataChange}
						disabled={true}
					/>
					<Typography variant="h6">Other Rules</Typography>
					<SBCheckBoxField
						id="enableHTMLsanitise"
						label="HTML Sanitize"
						checked={editedSecurity.enableHTMLsanitise}
						onChange={handleDataChange}
						disabled={true}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={handleSaveLocal}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SecurityEditModal;
