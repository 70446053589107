import React, { useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import * as Api from '../../../api/AdminApi';
import {
	LoadingSpinner,
	SBDateRangePicker
} from '../../ui';
import { UserContext } from '../../../contexts';
import TransactionLogsTable from './TransactionLogTable';

const MAX_DAY_DIFF = 31;

function TransactionLogPage() {
	const [loadingData, setLoadingData] = useState(false);
	const [logs, setLogs] = useState([]);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const { loadUsersById } = useContext(UserContext);

	const fetchData = async (requestOptions) => {
		setLoadingData(true);
		try {
			const loadedLogs = await Api.loadTransactionLogs(requestOptions);
			const uniqueUserIds = [...new Set(loadedLogs.map(log => log.userid))];
			await loadUsersById(uniqueUserIds);
			setLogs(loadedLogs);
		} catch (error) {
			console.error('Error fetching data', error);
		}
		setLoadingData(false);
	};

	const handleSetToFromDate = (newFromDate, newToDate) => {
		if (newFromDate && newToDate) {
			const requestOptions = {
				from: newFromDate,
				to: newToDate
			};
			fetchData(requestOptions);
		}
		setFromDate(newFromDate);
		setToDate(newToDate);
	}
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant={'h4'}>
					Transaction Log
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<SBDateRangePicker
					fromDate={fromDate}
					toDate={toDate}
					setToFromDate={handleSetToFromDate}
					maxDayDiff={MAX_DAY_DIFF}
				/>
			</Grid>
			{!loadingData &&
				<Grid item xs={12}>
					<TransactionLogsTable logs={logs} />
				</Grid>
			}
			<Grid item xs={12}>
				<LoadingSpinner isLoading={loadingData} />
			</Grid>
		</Grid>
	);
}

export default TransactionLogPage;