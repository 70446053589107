import { DEV_TEST_MODE, loadJsonFile, downloadJsonData, getFakeQueueIdObj } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';
import apiMessages from '../apiMessages';

// Api for:
// - Users
// - Document Team Members
// - Companies

export async function searchUsers(userEmails) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('searchUsers.json');
		}
		var searchRequest = {
			siteId: getSiteId(),
			emails: userEmails
		}
		let data = await springboardApiRequest(endpoints.users.search(), "POST", searchRequest);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching user data:', error);
		throw new Error('Failed to fetch user data.');
	}
}

export async function createUsers(users) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('searchUsers.json');
		}
		var request = {
			users: users
		}
		let data = await springboardApiRequest(endpoints.users.create(), "POST", request);
		console.log("Creating users:");
		console.log(data);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating user data:', error);
		throw new Error('Failed to create user data.');
	}
}

export async function loadUsers(userIds) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('get-users.json');
		}

		// Split user request into chunks of 25 ids
		const userIdChunks = chunkArray(userIds, 25);
		// Create an perform requests based on user id chunks
		const requests = userIdChunks.map(chunk => {
			return springboardApiRequest(endpoints.users.byIds(chunk), "GET");
		});

		// Execute requests in parallel
		const responses = await Promise.all(requests);

		// Extract 'users' property from each response and flatten
		const allUsersData = responses.map(response => response.data).flat();
		console.log("USER IDS:");
		console.log(allUsersData);
		return allUsersData;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching form data:', error);
		throw new Error('Failed to fetch form data.');
	}
}

export async function getWhoIAm() {
	try {
		if (false) {
			return loadJsonFile('getWhoIAm.json');
		}

		let data = await springboardApiRequest(endpoints.users.whoAmI(), "GET");
		//console.log("WHO I AM:", data.data);
		downloadJsonData(data.data, "getWhoIAm");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while loading who i am user data:', error);
		throw new Error('Failed to load who i am user data.');
	}
}

export async function loadRoles() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadRoles.json');
		}

		const rolesEndpoint = endpoints.users.rolesBySite(getSiteId());
		let data = await springboardApiRequest(rolesEndpoint, "GET");
		downloadJsonData(data.data, "loadRoles");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching roles data:', error);
		throw new Error('Failed to fetch roles data.');
	}
}


export async function loadDocumentsTeamsByProjectId(projectId) {
	try {
		console.log("LOADING DOCUMENTS");
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadDocumentsTeamsByProjectId.json');
		}
		const documentsEndpoint = endpoints.documentTeamMembers.byProjectId(projectId);
		let data = await springboardApiRequest(documentsEndpoint, "GET");
		downloadJsonData(data.data, "loadDocumentsTeamsByProjectId");
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while fetching document data:', error);
		throw new Error('Failed to fetch document data.');
	}
}

async function chunkChangeListPostRequest(endpoint, apiMsg, changeList) {
	if (DEV_TEST_MODE) {
		return changeList;
	}
	// Group the changeList by documentId
	const groupedChangeList = changeList.reduce((acc, change) => {
		if (!acc[change.documentId]) {
			acc[change.documentId] = [];
		}
		acc[change.documentId].push(change);
		return acc;
	}, {});

	const responses = [];
	// Iterate over each group and perform the API request
	for (const [documentId, changes] of Object.entries(groupedChangeList)) {
		let requestId = apiRequestManager.addRequestToQueue(`${endpoint}_${documentId}`);
		try {
			const docSecurityHeader = { documentId: documentId };
			let data = await springboardApiRequest(endpoint, "POST", changes, docSecurityHeader);
			// TODO: Use this data containing any errors...
			responses.push(data);
			const docName = changes.find((obj) => obj.documentId === documentId).documentName;
			apiRequestManager.setRequestSuccess(requestId, `${apiMsg.success} ${docName}!`);
		} catch (error) {
			let errMsg = `${apiMsg.error} ${error.message}`;
			apiRequestManager.setRequestFailure(requestId, errMsg);
			// Update change list to remove current request as this request failed
			changeList = changeList.filter(change => change.documentId !== documentId);
		}
	}
	console.log(`Doc team members change list for: ${endpoint}`, changeList);
	console.log("RESPONSES:", responses);
	return changeList;
}

export async function addRolesToTeamMembers(changeList) {
	let endpoint = endpoints.documentTeamMembers.addRoles();
	let apiMsg = apiMessages.documentTeamMembers.addRoles;
	return await chunkChangeListPostRequest(endpoint, apiMsg, changeList);
}

export async function removeRolesFromTeamMembers(changeList) {
	let endpoint = endpoints.documentTeamMembers.removeRoles();
	let apiMsg = apiMessages.documentTeamMembers.removeRoles;
	return await chunkChangeListPostRequest(endpoint, apiMsg, changeList);
}

export async function setExpirationForTeamMembers(changeList) {
	let endpoint = endpoints.documentTeamMembers.setAccessExpiration();
	let apiMsg = apiMessages.documentTeamMembers.setAccessExpiration;
	return await chunkChangeListPostRequest(endpoint, apiMsg, changeList);
}

export async function setRoleDescriptionForTeamMembers(changeList) {
	let endpoint = endpoints.documentTeamMembers.setRoleDescription();
	let apiMsg = apiMessages.documentTeamMembers.setRoleDescription;
	return await chunkChangeListPostRequest(endpoint, apiMsg, changeList);
}

export async function setRemoveAccessForTeamMembers(changeList) {
	let endpoint = endpoints.documentTeamMembers.removeAccess();
	let apiMsg = apiMessages.documentTeamMembers.removeAccess;
	return await chunkChangeListPostRequest(endpoint, apiMsg, changeList);
}


// Companies:
export async function loadCompaniesByIds(companyIds) {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('loadCompaniesByIds.json');;
		}

		let companyReq = {
			ids: companyIds
		}
		let data = await springboardApiRequest(endpoints.companies.byIds(), "POST", companyReq);
		console.log("Company DATA:", data.data);
		downloadJsonData(data.data, "loadCompaniesByIds");
		return data.data;
	} catch (error) {
		console.error('Error while getting company data:', error);
		throw new Error('Failed to get company data.');
	}
}

export async function searchCompanyByName(name) {
	try {
		const data = await springboardApiRequest(endpoints.companies.searchByName(name), "GET");
		downloadJsonData(data.data, "searchCompanyByName");
		return data.data;
	} catch (error) {
		console.error('Error while searching company name:', error);
		throw new Error('Failed searching company name.');
	}
}