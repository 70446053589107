import React from 'react';
import {
	Grid,
	Switch,
} from '@mui/material';
import {
	SelectDropdown
} from '../../ui';
import { LOIStageList } from '../../../constants/LOIConstants';

function AddLOIRow(props) {
	const handleSwitchChange = (e) => {
		const propertyName = e.target.name;
		const newValue = e.target.checked;
		props.onDataChange(props.classification, props.attributeId, propertyName, newValue);
	};

	const handleStageChange = (stage) => {
		props.onDataChange(props.classification, props.attributeId, "stage", stage);
	}

	return (
		<>
			<Grid item xs={3}>
				{props.classification}
			</Grid>
			<Grid item xs={3}>
				{props.attribute}
			</Grid>
			<Grid item xs={1}>
				<Switch
					name={"enabled"}
					onChange={handleSwitchChange}
					checked={props.enabled}
					color="primary"
					size="medium"
				/>
			</Grid>
			<Grid item xs={3}>
				<SelectDropdown
					id={"Stage"}
					value={props.stage}
					options={LOIStageList}
					onChange={handleStageChange}
					fullWidth
				/>
			</Grid>
			<Grid item xs={1}>
				<Switch
					name={"displayed"}
					onChange={handleSwitchChange}
					checked={props.displayed}
					color="primary"
					size="medium"
				/>
			</Grid>
			<Grid item xs={1}>
				<Switch
					name={"required"}
					onChange={handleSwitchChange}
					checked={props.required}
					color="primary"
					size="medium"
				/>
			</Grid>
		</>
	);
}

export default AddLOIRow;