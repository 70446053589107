import React from 'react';
import Button from '@mui/material/Button';

const convertFileTypesToAcceptString = (fileTypes) => {
	if (!fileTypes) return '';
	return Object.values(fileTypes).flat().join(',');
};

const FileUploadButton = ({ onFileSelect, children, acceptedFileTypes = {} }) => {
	const acceptString = convertFileTypesToAcceptString(acceptedFileTypes);
	return (
		<Button variant="contained" component="label">
			{children}
			<input
				type="file"
				hidden
				multiple
				accept={acceptString}
				onChange={(event) => onFileSelect(event.target.files)}
			/>
		</Button>
	);
};

export default FileUploadButton;