import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const CalculatedStartDatesTable = ({ dates }) => {
	if (!dates)
		return null;
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Step Title</TableCell>
						<TableCell>Calculated Start Date</TableCell>
						<TableCell>Calculated End Date</TableCell>
						<TableCell>Duration Business Days</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dates.map((item) => (
						<TableRow key={item.id}>
							<TableCell>{item.title}</TableCell>
							<TableCell>{new Date(item.startDate).toLocaleDateString()}</TableCell>
							<TableCell>{new Date(item.endDate).toLocaleDateString()}</TableCell>
							<TableCell>{item.duration}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CalculatedStartDatesTable;
