import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Typography } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { convertToTreeStructure, getAllItemIds, addNewUniqueIds } from '../../../helpers/GeneralHelpers';


const getAllSelectedSectionIds = (treeData) => {
	return getAllItemIds(treeData, "nodes", (section) => section.isKeyForm);
}

const getAllSystemSectionIds = (treeData) => {
	return getAllItemIds(treeData, "nodes", (section) => section.isSystem);
}

const getAllHeadingLevelOneSectionIds = (treeData) => {
	return getAllItemIds(treeData, "nodes", (section) => section.isHeadingLevelOne);
}

const getAllSectionItemIdsWithSelectedChildren = (treeData) => {
	const itemIds = [];

	const registerItemId = (section) => {
		let hasKeyFormDescendant = false;

		if (section.nodes?.length) {
			section.nodes.forEach((node) => {
				if (registerItemId(node)) {
					hasKeyFormDescendant = true;
				}
			});
		}

		if (hasKeyFormDescendant) {
			itemIds.push(section.id);
		}

		return section.isKeyForm || hasKeyFormDescendant;
	};

	treeData.forEach(registerItemId);

	return itemIds;
};

const SectionTreeEditModal = ({ open, onClose, sectionTree, onSave }) => {
	const sectionTreeData = useMemo(() => convertToTreeStructure(sectionTree, "id", "name", "nodes"), [sectionTree]);

	const [expandedItems, setExpandedItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (open) {
			setSelectedItems(getAllSelectedSectionIds(sectionTree));
			setExpandedItems(getAllSectionItemIdsWithSelectedChildren(sectionTree));
		}
	}, [sectionTree, open]);

	const handleSelectedItemsChange = (event, ids) => {
		setSelectedItems(ids);
	};

	const handleExpandedItemsChange = (event, itemIds) => {
		setExpandedItems(itemIds);
	};

	const handleSelectAllSystems = () => {
		let systemIds = getAllSystemSectionIds(sectionTree);
		console.log("SYSTEMS:", systemIds);
		setSelectedItems(prevItems => {
			return addNewUniqueIds(prevItems, systemIds);
		});
		setExpandedItems(prevItems => {
			return addNewUniqueIds(prevItems, systemIds);
		});
	}

	const handleSelectAllHeadings = () => {
		let headingIds = getAllHeadingLevelOneSectionIds(sectionTree);
		setSelectedItems(prevItems => {
			return addNewUniqueIds(prevItems, headingIds);
		});
		setExpandedItems(prevItems => {
			return addNewUniqueIds(prevItems, headingIds);
		});
	}

	const handleConfirmSelection = () => {
		onClose();
		onSave(selectedItems);
	}

	const handleItemSelectionToggle = (event, itemId, isSelected) => {
		console.log("ITEM:", itemId);
		console.log("EVENT:", event);
		console.log("SELECTED:", isSelected);
		setSelectedItems((prevSelectedItems) => {
			console.log("PREV ITEMS:", prevSelectedItems);
			if (prevSelectedItems.includes(itemId)) {
				return prevSelectedItems.filter((i) => i !== itemId);
			} else {
				return [...prevSelectedItems, itemId];
			}
		});
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h4">Manage Sections</DialogTitle>
			<DialogContent>
				<Stack spacing={1}>
					<Typography variant="body1">
						Select Sections to Add
					</Typography>
					<ButtonGroup variant="outlined" size="small">
						<Button onClick={handleSelectAllSystems}>
							Select All Systems
						</Button>
						<Button onClick={handleSelectAllHeadings}>
							Select All Heading Level 1's
						</Button>
					</ButtonGroup>

					<RichTreeView
						multiSelect
						checkboxSelection
						items={sectionTreeData}
						selectedItems={selectedItems}
						defaultExpandedItems={[]}
						onSelectedItemsChange={handleSelectedItemsChange}
						expandedItems={expandedItems}
						onExpandedItemsChange={handleExpandedItemsChange}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" onClick={handleConfirmSelection}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SectionTreeEditModal;
