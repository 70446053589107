import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { AddCircle, RemoveCircle, Person, Security } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';

const getChangeDescription = (change, role) => {
	const action = change === 1 ? 'Added' : 'Removed';
	const target = role ? `role ${role}` : 'user';
	const icon = change === 1 ? AddCircle : RemoveCircle;
	const color = change === 1 ? green[500] : red[500];
	const targetIcon = role ? Security : Person;

	return {
		description: `${action} ${target}`,
		icon: icon,
		color: color,
		targetIcon: targetIcon
	};
};

const RoleChangeDisplay = ({ change, role }) => {
	const { description, icon: Icon, color, targetIcon: TargetIcon } = getChangeDescription(change, role);

	return (
		<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
			{/* <Avatar sx={{ width: 24, height: 24, backgroundColor: color, marginRight: 1 }}>
				<Icon sx={{ fontSize: 16 }} />
			</Avatar> */}
			<Avatar sx={{ width: 24, height: 24, backgroundColor: color, marginRight: 1 }}>
				<TargetIcon sx={{ fontSize: 16 }} />
			</Avatar>
			<Typography variant="body2">{description}</Typography>
		</div>
	);
};

export default RoleChangeDisplay;
