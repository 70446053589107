import { DEV_TEST_MODE, loadJsonFile, downloadJsonData } from './devApi';
import apiRequestManager from '../Services/ApiRequestManager';
import { chunkArray } from '../../helpers/GeneralHelpers';
import { getSiteId } from '../configManager';
import { springboardApiRequest } from '../SpringboardApiHandler';
import endpoints from '../endpoints';


// SITE MANAGEMENT PAGE:
export async function getSecurityRolesBySiteId() {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.getSecurityRolesBySiteId(), "GET");
		downloadJsonData(data.data, "getSecurityRolesBySiteId");
		return data.data;
	} catch (error) {
		console.error('Error while loading security roles data:', error);
		throw new Error('Failed loading security roles data.');
	}
}

export async function getGlobalSecurity() {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.getGlobalSecurity(), "GET");
		downloadJsonData(data.data, "getGlobalSecurity");
		return data.data;
	} catch (error) {
		console.error('Error while loading global security data:', error);
		throw new Error('Failed loading global security data.');
	}
}

export async function getMySecurity() {
	try {
		if (false) {
			return loadJsonFile('getMySecurity.json');
		}
		const data = await springboardApiRequest(endpoints.documentTeamMembers.getMySecurity(), "GET");
		downloadJsonData(data.data, "getMySecurity");
		return data.data;
	} catch (error) {
		console.error('Error while loading my security data:', error);
		throw new Error('Failed loading my security data.');
	}
}

export async function createRole(role) {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.createSiteSecurityRole(), "POST", role);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while creating role data:', error);
		throw new Error('Failed to create role data.');
	}
}

export async function updateRole(role) {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.updateSiteSecurityRole(), "POST", role);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating role data:', error);
		throw new Error('Failed to update role data.');
	}
}

export async function deleteRole(id) {
	try {
		const request = {};
		const data = await springboardApiRequest(endpoints.siteManagement.deleteSiteSecurityRole(id), "POST", request);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while deleting role data:', error);
		throw new Error('Failed to delete role data.');
	}
}

export async function updateRoleMatrix(role) {
	try {
		const data = await springboardApiRequest(endpoints.siteManagement.updateSiteSecurityRoleMaxtrix(), "POST", role);
		return data;
	} catch (error) {
		// Handle error here
		console.error('Error while updating role matrix data:', error);
		throw new Error('Failed to update role matrix data.');
	}
}

export async function exportSecurity() {
	try {
		const request = {};
		const data = await springboardApiRequest(endpoints.siteManagement.exportSecurity(), "POST", request);
		return data.data;
	} catch (error) {
		// Handle error here
		console.error('Error while exporting security data:', error);
		throw new Error('Failed to export security data.');
	}
}
