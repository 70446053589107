import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import LoadingPage from '../../pages/LoadingPage';
import BasicTxtField from '../../ui/BasicTxtField';
import * as Validation from '../../../services/Validation';

const DIR_LEFT = -1;
const DIR_RIGHT = 1;

const formValidators = {
	email: [Validation.validateNotEmpty, Validation.validateMaxLength(255), Validation.validateEmail],
	firstName: [Validation.validateNotEmpty, Validation.validateMaxLength(255)],
	secondName: [Validation.validateNotEmpty, Validation.validateMaxLength(255)],
	company: [Validation.validateNotEmpty, Validation.validateMaxLength(255)],
}

function UserEmailConfirmForm({ users, updateUserData, onComplete }) {
	const findIndex = (direction, startIndex = 0) => {
		let index = startIndex;

		while (index >= 0 && index < users.length && users[index].matchType === "Exact") {
			index += direction;
		}

		return index;
	};
	const initialIndex = findIndex(DIR_RIGHT);

	const [currentIndex, setCurrentIndex] = useState(initialIndex);

	const getFormErrorsForUser = (index) => {
		let curUser = users[index];
		if (!curUser)
			return {};

		const newErrors = {};

		Object.keys(formValidators).forEach(fieldName => {
			const fieldValue = curUser[fieldName];
			newErrors[fieldName] = Validation.getValidationErrors(fieldValue, formValidators[fieldName]);
		});
		return newErrors;
	}

	const [errors, setErrors] = useState(getFormErrorsForUser(currentIndex));

	const handleNext = () => {
		let nextIndex = findIndex(DIR_RIGHT, currentIndex + 1);
		setCurrentIndex(nextIndex);
		setErrors(getFormErrorsForUser(nextIndex));
	};

	const handlePrev = () => {
		let prevIndex = findIndex(DIR_LEFT, currentIndex - 1);
		// If prevIndex goes negative, there are no more valid items
		if (prevIndex >= 0) {
			setCurrentIndex(prevIndex);
			setErrors(getFormErrorsForUser(prevIndex));
		}
	};

	const isFormErrors = () => {
		return Object.values(errors).some(errorArray => errorArray.length > 0);
	}

	const handleUpdateUserData = (value, propertyName) => {
		// Validate based on property
		const propertyErrors = Validation.getValidationErrors(value, formValidators[propertyName]);

		setErrors(prevErrors => ({
			...prevErrors,
			[propertyName]: propertyErrors
		}));

		updateUserData(currentIndex, propertyName, value);
	}

	// Ensures form moves on when no more valid users are remaining.
	useEffect(() => {
		if (currentIndex >= users.length) {
			onComplete();
		}
	}, [currentIndex, users, onComplete]);


	const handleEmailSelection = (e) => {
		const selectedEmail = e.target.value;

		if (selectedEmail === currentUser.typedEmail) {
			updateUserData(currentIndex, 'id', 'NEW');
			updateUserData(currentIndex, 'email', currentUser.typedEmail);
			updateUserData(currentIndex, 'firstName', '');
			updateUserData(currentIndex, 'secondName', '');
			updateUserData(currentIndex, 'company', '');
			setErrors(getFormErrorsForUser(currentIndex));
		} else {
			const matchedUser = currentUser.matchedUsers.find(user => user.email === selectedEmail);
			if (matchedUser) {
				updateUserData(currentIndex, 'id', matchedUser.id);
				updateUserData(currentIndex, 'email', matchedUser.email);
				updateUserData(currentIndex, 'firstName', matchedUser.firstName);
				updateUserData(currentIndex, 'secondName', matchedUser.secondName);
				updateUserData(currentIndex, 'company', matchedUser.companyName);
			}
		}
	};

	if (!users.length) {
		return <div>No users to display.</div>;
	}
	if (currentIndex === users.length) {
		return <LoadingPage />
	}
	const currentUser = users[currentIndex];
	const isEditable = currentUser.id === "NEW";
	return (
		<div>
			<Typography variant="h6">Check the email addresses of the new users</Typography>
			<Typography variant="body1" color="textSecondary">Inputted text : {currentUser.typedEmail}</Typography>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					{currentUser.matchType === "Suggested" && (
						<>
							<Typography variant="body1" color="textSecondary">Did you mean:</Typography>
							<Select
								value={currentUser.email}
								onChange={handleEmailSelection}
								fullWidth
							>
								<MenuItem value={currentUser.typedEmail}>{currentUser.typedEmail} (Your Input)</MenuItem>
								{currentUser.matchedUsers.map((user) => (
									<MenuItem key={user.id + user.email} value={user.email}>{user.email}</MenuItem>
								))}
							</Select>
						</>
					)}
				</Grid>
				<Grid item xs={6} />
				<br />

				<Grid item xs={6}>
					<BasicTxtField
						id={"email"}
						label="Email"
						value={currentUser.email}
						onChange={handleUpdateUserData}
						errors={errors['email']}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6} />

				<Grid item xs={6}>
					<BasicTxtField
						id={"firstName"}
						label="First Name"
						value={currentUser.firstName}
						onChange={handleUpdateUserData}
						errors={errors['firstName']}
						fullWidth
						disabled={!isEditable}
					/>
				</Grid>
				<Grid item xs={6}>
					<BasicTxtField
						id={"secondName"}
						label="Second Name"
						value={currentUser.secondName}
						onChange={handleUpdateUserData}
						errors={errors['secondName']}
						fullWidth
						disabled={!isEditable}
					/>
				</Grid>

				<Grid item xs={6}>
					<BasicTxtField
						id={"company"}
						label="Company"
						value={currentUser.company}
						onChange={handleUpdateUserData}
						errors={errors['company']}
						fullWidth
						disabled={!isEditable}
					/>
				</Grid>
				<Grid item xs={6} />

				<Grid item xs={6}>
					<BasicTxtField
						id={"phone"}
						label="Phone"
						value={currentUser.phone}
						onChange={handleUpdateUserData}
						errors={errors['phone']}
						fullWidth
						disabled={!isEditable}
					/>
				</Grid>
				<Grid item xs={6} />
			</Grid>
			<br />
			<div style={{ textAlign: 'right' }}>
				<Button disabled={currentIndex === 0} onClick={handlePrev} variant="contained">Previous</Button>
				<Button disabled={isEditable && isFormErrors()} onClick={handleNext} variant="contained" style={{ paddingLeft: '54px', paddingRight: '54px', marginLeft: '10px' }}>
					Next &gt;
				</Button>
			</div>
		</div>
	);
}

UserEmailConfirmForm.propTypes = {
	users: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		matchType: PropTypes.string.isRequired,
		typedEmail: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		secondName: PropTypes.string.isRequired,
		company: PropTypes.string.isRequired,
		phone: PropTypes.string.isRequired
	})).isRequired,
	updateUserData: PropTypes.func.isRequired,
	onComplete: PropTypes.func
};

export default UserEmailConfirmForm;