import React, { useState } from 'react';
import {
	Button,
	Grid,
	TextField,
} from '@mui/material';
import {
	SelectDropdown,
	GridDivider
} from '../../../ui';
import AttributeLookup from './AttributeLookup';

const concatenateAddPartList = [
	{
		id: "text",
		name: "Text Seperator"
	},
	{
		id: "attribute",
		name: "Attribute Lookup"
	},
];

function DataConcatenatePartAdder({ onAddPart }) {
	const [concatAddSelector, setConcatAddSelector] = useState("");
	const [addPartValue, setAddPartValue] = useState("");

	const handleAddPart = () => {
		onAddPart(addPartValue);
	}

	const handleChangeSelector = (value) => {
		setAddPartValue("");
		setConcatAddSelector(value);
	}

	return (
		<Grid item xs={12} container spacing={1}>
			<Grid item xs={12}>
				<SelectDropdown
					id={"concatAddPart"}
					label={"Add Part Type"}
					value={concatAddSelector}
					options={concatenateAddPartList}
					onChange={handleChangeSelector}
					fullWidth
				/>
			</Grid>
			<GridDivider />
			{concatAddSelector === "text" && (
				<Grid item xs={12}>
					<TextField
						size="small"
						label="Value"
						variant="outlined"
						value={addPartValue}
						onChange={(e) => setAddPartValue(e.target.value)}
						placeholder="Enter value"
						fullWidth
					/>
				</Grid>
			)}
			{concatAddSelector === "attribute" && (
				<Grid item xs={12}>
					<AttributeLookup
						value={addPartValue}
						onChange={setAddPartValue}
					/>
				</Grid>
			)}

			{addPartValue !== "" && (
				<>
					<Grid item xs={12}>
						<Button variant="contained" onClick={handleAddPart}>Add Part</Button>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default DataConcatenatePartAdder;