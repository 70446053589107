import React, { useContext, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Tooltip,
	Typography
} from '@mui/material';
import { UserContext } from '../../../contexts';
import SecurityRolesTable from './SecurityRolesTable';
import EditRoleModal from './EditRoleModal';
import ConfirmationDialog from '../../modals/ConfirmationDialog';

function SecurityRolesPage() {
	const [editRoleOpen, setEditRoleOpen] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [currentRole, setCurrentRole] = useState(null);
	const [deleteRole, setDeleteRole] = useState({ id: '', name: '' });
	const { roles, updateSiteRole, securityRoles, deleteSiteRole } = useContext(UserContext);

	const handleEditRole = (role) => {
		setCurrentRole(role);
		setEditRoleOpen(true);
	};

	const handleSave = async (siteRole) => {
		updateSiteRole(siteRole);
	};

	const handleDeleteRole = (role) => {
		setDeleteRole({ id: role.id, name: role.name });
		handleOpenDeleteDialog();
	};

	const handleDelete = async () => {
		deleteSiteRole(deleteRole.id);
		handleCloseDeleteDialog();
	};

	const handleOpenDeleteDialog = () => {
  	setOpenDeleteConfirm(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirm(false);
		setDeleteRole({ id: '', name: '' });
	};

	return (
		<Box>
			<Stack spacing={2}>
				<Typography variant="h4">Security Roles</Typography>
				<SecurityRolesTable
					roles={roles}
					securityRoles={securityRoles}
					onEdit={handleEditRole}
					onDelete={handleDeleteRole}
				/>
				<Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', maxWidth: '360px' }}>
					<Tooltip title={roles.length >= 20 ? 'Maximum 20 roles reached' : ''} placement="bottom" arrow>
						<span>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleEditRole({})}
								disabled={roles.length >= 20}>
								Add Role
							</Button>
						</span>
					</ Tooltip>
				</Stack>
				<EditRoleModal
					open={editRoleOpen}
					onClose={() => setEditRoleOpen(false)}
					currentRole={currentRole}
					onSave={handleSave}
				/>
			</Stack>
			<ConfirmationDialog
				open={openDeleteConfirm}
				onClose={handleCloseDeleteDialog}
				title="Delete security role"
				message={`Please confirm that you would like to delete security role ${deleteRole.name}`}
				onConfirm={handleDelete}
			/>
		</Box>
	);
}

export default SecurityRolesPage;