import { ServerDateDisplay } from "../../ui";
import { Typography } from "@mui/material";
import { EXPIRED_DATE_COLOR } from '../../../constants/Constants';

function UserReportCellRenderer(props) {
	const user = props.value;
	if (!user) {
		return null;
	}
	return (
		<>
			<Typography>
				Project invite sent: <ServerDateDisplay date={user.lastLoginSent} />
			</Typography>
			<Typography style={{ color: user.hasExpired ? EXPIRED_DATE_COLOR : 'inherit' }}>
				Expires: <ServerDateDisplay date={user.dateExpires} />
			</Typography>
			<Typography>
				Last viewed: <ServerDateDisplay date={user.lastViewed} />
			</Typography>
			{user.roleDescription &&
				<Typography>
					Role desc: {user.roleDescription}
				</Typography>
			}
		</>
	);
}

export default UserReportCellRenderer;