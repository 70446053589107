let apiKey = null;
let siteId = null;
let baseURL = null;

const DEFAULT_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export function setAPIKey(key) {
	apiKey = key;
}

export function getAPIKey() {
	if (apiKey === null) {
		throw new Error('API key has not been set.');
	}
	return apiKey;
}

export function setSiteId(id) {
	siteId = id;
}

export function getSiteId() {
	if (siteId === null) {
		throw new Error('Site ID has not been set.');
	}
	return siteId;
}

export function setBaseURL(url) {
	baseURL = url;
}

export function getBaseURL() {
	return baseURL || DEFAULT_BASE_URL;
}