import { DEV_TEST_MODE, loadJsonFile } from './devApi';
import { springboardApiRequest } from '../SpringboardApiHandler';
import apiRequestManager from '../Services/ApiRequestManager';
import endpoints from '../endpoints';
import apiMessages from '../apiMessages';

export async function getFileCategories() {
	try {
		if (DEV_TEST_MODE) {
			return loadJsonFile('getFileCategories.json');
		}
		let data = await springboardApiRequest(endpoints.fileCategories.bySideId(), "GET");
		return data.data;
	} catch (error) {
		console.error('Error while fetching file categories:', error);
		//throw new Error('Failed to fetch file categories.');
	}
}

export async function createFileCategory(fileCategory) {
	if (DEV_TEST_MODE) {
		return fileCategory;
	}
	let req = () => springboardApiRequest(endpoints.fileCategories.create(), "POST", fileCategory);
	let data = await apiRequestManager.withReqManager(req, apiMessages.fileCategories.create);
	return data.data;
}

export async function updateFileCategory(fileCategory) {
	if (DEV_TEST_MODE) {
		return fileCategory;
	}
	let req = () => springboardApiRequest(endpoints.fileCategories.update(), "POST", fileCategory);
	let data = await apiRequestManager.withReqManager(req, apiMessages.fileCategories.update);
	return data.data;
}

export async function createFileTag(fileTag) {
	if (DEV_TEST_MODE) {
		return fileTag;
	}
	let req = () => springboardApiRequest(endpoints.fileTags.create(), "POST", fileTag);
	let data = await apiRequestManager.withReqManager(req, apiMessages.fileTags.create);
	return data.data;
}

export async function updateFileTag(fileTag) {
	if (DEV_TEST_MODE) {
		return fileTag;
	}

	let req = () => springboardApiRequest(endpoints.fileTags.update(), "POST", fileTag);
	let data = await apiRequestManager.withReqManager(req, apiMessages.fileTags.update);
	return data.data;
}
