import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';

const QuickLinksTable = ({ quickLinks }) => (
	<Card>
		<CardContent>
			<Typography variant="h6">Quick Links</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Position</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>URL</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{quickLinks.map((link) => (
							<TableRow key={link.id}>
								<TableCell>{link.number}</TableCell>
								<TableCell>{link.description}</TableCell>
								<TableCell>{link.url}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CardContent>
	</Card>
);

export default QuickLinksTable;