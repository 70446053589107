import React, { useContext } from 'react';
import { Grid, Typography, Divider, Box } from '@mui/material';
import { menuItems } from '../../../constants/MenuConstants';
import { SiteSecurityContext } from '../../../contexts';
import SecurityLoadingAlert from './SecurityLoadingAlert';

const HomePage = ({ navigateTo }) => {
	const { isSiteAdmin, securityLoaded } = useContext(SiteSecurityContext);
	return (
		<Box sx={{ padding: 2 }}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SecurityLoadingAlert loading={!securityLoaded} />
				</Grid>
				{menuItems.map((item, index) => {
					if (item.isAdminOnly && !isSiteAdmin) {
						return null;
					}

					if (item.isHeader) {
						return (
							<Grid item xs={12} key={index}>
								<Typography variant="h6">{item.title}</Typography>
							</Grid>
						);
					}
					if (item.title === "Divider") {
						return (
							<Grid item xs={12} key={index}>
								<Divider />
							</Grid>
						);
					}

					const IconComponent = item.icon;
					return (
						<Grid item xs={12} sm={6} md={4} key={index}>
							<Box
								onClick={() => navigateTo(item.page)}
								sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#f0f0f0' } }}
								disabled={!item.page}
							>
								<IconComponent style={{ fontSize: '3rem', marginRight: 8 }} />
								<Typography>{item.title}</Typography>
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};

export default HomePage;
