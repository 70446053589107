import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { getDisplayLabel } from '../../helpers/GeneralHelpers.js';

class BasicTxtField extends React.Component {
	static defaultProps = {
		label: null,
		width: 300,
		errors: [],
		fullWidth: false,
		disabled: false,
		canEdit: true
	};
	render() {
		const { id, label, canEdit, onChange, validators, value, errors, width, fullWidth, disabled, ...other } = this.props;
		const displayLabel = getDisplayLabel(label, id);

		// Determine the actual disabled state
		const isDisabled = disabled || !canEdit;

		// Determine width or fullWidth
		const styleProps = fullWidth ? { fullWidth: true } : { sx: { width: width } };

		return (
			<TextField
				id={id}
				size="small"
				label={displayLabel}
				value={value || ''}
				// Only show errors if the field is editable
				error={!isDisabled && errors.length > 0}
				// Only display helper text if the field is editable
				helperText={!isDisabled ? errors.join(' ') : ''}
				onChange={(event) => { onChange(event.target.value, id); }}
				InputProps={{
					readOnly: isDisabled,
				}}
				disabled={disabled}
				{...styleProps}
				{...other}
			/>
		);
	}
}

BasicTxtField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	canEdit: PropTypes.bool.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	onChange: PropTypes.func.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string),
	width: PropTypes.number,
	fullWidth: PropTypes.bool
};

export default BasicTxtField;