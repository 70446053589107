import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	TextField,
	Typography,
	Stack
} from '@mui/material';
import {
	BasicTxtField
} from '../../ui';
import { WorkflowsContext } from '../../../contexts';
import * as Validation from '../../../services/Validation';
import { isObjectEmpty } from '../../../helpers/GeneralHelpers';

const formValidators = {
	title: [Validation.validateNotEmpty]
}

const WorkflowEditPage = ({ setEditWorkflow, workflowData, getapis }) => {
	const [busy, setBusy] = useState(false);
	const [workflow, setWorkflow] = useState({
		id: workflowData.id,
		title: workflowData.title,
		description: workflowData.description || ''
	});
	const [formErrors, setFormErrors] = useState({});

	const { updateWorkflow } = useContext(WorkflowsContext);

	// useEffect(() => {
	// 	console.log('edited workflow', workflow);
	// }, [workflow])

	const handleDataChange = (propertyName, newValue) => {
		setWorkflow(prevData => ({ ...prevData, [propertyName]: newValue }));
	};

	const handleSubmit = async () => {
		if (!isFormValid()) return;

		setBusy(true);
		const workflowData = await updateWorkflow(workflow);

		if (!workflowData) {
			return setBusy(false);
		}
		else {
			setEditWorkflow(false);
			getapis();
		}
	};

	const isFormValid = () => {
		let newFormErrors = {};

		Validation.setFieldErrors(workflow.title, "title", formValidators, newFormErrors);

		setFormErrors(newFormErrors);
		return isObjectEmpty(newFormErrors);
	}

	return (
		<>
			{busy &&
				<Box>
					<CircularProgress />
				</Box>
			}
			{!busy &&
				<Box className="form-container">
					<Stack sx={{ mb: 3 }} direction='column' spacing={3}>
						<Stack direction='row' justifyContent="space-between"  alignItems="flex-end">
							<Typography variant="body1">Edit workflow</Typography>
							<Typography variant="body2">* Required field</Typography>
						</Stack>
						<BasicTxtField
							id={"Title"}
							label="Title"
							value={workflow.title}
							onChange={(value) => handleDataChange("title", value)}
							fullWidth={true}
							errors={formErrors['title']}
							required
						/>
						<TextField
							id={"Description"}
							label="Description"
							value={workflow.description}
							onChange={(value) => handleDataChange("description", value.target.value)}
							multiline
							rows={4}
						/>
					</Stack>
					<Stack direction='row' spacing={2} justifyContent="flex-end">
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => setEditWorkflow(false)}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={() => handleSubmit()}>
							Submit
						</Button>
					</Stack>
				</Box>
			}
		</>
	)

}
export default WorkflowEditPage;