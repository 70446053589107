import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, TextareaAutosize } from '@mui/material';
import NextBtn from '../../ui/NextBtn';
import { searchUsers } from '../../../api/AdminApi';
import { isValidEmail } from '../../../services/Validation';
import { SingleSelectUserTable } from '../../ui/Tables';
import { transformSearchData } from './UserEmailHelpers';

const NewUsersEmailInput = ({ onInputEmails, onComplete, allowUserSelection }) => {
	const [emails, setEmails] = useState('');
	const [isValid, setIsValid] = useState(true);

	const getEmailList = (emailStr) => {
		return emailStr.split('\n').filter(email => email.trim() !== '');
	}

	const validateEmails = (emailStr) => {
		const emailArray = getEmailList(emailStr)

		if (emailArray.length > 20) {
			return false;
		}

		return emailArray.every(isValidEmail);
	};

	const handleChange = (e) => {
		setEmails(e.target.value);
		setIsValid(validateEmails(e.target.value));
	};

	const handleNext = async () => {
		// Convert emails to user objects
		const initialUsers = getEmailList(emails);
		let userResult = await searchUsers(initialUsers);
		const confirmUsers = transformSearchData(userResult);
		onInputEmails(confirmUsers);
		onComplete();
	};

	const handleUserClick = (user) => {
		setEmails(prevEmails => {
			let emailList = getEmailList(prevEmails);
			if (!emailList.includes(user.email)) {
				const updatedEmails = [...emailList, user.email];
				const emailString = updatedEmails.join('\n');
				setIsValid(validateEmails(emailString));
				return emailString;
			}
			return prevEmails;
		})
	}

	return (
		<div>
			<Typography variant="h6">Enter the email addresses of the new users</Typography>
			<Typography variant="body1" color="textSecondary">
				Please enter one email per line. (Maximum 20 users)&nbsp;
			</Typography>
			{allowUserSelection && (
				<Typography variant="body1" color="textSecondary">
					or click on the existing User from the list below to add, then press OK.
				</Typography>
			)}
			<TextareaAutosize
				minRows={10}
				placeholder="Paste email addresses here..."
				value={emails}
				onChange={handleChange}
				style={{ width: '100%', marginBottom: '10px' }}
			/>
			{allowUserSelection && <SingleSelectUserTable onUserClick={handleUserClick} />}
			{!isValid && <Typography color="error">Invalid email format or more than 20 emails entered.</Typography>}
			<NextBtn disabled={!emails.trim() || !isValid} onClick={handleNext} />
		</div>
	);
};

NewUsersEmailInput.propTypes = {
	onInputEmails: PropTypes.func.isRequired,
	onComplete: PropTypes.func
};

export default NewUsersEmailInput;