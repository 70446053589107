import React from 'react';
import { AvatarGroup, Stack, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ReviewUserAvatar from '../ReviewUserAvatar';
import ResponsibleUserAvatar from '../ResponsibleUserAvatar';

function ReviewAvatarBuilder({ user }) {
	if (!user) {
		return null;
	}
	const initials = user.firstName[0] + user.secondName[0];
	const tooltipContent = (
		<div>
			<Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold' }}>
				{user.name}
			</Typography>
			<Typography variant="body2" component="div">
				{user.email}
			</Typography>
			<Typography variant="body2" component="div">
				(Double click to remove)
			</Typography>
		</div>
	);
	return (
		<Tooltip title={tooltipContent} arrow>
			<ReviewUserAvatar>
				{initials}
			</ReviewUserAvatar>
		</Tooltip>
	);
}

function ResponsibleUserAvatarBuilder({ user }) {
	if (!user) {
		return null;
	}
	const initials = user.firstName[0] + user.secondName[0];
	const tooltipContent = (
		<div>
			<Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold' }}>
				{user.name}
			</Typography>
			<Typography variant="body2" component="div">
				{user.email}
			</Typography>
		</div>
	);
	return (
		<Tooltip title={tooltipContent} arrow>
			<ResponsibleUserAvatar>
				{initials}
			</ResponsibleUserAvatar>
		</Tooltip>
	)
}

function ReviewAvatarGroup({ users }) {
	return <AvatarGroup max={3} spacing={3} sx={{
		'& .MuiAvatar-root': {
			width: 30,
			height: 30,
			fontSize: '0.9rem',
			border: '1px solid white',
		},
		'& .MuiAvatar-root:last-child': {
			fontSize: '0.9rem',
			color: '#222222'
		}
	}}>
		{users && users.map((user, key) => {
			return <ReviewAvatarBuilder key={key} user={user} />
		})}
	</AvatarGroup>;
}

function ReviewDisabledTooltip() {
	return (
		<Tooltip title="Section is currently not enabled for review" arrow>
			<WarningAmberOutlinedIcon color="warning" />
		</Tooltip>
	)
}

function AvatarCellRenderer(props) {
	//console.log("AVATAR CELL");
	//console.log("AVATAR CELL:", props);
	const { value, allowReviews } = props;
	const user = value?.responsibleUser || value?.defaultResponsibleUser;
	const reviewUsers = value?.reviewUsers;
	return (
		<Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
			<ResponsibleUserAvatarBuilder user={user} />
			{allowReviews ?
				<ReviewAvatarGroup users={reviewUsers} />
				:
				<ReviewDisabledTooltip />
			}
		</Stack>
	);
}

export default AvatarCellRenderer;