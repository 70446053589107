import React from 'react';
import { Box } from '@mui/material';
import { LoadingBox } from '../ui';

function LoadingPage() {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			bgcolor="grey"
		>
			<LoadingBox />
		</Box>
	);
}

export default LoadingPage;