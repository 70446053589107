import React, { useEffect, useState} from 'react';
import {
	Button,
	DialogActions,
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
} from '@mui/material';
import MultiSelectUserTable from '../ui/Tables/MultiSelectUserTable';

function ConfirmUserSelectModal({
	title,
	open,
	onClose,
	onSave,
	initialIds
}) {
	const [selectedIds, setSelectedIds] = useState(initialIds);
	useEffect(() => {
		setSelectedIds(initialIds);
	}, [initialIds])

	const handleSaveClick = () => {
		onClose();
		onSave(selectedIds);
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle variant="h5">
				{title}
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">
					Confirm User Selection:
				</Typography>
				<MultiSelectUserTable
					allowedIds={initialIds}
					onChange={setSelectedIds}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleSaveClick}
					variant="contained"
					color={"primary"}
					autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog >
	);
}

export default ConfirmUserSelectModal;