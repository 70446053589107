import React, { useContext, useMemo } from 'react';
import {
	Box,
} from '@mui/material';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton
} from '@mui/x-data-grid';
import { UserModalTrigger } from '../../modals';
import { UserContext, ProjectContext } from '../../../contexts/';
import RoleChangeDisplay from './RoleChangeDisplay';
import { csvTitleDate } from '../../../helpers/GeneralHelpers';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: 'UserRoleChanges-' + csvTitleDate() }} />
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

const UserRoleChangesTable = ({ userRoleChanges, documents }) => {
	const { users } = useContext(UserContext);
	const { projects } = useContext(ProjectContext);

	const userIdToNameMap = useMemo(() => users.reduce((map, user) => {
		map[user.id] = user.name;
		return map;
	}, {}), [users]);

	const projectIdToNameMap = useMemo(() => projects.reduce((map, project) => {
		map[project.id] = project.name;
		return map;
	}, {}), [projects]);

	const getProjectName = (projectId) => {
		return projectId ? projectIdToNameMap[projectId] : 'N/A';
	}

	const getDocumentName = (documentId) => {
		const document = documents.find(({id}) => id === documentId);
		return document.name
	}

	const columns = [
		{
			field: 'byUserId',
			headerName: 'Actioning User',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return userIdToNameMap[row.byUserId];
			},
			renderCell: (params) => (
				<UserModalTrigger userId={params.row.byUserId} />
			)
		},
		{
			field: 'role',
			headerName: 'Change',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				const action = row.change === 1 ? 'added' : 'removed';
				const target = row.role ? `role ${row.role}` : 'user';
				return action + ' ' + target;
			},
			renderCell: (params) => (
				<RoleChangeDisplay change={params.row.change} role={params.row.role} />
			)
		},
		{
			field: 'userId',
			headerName: 'User',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return userIdToNameMap[row.userId];
			},
			renderCell: (params) => (
				<UserModalTrigger userId={params.row.userId} />
			)
		},
		{
			field: 'projectId',
			headerName: 'Project',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return getProjectName(row.projectId);
			}
		},
		{
			field: 'documentId',
			headerName: 'Document',
			minWidth: 160,
			flex: 1,
			valueGetter: (value, row) => {
				return row.documentId ? getDocumentName(row.documentId) : 'N/A';
			}
		},
		{
			field: 'dateLogged',
			headerName: 'Actioned',
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row) => {
				return new Date(row.dateLogged).toLocaleString({}, {dateStyle: 'short', timeStyle: 'medium'});
			}
		}
	]

	return (
		<Box sx={{ width: '100%' }}>
			<DataGrid
				rows={userRoleChanges}
				columns={columns}
				autoHeight
				rowHeight={42}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10
						}
					}
				}}
				pageSizeOptions={[5, 10, 25, 50]}
				disableRowSelectionOnClick
				sx={{ border: 'none' }}
				slots={{
					toolbar: CustomToolbar,
				}}
			/>
		</Box>
	);
};

export default UserRoleChangesTable;
