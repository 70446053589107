import React from 'react';
import EDocsLogo from '../ui/EDocsLogo';

function UnauthorisedPage() {
	return (
		<div>
			<EDocsLogo />
			<h2>You are not authorised to view this page</h2>
		</div>
	);
}

export default UnauthorisedPage;