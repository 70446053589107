
// Teams
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// Sites
import ApartmentIcon from '@mui/icons-material/Apartment';
import LockIcon from '@mui/icons-material/Lock';

// Projects
import LayersIcon from '@mui/icons-material/Layers';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

// Documents
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import QrCodeIcon from '@mui/icons-material/QrCode';

// Information Requirements
import TableRowsIcon from '@mui/icons-material/TableRows';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ViewStreamIcon from '@mui/icons-material/ViewStream';

// Advanced
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

export const drawerWidth = 350;

export const menuItems = [
	{ title: "Teams", isHeader: true },
	{ title: "Users", page: "users", icon: PeopleIcon },
	{ title: "Administrators", page:"Administrators", icon: PersonIcon },
	{ title: "User Role Changes", isAdminOnly: true, page: "UserRoleChangesPage", icon: AssignmentIndIcon},
	{ title: "Divider" },
	{ title: "Sites", isHeader: true },
	{ title: "Site Config", page: "SitePage", icon: ApartmentIcon },
	{ title: "Site Security", isAdminOnly: true, page: "SiteSecurityPage", icon: LockIcon },
	{ title: "Divider" },
	{ title: "Projects", isHeader: true },
	{ title: "Projects config and key dates", page:"Projects", icon: LayersIcon },
	{ title: "Locations, spaces, floors, zones", page:"LocationsPage", icon: SpaceDashboardIcon },
	{ title: "Divider" },
	{ title: "Documents", isHeader: true },
	{ title: "Documents", page: "Documents", icon: FormatAlignLeftIcon },
	{ title: "Workflow responsibility and reviewers", page: "workflowResponsibility", icon: ViewModuleIcon },
	{ title: "Workflows", page: "Workflows", icon: ChecklistIcon },
	{ title: "File tags", page: "FileCategories", icon: MoreHorizIcon },
	{ title: "Shared links manager", page: "SharedLinksManagerPage", icon: QrCodeIcon },
	{ title: "Transaction log", isAdminOnly: true, page: "TransactionLogPage", icon: ReceiptLongIcon },
	{ title: "Divider" },
	{ title: "Information Requirements", isHeader: true },
	{ title: "Level of information - library", page: "LevelsOfInformationPage", icon: TableRowsIcon },
	{ title: "Attributes", page: "AttributeGroupsPage", icon: CreditCardIcon },
	{ title: "Classifications", page: "ClassificationsPage", icon: ViewStreamIcon },
	{ title: "Divider" },
	{ title: "Advanced", isHeader: true },
	{ title: "User lookup", page: "UserLookupPage", icon: PersonSearchIcon }
];