const TIME_TOLERANCE = 500; // Used as a margin of error for estimates.

export default class DataLoadProfiler {
	constructor(progressUpdateCallback) {
		this.queue = [];
		this.averageLoadTime = 3000;
		this.totalItems = 0;
		this.isProcessing = false;
		this.timeoutInterval = null;
		this.progressInterval = null;
		this.timeElapsed = 0;

		this.progressUpdateCallback = progressUpdateCallback;
	}

	startProgressEstimation() {
		this.progressInterval = setInterval(() => {
			this.timeElapsed += 100;

			const actualProgress = this.getActualProgress();
			const estimatedRemainingTime = this.getEstimatedRemainingTime();
			const timeSinceLastProcessed = this.getTimeSinceLastProcessed();

			// Estimated progress for the remaining portion
			const estimatedProgressForRemaining = (timeSinceLastProcessed / estimatedRemainingTime) * (100 - actualProgress);
			const finalProgress = actualProgress + estimatedProgressForRemaining;
			/*
			console.log("averageLoadTime:", this.averageLoadTime);
			console.log("totalItems:", this.totalItems);
			console.log("estimatedTotalTime:", timeSinceLastProcessed);
			console.log("time elapsed:", this.timeElapsed);
			console.log("Queue length" + this.queue.length);

			console.log("estimatedProgress:", finalProgress);
			*/
			// Call the callback with the estimated progress
			this.progressUpdateCallback(Math.min(finalProgress, 100));
		}, 100);
	}

	stopProgressEstimation() {
		clearInterval(this.progressInterval);
		this.progressInterval = null;
		this.progressUpdateCallback(100); // Inform parent of complete downloads
	}

	finishDataLoad() {
		this.stopProgressEstimation();
		this.isProcessing = false;
		this.totalItems = 0;
		this.timeElapsed = 0;
	}

	addToQueue(item) {
		if (this.queue.includes(item)) {
			return; // Return early if the item is already in the queue
		}
		this.queue.push(item);
		this.totalItems += 1;

		// Start the processing if not already started
		if (!this.isProcessing) {
			this.processQueue();
		}

		// Ensure timeout is setup to periodically check queue
		if (!this.timeoutInterval) {
			this.timeoutInterval = setInterval(() => {
				if (!this.isProcessing && this.queue.length > 0) {
					this.processQueue();
				}
			}, 2000);
		}
	}

	async processQueue() {
		this.isProcessing = true;
		console.log("Process Queue starting!");
		this.startProgressEstimation();
		while (this.queue.length > 0) {
			const item = this.queue[0];
			const startTime = performance.now();

			await this.loadFunction(item);

			const endTime = performance.now();
			const timeTaken = endTime - startTime;
			const weight = 0.9; // You can adjust this value. Higher values make the average adjust faster.
			this.averageLoadTime = (this.averageLoadTime * (1 - weight)) + (timeTaken * weight);

			this.queue.shift();
		}
		this.finishDataLoad();
	}

	// Placeholder for the actual data loading function
	async loadFunction(item) {
		// This function should be overridden or provided by the class using DataLoadProfiler.
		return new Promise(resolve => setTimeout(resolve, 1000));
	}

	getQueueSize() {
		return this.queue.length;
	}

	// Actual progress based on items already processed
	getActualProgress() {
		return ((this.totalItems - this.queue.length) / this.totalItems) * 100;
	}

	// How much of the estimated remaining time has passed since the last item was processed
	getTimeSinceLastProcessed() {
		return this.timeElapsed - (this.totalItems - this.queue.length) * this.averageLoadTime;
	}

	getEstimatedTotalTime() {
		return this.averageLoadTime * this.totalItems;
	}

	// Remaining time based on average load time of the remaining items
	getEstimatedRemainingTime() {
		return (this.averageLoadTime * this.queue.length) + TIME_TOLERANCE;
	}
}