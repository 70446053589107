import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Stack,
} from '@mui/material';
import {
	SelectDropdown,
	ConditionalRenderer
} from '../../../ui';
import { SelectedLOIDataContext } from '../../../../contexts';

function AttributeLookup({ value, onChange }) {
	const { loiAttributes } = useContext(SelectedLOIDataContext);
	const [structuralName, setStructuralName] = useState("");
	const [structuralNameList, setStructuralNameList] = useState([]);
	const [attributeList, setAttributeList] = useState([]);
	const [attribute, setAttribute] = useState("");

	const createAttrListFromStructName = useCallback((name) => {
		const attrList = loiAttributes
			.filter(attr => attr.structuralName === name)
			.map(attr => ({
				id: attr.id,
				name: attr.name
			}));
		return attrList;
	}, [loiAttributes]);

	useEffect(() => {
		let foundAttr = loiAttributes.find((attr) => attr.id === value);
		if (!foundAttr)
			return;

		setStructuralName(foundAttr.structuralName);
		setAttribute(foundAttr.id);
		const attrList = createAttrListFromStructName(foundAttr.structuralName);
		setAttributeList(attrList);
	}, [value, loiAttributes, createAttrListFromStructName]);

	useEffect(() => {
		const structNameList = loiAttributes.reduce((unique, attr) => {
			// Check if the structuralName is already in the unique array
			if (!unique.some(u => u.id === attr.structuralName)) {
				unique.push({
					id: attr.structuralName,
					name: attr.structuralName
				});
			}
			return unique;
		}, []);
		setStructuralNameList(structNameList);
	}, [loiAttributes]);

	const handleSetStructuralName = (name) => {
		setStructuralName(name);
		const attrList = createAttrListFromStructName(name);
		setAttributeList(attrList);
	}

	const handleSetAttribute = (attr) => {
		setAttribute(attr);
		onChange(attr);
	}

	return (
		<Stack spacing={1}>
			<SelectDropdown
				id={"updatedByStruct"}
				label={"Attribute"}
				value={structuralName}
				options={structuralNameList}
				onChange={handleSetStructuralName}
				fullWidth
			/>
			<ConditionalRenderer shouldRenderChildren={structuralName !== ""}>
				<SelectDropdown
					id={"updatedByStruct"}
					label={"Attribute"}
					value={attribute}
					options={attributeList}
					onChange={handleSetAttribute}
					fullWidth
				/>
			</ConditionalRenderer>
		</Stack>
	);
}

export default AttributeLookup;