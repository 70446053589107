import React from 'react';
import { Typography, List } from '@mui/material';
import { useDrop } from 'react-dnd';
import { DraggableItem } from './DraggableItem';
import { DroppableItem } from './DroppableItem';

// Droppable List component
export const DroppableList = ({
	title,
	itemList,
	moveItem,
	listType,
	itemType
}) => {
	const [{ isOver }, drop] = useDrop({
		accept: itemType,
		hover(item, monitor) {
			if (item.dragListType !== listType) {
				moveItem(item.index, 0, item.dragListType, listType);
				item.index = 0;
				item.dragListType = listType;
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	return (
		<div style={{ width: '100%' }}>
			<div style={{ padding: '16px' }}>
				<Typography variant="h6">{title}</Typography>
				<List
					ref={drop}
					sx={{
						minHeight: 200,
						border: '1px solid #ccc',
						padding: 2,
						backgroundColor: isOver ? '#a8ffc8' : '#ffffff',
					}}>
					{itemList.length === 0 ? (
						<DroppableItem
							index={0} // Empty list means first position
							listType={listType}
							onDropItem={moveItem}
							itemType={itemType}
						/>
					) : (
						itemList.map((item, index) => (
							<React.Fragment key={item.id}>
								<DraggableItem
									index={index}
									listItem={item}
									moveItem={moveItem}
									listType={listType}
									itemType={itemType}
								/>
							</React.Fragment>
						))
					)}
				</List>
			</div>
		</div>
	);
};