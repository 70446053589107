

export const convertSiteLocationDataToServerFormat = (locationData) => {
	const mappedSiteLocationData = {
		id: locationData.id,
		name: locationData.name,
		overrideSiteName: locationData.locationProperties.overrideSiteName,
		overrideProjectName: locationData.locationProperties.overrideProjectName,
		projectDescription: locationData.locationProperties.projectDescription,
		siteDescription: locationData.locationProperties.siteDescription,
		areaMeasurement: locationData.locationProperties.areaMeasurement,
		linearUnits: locationData.locationProperties.linearUnits,
		areaUnits: locationData.locationProperties.areaUnits,
		volumeUnits: locationData.locationProperties.volumeUnits,
		currencyUnits: locationData.locationProperties.currencyUnits,
		description: locationData.locationProperties.description,
		classification: locationData.locationProperties.classification,
		mapMarker: locationData.locationProperties.mapMarker,
		add1: locationData.locationProperties.address.add1,
		add2: locationData.locationProperties.address.add2,
		add3: locationData.locationProperties.address.add3,
		townCity: locationData.locationProperties.address.townCity,
		county: locationData.locationProperties.address.county,
		country: locationData.locationProperties.address.country,
		postcode: locationData.locationProperties.address.postcode,
		lat: locationData.locationProperties.address.lat,
		lng: locationData.locationProperties.address.lng,
	};

	return mappedSiteLocationData;
}