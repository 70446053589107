import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AllowedActions } from '../constants/Constants.js';
import {
	SBDatePicker,
	SelectDropdown,
	BasicTxtField
} from './ui/';



class UserSubActionManager extends Component {
	renderSubAction() {
		const { action, roles, selectedSubAction, onSubActionChange } = this.props;

		switch (action) {
			case AllowedActions.SET_ROLE_DESCRIPTION:
				return (
					<BasicTxtField
						id={"roleDescription"}
						label="Role Description"
						value={selectedSubAction}
						onChange={(value, id) => onSubActionChange(value)}
						errors={[]}
						fullWidth
						disabled={false}
					/>
				)

			case AllowedActions.REMOVE_ACCESS:
				return null;

			case AllowedActions.SET_ACCESS_EXPIRATION:
				return (
					<SBDatePicker
						label="Access Expiration"
						date={selectedSubAction}
						onDateChange={onSubActionChange}
					/>
				)

			case AllowedActions.ADD_ROLE:
			case AllowedActions.REMOVE_ROLE:
				return (
					<SelectDropdown
						id={"role"}
						value={selectedSubAction}
						options={roles}
						onChange={onSubActionChange}
						fullWidth
					/>
				);

			default:
				return null;
		}
	}

	render() {
		return (
			<>
				{this.renderSubAction()}
			</>
		);
	}
}

UserSubActionManager.propTypes = {
	action: PropTypes.string.isRequired,
	roles: PropTypes.arrayOf(PropTypes.shape()),
	selectedSubAction: PropTypes.string.isRequired,
	onSubActionChange: PropTypes.func.isRequired
};

UserSubActionManager.defaultProps = {
	roles: []
};

export default UserSubActionManager;